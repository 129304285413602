import { FormControlLabel, Switch } from '@material-ui/core';
import Settings from 'models/Settings';
import * as React from 'react';
import { handleCheckboxChange, handleCheckboxChangeAndSave } from 'utils/FormUtils';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

interface FeeOptionPriceInclusionToggleProps {
  settings: Settings
}

export default class FeeOptionPriceInclusionToggle extends ObserverComponent<FeeOptionPriceInclusionToggleProps> {
  get isWorkingOnACopy() {
    const { settingsStore } = this.context;
    return this.props.settings !== settingsStore.settings;
  }

  _render() {
    const { settingsStore } = this.context;
    const { settings } = this.props;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={settings.areFeesIncludedInItemPrice}
            onChange={this.isWorkingOnACopy
              ? handleCheckboxChange(settings, 'areFeesIncludedInItemPrice')
              : handleCheckboxChangeAndSave(settings, 'areFeesIncludedInItemPrice', settingsStore)
            }
          />
        }
        label={i18n.t('Include project fees in task prices.')}
      />
    );
  }
}