import { remove } from 'lodash';
import { computed, observable } from "mobx";
import Dialog from "models/Dialog";
import BaseStore from './BaseStore';

export default class DialogsStore extends BaseStore {
  @observable visibleDialogs: Dialog[] = [];

  @computed get topMostDialog(): Dialog {
    const visibleDialogs = this.visibleDialogs;
    return this.visibleDialogs.length > 0 ? visibleDialogs[0] : null;
  }

  showDialog(dialog: Dialog) {
    if (!this.visibleDialogs.includes(dialog)) {
      this.visibleDialogs.unshift(dialog);
    } else {
      //hack... sometimes a visible dialog needs a reset from open=false to open=true
      remove(this.visibleDialogs, visibleDialog => visibleDialog === dialog);
      setTimeout(() => this.visibleDialogs.push(dialog), 500);
    }
  }

  hideDialog(dialogId: ModelId) {
    remove(this.visibleDialogs, visibleDialog => visibleDialog.id === dialogId);
  }
}
