
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { getFeetPortion, getNumInchesPortion } from 'utils/MeasurementFormatter';
import { InputNumberFormatFeet, InputNumberFormatInches } from 'utils/NumberFormatter';
import i18n from 'utils/i18n';
import TextField from '../TextField/TextField';

const styles = require('./FeetInchesField.module.scss');

interface FeetInchesFieldProps {
  shouldFocusOnMount?: boolean,
  value: number,
  onChange: (value: number) => void
  onConfirm?: () => void,
  nextTabItemRef?: any, // ref
  className?: string,
}

interface FeetInchesFieldState {
  feet: number,
  inches: number
}

export default class FeetInchesField extends ObserverComponent<FeetInchesFieldProps, FeetInchesFieldState> {
  inchesRef;

  state = {
    feet: 0,
    inches: 0
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      feet: getFeetPortion(value),
      inches: getNumInchesPortion(value),
    })
  }

  onChangeFeet = ({ target: { value } }: { target: { value: number } }) => {
    const prevValue = this.props.value || 0;

    const newValue = (value || 0) + getNumInchesPortion(prevValue) / 12;// + getFractionPortion(prevValue).

    this.setState({
      feet: value
    });

    const { onChange } = this.props;
    onChange(newValue);
  }

  onChangeInches = ({ target: { value: inches } }: { target: { value: number } }) => {
    const prevValue = this.props.value || 0;

    const newValue = getFeetPortion(prevValue) + (inches || 0) / 12;// + getFractionPortion(prevValue).

    this.setState({
      inches
    });

    const { onChange } = this.props;
    onChange(newValue);
  }

  onFeetKeyDown = (event) => {
    if (event.key === '-' || event.key === 'Enter' || event.key === 'Tab') {
      const domInput = ReactDOM.findDOMNode(this.inchesRef).querySelector('input');
      domInput.focus();
      document.execCommand("selectall", null);
      event.preventDefault();
      event.stopPropagation();
    }
  }
  onInchesKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.props.onConfirm?.();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  _render() {
    const { feet, inches } = this.state;
    const { shouldFocusOnMount, className } = this.props;

    return (
      <div className={className + ' ' + styles.root}>

        {/* Could change to combobox but too complicated for now */}
        <TextField
          className={styles.textField + ' ' + styles.feetInput}
          label={i18n.t('Feet')}
          onChange={this.onChangeFeet}
          onKeyDown={this.onFeetKeyDown}
          value={feet}
          shouldFocusOnMount={shouldFocusOnMount}
          shouldSelectAllOnFocus
          InputProps={{
            inputComponent: InputNumberFormatFeet,
          }}
          inputProps={{
            size: 5,
          }}
        />

        <div className={styles.dash}>
          —
        </div>

        <TextField
          ref={ref => this.inchesRef = ref}
          className={styles.textField + ' ' + styles.inchesInput}
          label={i18n.t('Inches')}
          onChange={this.onChangeInches}
          onKeyDown={this.onInchesKeyDown}
          value={inches}
          shouldSelectAllOnFocus
          InputProps={{
            inputComponent: InputNumberFormatInches,
          }}
          inputProps={{
            size: 5,
          }}
        />
      </div>
    )
  }
}