
import ObserverComponent from 'components/common/ObserverComponent';
import { DrawToolType } from 'constants/DrawToolType';
import { throttle } from 'lodash';
import { action } from 'mobx';
import CountPoint from 'models/CountPoint';
import Point from 'models/Point';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { cursorToLocalPoint } from 'utils/Coords';
import { drawToolMouseMoveThrottledBase, MOUSE_MOVE_THROTTLE_TIME, segmentsNodeNameByType } from 'utils/DrawToolsUtils';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { getSafe } from 'utils/Utils';

export default class AddCountPointTool extends ObserverComponent {
  svgTag: SVGSVGElement;

  createLeafEdgeCaseParentGroup = (leafNode: TreeNode, batch: WriteBatch) => {
    const { treeNodesStore, drawToolsStore } = this.context;
    const { selectedTool, selectedCountToolMeasurement } = drawToolsStore;

    const parentNode = new TreeNode(this.context);
    parentNode._name = { ...leafNode._name };
    parentNode.isExpanded = true;

    const countParentNode = this.createParentGroup(parentNode, batch);
    treeNodesStore.toggleNodeMeasurements([selectedCountToolMeasurement], true, countParentNode, false, batch);

    treeNodesStore.batchAddEditItem(parentNode, batch);
    treeNodesStore.appendNode(
      parentNode,
      leafNode.parent,
      undefined,
      batch,
    );

    treeNodesStore.appendNode(
      leafNode,
      parentNode,
      undefined,
      batch,
    );

    treeNodesStore.appendNode(
      countParentNode,
      parentNode,
      undefined,
      batch,
    );

    // move leaf measurement to parent node
    const mv = leafNode.ownMeasurementValuesArray.slice(0);
    leafNode.ownMeasurementValues.clear();
    treeNodesStore.applyMeasurementValues(parentNode, mv, false, batch);

    treeNodesStore.selectedTreeNode = parentNode;

    return countParentNode;
  }

  createParentGroup = (parentNode: TreeNode, batch: WriteBatch) => {
    const { treeNodesStore, drawToolsStore, shapesStore } = this.context;

    const { selectedCountToolMeasurement } = drawToolsStore;

    const countParentNode = new TreeNode(this.context);
    countParentNode._name = { ...selectedCountToolMeasurement._name };
    countParentNode.isExpanded = false;
    treeNodesStore.batchAddEditItem(countParentNode, batch);
    treeNodesStore.appendNode(
      countParentNode,
      parentNode,
      undefined,
      batch,
    );

    return countParentNode;
  }

  componentDidMount() {
    this.svgTag.addEventListener('click', this.mouseDown);
    window.document.addEventListener('mousemove', this.mouseMove);
    window.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.svgTag.removeEventListener('click', this.mouseDown);
    window.document.removeEventListener('mousemove', this.mouseMove);
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent) => {
    const { drawToolsStore } = this.context;

    if (e.key.toLowerCase() === 'z' && (e.metaKey || e.ctrlKey)) {
      this.undoLastPoint();
    } else if (e.key === 'Escape') {
      drawToolsStore.selectedTool = DrawToolType.Select;
    }
  }

  undoLastPoint = () => {

  }

  @action.bound
  mouseDown(ev: MouseEvent) {
    const { drawToolsStore, commonStore, treeNodesStore, shapesStore } = this.context;
    const { selectedTool, selectedCountToolMeasurement } = drawToolsStore;
    if (
      !selectedCountToolMeasurement ||
      ev.ctrlKey ||
      ev.button || // button is either 0 for left click or undefined for touch event
      shapesStore.isDragPanningOnDrawing ||
      getSafe(() => (ev.target as HTMLElement).tagName === 'INPUT') ||
      (drawToolsStore.isDrawingWithTouch && drawToolsStore.segmentsGroupBeingAdded)
    ) {
      return;
    }

    const batch = firestoreBatch();

    drawToolsStore.isDrawingWithTouch = ev.type == 'touchstart';

    // not that big, and this allows count to refresh instantly in measurements sidebar
    //commonStore.isBigUpdateOngoing = true;

    const { rootNode, selectedTreeNode } = treeNodesStore;

    const cursorPt = drawToolsStore.lastMousePt || cursorToLocalPoint(ev, this.svgTag);

    if (selectedTreeNode !== rootNode && !selectedTreeNode.hasChildren) {
      selectedTreeNode.isExpanded = false;
    }

    let parentNode = selectedTreeNode || rootNode;

    // cases:
    // new empty group: create subgroup
    // group with single measurement = selected measurement, use group directly
    // group with child node that has single measurement = selected measurement, use child node
    // leaf group: create parent group, move ownMeasurementValues to parent group, create subgroup of new parent

    if (!parentNode.areChildrenAllowed) {
      // edge case, we are in a leaf node that has measurements, and we need the count to bubble up to that leaf
      // need to create new container group to contain both selected node and count container node, and move measurements to new node
      if (selectedTreeNode.unitMeasurements.find(m => m.id === selectedCountToolMeasurement?.id)) {
        parentNode = this.createLeafEdgeCaseParentGroup(selectedTreeNode, batch);
      } else {
        // any parent node will do
        while (!parentNode.areChildrenAllowed) {
          parentNode = parentNode.parent;
        }
      }
    }

    if (!drawToolsStore.segmentsGroupBeingAdded || ![treeNodesStore.selectedTreeNode, ...treeNodesStore.selectedTreeNode.descendants].includes(drawToolsStore.segmentsGroupBeingAdded)) {
      // check if we are already in a suitable parent group
      if (
        parentNode.unitMeasurements.length === 1 && selectedCountToolMeasurement?.id === parentNode.unitMeasurements[0].id /*||
        isEmpty(parentNode.measurements) && selectedCountToolMeasurement && !parentNode.isRootNode*/
      ) {
        drawToolsStore.segmentsGroupBeingAdded = parentNode;
      } else {
        drawToolsStore.segmentsGroupBeingAdded = (
          parentNode.descendants.find(node => (
            node.unitMeasurements.length === 1 && selectedCountToolMeasurement?.id === node.unitMeasurements[0].id
          )) || this.createParentGroup(parentNode, batch)
        );
      }

      // could be used to use formula in parent node instead of individual count of 1 in children
      /*
      treeNodesStore.toggleNodeMeasurements([selectedCountToolMeasurement], true, drawToolsStore.segmentsGroupBeingAdded, false, batch);
      const unitMeasurementValue = drawToolsStore.segmentsGroupBeingAdded.ownMeasurementValues.get(selectedCountToolMeasurement.id);
      unitMeasurementValue.__formula = { en: `${COUNT_FUNCTION}()` };
      */
    }

    // ensure count measure is visible
    document.querySelectorAll(`div[id="${selectedCountToolMeasurement.id}"]`).forEach(elem => elem.scrollIntoViewIfNeeded());

    const newCountPoint = new CountPoint(this.context, [new Point(cursorPt.x, cursorPt.y)]);
    newCountPoint.point.isSnappable = false;
    shapesStore.batchAddEditItem(newCountPoint, batch);

    const countNode = new TreeNode(this.context);
    countNode._name = i18n.tAll(segmentsNodeNameByType[selectedTool], { number: drawToolsStore.segmentsGroupBeingAdded.children.length + 1 });
    countNode.isExpanded = false;
    countNode.shape = newCountPoint;

    treeNodesStore.appendNode(
      countNode,
      drawToolsStore.segmentsGroupBeingAdded,
      undefined,
      batch,
    );

    // clear old system of count when measurement was in the child node
    treeNodesStore.toggleNodeMeasurements([selectedCountToolMeasurement], false, drawToolsStore.segmentsGroupBeingAdded, undefined, batch);

    treeNodesStore.toggleNodeMeasurements([selectedCountToolMeasurement], true, drawToolsStore.segmentsGroupBeingAdded, false, batch);

    const unitMeasurementValue = drawToolsStore.segmentsGroupBeingAdded.ownMeasurementValues.get(selectedCountToolMeasurement.id);
    unitMeasurementValue.__formula = { en: 'COUNT()' };

    treeNodesStore.batchAddEditItem(drawToolsStore.segmentsGroupBeingAdded, batch);
    treeNodesStore.batchAddEditItem(countNode, batch);

    batch.commit();

    commonStore.isBigUpdateOngoing = false;
  }


  @action.bound
  mouseMove(ev: MouseEvent) {
    this.mouseMoveThrottled(ev);
  }

  @action
  mouseMoveThrottled = throttle((ev: MouseEvent, skipLocalCoordsConversion: boolean = false) => {
    drawToolMouseMoveThrottledBase(ev, this.svgTag, this.context);

    // all of this needs to go to base function
    if (getSafe(() => ['INPUT', 'BUTTON'].includes((ev.target as HTMLElement).tagName))) {
      return;
    }

    const { snapStore, drawToolsStore, commonStore, settingsStore, userInfoStore } = this.context;
    let cursorPt = snapStore.cursorPosition;

    if (!skipLocalCoordsConversion) {
      cursorPt = cursorToLocalPoint(ev, this.svgTag);
      snapStore.cursorPosition = cursorPt;
    }

    const { snapPoint, snapLineHorizontal, snapLineVertical } = snapStore;
    let newEndPt = cursorPt;

    if (snapLineHorizontal) {
      newEndPt = new Point(
        newEndPt.x,
        snapLineHorizontal.points[1].y,
      );
    }

    if (snapLineVertical) {
      newEndPt = new Point(
        snapLineVertical.points[1].x,
        newEndPt.y,
      );
    }

    newEndPt = snapPoint?.clone?.() || newEndPt;
    newEndPt.isSnappable = false;

    drawToolsStore.lastMousePt = newEndPt;


  }, MOUSE_MOVE_THROTTLE_TIME)

  _render() {
    return (
      <g id="AddCountPointTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement) || this.svgTag} />
    );
  }
}