import ReportHeaderRow from 'components/reports/ReportHeaderRow';
import { ReportBodyRow, ReportRow, ReportRowsGroup } from 'components/reports/ReportInterfaces';
import { BuiltinCategories } from 'constants/BuiltinCategories';
import { ProvidingItemQuantityBehaviour, ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { ReportGrouping, ReportHeaderMeasurementsVisibility, ReportSortType, ReportSubtypes, ReportTasksFilter, ReportTasksVisibility, ReportTotalsGrouping, ReportTotalsVisibility } from "constants/ReportOptionsConstants";
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from "draft-js";
import { compact, flatten, isEmpty, last, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import { computedFn } from 'mobx-utils';
import { date, object, serializable } from "serializr";
import Stores from 'stores/Stores';
import { sumPrices } from 'utils/CurrencyUtil';
import { getVisibleRows, mergeTasksByProvidingItem, processTasks, reportHasOnlyOneGroup } from 'utils/ReportUtil';
import { getDefaultGeneralTermsRichText, getDefaultSignatureHtmlText, getEmptyRichText } from "utils/RichTextUtil";
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import { localized } from 'utils/localized';
import Category from './Category';
import ModelBase from "./ModelBase";
import Task from './Task';
import TreeNode from './TreeNode';
import UserColors from './UserColors';

export default class Report extends ModelBase {
  type = 'Report';
  @serializable @observable subtype = ReportSubtypes.Invoice;

  @observable @serializable tasksFilter = ReportTasksFilter.AllTasks;

  @serializable @observable name = '';
  @serializable @observable number = ''; // string in case someone wants to use letters
  @serializable @observable clientName = '';
  @serializable @observable clientAddress = '';

  // also present in User object to facilitate viewing users contact info

  // allows to have more than one company for now, but should be moved to user as a 
  // list of companies
  // cannot use real undefined because not observable, cannot use empty string
  // because will not be able to completely erase the user value to enter new value
  @serializable @observable companyEmail = 'undefined';
  @serializable @observable street = 'undefined';
  @serializable @observable city = 'undefined';
  @serializable @observable province = 'undefined';
  @serializable @observable postalCode = 'undefined';
  @serializable @observable phoneNumber = 'undefined';
  @serializable @observable website = 'undefined';
  @serializable @observable companyName = 'undefined';
  @serializable @observable other = 'undefined';

  @serializable(object(UserColors)) @observable _logoColors: UserColors = new UserColors('undefined');

  @computed get logoColors() {
    if (this._logoColors.color1 === 'undefined') {
      // yish
      return this.stores.userInfoStore.user?.logoColors;
    }

    return this._logoColors;
  }

  set logoColors(logoColors) {
    this._logoColors = logoColors;
  }


  // cannot use real undefined because not observable, cannot use empty string
  // to differentiate between not set yet and forcibly hidden
  @serializable @observable preparedBy = 'undefined';

  @serializable @observable shouldShowTerms = true;
  @serializable @observable shouldShowIntro = false;
  @serializable @observable shouldShowSignature = true;
  @serializable @observable shouldShowNotes = false;
  @serializable @observable shouldShowPhotos = false;
  @serializable @observable shouldHighlightPriceChanges = false;

  @serializable @observable shouldShowReportTimeTotals = false;

  @serializable @observable shouldShowCustomHtmlInReportFooter = true;
  @serializable @observable customHtmlInReportFooterMarginTop = 0;

  @computed get isGroupedByTreeNodeFirst() {
    return (
      this.grouping === ReportGrouping.TreeNodeThenCategory || 
      this.grouping === ReportGrouping.TreeNode
    );
  }

  @serializable @observable _highlightPriceChangeDateMiliseconds = 0;

  @computed get highlightPriceChangeDateMiliseconds(): Date {
    return this._highlightPriceChangeDateMiliseconds
      ? new Date(this._highlightPriceChangeDateMiliseconds)
      : new Date((new Date().getTime()) - 30 * 24 * 60 * 60 * 1000);
  }
  set highlightPriceChangeDateMiliseconds(value: Date) {
    this._highlightPriceChangeDateMiliseconds = value?.getTime() || 0;
  }

  @serializable @observable shouldShowProvidingItemsIcons = true;

  // removing serializable so that date resets to current date when reloading page
  @serializable(date()) @observable date: Date = new Date();
  @serializable(date()) @observable _dueDate: Date = new Date();

  @serializable @observable areDatesLocked = false;

  @computed get dueDate(): Date {
    return this._dueDate.getTime() - this.date.getTime() > 0
      ? this._dueDate
      : new Date(this.date.getTime());
  }

  set dueDate(value) {
    this._dueDate = value;
  }

  @localized _generalTerms = '';
  @computed get generalTerms() {
    return this.__generalTerms[i18n.language] || getDefaultGeneralTermsRichText(i18n.language);
  }

  set generalTerms(value) {
    this._generalTerms = value;
  }
  @computed get hasGeneralTerms() {
    return this.generalTerms !== getDefaultGeneralTermsRichText(i18n.language) && this.generalTerms !== getEmptyRichText();
  }

  @localized _generalTerms2 = '';
  @computed get generalTerms2() {
    return (!this.__generalTerms2[i18n.language])
      ? convertToHTML(convertFromRaw(JSON.parse(this.generalTerms))) // convert
      : this._generalTerms2;
  }

  set generalTerms2(value: string) {
    this._generalTerms2 = value;
    if (value && this.generalTerms) {
      this._generalTerms = '';
    }
  }

  @localized _signatureText = '';
  @computed get signatureText() {
    return this.__signatureText[i18n.language] || getDefaultSignatureHtmlText(i18n.language);
  }

  set signatureText(value: string) {
    this._signatureText = value;
    if (value && value === getDefaultSignatureHtmlText(i18n.language)) {
      this.__signatureText[i18n.language] = '';
    }
  }

  @localized introText = '';

  @localized description = '';

  @serializable @observable grouping = ReportGrouping.TreeNodeThenCategory;
  @serializable @observable headerMeasurementsVisibility = ReportHeaderMeasurementsVisibility.Hidden;

  @serializable @observable sortBy = ReportSortType.NoSort;

  @serializable @observable treeMaxDepth = 999;

  @serializable @observable tasksVisibility = ReportTasksVisibility.ShowQuantities;
  @serializable @observable totalsGrouping = ReportTotalsGrouping.DetailedWithoutHours;

  @observable _totalsVisibility = ReportTotalsVisibility.Task;
  @serializable @computed get totalsVisibility() {
    return (
      reportHasOnlyOneGroup(this) &&
      this._totalsVisibility === ReportTotalsVisibility.GroupNotSubgroup
    )
      ? ReportTotalsVisibility.Group
      : this._totalsVisibility;
  }
  set totalsVisibility(value: ReportTotalsVisibility) {
    this._totalsVisibility = value;
  }

  @computed get shouldShowTasks() {
    return this.tasksVisibility !== ReportTasksVisibility.Hidden;
  }

  @computed get shouldShowQuantities() {
    return (
      this.tasksVisibility === ReportTasksVisibility.ShowQuantities ||
      this.hasTasksWithForcedQuantityDisplay
    );
  }

  @computed get shouldShowTaskCost() {
    return this.totalsVisibility === ReportTotalsVisibility.Task;
  }
  @computed get shouldShowTaskUnitPrice() {
    return this.shouldShowTaskCost && this.shouldShowQuantities;
  }

  // Needs to be rewritten has hierarchical structure then converted to flat, instead of directly as flat
  // this will simplify totals a lot
  @computed get reportRows(): ReportRowsGroup[] {
    const { categoriesStore, treeNodesStore, userInfoStore } = this.stores;
    const { grouping, treeMaxDepth, sortBy: sortType, shouldShowTasks, tasksFilter } = this;
    const { shouldShowAncestorsInReportTitles } = userInfoStore.user || {};
    // move default to store

    // pourrait mettre option pour pas skip vue d'ensemble

    // Mettre autre options, pour juste metttre les noeuds avec des ownTasks

    // Style: when treenode is first, use dark background for node that has tasks, then borders for category
    // when category  is first, use dark background for category, then light background for all node

    // even if we don't group by treenode,
    //const groupTypes = [TreeNode, Category];

    let groupTypes = [];
    let reportRows: ReportRow[] = [];

    let reportHeaderRows: ReportHeaderRow[] = [];

    const generalCategory = categoriesStore.getItem(BuiltinCategories.General);
    const allNodes = treeNodesStore.allNodes.filter(
      node => !node.shouldExcludeFromReports && node.hasNonEmptyTasks
    );

    const { rootNode } = treeNodesStore;

    if (!rootNode) {
      return [];
    }

    switch (grouping) {
      case ReportGrouping.None:
        groupTypes = [];
        break;
      case ReportGrouping.CategoryThenTreeNode:
        groupTypes = [Category, TreeNode];
        break;
      case ReportGrouping.TreeNodeThenCategory:
        groupTypes = [TreeNode, Category];
        break;
      case ReportGrouping.Category:
        groupTypes = [Category];
        break;
      case ReportGrouping.TreeNode:
        groupTypes = [TreeNode];
        break;
    }

    if (isEmpty(groupTypes)) {
      const headerRow = new ReportHeaderRow(this.stores);
      reportHeaderRows.push(headerRow);

      headerRow.indentLevel = 0;
      headerRow.name = shouldShowAncestorsInReportTitles ? treeNodesStore.getPath(rootNode) : rootNode.name;
      headerRow.isEmphasized = true;
      headerRow.tasks = mergeTasksByProvidingItem(rootNode.tasks, sortType, tasksFilter);

      const reportBodyRows = headerRow.tasks
        .map(task => new ReportBodyRow(task, 0));

      reportRows = [headerRow, ...reportBodyRows];
    } else {
      switch (groupTypes[0]) {
        case TreeNode:
          const isRootNodeVisible = !isEmpty(processTasks(rootNode.ownTasks, sortType, tasksFilter, true));
          reportRows = flatten(
            allNodes
              .filter(node => (
                (!node.isRootNode || !isEmpty(node.ownTasks)) &&
                node.ancestors.length <= treeMaxDepth &&
                !isEmpty(mergeTasksByProvidingItem(node.tasks, sortType, tasksFilter, true))
              )).map(node => {
                const indentLevel = (
                  node.isRootNode
                    ? 0
                    : (node.ancestors.length - (isRootNodeVisible ? 0 : 1))
                );

                const shouldIncludeChildTasks = node.ancestors.length === treeMaxDepth;

                const categories = shouldIncludeChildTasks
                  ? node.nonEmptyTaskCategories
                  : node.nonEmptyOwnTaskCategories;

                const tasks = shouldIncludeChildTasks
                  ? node.tasks
                  : node.ownTasks;

                // If treenodes is only group (no categories) or we skip general category
                const isLeafRow = groupTypes.length === 1 || (
                  groupTypes.length === 2 &&
                  categories.length === 1 &&
                  categories[0] === generalCategory
                );

                const headerRow = new ReportHeaderRow(this.stores);
                reportHeaderRows.push(headerRow);

                headerRow.indentLevel = indentLevel;
                headerRow.name = (shouldShowTasks && shouldShowAncestorsInReportTitles) ? treeNodesStore.getPath(node) : node.name;
                headerRow.measurementsToDisplay = node.measurementsToDisplay;
                headerRow.node = node;
                headerRow.isEmphasized = node.isRootNode || node?.parent?.isRootNode;

                // Next Report group type
                if (isLeafRow) {
                  headerRow.tasks = mergeTasksByProvidingItem(tasks, sortType, tasksFilter);

                  const parentHeaderRows = reportHeaderRows.filter(previousHeaderRow => headerRow?.node?.ancestors?.includes?.(previousHeaderRow.node));
                  parentHeaderRows.forEach(parentHeaderRow => parentHeaderRow.tasks = [...parentHeaderRow.tasks, ...headerRow.tasks]);

                  const reportBodyRows = headerRow.tasks
                    .map(task => new ReportBodyRow(task, indentLevel));

                  return [headerRow, ...reportBodyRows];
                }

                // possible recursive instead?
                const subHeaderRows = flatten(categories.map(category => {
                  const categoryHeaderRow = new ReportHeaderRow(this.stores);
                  reportHeaderRows.push(categoryHeaderRow);
                  categoryHeaderRow.isSemiEmphasized = true;
                  categoryHeaderRow.indentLevel = indentLevel + 1;
                  categoryHeaderRow.name = category.name;
                  categoryHeaderRow.tasks = mergeTasksByProvidingItem(tasks.filter(task => task.category === category), sortType, tasksFilter);

                  headerRow.tasks = [...(headerRow.tasks || []), ...categoryHeaderRow.tasks];

                  const reportBodyRows = categoryHeaderRow.tasks
                    .map(task => new ReportBodyRow(task, categoryHeaderRow.indentLevel));

                  return [categoryHeaderRow, ...reportBodyRows];
                }));

                const parentHeaderRows = reportHeaderRows.filter(previousHeaderRow => headerRow?.node?.ancestors?.includes?.(previousHeaderRow.node));
                parentHeaderRows.forEach(parentHeaderRow => parentHeaderRow.tasks = [...parentHeaderRow.tasks, ...headerRow.tasks]);

                return [headerRow, ...subHeaderRows];
              }));
          break;
        case Category:
          reportRows = flatten(
            rootNode.nonEmptyTaskCategories
              .map(category => {
                const headerRow = new ReportHeaderRow(this.stores);
                headerRow.category = category;
                reportHeaderRows.push(headerRow);
                const isRootNodeVisible = !isEmpty(processTasks(rootNode.ownTasksByCategoryId[category.id] || [], sortType, tasksFilter, true));

                headerRow.indentLevel = 0;
                headerRow.name = category.name;
                headerRow.isEmphasized = shouldShowTasks || groupTypes.length > 1;

                // Category is only group (no group by treenode)
                const isLeafRow = groupTypes.length === 1

                if (isLeafRow) {
                  headerRow.tasks = mergeTasksByProvidingItem(rootNode.tasksByCategoryId[category.id] || [], sortType, tasksFilter);
                  const reportBodyRows = headerRow.tasks
                    .map(task => new ReportBodyRow(task, headerRow.indentLevel));

                  return [headerRow, ...reportBodyRows];
                }

                // group is Category then TreeNode
                const subHeaderRows = allNodes
                  .filter(node => {
                    const tasks = processTasks(node.tasksByCategoryId[category.id] || [], undefined, tasksFilter);
                    const ownTasks = node.ownTasksByCategoryId[category.id] || [];
                    /* const numChildNodesWithTasks = size(
                       node.children.filter(childNode => !isEmpty(childNode.tasksByCategoryId[category.id]))
                     );*/
                    return (
                      node.ancestors.length <= treeMaxDepth &&
                      !isEmpty(tasks) &&
                      (!node.isRootNode || !isEmpty(ownTasks)) // &&
                      //(!isEmpty(ownTasks) || numChildNodesWithTasks > 1)
                    );
                  }).map(node => {
                    const shouldIncludeChildTasks = node.ancestors.length === treeMaxDepth;

                    const subHeaderRow = new ReportHeaderRow(this.stores);
                    subHeaderRow.category = category;
                    reportHeaderRows.push(subHeaderRow);
                    subHeaderRow.indentLevel = (
                      headerRow.indentLevel +
                      (isRootNodeVisible ? 1 : 0) +
                      (node.isRootNode ? 0 : node.ancestors.length + 1)
                    );

                    subHeaderRow.name = shouldShowAncestorsInReportTitles ? treeNodesStore.getPath(node) : node.name;
                    subHeaderRow.measurementsToDisplay = node.measurementsToDisplay;
                    subHeaderRow.node = node;

                    subHeaderRow.tasks = mergeTasksByProvidingItem(
                      shouldIncludeChildTasks
                        ? (node.tasksByCategoryId[category.id] || [])
                        : (node.ownTasksByCategoryId[category.id] || []),
                      sortType,
                      tasksFilter
                    );

                    headerRow.tasks = [...(headerRow.tasks || []), ...subHeaderRow.tasks];

                    const parentHeaderRows = reportHeaderRows.filter(previousHeaderRow => (
                      subHeaderRow?.node?.ancestors?.includes?.(previousHeaderRow.node) &&
                      previousHeaderRow.category === category
                    ));

                    parentHeaderRows.forEach(parentHeaderRow => parentHeaderRow.tasks = [...parentHeaderRow.tasks, ...subHeaderRow.tasks]);

                    const reportBodyRows = subHeaderRow.tasks
                      .map(task => new ReportBodyRow(task, subHeaderRow.indentLevel));

                    return [subHeaderRow, ...reportBodyRows];
                  });

                return flatten([headerRow, ...subHeaderRows]);
              }));
          break;
      }
    }


    const reportRowsByRowType: ReportRowsGroup[] = [];

    // create blocks of tbody or thead with consecutive rows of same type
    reportRows
      .forEach(row => {
        const lastGroup = last(reportRowsByRowType);
        if (!lastGroup || last(lastGroup.rows).type !== row.type) {
          reportRowsByRowType.push({ type: row instanceof ReportHeaderRow ? 'thead' : 'tbody', rows: [row] });
        } else {
          lastGroup.rows.push(row);
        }
      });


    return reportRowsByRowType;
  }

  // Applies the late filters that don't affect total, only visibility
  @computed get visibleReportRows(): ReportRowsGroup[] {
    const unmergedGroups = this.reportRows
      .map(rowGroup => (
        Object.assign(
          {},
          rowGroup,
          {
            rows: getVisibleRows(rowGroup.rows, this)
          })
      )).filter(rowsGroup => rowsGroup?.rows?.length !== 0);


    // merge consecutive groups of same type (somewhat duplicate to reportRows last lines)
    const mergedGroups = [] as ReportRowsGroup[];


    return unmergedGroups;

    // Can be really bad if merging too many thead "keep-together" in one big, because will not break to different pages
    // disabling, but maybe was useful for something else??
    unmergedGroups.forEach(group => {
      const lastGroup = last(mergedGroups);
      if (!lastGroup || lastGroup.type !== group.type) {
        mergedGroups.push(group);
      } else {
        lastGroup.rows = [...lastGroup.rows, ...group.rows];
      }
    });

    return mergedGroups;
  }

  @computed get visibleReportBodyRows(): ReportBodyRow[] {
    return compact(this.visibleReportRows.map(rowsGroup => rowsGroup.type === 'tbody' && rowsGroup.rows.filter(row => row.task)).flat());
  }

  // VERY FRAGILE FOR SOMETHING AS IMPORTANT AS CALCULATING PROJECT TOTAL!!!!
  // tasks as listed in report, instead of the ones create in projects (almost the same, but not exactly (varies by a few cents total)
  @computed get tasks(): Task[] {
    return this.reportRows.map(i => i.rows.map(j => j.task)).flat().filter(i => i);
  }

  @computed get hasTasksWithForcedQuantityDisplay() {
    return this.tasks.find(task => task.providingItem?.reportQuantityBehaviour === ProvidingItemQuantityBehaviour.AlwaysShow)
  }

  constructor(stores: Stores, id: ModelId, subtype: ReportSubtypes, name: string) {
    super(stores);
    this.id = id;
    this.subtype = subtype;
    this.name = name;
  }


  // Duplicate from TreeNode, but here we calculate according to report tasks, instead of project tasks
  getSubtotal = (
    subtype: ProvidingItemSubtype = null,
    category: Category = null,
    shouldIncludeFees = this.stores.commonStore.shouldIncludeFeesInTasks, // fees only included in report page....
  ) => this.computedGetSubtotal(subtype, category, shouldIncludeFees);

  // computed function doesn't allow for default params
  // CHECK ABOVE for params default values!
  computedGetSubtotal = computedFn((
    subtype: ProvidingItemSubtype,
    category: Category,
    shouldIncludeFees,
  ): number => {
    return sumPrices(
      this.tasks.filter(task => (
        (!subtype || getSafe(() => task.providingItem.subtype === subtype)) &&
        (!category || getSafe(() => task.category === category))
      ))
        .map(task => shouldIncludeFees ? task.priceWithFees : task.price)
    );
  })

  @computed get subtotalLabourBeforeFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Labour, null, false);
  }

  @computed get subtotalMaterialBeforeFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Material, null, false);
  }

  @computed get subtotalBeforeFees(): number {
    return this.getSubtotal(null, null, false);
  }

  @computed get subtotalLabourWithFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Labour, null, true);
  }

  @computed get subtotalMaterialWithFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Material, null, true);
  }

  @computed get subtotalWithFees(): number {
    const { settingsStore, totalsStore } = this.stores;
    return settingsStore.settings?.areFeesIncludedInItemPrice
      ? this.subtotalLabourWithFees + this.subtotalMaterialWithFees
      : this.subtotalBeforeFees + totalsStore.feesTotal;
  }

  @computed get subtotalLabour(): number {
    return this.getSubtotal(ProvidingItemSubtype.Labour);
  }

  @computed get subtotalMaterial(): number {
    return this.getSubtotal(ProvidingItemSubtype.Material);
  }

  @computed get subtotal(): number {
    return this.getSubtotal();
  }

  @computed get subtotalHours(): number {
    return sumBy(this.tasks, task => task.hours);
  }

}