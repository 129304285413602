
import { Button } from '@material-ui/core';
import ObserverComponent from 'components/common/ObserverComponent';
import { DrawToolType } from 'constants/DrawToolType';
import { Rectangle } from 'models/Rectangle';
import * as React from 'react';
import { findCentroid } from 'utils/GeometryUtils';
import i18n from 'utils/i18n';
import { getSafe } from 'utils/Utils';
import uuidv4 from 'uuid/v4';

const styles = require('./CropRectangleComponent.module.scss');

interface CropRectangleComponentProps {
  viewBoxRectangle: Rectangle,
}

export default class CropRectangleComponent extends ObserverComponent<CropRectangleComponentProps> {
  confirmCrop = () => {
    const { shapesStore, backgroundImagesStore, drawToolsStore, treeNodesStore } = this.context;
    const { backgroundImageCropRectangle } = shapesStore;
    const { rootNode } = treeNodesStore;
    const { backgroundImage } = rootNode;

    const newBackgroundImage = backgroundImage.clone(this.context, uuidv4());
    newBackgroundImage.cropRectangle = backgroundImageCropRectangle;
    backgroundImagesStore.addEditItem(newBackgroundImage);

    rootNode.backgroundImageId = newBackgroundImage.id;
    treeNodesStore.addEditItem(rootNode, true, ['backgroundImageId']);

    drawToolsStore.selectedTool = DrawToolType.Select;

    shapesStore.zoomController.zoomCompletelyOut();
  }

  _render() {
    // duplicate
    const { shapesStore } = this.context;
    const { backgroundImageCropRectangle } = shapesStore;

    if (!backgroundImageCropRectangle) {
      return null;
    }

    const shapeScale = getSafe(() => shapesStore.zoomController.observableScale || 1);


    const dialogHeight = 260;
    const dialogWidth = 300;

    const middlePt = findCentroid(backgroundImageCropRectangle.points.slice(0));
    middlePt.x -= dialogWidth / 2 / shapeScale;
    middlePt.y -= dialogHeight / 2 / shapeScale;

    const { viewBoxRectangle } = this.props;

    return (
      <g id="CropRectangle" className={styles.root}>
        <path d={viewBoxRectangle.pathString + ' ' + backgroundImageCropRectangle.pathStringReversed} className={styles.cropRectangle} />

        {shapesStore.isConfirmingBackgroundImageCrop && (
          <foreignObject width="300" height="260" x={middlePt.x} y={middlePt.y} style={{
            transform: `scale(${1 / shapeScale})`,
            transformOrigin: `${middlePt.x}px ${middlePt.y}px`,
          }}>
            <div xmlns="http://www.w3.org/1999/xhtml" className={styles.foreignContainer} >
              <div>
                {i18n.t("Cropping will create a copy of the image. The original image will still be available.")}
              </div>

              <Button variant="contained" color="primary" className={styles.actionButton} disabled={!backgroundImageCropRectangle.width || !backgroundImageCropRectangle.height} onClick={this.confirmCrop}>
                {i18n.t("Proceed with Crop")}
              </Button>
              <Button onClick={() => {
                // duplicate
                shapesStore.backgroundImageCropRectangle = new Rectangle(this.context);
                shapesStore.isCroppingBackgroundImageSecondPoint = false;
                shapesStore.isConfirmingBackgroundImageCrop = false;
              }}>
                {i18n.t("Cancel and start over")}
              </Button>
            </div>
          </foreignObject>
        )}
      </g>
    )
  }
}