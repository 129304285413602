
import Clipper from '@doodle3d/clipper-js';
import TriangleIcon from '@material-ui/icons/ChangeHistory';
import CropIcon from '@material-ui/icons/Crop';
import RectangleIcon from '@material-ui/icons/CropLandscape';
import ImageIcon from '@material-ui/icons/Image';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import MoveIcon from '@material-ui/icons/OpenWith';
import MarkerIcon from '@material-ui/icons/Room';
import AddBackgroundImageDialog from 'components/common/AddBackgroundImageDialog/AddBackgroundImageDialog';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import DrawingCopyDialog from 'components/common/DrawingCopyDialog/DrawingCopyDialog';
import { BuiltinCategories, BuiltinMeasurementCategories } from 'constants/BuiltinCategories';
import { ConfirmationBehavior } from 'constants/ConfirmationBehavior';
import { DRAWING_SCALE } from 'constants/Constants';
import { MeasurementType } from 'constants/MeasurementType';
import { NodeType } from 'constants/NodeType';
import { compact, flatten, isEmpty } from 'lodash';
import Dialog from 'models/Dialog';
import Measurement from 'models/Measurement';
import SimpleSurface from 'models/SimpleSurface';
import Surface from 'models/Surface';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import Stores from 'stores/Stores';
import uuidv4 from 'uuid/v4';
import { ArrowIcon, BasementIcon, DoorIcon, ExcavationIcon, GroupIcon, HoleIcon, InsertShapeIcon, PolygonIcon, RemoveDuplicatesIcon, RoofIcon2, SlopeIcon, WallsIcon3, WindowIcon } from '../components/common/Icons';
import { DrawToolType } from '../constants/DrawToolType';
import { isMouseInsideComponent, screenToLocalPoint } from './Coords';
import { WriteBatch } from './FirebaseInitializedApp';
import firestoreBatch from './FirestoreBatchUtil';
import { getSafe, isSafari } from './Utils';
import i18n from './i18n';

export const drawToolIconsByType = {
  [DrawToolType.Select]: <ArrowIcon />,
  [DrawToolType.Move]: <MoveIcon />,
  [DrawToolType.Wall]: <WallsIcon3 />,
  [DrawToolType.Excavation]: <ExcavationIcon />,
  [DrawToolType.Foundation]: <BasementIcon />,
  [DrawToolType.Doors]: <DoorIcon />,
  [DrawToolType.Windows]: <WindowIcon />,
  [DrawToolType.Roof]: <RoofIcon2 />,
  [DrawToolType.GeneralDraw]: <PolygonIcon />,
  [DrawToolType.GeneralCount]: <MarkerIcon />,
  [DrawToolType.PredefinedShapeRectangle]: <RectangleIcon />,
  [DrawToolType.PredefinedShapeTriangle]: <TriangleIcon />,
  [DrawToolType.BackgroundImage]: <ImageIcon />,
  [DrawToolType.BackgroundImageSetScale]: <LinearScaleIcon />,
  [DrawToolType.BackgroundImageCrop]: <CropIcon />,
  [DrawToolType.SlopeStreetView]: <SlopeIcon />,
  [DrawToolType.RemoveDuplicates]: <RemoveDuplicatesIcon />,
  [DrawToolType.Hole]: <HoleIcon />,
  [DrawToolType.GroupShapes]: <GroupIcon />,
  [DrawToolType.ImportDrawing]: <InsertShapeIcon />,
};

export const drawToolTypesByShortcutKey = (lang: LanguageKey) => lang === 'en'
  ? new Map([
    ['s', DrawToolType.Select], // escape
    ['m', DrawToolType.Move],
    ['d', DrawToolType.GeneralDraw],
    ['c', DrawToolType.GeneralCount],
    ['r', DrawToolType.PredefinedShapeRectangle],
    ['i', DrawToolType.BackgroundImage],
    ['a', DrawToolType.BackgroundImageSetScale],
    ['o', DrawToolType.BackgroundImageCrop],
    ['g', DrawToolType.GroupShapes],
    ['x', DrawToolType.ImportDrawing],
  ]) : new Map([
    ['s', DrawToolType.Select], // escape
    ['p', DrawToolType.Move],
    ['d', DrawToolType.GeneralDraw],
    ['c', DrawToolType.GeneralCount],
    ['r', DrawToolType.PredefinedShapeRectangle],
    ['i', DrawToolType.BackgroundImage],
    ['é', DrawToolType.BackgroundImageSetScale],
    ['a', DrawToolType.BackgroundImageCrop],
    ['g', DrawToolType.GroupShapes],
    ['x', DrawToolType.ImportDrawing],
  ]);

export const shortcutKeysByDrawToolTypes = (lang: LanguageKey) => new Map(Array.from(drawToolTypesByShortcutKey(lang), a => a.reverse()));

export const segmentsGroupNodeNameByType = {
  [DrawToolType.GeneralDraw]: 'Lengths',
  [DrawToolType.Wall]: 'Walls',
  [DrawToolType.Roof]: 'Roof Lines',
  [DrawToolType.Excavation]: 'Excavation Perimeter',
};

export const segmentsNodeNameByType = {
  [DrawToolType.GeneralDraw]: 'Length {{number}}',
  [DrawToolType.GeneralCount]: 'Item {{number}}',
  [DrawToolType.PredefinedShapeRectangle]: 'Length {{number}}',
  [DrawToolType.PredefinedShapeTriangle]: 'Length {{number}}',
  [DrawToolType.Wall]: 'Wall {{number}}',
  [DrawToolType.Roof]: 'Roof Line {{number}}',
  [DrawToolType.Excavation]: 'Excavation Line {{number}}',
};

export const surfaceNodeNameByType = {
  [DrawToolType.GeneralDraw]: 'Surface',
  [DrawToolType.PredefinedShapeRectangle]: 'Surface',
  [DrawToolType.PredefinedShapeTriangle]: 'Surface',
  [DrawToolType.Wall]: 'FloorOrCeilingSurface',
  [DrawToolType.Roof]: 'Roof Surface',
  [DrawToolType.Excavation]: 'Excavation',
}


// Most of this file should be converted to a store, so users can create
// other presets of default values

type MeasurementsInfo = {
  categories?: string[], // bad because should not use enums since can't extend
  measurements?: string[],
}

// Declare which measurements to auto add when drawing
type MeasurementsInfoByType = { [nodeType in NodeType]?: { [measurementType in DrawToolType]?: MeasurementsInfo } };

// todo: put in DB
const defaultMeasurements: MeasurementsInfoByType =
{
  [NodeType.Surface]: {
    [DrawToolType.GeneralDraw]: {
      measurements: [
        //MeasurementType.GeneralSurface,
      ]
    },
    [DrawToolType.Wall]: {
      categories: [
        BuiltinMeasurementCategories.FloorOrCeiling,
        BuiltinMeasurementCategories.Room
      ],
    },
    [DrawToolType.Roof]: {
      measurements: [
        MeasurementType.RoofSlopeRatio,
        MeasurementType.RoofSlopeDirection,
      ]
    },
    [DrawToolType.Excavation]: {},
  },
  [NodeType.Line]: {
    [DrawToolType.GeneralDraw]: {
      measurements: [
        //MeasurementType.GeneralLength,
      ]
    },
    [DrawToolType.Wall]: {
      categories: [
        BuiltinMeasurementCategories.Walls,
        BuiltinMeasurementCategories.Room
      ],
    },
    [DrawToolType.Roof]: {
      measurements: [
        MeasurementType.RoofRealLength,
        MeasurementType.RoofSlopeRatio,
        MeasurementType.RoofSlopeDirection,
      ]
    },
    [DrawToolType.Excavation]: {},
  }
}

// These transform the definitions above into measurements objects
// This should be in a store
// And constants possibly moved to DB
export const getDefaultMeasurements = (nodeType: NodeType, drawToolType: DrawToolType, stores: Stores): Measurement[] => {
  // Never worked and also performance hungry
  return [];
  const { measurementsStore } = stores;
  const measurementsInfo = getSafe(() => defaultMeasurements[nodeType][drawToolType]) || {};
  // hard to read
  return compact([
    ...flatten((measurementsInfo.categories || []).map(categId => getSafe(() => measurementsStore.itemsByCategSubcateg[categId][BuiltinCategories.General]) || [])),//measurementsStore.itemsByCateg[categId])),
    ...(measurementsInfo.measurements || []).map(measurementId => measurementsStore.getItem(measurementId)),
  ]);
};

// Draw tool options
export const drawToolsOptionsByType: { [key: string]: MeasurementType[] } = {
  [DrawToolType.Wall]: [
    MeasurementType.WallHeight,
    MeasurementType.WallStudsSpacing,
    MeasurementType.WallFurringSpacing,
  ],
  [DrawToolType.Roof]: [
    MeasurementType.RoofSlopeRatio,
    MeasurementType.RoofSlopeDirection,
  ],
}

export const askToSubtractSurfaceIfNeeded = async (surfacesToSubtract: Surface[], batchParam: WriteBatch = null) => {
  const batch = batchParam || firestoreBatch();

  if (isEmpty(surfacesToSubtract)) {
    return null;
  }

  const stores = surfacesToSubtract[0].stores;

  const { dialogsStore, treeNodesStore, shapesStore, userInfoStore, drawToolsStore } = stores;

  // key: surface to subtract, value: surface to subtract from
  const surfacesMap = new Map<Surface, Surface>();

  surfacesToSubtract.map(surfaceToSubtract => {
    const otherSurfaces = treeNodesStore.rootNode.shapes.filter(s => (
      s.type === 'Surface' &&
      s.id !== surfaceToSubtract.id &&
      treeNodesStore.getNodeForShape(s).isVisible
    ));

    const otherSurfacesClippers = otherSurfaces.map(surface => {
      const clipper = new Clipper(
        new Clipper([
          surface.points.map(pt => ({ X: pt.x, Y: pt.y })),
        ]).offset(0.0508 * DRAWING_SCALE /* 2 inches margin of tolerance to still consider inside */, { jointType: 'jtMiter' })
          .paths
      );

      clipper.id = surface.id;

      return clipper;
    });

    const otherSurfacesThatContainNewSurface = otherSurfacesClippers.filter(otherSurface => (
      surfaceToSubtract.points.every(pt => otherSurface.paths.length > 0 && otherSurface.pointInPath(0, { X: pt.x, Y: pt.y }))
    ));

    const surfaceToSubtractFrom = shapesStore.getItem(otherSurfacesThatContainNewSurface[0]?.id) as Surface;

    // for now, only works if only one surface to subtract from is found
    if (surfaceToSubtractFrom && otherSurfacesThatContainNewSurface.length === 1) {
      surfacesMap.set(surfaceToSubtract, surfaceToSubtractFrom);
    }
  });

  if (isEmpty(surfacesMap)) {
    return null;
  }

  return new Promise((resolve) => {

    const onConfirm = async (dialogId = null) => {
      // promises not really needed here, because everything sync
      await Promise.all(Array.from(surfacesMap.keys()).map(async (surfaceToSubtract) => {
        const surfaceToSubtractFrom = surfacesMap.get(surfaceToSubtract);
        const hole = new SimpleSurface();
        hole.points = surfaceToSubtract.points.slice(0).reverse();
        surfaceToSubtractFrom.holes.push(hole);
        shapesStore.batchAddEditItem(surfaceToSubtractFrom, batch);

        // select the furthest parent that includes only one surface (ie. if it'd a rectangle, select
        // the Rectangle node, not the Surface node within Rectangle)
        let newSelectedNode = surfaceToSubtractFrom.treeNode;
        let parent = newSelectedNode.parent;
        while (parent?.shapes.filter(s => s.type === 'Surface').length === 1) {
          newSelectedNode = parent;
          parent = newSelectedNode.parent;
        }
      }));


      if (!batchParam) {
        batch.commit();
      }

      if (dialogId) {
        dialogsStore.hideDialog(dialogId);
      }

      resolve(null);
    }

    const onClose = (shouldSave) => {
      if (!shouldSave) {
        resolve(null);
      }
    }

    if (surfacesMap.size > 0) {
      if (userInfoStore.user.subtractSurfacesBehavior === ConfirmationBehavior.Always) {
        onConfirm();
      } else if (userInfoStore.user.subtractSurfacesBehavior !== ConfirmationBehavior.Never) {
        const newDialog = new Dialog(stores);
        newDialog.dialogComponent = ({ open }) => (
          <ConfirmDialog
            open={open}
            dialogId={newDialog.id}
            onConfirm={onConfirm}
            onClose={onClose}
            title={i18n.t('Subtract this new surface from the underlying surface?')}
            /*extraButtons={
              <>
                <Button
                  className="alwaysOrNeverButton"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    userInfoStore.user.subtractSurfacesBehavior = ConfirmationBehavior.Never;
                    userInfoStore.addEditItem(userInfoStore.user, true, ['subtractSurfacesBehavior']);
                    onConfirm(newDialog.id);
                  }}>
                  {i18n.t('Never')}
                </Button>
                <Button
                  className="alwaysOrNeverButton"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    userInfoStore.user.subtractSurfacesBehavior = ConfirmationBehavior.Always;
                    userInfoStore.addEditItem(userInfoStore.user, true, ['subtractSurfacesBehavior']);
                    onConfirm(newDialog.id);
                  }}>
                  {i18n.t('Always')}
                </Button>
              </>
                }*/
          />
        );
        dialogsStore.showDialog(newDialog);
      }
    } else {
      resolve(null);
    }
  });
}

export const drawToolMouseMoveThrottledBase = (event: MouseEvent, svgTag: SVGSVGElement, context: Stores) => {
  const { snapStore, drawToolsStore, shapesStore, userInfoStore } = context;
  const { zoomController } = shapesStore;
  const { scrollContainer } = zoomController;
  let cursorPt = snapStore.cursorPosition;

  // when outside componnt, either do nothing, or move drawing if currently drawing
  if (!isMouseInsideComponent(event, shapesStore.zoomController.scrollContainer)) {
    if (!drawToolsStore.nodeBeingAdded) {
      if (snapStore.cursorPosition) {
        snapStore.cursorPosition = null;
      }
      return;
    }
    if (!isSafari()) {
      if (isMouseInsideComponent(event, svgTag)) {
        const SCROLL_ATTENUATOR = 0.00045;
        const scrollRect = scrollContainer.getBoundingClientRect();
        let scrollDeltaX = event.pageX < scrollRect.x
          ? (event.pageX - scrollRect.x)
          : Math.max(0, event.pageX - scrollRect.x - scrollRect.width)

        let scrollDeltaY = event.pageY < scrollRect.y
          ? (event.pageY - scrollRect.y)
          : Math.max(0, event.pageY - scrollRect.y - scrollRect.height);

        // should only scroll one direction at a time
        zoomController.lastMovementX = zoomController.lastMovementY = 0;

        (Math.abs(scrollDeltaX) - Math.abs(scrollDeltaY) > 0)
          ? (zoomController.lastMovementX = scrollDeltaX > 0 ? -SCROLL_ATTENUATOR * scrollContainer.scrollWidth : SCROLL_ATTENUATOR * scrollContainer.scrollWidth)
          : (zoomController.lastMovementY = scrollDeltaY > 0 ? -SCROLL_ATTENUATOR * scrollContainer.scrollHeight : SCROLL_ATTENUATOR * scrollContainer.scrollHeight);

        if (userInfoStore.user?.isDragMomentumEnabled) {
          zoomController.beginAccelerationTracking();
        }
      } else {
        shapesStore.zoomController.zoomOutOnShapesThrottled(999, screenToLocalPoint(event.clientX, event.clientY, shapesStore.zoomController.objectToZoom));
      }
    }
  } else {
    zoomController.cancelAccelerationTracking();
    shapesStore.zoomController.adjustSvgSizeThrottled.cancel();
  }
}

export const showAddBackgroundImageDialog = (stores: Stores) => event => {
  const { dialogsStore, treeNodesStore, drawToolsStore } = stores;
  drawToolsStore.selectedTool = DrawToolType.Select;

  const newDialog = new Dialog(stores);
  newDialog.dialogComponent = ({ open }) => (
    <AddBackgroundImageDialog
      open={open}
      dialogId={newDialog.id}
      treeNodeCopy={treeNodesStore.rootNode.clone()}
    />
  )
  dialogsStore.showDialog(newDialog);

  event.preventDefault();
  event.stopPropagation();
}

//https://stackoverflow.com/questions/24838629/round-off-float-to-nearest-0-5-in-python
export const roundToNearestStep = (value, step) => (
  Math.round(value * (1 / step)) / (1 / step)
);

export const showAddExistingDrawingDialog = (drawingStores: Stores) => (event) => {
  const { treeNodesStore, dialogsStore, drawToolsStore } = drawingStores;
  const { rootNode: drawingRootNode } = treeNodesStore;

  const onConfirm = (sourceNode: TreeNode) => {
    const batch = firestoreBatch();

    const nodeCopy = sourceNode.childDrawingNode.cloneDeep(batch, drawingStores, uuidv4(), new Map<string, string>(), []);
    nodeCopy.name = sourceNode.name.replace(' - ' + i18n.t('Reference Drawing'), '');
    nodeCopy.isDrawingNode = false;

    treeNodesStore.batchAddEditItem(nodeCopy, batch);
    treeNodesStore.appendNode(nodeCopy, drawingRootNode, undefined, batch);
    treeNodesStore.toggleNodeMeasurements(nodeCopy.measurements, false, undefined, undefined, batch);

    // make openings?
    askToSubtractSurfaceIfNeeded(nodeCopy.surfaceShapes, batch);

    batch.commit();
  }

  const newDialog = new Dialog(drawingStores);
  newDialog.dialogComponent = ({ open }) => (
    <DrawingCopyDialog
      open={open}
      dialogId={newDialog.id}
      treeNode={drawingRootNode}
      onConfirm={onConfirm}
    />
  )
  dialogsStore.showDialog(newDialog);
  drawToolsStore.shouldShowDrawingButtonOptions = false;
}

export const MOUSE_MOVE_THROTTLE_TIME = 30;