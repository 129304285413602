export const SAMPLE_REPORT_NUM_TASKS = 10;

export enum ReportGrouping {
  None = 'None',
  TreeNodeThenCategory = 'TreeNodeThenCategory',
  TreeNode = 'TreeNode',
  CategoryThenTreeNode = 'CategoryThenTreeNode',
  Category = 'Category',
}

export enum ReportSortType {
  NoSort, // not really 'no sort', sort in same order as in project
  MaterialFirst,
  MaterialLast,
  PriceList,
  Alphabetical,
}

export enum ReportSubtypes {
  Invoice = 'Invoice',
  Estimate = 'Estimate',
  Estimate2 = 'Estimate2',
  Estimate3 = 'Estimate3',
  Specifications = 'Specifications',
  BudgetPlanning = 'BudgetPlanning',
  Contract = 'Contract',
  Receipt = 'Receipt'
};

export enum ReportTasksFilter {
  AllTasks = 'AllTasks',
  HideMaterial = 'HideMaterial', // total still includes material
  HideLabour = 'HideLabour', // total still includes labour
  LabourOnly = 'LabourOnly', // total is only for material
  MaterialOnly = 'MaterialOnly', // total is only for material
}

export enum ReportTotalsVisibility {
  Task = 'Task',
  Group = 'Group',
  GroupNotSubgroup = 'GroupeNotSubgroup',
  Project = 'Project',
  None = 'None'
}

export enum ReportTotalsGrouping {
  DetailedWithoutHours = 'Group',
  DetailedWithHours = 'Task',
  SubtotalOnly = 'SubtotalOnly'
}

export enum ReportTasksVisibility {
  Hidden = 'Hidden',
  ShowQuantities = 'ShowQuantities',
  HideQuantities = 'HideQuantities'
}

export enum ReportHeaderMeasurementsVisibility {
  Hidden = 'Hidden',
  Visible = 'Visible',
  VisibleWithoutDrawing = 'VisibleWithoutDrawing',
}
