import { Checkbox } from '@material-ui/core';
import CheckedListIcon from '@material-ui/icons/PlaylistAddCheck';
import ObserverComponent from 'components/common/ObserverComponent';
import { CategoryType } from 'constants/CategoryType';
import { IDialogProps } from 'constants/CommonProps';
import { saveAs } from 'file-saver';
import Measurement from 'models/Measurement';
import * as React from 'react';
import { modelToPlainObjectNonNested } from 'utils/DeserializeUtils';
import { duplicateMeasurement, handleChangeActiveMeasurements, showCreateNewMeasurementDialog } from 'utils/MeasurementUtil';
import { formatUnit } from 'utils/UnitFormatter';
import i18n from 'utils/i18n';
import XLSX from 'xlsx';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import GroupedList from '../GroupedList/GroupedList';
import MeasurementComponent from '../MeasurementComponent/MeasurementComponent';
import MeasurementsSimpleList from '../MeasurementsSimpleList/MeasurementsSimpleList';
import SearchToolbar from '../SearchToolbar/SearchToolbar';


const styles = require('./MeasurementsListDialog.module.scss');

class MeasurementItemRenderer extends ObserverComponent<{ item: Measurement }> {
  _render() {
    const { item } = this.props;
    const { selectedTreeNode } = this.context.treeNodesStore;
    return (
      <MeasurementComponent
        measurement={item}
        measurementValue={selectedTreeNode.measurementValues.get(item.id)}
        treeNode={selectedTreeNode}
        shouldHideValue
      />
    );
  }
};

// we should show both inactive and active measurements instead of only inactive
export default class MeasurementsListDialog extends ObserverComponent<IDialogProps> {
  componentDidMount() {
    const { measurementsStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    measurementsStore.selectedItems = new Set(selectedTreeNode.measurementsToShow);
  }

  onClose = () => {
    const { measurementsStore } = this.context;
    //measurementsStore.selectedItems.clear();
    measurementsStore.searchQuery = '';
    //measurementsStore.categoryFilter = null;
    //measurementsStore.subcategoryFilter = null;
  }

  onDuplicateItem = (item: Measurement) => {
    duplicateMeasurement(item, this.context)(); // normally a handler, but here have to manually call
  }

  batchExport = () => {
    const { measurementsStore } = this.context;

    const shouldDebug = false;

    let plainItems = [];

    if (shouldDebug) {
      plainItems = measurementsStore.items
        .map(i => modelToPlainObjectNonNested(i));
    } else {
      plainItems = measurementsStore.itemsByCategSubcategFlattened
        .map(({ item }) => item as Measurement)
        .filter(i => i)
        .map(i => {
          return ({
            id: i.id,
            sousType: i.subtype,
            categorie: i.categoryName,
            sousCategorie: i.subcategoryName,
            nom: i.name,
            formule: i.defaultFormula,
            unite: formatUnit(i.displayUnit),
            description: i.description,
          }
          );
        });
    }

    /* make the worksheet */
    var ws = XLSX.utils.json_to_sheet(plainItems, {});

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Items");

    /* write workbook (use type 'binary') */
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "Mesures.xlsx");
  }

  toggleAdvancedMode = () => {
    const { settingsStore } = this.context;
    const { settings } = settingsStore;
    const { shouldShowMeasurementsAdvancedMode } = settings;

    settings.shouldShowMeasurementsAdvancedMode = !shouldShowMeasurementsAdvancedMode;
    // go back to normal mode next time?
    //settingsStore.addEditItem(settings);
  }

  _render() {
    const { open, dialogId } = this.props;
    const { treeNodesStore, measurementsStore, settingsStore, userInfoStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    const { searchedFilteredItems, groupedMeasurementsFlattenedForSelectedNode } = measurementsStore;
    const { shouldShowMeasurementsAdvancedMode } = settingsStore.settings;

    return (
      <ConfirmDialog
        dialogId={dialogId}
        open={open}
        title={<div className={styles.title} id="draggable-dialog-title">
          <CheckedListIcon />
          &nbsp;
          {i18n.t('Check measurements needed for "{{-treeNodeName}}"', { treeNodeName: selectedTreeNode.name })}
        </div>}
        onConfirm={() => handleChangeActiveMeasurements(this.context)}
        onClose={this.onClose}
        yesLabel={i18n.t('OK')}
        noLabel={i18n.t('Cancel')}
        extraButtons={undefined/*
          <div className={styles.advancedButtonContainer} >
            <a
              className={styles.enterQuantityLink}
              onClick={this.toggleAdvancedMode}
            >
              {shouldShowMeasurementsAdvancedMode ? i18n.t('Normal mode') : i18n.t('Advanced mode')}
            </a>
          </div>
        */}
        content={
          true
            ? (
              <div className={styles.root}>
                <SearchToolbar
                  className={styles.searchToolbar}
                  categoryType={CategoryType.Measurement}
                  store={measurementsStore}
                  shouldFilterBySubcategories
                  shouldFocusOnMount
                  optionsMenuItems={[{
                    icon: <Checkbox checked={measurementsStore.shouldShowHiddenItems} />,
                    text: i18n.t('Show hidden measurements'),
                    handler: () => { measurementsStore.shouldShowHiddenItems = !measurementsStore.shouldShowHiddenItems },
                  },
                  // duplicate
                  {
                    icon: <Checkbox checked={measurementsStore.shouldMarkMasterItems} />,
                    text: i18n.t('Identify original Evalumo measurements'),
                    handler: () => { measurementsStore.shouldMarkMasterItems = !measurementsStore.shouldMarkMasterItems },
                  },
                  {
                    icon: <Checkbox checked={measurementsStore.shouldMarkReadonlyItems} />,
                    text: i18n.t('Identify read-only measurements'),
                    handler: () => { measurementsStore.shouldMarkReadonlyItems = !measurementsStore.shouldMarkReadonlyItems },
                  },
                  ]}
                  onAdd={() => showCreateNewMeasurementDialog(this.context)}
                  onBatchExport={this.batchExport}
                />

                <GroupedList
                  shouldAllowReorderItems
                  className={styles.list}
                  ungroupedItems={searchedFilteredItems}
                  itemsByCategSubcategFlattened={groupedMeasurementsFlattenedForSelectedNode}
                  shouldAllowCheckboxes
                  store={measurementsStore}
                  onDuplicateItem={this.onDuplicateItem}
                  onChangeItemCategory={item => measurementsStore.openChangeCategoryDialog([item])}
                  itemHeight={40}
                  ItemRenderer={MeasurementItemRenderer}
                  hasSubsubcategories
                />
              </div>
            ) : (
              <MeasurementsSimpleList />
            )}
      />
    );
  }
}