
import { Button } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ObserverComponent from 'components/common/ObserverComponent';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { RightBarTabs } from 'constants/RightBarTabs';
import * as React from 'react';
import { InputNumberFormatPercent } from 'utils/NumberFormatter';
import i18n from 'utils/i18n';
import TextField from '../TextField/TextField';

const styles = require('./ReplaceWasteComponent.module.scss');

interface ReplaceWasteComponentState {
  isEditMode: boolean,
  currentWaste: number,
  newWaste: number,
  shouldChangeDefaultWaste: boolean,
}

export default class ReplaceWasteComponent extends ObserverComponent<{}, ReplaceWasteComponentState> {
  state = {
    isEditMode: false,
    numReplacementsMade: null,
    currentWaste: null,
    newWaste: null,
    shouldChangeDefaultWaste: false,
  }

  handleChange = (stateKey: keyof ReplaceWasteComponentState) => (event) => {
    const value = event?.value ?? event?.target?.value;

    this.setState({ [stateKey]: value });
  };

  handleReplace = () => {
    const { providingItemsStore, settingsStore, userInfoStore } = this.context;
    const { currentWaste, newWaste, shouldChangeDefaultWaste } = this.state;
    const numReplacementsMade = providingItemsStore.updateWasteRate(currentWaste, newWaste);

    this.setState({ numReplacementsMade });

    if (shouldChangeDefaultWaste) {
      userInfoStore.user.defaultWastePercent = newWaste;
      userInfoStore.addEditItem(userInfoStore.user, true, ['defaultWastePercent']);
    }

    settingsStore.settings.selectedRightBarTab = RightBarTabs.MY_ITEMS;
    providingItemsStore.subtypeFilter = ProvidingItemSubtype.Labour;
  };

  _render() {
    const { isEditMode, numReplacementsMade, currentWaste, newWaste, shouldChangeDefaultWaste } = this.state;
    return (
      <div className={styles.root}>
        {isEditMode && (
          <div className={styles.editMode}>
            <div className={styles.title}>
              {i18n.t('Replace Waste %')}
            </div>
            <div className={styles.instructions}>{i18n.t('')}</div>
            <div className={styles.components}>
              <TextField
                label={i18n.t('Current Waste %')}
                InputProps={{
                  inputComponent: InputNumberFormatPercent
                }}
                value={currentWaste}
                onChange={this.handleChange('currentWaste')}
                shouldSelectAllOnFocus
              />
              <ArrowRightIcon />
              <TextField
                label={i18n.t('New Waste %')}
                InputProps={{
                  inputComponent: InputNumberFormatPercent
                }}
                value={newWaste}
                onChange={this.handleChange('newWaste')}
                shouldSelectAllOnFocus
              />
              <Button
                className={styles.replaceAllButton}
                disabled={!currentWaste || !newWaste}
                onClick={this.handleReplace}
                variant="contained"
                color="primary"
              >
                {i18n.t('Replace')}<br />{i18n.t('all')}
              </Button>
            </div>
            {/* can't work because inside the dialog that already has a copy of user object to make other settings changes
             // could uncomment when moving this in Tools dialog
            <div onClick={() => { this.setState({ shouldChangeDefaultWaste: !shouldChangeDefaultWaste }); }}>
              <Checkbox checked={shouldChangeDefaultWaste} />
              <span className={styles.checkboxLabel} >{i18n.t('Also apply to all new items')}</span>
            </div>
            */
            }

            {numReplacementsMade !== null && (
              <div className={styles.replacementsMade}>{numReplacementsMade}&nbsp;{i18n.t(' replacement(s) made.')}</div>
            )}
          </div>
        )}

        {!isEditMode && (
          <a
            className={styles.goToEditModeButton} onClick={() => { this.setState({ isEditMode: true }) }}>
            {i18n.t('Waste % replacement tool')}
          </a>
        )
        }
      </div>
    )
  }
}