// taken from @progress/kendo-react-pdf

import { DrawOptions, Group } from '@progress/kendo-drawing';
import { PDFOptions } from '@progress/kendo-drawing/dist/npm/pdf';
import { SaveOptions } from '@progress/kendo-file-saver';
import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { PDFExportProps } from 'utils/PDFExportProps';
var defaultFileName = 'export.pdf';
var defaultCreator = 'Kendo UI PDF Generator';

export default class KendoDrawingAdapter {
  drawDOM;
  exportPDF;
  saveAs;
  domElement;
  options;
  convertPageTemplateToHtml;

  constructor(
    drawDOM: (element: HTMLElement, options: DrawOptions) => Promise<Group>,
    exportPDF: (group: Group, options: PDFOptions) => Promise<string>,
    saveAs: (dataUri: string, fileName: string, options: SaveOptions) => void,
    domElement: HTMLElement,
    options?: PDFExportProps,
  ) {
    if (options === void 0) { options = {}; }
    var _this = this;
    this.drawDOM = drawDOM;
    this.exportPDF = exportPDF;
    this.saveAs = saveAs;
    this.domElement = domElement;
    this.options = options;
    this.convertPageTemplateToHtml = function (pageContext) {
      var pageTemplateContent = ReactDOMServer.renderToStaticMarkup(React.createElement(_this.options.pageTemplate, {
        pageNum: pageContext.pageNum, totalPages: pageContext.totalPages
      }));
      return "<span>" + pageTemplateContent + "</span>";
    };
  }

  savePDF(callback?: () => void): void {
    var _this = this;
    var savePromise = this.drawDOM(this.domElement, this.getDrawOptions())
      .then(function (group) { return _this.exportPDF(group, _this.getPDFOptions()); })
      .then(function (dataUri) { return _this.saveAs(dataUri, _this.options.fileName || defaultFileName, _this.getSaveOptions()); });
    if (callback) {
      savePromise.then(callback, callback);
    }
  };
  getDrawOptions() {
    return {
      avoidLinks: this.options.avoidLinks,
      forcePageBreak: this.options.forcePageBreak,
      keepTogether: this.options.keepTogether,
      margin: this.options.margin,
      paperSize: this.options.paperSize,
      landscape: this.options.landscape,
      repeatHeaders: this.options.repeatHeaders,
      shouldShowTasks: this.options.shouldShowTasks,
      progress: this.options.progress,
      scale: this.options.scale,
      template: this.options.pageTemplate && this.convertPageTemplateToHtml
    };
  };
  getPDFOptions() {
    return {
      author: this.options.author,
      creator: this.options.creator || defaultCreator,
      date: this.options.date,
      imgDPI: this.options.imageResolution,
      keywords: this.options.keywords,
      landscape: this.options.landscape,
      margin: this.options.margin,
      multiPage: true,
      paperSize: this.options.paperSize,
      producer: this.options.producer,
      subject: this.options.subject,
      title: this.options.title
    };
  };

  getSaveOptions() {
    return {
      forceProxy: this.options.forceProxy,
      proxyData: this.options.proxyData,
      proxyTarget: this.options.proxyTarget,
      proxyURL: this.options.proxyURL
    };
  };
}
