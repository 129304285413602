import { Button, LinearProgress, Paper, Tab, Tabs } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import WarningIcon from '@material-ui/icons/Warning';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { SaveOptions } from '@progress/kendo-file-saver';
import * as classnames from 'classnames';
import ColorButton from 'components/common/ColorButton/ColorButton';
import { PdfIcon, RulerIcon } from 'components/common/Icons';
import ObserverComponent from 'components/common/ObserverComponent';
import Panel from 'components/common/Panel/Panel';
import ReportClientInfo from 'components/common/ReportClientInfo/ReportClientInfo';
import ReportContentListOptions from 'components/common/ReportContentListOptions/ReportContentListOptions';
import ReportDate from 'components/common/ReportDate/ReportDate';
import ReportEditableSection from 'components/common/ReportEditableSection/ReportEditableSection';
import ReportFeesOptions from 'components/common/ReportFeesOptions/ReportFeesOptions';
import ReportHeader from 'components/common/ReportHeader/ReportHeader';
import ReportNumber from 'components/common/ReportNumber/ReportNumber';
import ReportProjectDescription from 'components/common/ReportProjectDescription/ReportProjectDescription';
import ReportTasksFilterComponent from 'components/common/ReportTasksFilterComponent/ReportTasksFilterComponent';
import ReportTaxesOptions from 'components/common/ReportTaxesOptions/ReportTaxesOptions';
import ReportTypeComponent from 'components/common/ReportTypeComponent/ReportTypeComponent';
import RichText2 from 'components/common/RichText2/RichText2';
import SettingsEditDialog from 'components/common/SettingsEditDialog/SettingsEditDialog';
import { Typography } from 'components/common/Typography/Typography';
import PageTemplate from 'components/reports/PageTemplate/PageTemplate';
import ReportContentList from 'components/reports/ReportContentList/ReportContentList';
import ReportNotes from 'components/reports/ReportNotes/ReportNotes';
import ReportPhotos from 'components/reports/ReportPhotos/ReportPhotos';
import ReportPreparedBy from 'components/reports/ReportPreparedBy/ReportPreparedBy';
import ReportThankYou from 'components/reports/ReportThankYou/ReportThankYou';
import ReportTotal from 'components/reports/ReportTotal/ReportTotal';
import { ReportSubtypes, ReportTotalsVisibility } from 'constants/ReportOptionsConstants';
import { isEmpty } from 'lodash';
import Dialog from 'models/Dialog';
import UserColors from 'models/UserColors';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { match } from 'react-router';
import sanitizeHtml from 'sanitize-html';
import { handleCheckboxChangeAndSave } from 'utils/FormUtils';
import i18n from 'utils/i18n';
import KendoDrawingAdapter from 'utils/KendoDrawingAdapter';
import { PDFExportProps } from 'utils/PDFExportProps';
import { b64toBlob, isFilterAffectingTotal } from 'utils/ReportUtil';
import { getSafe } from 'utils/Utils';
/* tslint:enable */
import IntegerField from 'components/common/IntegerField/IntegerField';
import { InputNumberFormatInteger } from 'utils/NumberFormatter';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import './ReportView.scss';

/* tslint:disable */
const styles = require('./ReportView.module.scss');

enum ReportTabs {
  DESIGN,
  PDF,
}

interface IReportViewProps {
  match: match<{ [projectId: string]: string }>
}

interface IReportViewState {
  selectedTab: ReportTabs,
  pdfUrl: string,
  reportGenerationProgress: number;
  cancelReport: () => void | null;
}

class ReportView extends ObserverComponent<IReportViewProps, IReportViewState> {
  page: HTMLElement;
  reportScrollContainer;

  state = {
    selectedTab: ReportTabs.DESIGN,
    pdfUrl: '',
    reportGenerationProgress: 0,
    cancelReport: null,
  }

  saveToBlob = (dataUri: string, fileName: string, options: SaveOptions) => {
    const b64 = dataUri.replace('data:application/pdf;base64,', '');
    // could improve download button file name with
    // https://stackoverflow.com/questions/53548182/can-i-set-the-filename-of-a-pdf-object-displayed-in-chrome
    const newUri = URL.createObjectURL(b64toBlob(b64, 'application/pdf')) + '#toolbar=1&view=FitH&pagemode=thumbs';
    this.setState({
      pdfUrl: newUri,
    });
  }

  savePDF = (domElement, options: PDFExportProps) => {
    /*defineFont({
      "Montserrat|Medium":
        "https://app.evalumo.com/fonts/Montserrat-Medium.ttf",
    });*/

    new KendoDrawingAdapter(drawDOM, exportPDF, this.saveToBlob, domElement, options).savePDF(() => { ; });
  }

  download = () => {
    const { pdfUrl } = this.state;
    if (!pdfUrl) {
      return;
    }
    const { projectsStore, reportsStore } = this.context;

    const projectName = getSafe(() => projectsStore.selectedProject.name) || '';
    const filename = `${projectName} - ${i18n.t(reportsStore.report.subtype)}.pdf`;
    var link = document.createElement('a');
    link.href = pdfUrl;
    link.download = filename;
    link.click();

    trackInteraction(TrackedInteractions.DownloadOrSendReport);
  }

  onChangeSelectedTab = (event, selectedTab) => {
    if (this.state.cancelReport) {
      this.state.cancelReport();
    }

    this.setState({
      selectedTab,
      pdfUrl: '',
      reportGenerationProgress: 0,
      cancelReport: null,
    },
      () => setTimeout(() => {
        const { projectsStore, reportsStore } = this.context;
        const { selectedProject } = projectsStore;

        const pageComponent = ReactDOM.findDOMNode(this.page);

        // glitch because not always defined (about 5% of times)
        if (!pageComponent && selectedTab === ReportTabs.PDF) {
          debugger;
          console.error('bug pageComponent undefined')
        }
        if (pageComponent && selectedTab === ReportTabs.PDF) {
          this.savePDF(ReactDOM.findDOMNode(this.page), {
            // Any option added here, must be copied in KendoDrawingAdapter
            repeatHeaders: true,
            keepTogether: '.keep-together',
            forcePageBreak: '.force-page-break',
            paperSize: 'Letter',
            margin: { left: '0cm', top: '3cm', right: '0cm', bottom: '2.3cm' },
            pageTemplate: PageTemplate,
            title: selectedProject.name + ' - Evalumo',
            scale: 0.5,
            imageResolution: 300,
            progress: ({ pageNum, totalPages, cancel }) => {
              this.setState({ reportGenerationProgress: Math.round(pageNum / totalPages * 100), cancelReport: cancel })
            },
            shouldShowTasks: reportsStore.selectedItem?.shouldShowTasks // custom
          });
        }
      }, 1200) // enough time for drawings to resize
    );
  };

  editUserInfo = () => {
    const { dialogsStore, settingsStore, userInfoStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <SettingsEditDialog open={open} dialogId={newDialog.id} settings={settingsStore.settings} />
    )
    dialogsStore.showDialog(newDialog);
  }


  public _render() {
    const { projectsStore, reportsStore, treeNodesStore, userInfoStore } = this.context;
    const {
      selectedTab,
      pdfUrl,
      reportGenerationProgress,
    } = this.state;

    const { selectedProject } = projectsStore;
    const { rootNode } = treeNodesStore;

    if (!selectedProject || !rootNode) {
      return null;
    }

    const report = reportsStore.selectedItem;

    if (!report) {
      return null;
    }

    const { shouldShowIntro, shouldShowTerms, shouldShowSignature, tasksFilter, shouldShowCustomHtmlInReportFooter, customHtmlInReportFooterMarginTop } = report;
    const { canManuallyChangeLogoColor, reportFontSizeScale, shouldBreakBeforeTerms, shouldNeverInlineNotesInReport } = userInfoStore.user || {};

    const nodesWithNotes = treeNodesStore.allNodes.filter(node => node.hasDescription || sanitizeHtml(node.description2, { allowedTags: [] }));
    const shouldShowNotes = report.shouldShowNotes && !isEmpty(nodesWithNotes);

    const nodesWithPhotos = treeNodesStore.allNodes.filter(node => (
      node.imageUrl &&
      // skip images inserted automatically after dragging tasks list
      !node.imageUrl.includes('%2Fmaster%2F') &&
      !node.imageUrl.includes('.svg') &&
      !node.shouldSkipImageInReport
    ));
    const shouldShowPhotos = report.shouldShowPhotos && !isEmpty(nodesWithPhotos);

    const logoColor = report.logoColors.color1;
    const logoColorPale = report.logoColors.colorPale;
    const logoColorText = report.logoColors.colorText;

    const hasNodesExcludedFromReports = rootNode.descendants.find(descendant => descendant.shouldExcludeFromReports);

    const { customHtmlInReportFooter } = userInfoStore.user || {}

    return (
      <div id="ReportView" className={styles.root}>

        <style dangerouslySetInnerHTML={{
          __html: `
                :root {
                  --logo-color: ${logoColor};
                  --logo-color-pale: ${logoColorPale};
                  --logo-color-text: ${logoColorText};
                  --report-font-size-scale: ${reportFontSizeScale || 1}; 
                }
              `}} />

        <Panel
          header={
            <div className={styles.reportNav} >
              <Tabs
                className={styles.tabs}
                value={this.state.selectedTab}
                onChange={this.onChangeSelectedTab}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab icon={<RulerIcon />} label={i18n.t('Setup')} />
                <Tab icon={<PdfIcon />} label={i18n.t('Preview')} />
                {/*<Tab icon={<NotificationsIcon />} label={i18n.t('View notifications')} />*/}
              </Tabs>

              {selectedTab === ReportTabs.PDF && pdfUrl && (
                <Button className={styles.button} onClick={this.download} >
                  <DownloadIcon />&nbsp;
                  {i18n.t('Download')}
                </Button>
              )}
            </div>
          }
          content={
            <div className={styles.report + ' ' + styles.scrollContainer}>
              <div className={styles.tabContent} id="report-scroll-container">
                <div className={classnames(
                  styles.reportPreparation, {
                  [styles.hiddenOutside]: selectedTab !== ReportTabs.DESIGN, // ??
                }
                )} >

                  {/* MOVE TO COMPONENT: Complete page that gets converted to PDF but is not visible before that */}
                  {(selectedTab === ReportTabs.PDF || reportsStore.isGeneratingExcel) && (
                    <Paper elevation={3} className={classnames(
                      'hiddenOutside',
                      'printablePage',
                      styles.page,
                      styles.printablePage,
                      styles.hiddenOutside,
                    )} >
                      <div
                        ref={page => this.page = page}
                        className={styles.pageContent}
                      >
                        { /* <PageTemplate /> (added during print) */}

                        {/* TODO REMOVE DUPLCATION */}

                        <div className={styles.firstPageInfo}>
                          <div className={styles.columnOne}>
                            <div className={styles.reportType}>
                              <ReportTypeComponent report={report} />
                              {isFilterAffectingTotal(tasksFilter) && <ReportTasksFilterComponent report={report} />}
                            </div>
                            <ReportProjectDescription report={report} className={styles.reportProperties} />
                            <ReportClientInfo report={report} className={styles.reportProperties} />
                          </div>

                          <div className={styles.separator} />

                          <div className={styles.columnTwo}>
                            <ReportDate report={report} className={styles.reportProperties} />
                            {report.subtype === ReportSubtypes.Invoice && <ReportDate dateField='dueDate' report={report} className={styles.reportProperties} />}
                            <ReportNumber report={report} className={styles.reportProperties} />
                          </div>

                          {report.subtype === ReportSubtypes.Receipt && <div className={styles.paid}>{i18n.t('Paid')}</div>}
                        </div>

                        {shouldShowIntro && <RichText2 model={report} className={styles.introText} property="introText" />}

                        <ReportContentList report={report} />

                        <ReportPreparedBy report={report} />

                        {report.totalsVisibility !== ReportTotalsVisibility.None && <ReportTotal shouldPageBleed={!shouldShowNotes} />}

                        {shouldShowNotes && (shouldNeverInlineNotesInReport || !report.isGroupedByTreeNodeFirst) && <ReportNotes nodesWithNotes={nodesWithNotes} />}
                        {shouldShowPhotos && <ReportPhotos nodesWithPhotos={nodesWithPhotos} />}

                        {/*<ReportTotalInWords /> */}

                        <div className="keep-together" style={{ marginTop: '35px' }}>
                          {shouldBreakBeforeTerms && <div className="force-page-break" />}
                          {shouldShowTerms && <RichText2 model={report} property="generalTerms2" />}
                          {shouldShowSignature && <RichText2 model={report} property="signatureText" />}
                          <ReportThankYou />
                          {shouldShowCustomHtmlInReportFooter && customHtmlInReportFooter && (
                            <div className="FooterCustomHtml" style={{ marginTop: customHtmlInReportFooterMarginTop }} dangerouslySetInnerHTML={{ __html: userInfoStore.user?.customHtmlInReportFooter }}></div>
                          )}
                        </div>
                      </div>
                    </Paper>
                  )}

                  {/* end printable ---------- start editable */}

                  <ReportEditableSection
                    title={i18n.t('Header')}
                    isFirstSection
                    className={classnames(
                      styles.page,
                      styles.firstPage,
                      styles.header,
                      styles.isEditable,
                    )}
                    children={<>
                      <ReportHeader isEditable />
                      <div className={styles.firstPageInfo}>
                        <div className={styles.columnOne}>
                          <div className={styles.reportType}>
                            <ReportTypeComponent isEditable report={report} />
                          </div>
                          <ReportProjectDescription report={report} isEditable />
                          <ReportClientInfo report={report} isEditable />
                        </div>

                        <div className={styles.separator} />

                        <div className={styles.columnTwo}>
                          <ReportDate report={report} isEditable className={styles.reportProperties} />
                          {report.subtype === ReportSubtypes.Invoice && <ReportDate dateField='dueDate' report={report} isEditable className={styles.reportProperties} />}
                          <ReportNumber report={report} isEditable className={styles.reportProperties} />

                          <div style={{ flex: 1 }} />

                          {canManuallyChangeLogoColor && (
                            <ColorButton
                              className={styles.colorButton}
                              color={report.logoColors.color1}
                              onChange={color => {
                                const { rgb } = color;
                                const rbgString = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
                                report.logoColors = new UserColors(rbgString);
                                reportsStore.addEditItemDebounced(report, true, ['_logoColors']);
                              }}
                            />
                          )}

                        </div>

                        {report.subtype === ReportSubtypes.Receipt && <div className={styles.paid}>{i18n.t('Paid')}</div>}
                      </div>

                      <ReportPreparedBy report={report} isEditable className={styles.preparedByEditable} />
                    </>}
                  />

                  <ReportEditableSection
                    onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowIntro', reportsStore)}
                    isToggled={shouldShowIntro}
                    title={i18n.t('Introduction')}
                    className={styles.page}
                    children={<RichText2 model={report} property="introText" store={reportsStore} isEditable scrollingContainer="#report-scroll-container" />}
                  />

                  <ReportEditableSection
                    title={i18n.t('Content')}
                    className={classnames(styles.page, styles.reportContent)}
                    children={
                      <div>
                        <ReportContentListOptions report={report} />

                        {hasNodesExcludedFromReports && (
                          <div className={styles.hasNodesExcludedFromReports}><WarningIcon /> {i18n.t('Reminder: Some groups are currently excluded from this report.')}</div>
                        )}

                        <ReportContentList isSampleList report={report} />

                        {/* DEBUG */}
                        {/*<ReportTotal shouldPageBleed={!shouldShowNotes} />*/}
                      </div>
                    }
                  />

                  <ReportEditableSection
                    title={i18n.t('Fees')}
                    className={styles.page}
                    children={<ReportFeesOptions />}
                  />

                  <ReportEditableSection
                    title={i18n.t('Taxes')}
                    className={styles.page}
                    children={<ReportTaxesOptions />}
                  />


                  {!isEmpty(nodesWithNotes) && (
                    <ReportEditableSection
                      onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowNotes', reportsStore)}
                      isToggled={shouldShowNotes}
                      title={i18n.t('Notes')}
                      className={styles.page}
                    >
                      {(report.isGroupedByTreeNodeFirst && !shouldNeverInlineNotesInReport)
                        ? (
                          <div>{i18n.t('Notes are now shown beside tasks in the main content when possible (when report is grouped by Location first).')}</div>
                        ) : (
                          <ReportNotes nodesWithNotes={nodesWithNotes} />
                        )}
                    </ReportEditableSection>
                  )}

                  {!isEmpty(nodesWithPhotos) && (
                    <ReportEditableSection
                      onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowPhotos', reportsStore)}
                      isToggled={shouldShowPhotos}
                      title={i18n.t('Photos')}
                      className={styles.page}
                    >
                      <ReportPhotos nodesWithPhotos={nodesWithPhotos} />
                    </ReportEditableSection>
                  )}

                  <ReportEditableSection
                    onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowTerms', reportsStore)}
                    isToggled={shouldShowTerms}
                    title={i18n.t('Terms & conditions')}
                    className={styles.page}
                    children={<RichText2 model={report} property="generalTerms2" store={reportsStore} isEditable scrollingContainer="#report-scroll-container" />}
                  />

                  <ReportEditableSection
                    onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowSignature', reportsStore)}
                    isToggled={shouldShowSignature}
                    title={i18n.t('Signature')}
                    className={styles.page}
                    children={<RichText2 model={report} canAddSignature property="signatureText" store={reportsStore} isEditable scrollingContainer="#report-scroll-container" />}
                  />

                  {customHtmlInReportFooter && (
                    <ReportEditableSection
                      onToggleChange={handleCheckboxChangeAndSave(report, 'shouldShowCustomHtmlInReportFooter', reportsStore)}
                      isToggled={shouldShowCustomHtmlInReportFooter}
                      title={i18n.t('Custom content')}
                      className={styles.page}
                      children={
                        <div>
                          <div>
                            <IntegerField
                              label={i18n.t('Spacing with previous section')}
                              className={styles.customHtmlMarginTopField}
                              value={customHtmlInReportFooterMarginTop}
                              onChange={event => {
                                try {
                                  report.customHtmlInReportFooterMarginTop = parseInt(event.target.value);
                                  reportsStore.addEditItemDebounced(report, true, ['customHtmlInReportFooterMarginTop']);
                                } catch (e) {
                                  report.customHtmlInReportFooterMarginTop = 0;
                                }
                              }}
                              InputProps={{ inputComponent: InputNumberFormatInteger }}
                              //disabled={hasVariables}
                              margin="none"
                            />
                          </div>
                          <div className="FooterCustomHtml" style={{ marginTop: customHtmlInReportFooterMarginTop }} dangerouslySetInnerHTML={{ __html: customHtmlInReportFooter }}></div>
                        </div>
                      }
                    />
                  )}

                  <div className={styles.bottomButton} >
                    <Button color="primary" onClick={() => this.onChangeSelectedTab(null, ReportTabs.PDF)} variant="contained">
                      <PdfIcon />
                      {i18n.t('View PDF')}
                    </Button>
                  </div>

                </div>

                <div className={styles.preview} hidden={selectedTab !== ReportTabs.PDF}>

                  {pdfUrl ? (
                    <object
                      data={pdfUrl}
                      className={styles.pdf}
                    />
                  ) : (
                    <div className={styles.progressContainer}>
                      <Typography variant="subtitle2">{i18n.t('Preparing document')}</Typography>
                      <LinearProgress className={styles.progressBar} value={reportGenerationProgress} variant="determinate" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          } />
      </div>
    );
  }
}

export default ReportView;