
import { ClickAwayListener, IconButton, ListSubheader } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import Task from 'models/Task';
import * as React from 'react';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./TaskSeparator.module.scss');

interface TaskSeparatorProps {
  task: Task,
}

interface TaskSeparatorState {
  taskCopy: Task,
}

export default class TaskSeparator extends ObserverComponent<TaskSeparatorProps, TaskSeparatorState> {
  state = {
    taskCopy: null,
  }

  static getDerivedStateFromProps(props, state) {
    // not sure if this will get called too much
    if (props.task && !state.taskCopy) {
      return {
        taskCopy: props.task.clone(),
      }
    }

    return {};
  }

  enterEditMode = () => {
    const { tasksStore } = this.context;
    const { task } = this.props;

    this.setState({
      taskCopy: task.clone()
    },
      () => tasksStore.taskBeingEdited = this.props.task);
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.taskCopy.description = event.target.value;
  }

  exitEditMode = () => {
    const { tasksStore } = this.context;
    this.setState({
      taskCopy: null
    },
      () => tasksStore.taskBeingEdited = null);
  }

  saveDescription = () => {
    const { tasksStore } = this.context;

    tasksStore.addEditItem(this.state.taskCopy);
    this.exitEditMode();
  }

  _render() {
    const { tasksStore } = this.context;

    let task = this.state.taskCopy || this.props.task;
    const isEditMode = tasksStore.taskBeingEdited === this.props.task;

    return (
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.saveDescription} mouseEvent={isEditMode ? 'onClick' : false}>
        <div className={styles.root}>
          {isEditMode
            ? ([
              <TextField
                shouldFocusOnMount
                label={""}
                className={styles.textField}
                value={task.description}
                onChange={this.handleChange}
                margin="none"
                key="e1"
                onKeyUp={event => event.keyCode === 13 && this.saveDescription()}
              />,
              <div className={styles.formulaAcceptButtons} key="e2">
                <IconButton className={styles.cancelButtonIcon} onClick={this.exitEditMode}><CancelIcon /></IconButton>
                <IconButton className={styles.acceptButtonIcon} onClick={this.saveDescription}><AcceptIcon /></IconButton>
              </div>
            ]) : (
              <ListSubheader className={styles.description} component="div" onClick={this.enterEditMode}>
                {task.description}
              </ListSubheader>
            )}
        </div>
      </ClickAwayListener>
    )
  }
}