import { Button, IconButton, List } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/core/internal/svg-icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import { Omit } from '@material-ui/types';
import classnames from 'classnames';
import clsx from 'clsx';
import { BuiltinCategories } from 'constants/BuiltinCategories';
import { defer, first } from 'lodash';
import React, { CSSProperties, HTMLAttributes } from 'react';
import Select, { createFilter } from 'react-select';
import SelectCreatable from 'react-select/creatable';
import { MenuListComponentProps, MenuPortalProps } from 'react-select/lib/components/Menu';
import { ActionMeta } from 'react-select/lib/types';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ValueType } from 'react-select/src/types';
import { FixedSizeList } from 'react-window';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./ComboBox.module.scss');

interface OptionType {
  label: string;
  value: string;
  isEmpty?: boolean; // category doesn't contain any items
}

export function isFocused({ props: { isFocused } = {} } = {}) {
  return isFocused === true;
}

export function getFocusedIndex(children) {
  return Math.max(
    children.findIndex(isFocused),
    0
  );
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  const {
    inputValue,
    onCreateOption,
  } = props.selectProps;

  return onCreateOption ? (
    <div className={styles.addMissingOption}>
      {i18n.t(BuiltinCategories.General)}
    </div>
  ) : (
    <div
      className={styles.addMissingOption}
      dangerouslySetInnerHTML={{
        __html: i18n.t("No result found for <b>{{- item}}</b>", { item: inputValue })
      }}
    />
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, isDisabled },
  } = props;

  return (
    <TextField
      disabled={isDisabled}
      className={styles.textField}
      InputProps={{
        //className: classes.Input,
        inputComponent,
        inputProps: {
          className: styles.input + ' ' + classes?.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

const OptionWrapper = (props: OptionProps<OptionType>) => {
  const { innerProps, isFocused, ...otherProps } = props;
  const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
  const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
  return <Option {...newProps} />
}

class Option extends React.Component<OptionProps<OptionType>> {
  render() {
    const { data, isSelected, value, innerProps, selectProps, innerRef, isFocused } = this.props;
    const { SuggestionIcon, onEditOption, onDeleteOption } = selectProps;

    return (
      <MenuItem
        selected={isFocused}
        ref={innerRef}
        className={styles.menuItem}
        component="div"
        style={{
          height: 52,
          fontWeight: isSelected ? 500 : data.isEmpty ? 300 : 400,
          fontStyle: value === null ? 'italic' : '',
        }}
        {...innerProps}
      >
        <div className={styles.option}>
          {SuggestionIcon && <SuggestionIcon modelId={data.value} />}
          <div className={styles.label}>{data.label}</div>
        </div>
      </MenuItem>
    );
  }
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>;
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography className={styles.placeholder + ' combobox-placeholder'} {...innerProps} component="span">
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={styles.singleValue} {...props.innerProps} component="span">
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return <div className={styles.valueContainer}>{props.children}</div>;
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(styles.chip, {
        [styles.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}


function Menu(props: MenuProps<OptionType>) {
  const { children, className, cx, getStyles, innerRef, innerProps, selectProps } = props;
  const { onCreateOption, onManageClick, manageLabel, addNewLabel, menuStyles } = selectProps;

  return (
    <div
      className={classnames({ menu: true, [styles.isCreateable]: !!onCreateOption }, className, styles.paper)}
      {...innerProps}
      ref={innerRef}
      style={{...getStyles('menu', props), ...menuStyles }}
    >
      <div className={styles.paddedContainer} style={((!onManageClick && !onCreateOption)) ? { paddingBottom: 0 } : {}}>
        {children}
      </div>

      {
        (onManageClick || onCreateOption) && (
          <div className={styles.buttons}>
            {onCreateOption && (
              <Button
                onMouseDown={(event) => { event.stopPropagation(); setImmediate(() => { onCreateOption(); }); }}
                className={styles.button}
              >
                <AddIcon />
                &nbsp;
                {addNewLabel || i18n.t('Create new')}
              </Button>
            )}

            {onManageClick && (
              <Button
                className={styles.button}
                onMouseDown={(event) => { event.stopPropagation(); setImmediate(() => { onManageClick(); }); }}
              >
                <SettingsIcon />
                &nbsp;
                {manageLabel || i18n.t('Manage')}
              </Button>
            )}
          </div >
        )
      }
    </div >
  );
}

function MenuPortal(props: MenuPortalProps<OptionType>) {
  return (
    <div className={styles.portal}>
      {props.children}
    </div>
  );
}

const IndicatorsContainer = props => (
  <IconButton onClick={props.onClick}>
    <ArrowDropDownIcon className={
      props.selectProps.menuIsOpen ?
        'is-open' :
        ''
    } />
  </IconButton>
);

class MenuListItem extends React.Component {
  render() {
    const { style, index, data } = this.props;

    return (
      <div className="option-wrapper" style={style}>
        {data[index]}
      </div>
    );
  }
}

class MenuList extends React.PureComponent<MenuListComponentProps<OptionType>> {
  listRef;

  render() {
    const { children, maxHeight, options, getValue, selectProps } = this.props;
    if (!children || !Array.isArray(children)) {
      return children;
    }

    if (!selectProps.isVirtualized) {
      return (
        <List style={{ height: maxHeight - 53, overflowY: 'auto' }}>
          {children.map((child, index) => (
            <MenuListItem data={children} index={index} key={`child${index}`} />
          ))}
        </List>
      );
    }

    const height = 52;
    const selectedValues = getValue() as OptionType[];
    const focusedIndex = getFocusedIndex(children);
    const initialOffset = selectedValues[0] ? options.indexOf(selectedValues[0]) * height : 0;

    if (this.listRef && focusedIndex >= 0) {
      defer(() => {
        this.listRef?.scrollToItem?.(focusedIndex);
      })
    }

    return (
      <FixedSizeList
        ref={ref => this.listRef = ref}
        //width={''}
        itemSize={height}
        itemData={children}
        height={maxHeight - 53}
        itemCount={children.length}
        initialScrollOffset={initialOffset}
      >
        {MenuListItem}
      </FixedSizeList>
    )
  }
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
  //MenuPortal,
  MenuList,
};

interface ComboBoxProps {
  className?: string,
  onCreateOption?: (value?: string) => void,
  onEditOption?: (optionModelId: ModelId) => void,
  onDeleteOption?: (optionModelId: ModelId) => void,
  onManageClick?: (event: any) => void,
  onReorderOptions?: () => void,
  formatCreateLabel?: (value: string) => string,
  manageLabel?: string,
  addNewLabel?: string,
  label?: string,
  placeholder?: string,
  classes?: any,
  suggestions: OptionType[],
  SuggestionIcon: React.Component<{ modelId: ModelId }> | (({ modelId }: { modelId: ModelId; }) => JSX.Element),
  value: ValueType<OptionType>,
  onChange: (value: ValueType<OptionType>, action: ActionMeta) => void,
  disabled?: boolean,
  shouldFocusOnMount?: boolean,
  menuMinimumWidth: number,
  shouldSelectFocusedOptionOnBlur: boolean,
  isMulti?: boolean
  isVirtualized?: boolean,
  menuStyles?: any,
}

interface ComboBoxState {
  menuIsOpen: boolean,
}

class ComboBox extends ObserverComponent<ComboBoxProps, ComboBoxState> {
  state = {
    menuIsOpen: false,
  };

  static defaultProps = {
    isVirtualized: true,
  }

  selectRef;

  menuPortalCSS = (props) => {
    let { left, width } = props;

    // should be dynamic depending on longest option width
    const MENU_MIN_WIDTH = 360;

    if (width < MENU_MIN_WIDTH) {
      if (left - MENU_MIN_WIDTH > 0) {
        // align right
        left = left - (MENU_MIN_WIDTH - width);
      }

      width = MENU_MIN_WIDTH;
    }


    return ({
      ...props,
      left,
      width,
      zIndex: 1500, // 1300 is a dialog
    });
  }

  selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: 'black',

      '& input': {
        marginLeft: '3px',
        marginTop: '4px',
        font: 'inherit',
      },
    }),
    menuPortal: this.menuPortalCSS,
  };

  onInputChange = (props, { action }) => {
    if (action === "menu-close") {
      this.selectRef.nativeElement.blur();
      this.setState({ menuIsOpen: false });
    }
  };

  _render() {
    const { suggestions, value, classes, onChange, placeholder, onManageClick, onCreateOption, onEditOption, onDeleteOption, onReorderOptions, label, className, manageLabel, addNewLabel, disabled, formatCreateLabel, SuggestionIcon, shouldFocusOnMount, onKeyDown, shouldSelectFocusedOptionOnBlur, isMulti, isVirtualized, menuStyles } = this.props;

    const flattenedSuggestions = suggestions.map(suggestion => suggestion.options || suggestion).flat();
    const selectedSuggestions = flattenedSuggestions.filter(suggestion => suggestion.value === value || value?.includes?.(suggestion.value));

    // could add condition to make creatable or not
    const SelectComponent = onCreateOption ? SelectCreatable : Select;

    return (
      <SelectComponent
        ref={ref => this.selectRef = ref}
        autoFocus={shouldFocusOnMount}
        openMenuOnFocus
        isDisabled={disabled}
        className={classnames(className || '', { [styles.hasLabel]: label })}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        maxMenuHeight={500}
        styles={this.selectStyles}
        TextFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
          },
        }}
        placeholder={placeholder || ' '}
        options={suggestions}
        filterOption={createFilter({ stringify: option => option.data.label || '' })}
        //getOptionLabel={this.getOptionLabel(selectedSuggestion)}
        manageLabel={manageLabel}
        addNewLabel={addNewLabel}
        menuStyles={menuStyles}
        components={components}
        value={isMulti ? selectedSuggestions : (first(selectedSuggestions) || null)}
        onKeyDown={onKeyDown}
        onChange={onChange}
        autoComplete="off"
        ariaAutoComplete="off"
        onManageClick={onManageClick}
        onCreateOption={onCreateOption}
        onEditOption={onEditOption}
        onDeleteOption={onDeleteOption}
        onReorderOptions={onReorderOptions}
        onMenuClose={() => {;}}
        formatCreateLabel={formatCreateLabel}
        SuggestionIcon={SuggestionIcon}
        classes={classes}
        //onFocus={() => console.log('combobox focus', document.activeElement)}
        //onBlur={() => console.log('combobox blur')}
        shouldSelectFocusedOptionOnBlur={shouldSelectFocusedOptionOnBlur}
        isMulti={isMulti}
        isVirtualized={isVirtualized}
      //menuIsOpen={menuIsOpen}
      //onFocus={() => this.setState({ menuIsOpen: true })}
      //onInputChange={this.onInputChange}
      //onClick={() => this.setState({ menuIsOpen: true })}
      />
    );
  }
}

export default ComboBox;
