
import { Button } from '@material-ui/core';
import * as React from 'react';
import { firestore } from 'utils/FirebaseInitializedApp';
import ObserverComponent from '../ObserverComponent';
;

const styles = require('./DebugView.module.scss');

interface IDebugViewState {
  isDeleteDone: boolean,
}

export default class DebugView extends ObserverComponent<IDebugViewState> {
  state = {
    isDeleteDone: false,
  }

  componentDidMount() {
    //ProjectsStore.selectedProjectId = 'test';
  }

   debugReset = async () => {
    const { projectsStore } = this.context;
    await Promise.all([
      projectsStore.DEBUGdeleteAllItems(),
    ]);


    this.setState({ isDeleteDone: true });
  }

  temp = () => {

    const db = firestore();

    const projectsUrl = '/users/batisoft@gmail.com/projects';

    const projectsRef = db.collection(projectsUrl);
    projectsRef.get().then(value => {
      value.docs.forEach(doc => {
        const treeNodesRef = db.collection(projectsUrl + '/' + doc.id + '/treeNodes');
        treeNodesRef.get().then(value => {
          value.docs.forEach(nodeDoc => {
            db.doc(projectsUrl + '/' + doc.id + '/treeNodes/' + nodeDoc.id)
              .update({ isDeleted: false });
          });
        });
      });
    });
  }

  _render() {
    const { isDeleteDone } = this.state;
    return isDeleteDone
      ? (
        <div>Les projets ont été effacés</div>
      ) : (
        <div className={styles.root} id="debugbuttoncontainer">
          <Button onClick={this.temp}>temp feature</Button>
          <Button id="debugbutton" variant="contained" color="primary" onClick={this.debugReset}>Effacer tous les projets!</Button>
          <br />
          <br />
          Il faut rafraîchir la page de projet après avoir cliqué sur le bouton

      </div>
      );
  }
}