import { MeasurementType } from "constants/MeasurementType";
import { computed, observable } from "mobx";
import { serializable } from "serializr";
import Stores from 'stores/Stores';
import MeasurementValue from "./MeasurementValue";
import ModelBase from "./ModelBase";

// not saved to db, but needed to be able to duplicate when editing
export default class DrawToolsOption extends ModelBase {
  type = 'DrawToolOption';
  @serializable @observable measurementType: MeasurementType = MeasurementType.WallHeight;
  @serializable @observable optionValue = 0;

  @computed get formula(): MeasurementValue {
    const { measurementsStore } = this.stores;
    if (!measurementsStore.isReady) {
      return null;
    }

    return new MeasurementValue(
      this.stores,
      null,
      measurementsStore.getItem(this.measurementType), (
        this.optionValue
          ? '' + this.optionValue
          : undefined
      )
    );
  }

  // pas sur
  set formula(newFormula: MeasurementValue) {
    this.optionValue = newFormula.metricValue;
  }

  constructor(stores: Stores, measurementType: MeasurementType) {
    super(stores);
    this.measurementType = measurementType;
  }
}
