
import { Dialog, DialogProps, Paper } from '@material-ui/core';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import Draggable from 'react-draggable';

const styles = require('./DraggableDialog.module.scss');

const onDragStart = (event) => {
  if (event.target.tagName !== 'DIV') {
    return false;
  }
}

function PaperComponent(props) {
  return (
    <Draggable onStart={onDragStart} bounds="parent" handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default class DraggableDialog extends ObserverComponent<DialogProps> {
  _render() {
    return (
      <Dialog
        {...this.props}
        PaperComponent={PaperComponent}
      />
    )
  }
}