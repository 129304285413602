import Task from 'models/Task';
import ReportHeaderRow from './ReportHeaderRow';

export type ReportRow = ReportHeaderRow | ReportBodyRow;

export interface ReportRowsGroup {
  type: 'tbody' | 'thead',
  rows: ReportRow[],
}

export class ReportBodyRow {
  type = 'ReportBodyRow';
  constructor(public task: Task, public indentLevel: number) { }
}

