
import * as React from 'react';
import i18n from 'utils/i18n';
import FeesDetailsForm from '../FeesDetailsForm/FeesDetailsForm';
import ObserverComponent from '../ObserverComponent';
import TaxesCalculationDetailsForm from '../TaxesCalculationDetailsForm/TaxesCalculationDetailsForm';

const styles = require('./ReportTaxesOptions.module.scss');

export default class ReportTaxesOptions extends ObserverComponent {
  _render() {
    const { settingsStore, userInfoStore } = this.context;
    const { user } = userInfoStore;

    return (
      <div className={styles.root}>
        <FeesDetailsForm isTax models={[settingsStore.settings]} />

        {user?.shouldShowTaxesFilter && (<>
          <div className={styles.subheader}>
            {i18n.t('Taxes Calculation Options')}
          </div>

          <TaxesCalculationDetailsForm models={[settingsStore.settings]} />
        </>)}

      </div>
    )
  }
}