import BaseStore from './BaseStore';

export default class DbCacheStore extends BaseStore {
  // db path -> object
  cache = new Map<string, any>();


  // no gain to make by not clearing everything because, store still needs to subscribe to user collection
  // to listen for changes
  clearProjectSpecificCache() {
    this.cache.clear();

    /*
    Object.keys(this.cache).forEach(key => {
      if (
        key.includes(`/users/${this.stores.userInfoStore.userEmail}/projects/`) ||
        key.includes(`/users/master/projects/`)
      ) {
        this.cache.delete(key);
      }
    });
    */
  }
}
