
import { FilledInput, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { IEditableProps, IReportProps } from 'constants/CommonProps';
import { ReportSubtypes } from 'constants/ReportOptionsConstants';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

const styles = require('./ReportTypeComponent.module.scss');

export default class ReportTypeComponent extends ObserverComponent<IReportProps & IEditableProps> {
  changeType = ({ target: { value } }) => {
    const { reportsStore } = this.context;
    const { report } = this.props;
    report.subtype = value;
    reportsStore.addEditItem(report, true, ['subtype']);
  }

  _render() {
    const { isEditable, report } = this.props;

    return isEditable ? (
      <FormControl className={styles.root + ' ' + styles.isEditable} margin="normal">
        <InputLabel>{i18n.t('Document Type')}</InputLabel>
        <Select
          value={report.subtype}
          onChange={this.changeType}
          input={<FilledInput className={styles.input} />}
        >
          {Object.values(ReportSubtypes).map((type, index) => (
            <MenuItem key={index} value={type}>{i18n.t(type)}</MenuItem>
          ))}
          {/*<MenuItem value={'other'}>{i18n.t('Autre')}</MenuItem>*/}
        </Select>
      </FormControl>
    ) : (
        <div className={styles.root}>
          {i18n.t(report.subtype)}
        </div>
      )
  }
}