import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import { observer } from 'mobx-react';
import Project from 'models/Project';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import ComboBox from '../ComboBox/ComboBox';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';

const styles = require('./CopyNodeToExternalProjectDialog.module.scss');

interface CopyNodeToExternalProjectProps extends IDialogProps {
  node: TreeNode;
}

// should better think how to store selected node with multiple trees shown
interface CopyNodeToExternalProjectDialogState {
  selectedDestinationProject: Project,
  isCopying: boolean
}

export default class CopyNodeToExternalProjectDialog extends ObserverComponent<CopyNodeToExternalProjectProps, CopyNodeToExternalProjectDialogState> {
  state = {
    selectedDestinationProject: null,
   isCopying: false,
  }

  onConfirm = async () => {
    const { treeNodesStore } = this.context;
    const { node } = this.props;
    const { selectedDestinationProject } = this.state;
    this.setState({isCopying: true});

    const nodeCopy =  await treeNodesStore.duplicateNodeToExternalProject(node, selectedDestinationProject);

    this.setState({isCopying: false});

    return nodeCopy;
  }

  onChangeProject = (target) => {
    const { projectsStore } = this.context;
    this.setState({selectedDestinationProject: projectsStore.getItem(target.value)});
  }

  _render() {
    const { open, dialogId, node } = this.props;
    const { selectedDestinationProject, isCopying } = this.state;
    const { projectsStore } = this.context;

    return (
      <ConfirmDialog
        dialogId={dialogId}
        open={open}
        title={i18n.t('Choose project where to copy group "{{nodeName}}"', { nodeName: node.name, interpolation: {escapeValue: false} })}
        onConfirm={this.onConfirm}
        yesLabel={i18n.t('Proceed with copy')}
        noLabel={i18n.t('Cancel')}
        isActionButtonDisabled={!selectedDestinationProject}
        content={
          <div className={styles.root}>
            <div>
              <div style={{marginTop:4, marginBottom:4}}>
                {i18n.t('Copy group "{{nodeName}}" to :', { nodeName: node.name, interpolation: {escapeValue: false} })}
              </div>
              <ComboBox
                shouldFocusOnMount
                className={styles.projectCombobox}
                label={i18n.t('Project')}
                value={selectedDestinationProject?.id || null}
                onChange={this.onChangeProject}
                suggestions={projectsStore.allItemsByCategSubcategFlattened.filter(i => i.item && i.item.id !== projectsStore.selectedProject.id).map(i =>
                  ({
                    index: i.item.index,
                    value: i.item.id,
                    label: i.item.name,
                  }))
                }
                SuggestionIcon={observer(({ modelId }: { modelId: ModelId }) => <AvatarImageWithFallback model={projectsStore.getItem(modelId)} />)}
              />
            </div>

            {isCopying && <LoadingOverlay message={i18n.t('Copying in progress...')} />}
          </div>
        }
      />
    );
  }
}