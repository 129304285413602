
import { FilledInput, IconButton, MenuItem, Select, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { IEditComponentProps } from 'constants/CommonProps';
import { FeeCategoriesOptions } from 'constants/FeeCategoriesOptions';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { isEmpty, remove } from 'lodash';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import Fee from 'models/Fee';
import Settings from 'models/Settings';
import Tax from 'models/Tax';
import * as React from 'react';
import { InputNumberFormatPercent, InputNumberFormatPercentTax } from 'utils/NumberFormatter';
import i18n from 'utils/i18n';
import Button from '../Button/Button';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import ObserverComponent from '../ObserverComponent';
import Table from '../Table/Table';
import TextField from '../TextField/TextField';

const colors = require('../../../Colors.scss');
const styles = require('./FeesDetailsForm.module.scss');

interface FeesDetailsFormProps {
  isTax?: boolean,
}

export default class FeesDetailsForm extends ObserverComponent<IEditComponentProps & FeesDetailsFormProps, FeesDetailsFormState> {
  get settings() {
    return this.props.models[0] as Settings;
  }

  get isWorkingOnACopy() {
    const { settingsStore } = this.context;
    return this.settings !== settingsStore.settings;
  }

  get fees() {
    return this.props.isTax
      ? this.settings.taxes
      : this.settings.fees;
  }

  handleChange = (feeIndex: number, propertyName: keyof Tax) => ({ target: { value } }) => {
    const isNumber = propertyName === 'percentage';
    this.fees[feeIndex][propertyName] = isNumber ? parseFloat(value) : value;
  }

  askToDeleteRow = (feeIndex: number) => () => {
    const { dialogsStore } = this.context;
    const { isTax } = this.props;

    if (this.isWorkingOnACopy) {
      this.deleteRow(feeIndex);
    } else {
      const newDialog = new Dialog(this.context);
      newDialog.dialogComponent = ({ open }) => (
        <ConfirmDialog
          open={open}
          dialogId={newDialog.id}
          onConfirm={() => this.deleteRow(feeIndex)}
          // not using i18n interpolation because of 'cette taxe' vs 'ce frais' (gender)
          title={i18n.t(`Do you really want to delete this ${isTax ? 'tax' : 'fee'}?`)}
          actionButtonColor={colors.red}
        />
      );
      dialogsStore.showDialog(newDialog);
    }
  }

  deleteRow = (feeIndex: number) => {
    this.fees.splice(feeIndex, 1);

    this.saveToDbIfNotWorkingOnACopy();
  }

  addRow = () => {
    const { isTax } = this.props;
    this.fees.push(isTax ? new Tax(this.context) : new Fee(this.context));

    this.saveToDbIfNotWorkingOnACopy();
  }

  saveToDbIfNotWorkingOnACopy = () => {
    const { settingsStore } = this.context;
    const { isTax } = this.props;

    if (!this.isWorkingOnACopy) {
      settingsStore.addEditItem(this.settings, true, [isTax ? 'taxes' : 'fees']);
    }
  }

  handleSubtypeChange = (feeIndex) => ({ target: { value } }) => {
    this.fees[feeIndex].providingItemsSubtypes = JSON.parse(value);
  }

  handleChangeCategoryOption = (feeIndex) => ({ target: { value } }) => {
    const fee = this.fees[feeIndex];
    fee.categorySelectedOption = value;
  }

  handleChangeCategories = (feeIndex) => (category: Category, action: 'select-option' | 'deselect-option') => {
    const fee = this.fees[feeIndex];

    if (fee.categorySelectedOption === FeeCategoriesOptions.ChooseExcluded) {
      if (action === 'select-option') {
        fee.excludedProvidingItemsCategoryIds.push(category.id);
      } else if (action = 'deselect-option') {
        remove(fee.excludedProvidingItemsCategoryIds, categoryId => categoryId === category.id);
      } else {
        debugger;
      }
    } else if (fee.categorySelectedOption === FeeCategoriesOptions.ChooseIncluded) {
      if (action === 'select-option') {
        fee.providingItemsCategoryIds.push(category.id);
      } else if (action = 'deselect-option') {
        remove(fee.providingItemsCategoryIds, categoryId => categoryId === category.id);
      } else {
        debugger;
      }
    } else {
      debugger;
    }

    this.saveToDbIfNotWorkingOnACopy();
  }

  _render() {
    const settings = this.settings;
    const fees = this.fees;
    const { isTax } = this.props;

    return (
      <div className={styles.root}>
        <Table style={isTax ? {} : { tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 200 }}>{isTax ? i18n.t('Tax name') : i18n.t('Fee name')}</TableCell>
              {isTax && <TableCell>{i18n.t('Tax #')}</TableCell>}
              <TableCell align="right" style={isTax ? {} : { width: 80 }}>{i18n.t('Rate')}</TableCell>
              {!isTax && <TableCell align="left">{i18n.t('Item Types')}</TableCell>}
              {!isTax && <TableCell align="left">
                <div className={styles.itemCategoriesHeader}>
                  {i18n.t('Item Categories')}
                  <Tooltip title={i18n.t('Category used to classify the item in "My Items" list (right sidebar). Not to be confused with the category of the task (middle section).')}><InfoIcon style={{ width: '18px' }} /></Tooltip>
                </div>
              </TableCell>
              }
              {!isTax && this.fees.find(fee => fee.categorySelectedOption !== FeeCategoriesOptions.All) && <TableCell style={{ width: '350px' }} /> /* item categories part 2 */}
              <TableCell style={{ width: '40px' }} />
            </TableRow>
          </TableHead>

          <TableBody>
            {
              fees.map((fee, feeIndex) => {
                const feeSubtypes = isEmpty(fee.providingItemsSubtypes)
                  ? Object.values(ProvidingItemSubtype)
                  : fee.providingItemsSubtypes;

                return (
                  <TableRow
                    key={feeIndex}
                  >
                    <TableCell>
                      <TextField
                        id={`fee name ${feeIndex}`}
                        value={fee.name}
                        onChange={this.handleChange(feeIndex, 'name')}
                        onBlur={this.saveToDbIfNotWorkingOnACopy}
                        inputProps={{ style: { textAlign: 'left' } }}
                        margin="none"
                        fullWidth
                      />
                    </TableCell>

                    {isTax && (
                      <TableCell>
                        <TextField
                          id={`tax number ${feeIndex}`}
                          value={(fee as Tax).number}
                          onChange={this.handleChange(feeIndex, 'number')}
                          onBlur={this.saveToDbIfNotWorkingOnACopy}
                          inputProps={{ style: { textAlign: 'left' } }}
                          margin="none"
                          fullWidth
                          placeholder={fee._name.fr === 'TPS' ? '000000000 RT0001' : fee._name.fr === 'TVQ' ? '0000000000 TQ0001' : ''}
                        />
                      </TableCell>
                    )}

                    <TableCell>
                      <TextField
                        id={`fee percentage ${feeIndex}`}
                        value={fee.percentage}
                        onChange={this.handleChange(feeIndex, 'percentage')}
                        onBlur={this.saveToDbIfNotWorkingOnACopy}
                        margin="none"
                        inputProps={{ style: { textAlign: 'right' } }}
                        fullWidth
                        InputProps={{
                          inputComponent: isTax ? InputNumberFormatPercentTax : InputNumberFormatPercent
                        }}
                      />
                    </TableCell>
                    {!isTax && (
                      <>
                        <TableCell>
                          <Select
                            value={JSON.stringify(fee.providingItemsSubtypes)}
                            onChange={this.handleSubtypeChange(feeIndex)}
                            onBlur={this.saveToDbIfNotWorkingOnACopy}
                            input={<FilledInput className={styles.textFieldNoLabel} />}
                          >
                            <MenuItem value={"[]"}>{i18n.t('All types')}</MenuItem>
                            <MenuItem value={'["Material"]'}>{i18n.t('Material only')}</MenuItem>
                            <MenuItem value={'["Labour"]'}>{i18n.t('Labour and fees only')}</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            value={fee.categorySelectedOption}
                            onChange={this.handleChangeCategoryOption(feeIndex)}
                            input={<FilledInput className={styles.textFieldNoLabel} />}
                          >
                            <MenuItem value={FeeCategoriesOptions.All}>{i18n.t('All categs.')}</MenuItem>
                            <MenuItem value={FeeCategoriesOptions.ChooseIncluded}>{i18n.t('Applicable categories')}</MenuItem>
                            <MenuItem value={FeeCategoriesOptions.ChooseExcluded}>{i18n.t('Excluded categories')}</MenuItem>
                          </Select>
                        </TableCell>
                        {this.fees.find(fee => fee.categorySelectedOption !== FeeCategoriesOptions.All) && (
                          <TableCell className={styles.categoriesComboboxCell}>
                            {fee.categorySelectedOption !== FeeCategoriesOptions.All && (
                              <CategoriesCombobox
                                placeholder={fee.categorySelectedOption === FeeCategoriesOptions.ChooseIncluded ? i18n.t('Choose applicable categories') : i18n.t('Choose excluded categories')}
                                className={styles.categoriesCombobox}
                                selectedCategories={
                                  fee.categorySelectedOption === FeeCategoriesOptions.ChooseExcluded
                                    ? fee.excludedProvidingItemsCategories
                                    : fee.providingItemsCategories
                                }
                                categoryTypes={feeSubtypes}
                                onChange={this.handleChangeCategories(feeIndex)}
                                inputClassName={styles.categoriesComboboxInput}
                                shouldHideBottomBar
                                isMulti
                              />
                            )}
                          </TableCell>
                        )}
                      </>
                    )}
                    <TableCell>
                      <div className="rowHoverButtons">
                        <IconButton onClick={this.askToDeleteRow(feeIndex)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Button onClick={this.addRow}>
          <AddIcon />
          {isTax ? i18n.t('Add a tax') : i18n.t('Add a fee')}
        </Button>
      </div>
    )
  }
}