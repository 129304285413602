import { DbLocationType } from 'constants/DbLocationType';
import CopiedTask from 'models/CopiedTask';
import FirebaseStore from 'stores/FirebaseStore';

class CopiedTasksStore extends FirebaseStore<CopiedTask>   {
  storeKey = 'copiedTasks';

  dbLocationsTypes = new Set([
    DbLocationType.User, 
  ]);

}

export default CopiedTasksStore;
