
import { BottomNavigationAction, BottomNavigationActionProps, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { DrawToolType } from 'constants/DrawToolType';
import * as React from 'react';
import { drawToolIconsByType, shortcutKeysByDrawToolTypes } from 'utils/DrawToolsUtils';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

const styles = require('./DrawToolButton.module.scss');

interface DrawToolButtonBaseProps {
  showLabel?: boolean,
  placement?: 'left' | 'bottom',
  toolType?: DrawToolType,
  disabledTitle?: string,
}

export type DrawToolButtonProps = DrawToolButtonBaseProps & BottomNavigationActionProps;

class DrawToolButton extends ObserverComponent<DrawToolButtonProps> {
  boldenShortcutKey(shortcutKey: string, label: string) {
    if (!shortcutKey) {
      return <span>{label}</span>;
    }

    const shortcutKeyIndex = label.toLowerCase().indexOf(shortcutKey);
    const hasShortcut = (shortcutKey && shortcutKeyIndex >= 0);

    const labelPart1 = hasShortcut
      ? label.substring(0, shortcutKeyIndex)
      : label;

    const labelPart2 = hasShortcut && label.substring(shortcutKeyIndex, shortcutKeyIndex + 1);
    const labelPart3 = hasShortcut && label.substring(shortcutKeyIndex + 1)

    return <span>{labelPart1}<b>{labelPart2 || ''}</b>{labelPart3 || ''}</span>;
  }

  _render() {
    const { drawToolsStore } = this.context;
    const { placement, toolType, disabled, disabledTitle } = this.props;


    const label = i18n.t(toolType);
    const shortcutKey = shortcutKeysByDrawToolTypes(i18n.language).get(toolType) || null;
    const labelWithBoldShortcutKey = this.boldenShortcutKey(shortcutKey as string, label);

    return (
      <Tooltip
        classes={{
          popper: styles.tooltip
        }}
        title={disabled ? disabledTitle : label}
        placement={placement || 'left'}
      >
        {/* extra div to allow tooltip to work on disabled button (no pointer events) */}
        <div> 
          <BottomNavigationAction
            {...this.props}
            showLabel
            value={toolType}
            className={classnames(styles.button, {[styles.disabled]: disabled})}
            selected={drawToolsStore.selectedTool === toolType}
            icon={drawToolIconsByType[toolType]}
            label={labelWithBoldShortcutKey}
          />
        </div>
      </Tooltip>
    )
  }
}

export default DrawToolButton;