import { observable } from 'mobx';
import { serializable } from "serializr";
import { localized } from "utils/localized";
import uuidv4 from 'uuid/v4';
import ModelBase from './ModelBase';

class ProvidingItemMeta extends ModelBase {
  @serializable type = 'ProvidingItemMeta';
  @observable @serializable id: ModelId = uuidv4();

  @localized description = '';

  // DO NOT USE (only there for compatibility)
  @localized url = '';
}

export default ProvidingItemMeta;