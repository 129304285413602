
import { observer } from 'mobx-react';
import TasksList from 'models/TasksList';
import * as React from 'react';

const styles = require('./TasksListAvatar.module.scss');

interface ITasksListAvatarProps {
  tasksList: TasksList,
  className?: string,
}

@observer
export default class TasksListAvatar extends React.Component<ITasksListAvatarProps> {
  render() {
    return null; // todo!
    /*const { tasksList, className } = this.props;

    const nonSeparatorTasks = tasksList.tasks
      .filter(task => getSafe(() => task.providingItem.thumbUrl));

    const numColumns = (Math.min(4, Math.ceil(Math.sqrt(nonSeparatorTasks.length))));

    return (
      <Avatar className={styles.bigImage + ' ' + className}>
        {nonSeparatorTasks
          .map(task => {
            let src = getSafe(() => task.providingItem.thumbUrl);

            return <img src={src} style={{ width: `calc(100% / ${numColumns})` }} />;
          })}
      </Avatar>
    )*/
  }
}