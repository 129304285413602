import { FilledInput, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, Tooltip } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import LanguageIcon from '@material-ui/icons/Language';
import SettingsIcon from '@material-ui/icons/Settings';
import SyncIcon from '@material-ui/icons/Sync';
import { IEditComponentProps } from 'constants/CommonProps';
import { Currency } from 'constants/Currency';
import { RoundingMethod } from 'constants/RoundingMethod';
import { UnitSystem } from 'constants/UnitSystem';
import Settings from 'models/Settings';
import User from 'models/User';
import * as React from 'react';
import { handleChange, handleCheckboxChange } from 'utils/FormUtils';
import { InputNumberFormatPercent } from 'utils/NumberFormatter';
import i18n from 'utils/i18n';
import { HelmetIcon, LabIcon, MeasuringTapeIcon } from '../Icons';

import { isEmpty } from 'lodash';
import { compressDrawingShapes } from 'utils/TreeUtil';
import { sleep } from 'utils/Utils';
import ConfirmDeleteButton from '../ConfirmDeleteButton/ConfirmDeleteButton';
import ObserverComponent from '../ObserverComponent';
import ReplaceLabourRatesComponent from '../ReplaceLabourRatesComponent/ReplaceLabourRatesComponent';
import ReplaceWasteComponent from '../ReplaceWasteComponent/ReplaceWasteComponent';
import TextField from '../TextField/TextField';

const styles = require('./SettingsDetailsForm.module.scss');

export default class SettingsDetailsForm extends ObserverComponent<IEditComponentProps> {
  get settings() {
    return this.props.models[0] as Settings;
  }

  get user() {
    return this.props.models[1] as User;
  }

  get nonImpersonatedUser() {
    return (this.props.models[2] || this.props.models[1]) as User;
  }

  _render() {
    const { settingsStore, providingItemsStore } = this.context;
    return (
      <div className={styles.root}>
        {/*<div className={styles.header}>Général</div>*/}
        <div className={styles.formRow}>
          <LanguageIcon className={styles.formIcon} />

          <FormControl className={styles.textField} margin="normal">
            <InputLabel>{i18n.t('Language')}</InputLabel>
            <Select
              value={this.settings.language}
              onChange={handleChange(this.settings, 'language')}
              input={<FilledInput />}
            >
              {settingsStore.supportedLanguages.map(lang => (
                <MenuItem key={lang} value={lang}>{i18n.t(lang)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.formRow}>
          <MeasuringTapeIcon className={styles.formIcon} />

          <FormControl className={styles.textField} margin="normal">
            <InputLabel>{i18n.t('Unit System')}</InputLabel>
            <Select
              value={this.settings.unitSystem}
              onChange={handleChange(this.settings, 'unitSystem')}
              input={<FilledInput />}
            >
              <MenuItem value={UnitSystem.Imperial}>{i18n.t('Imperial')}</MenuItem>
              <MenuItem value={UnitSystem.Metric}>{i18n.t('Metric')}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={styles.formRow}>
          <MoneyIcon className={styles.formIcon} />

          <FormControl className={styles.textField} margin="normal">
            <InputLabel>{i18n.t('Currency')}</InputLabel>
            <Select
              value={this.settings.currency}
              onChange={handleChange(this.settings, 'currency')}
              input={<FilledInput />}
            >
              <MenuItem value={Currency.CanadianDollar}>{i18n.t('Dollar ($)')}</MenuItem>
              <MenuItem value={Currency.Euro}>{i18n.t('Euro (€)')}</MenuItem>
              <MenuItem value={Currency.Pound}>{i18n.t('Pound (£)')}</MenuItem>
              <MenuItem value={Currency.Yen}>{i18n.t('Yen (¥)')}</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* 
// labour rounding should be by hour, but doesn't currently work

        <div className={styles.formRow} style={{ marginTop: 5 }}>
          <HelmetIcon className={styles.formIcon} />

          <FormGroup>
            <FormControlLabel
              className={styles.textField}
              margin="normal"
              control={
                <div style={{ marginLeft: -7 }}>
                  <Switch
                    checked={this.settings.shouldRoundLabour}
                    onChange={handleCheckboxChange(this.settings, 'shouldRoundLabour')}
                  />
                </div>
              }
              label={i18n.t('Round up labour duration')} />
          </FormGroup>
        </div>


*/}

        <div className={styles.formRow} style={{ marginTop: 5 }}>
          <SettingsIcon className={styles.formIcon + ' ' + styles.hasMultipleOptions} />
          <div className={styles.formColumn}>
            <FormControl className={styles.textField} margin="normal">
              <InputLabel>{i18n.t('Default rounding method')}</InputLabel>
              <Select
                value={this.settings.defaultRoundingMethod}
                onChange={handleChange(this.settings, 'defaultRoundingMethod')}
                input={<FilledInput />}
              >
                <MenuItem value={RoundingMethod.RoundByProject}>{i18n.t('Round quantity for project')}</MenuItem>
                <MenuItem value={RoundingMethod.RoundByTask}>{i18n.t('Round quantity of each task')}</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label={i18n.t("Default Waste % for new items")}

              value={this.user.defaultWastePercent}
              onChange={handleChange(this.user, 'defaultWastePercent')}
              shouldSelectAllOnFocus
              InputProps={{
                inputComponent: InputNumberFormatPercent
              }}
            />
          </div>

        </div>

        <div id="shouldAutoSaveMyItems" className={styles.formRow} style={{ marginTop: 5, display: 'none' }}>
          <SyncIcon className={styles.formIcon + ' ' + styles.formIconAlignTop} />

          <div className={styles.formColumn}>
            <Tooltip title={i18n.t('Items added or modified in the middle section "Tasks by categories" can be synced automatically (or not) to the right section "My Items".')}>
              <FormGroup>
                <FormControlLabel
                  className={styles.textField}
                  margin="normal"
                  control={
                    <div style={{ marginLeft: -7 }}>
                      <Switch
                        checked={this.nonImpersonatedUser.shouldAutoSyncProvidingItems}
                        onChange={handleCheckboxChange(this.nonImpersonatedUser, 'shouldAutoSyncProvidingItems')}
                      />
                    </div>
                  }
                  label={i18n.t('Auto-save project items to "My Items"')} />
              </FormGroup>
            </Tooltip>
          </div>
        </div>

        {this.user.versionOnCreation <= 2 && (
          <div id="canChangeEvalumoItems" className={styles.formRow} style={{ marginTop: 5 }}>
            <LabIcon className={styles.formIcon} />
            <div className={styles.formColumn}>
              <FormGroup>
                <FormControlLabel
                  className={styles.textField}
                  margin="normal"
                  control={
                    <div style={{ marginLeft: -7 }}>
                      <Switch
                        checked={this.user.canChangeEvalumoItems}
                        onChange={handleCheckboxChange(this.user, 'canChangeEvalumoItems')}
                      />
                    </div>
                  }
                  label={i18n.t('Allow changing formulas created by Evalumo')} />
              </FormGroup>
            </div>
          </div>
        )}

        <div className={styles.formRow} style={{ marginTop: 5 }}>
          <HelmetIcon className={styles.formIcon + ' ' + styles.formIconAlignTop} />

          {/* to override by css, because not something we want to use for many people the way it is */}
          <div className={styles.formColumn}>
            <FormGroup id="shouldAlwaysShowTimeInReports" style={{ display: 'none' }} >
              <FormControlLabel
                className={styles.textField}
                margin="normal"
                control={
                  <div style={{ marginLeft: -7 }}>
                    <Switch
                      checked={this.user.shouldAlwaysShowTimeInReports}
                      onChange={handleCheckboxChange(this.user, 'shouldAlwaysShowTimeInReports')}
                    />
                  </div>
                }
                label={i18n.t('Always show labour hours in reports tasks')} />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                className={styles.textField}
                margin="normal"
                control={
                  <div style={{ marginLeft: -7 }}>
                    <Switch
                      checked={this.settings.shouldAllowWasteForLabour}
                      onChange={handleCheckboxChange(this.settings, 'shouldAllowWasteForLabour')}
                    />
                  </div>
                }
                label={i18n.t('Use a waste % on labour')} />
            </FormGroup>

            <ReplaceLabourRatesComponent />
            <br />
            <ReplaceWasteComponent />


            <ConfirmDeleteButton
              className={styles.deleteButton}
              style={{ display: 'none' }}
              onClick={async () => {
                const { treeNodesStore } = this.context;

                await treeNodesStore.allVisibleNodes.reduce(async (previousTask, node) => {
                  await previousTask;

                  if (
                    !node.childDrawingNode ||
                    isEmpty(node.childDrawingNode.shapesIds)
                  ) {
                    return '';
                  }

                  if (!node.childDrawingNode.bubbleDescendants.some(bubbleDescendant => bubbleDescendant.ownMeasurementValuesArray.length > 0)) {
                    await compressDrawingShapes(node.childDrawingNode);
                  } else {
                    await Promise.all(node.childDrawingNode.children.map(async (childNode) => {
                      if (
                        !childNode.bubbleDescendants.some(bubbleDescendant => bubbleDescendant.ownMeasurementValuesArray.length > 0) &&
                        !isEmpty(node.shapesIds)
                      ) {
                        await compressDrawingShapes(childNode);
                      }
                    }));
                  }

                  await sleep(500);
                  return '';
                }, Promise.resolve(''));
              }}
              text={i18n.t('Compress Drawings')}
            />
          </div>
        </div>
      </div >
    )
  }
}