/*
import PhoneIcon from '@material-ui/icons/CallOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import WebsiteIcon from '@material-ui/icons/Public';*/
import * as classnames from 'classnames';
import { isEmpty, times, zipObject } from 'lodash';
import Report from 'models/Report';
import User from 'models/User';
import UserColors from 'models/UserColors';
import * as React from 'react';
import { handleChange } from 'utils/FormUtils';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import i18n from 'utils/i18n';
import RGBaster from 'utils/rgbaster';
import ButtonOverlay from '../ButtonOverlay/ButtonOverlay';
import { LogoPlaceholderIcon } from '../Icons';
import ImageBox from '../ImageBox/ImageBox';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./ReportHeader.module.scss');

interface IReportHeaderProps {
  isEditable?: boolean,
}

function prepareLine1(items: string[]) {
  return items
    .filter(item => !isEmpty(item))
    .join(', ');
}

function prepareLine2(items: [string, string][]) {
  return items
    .filter(item => !isEmpty(item[1]))
    .map(item => <span className={styles.lineItem}>{isEmpty(item[0]) ? item[1] : item.join(': ')}</span>)
}

export default class ReportHeader extends ObserverComponent<IReportHeaderProps> {
  handleChange = (field: keyof User) => event => {
    const { userInfoStore } = this.context;
    let { value } = event.target;
    const { user } = userInfoStore;

    user[field] = value;
  }

  // ALL fields in this component are present both in user and report objects
  saveReportToDb = (fieldName: keyof Report & keyof User) => () => {
    const { reportsStore, userInfoStore } = this.context;
    const { user } = userInfoStore;
    const report = reportsStore.selectedItem;
    if (report) {
      reportsStore.addEditItem(report, true, [fieldName]);
    }
    if (user) {
      user[fieldName] = report[fieldName] === 'undefined' ? '' : report[fieldName];
      userInfoStore.addEditItem(user, true, [fieldName]);
    }
  }

  detectLogoColor = () => {
    const { reportsStore, userInfoStore } = this.context;
    const report = reportsStore.selectedItem;

    RGBaster.colors(report.imageUrl, {
      paletteSize: 1,
      success: function (color) {
        report.logoColors = new UserColors(color);
        reportsStore.addEditItem(report, true, ['_logoColors']);
      }
    })

    trackInteraction(TrackedInteractions.UploadLogo);
  }

  _render() {
    const { userInfoStore, reportsStore, subscriptionsStore } = this.context;
    const { isEditable } = this.props;
    const { user } = userInfoStore;

    if (!user) {
      return null;
    }

    const report = reportsStore.selectedItem;

    const { logoWidth, logoHeight, reportHeaderPaddingTop } = user;

    const companyFieldsKeys = ['street', 'city', 'province', 'postalCode', 'phoneNumber', 'companyEmail', 'website', 'companyName', 'other'];

    const companyFields = zipObject(companyFieldsKeys, companyFieldsKeys.map(field => (
      report[field] === 'undefined'
        ? (user?.[field] && user[field] !== 'undefined' ? user[field] : '')
        : report[field]
    )));

    return (
      <div
        className={classnames(styles.root, { [styles.isEditable]: isEditable })}
        style={{ paddingTop: reportHeaderPaddingTop }}
      >
        {!isEditable && subscriptionsStore.isTrial && (
          <div className={styles.trialWatermark}>
            {times(6, i => (
              <div key={i}>
                {i18n.t('Trial Version')}
              </div>
            ))}
          </div>
        )}

        <ImageBox
          className={styles.logo}
          placeHolderIcon={<LogoPlaceholderIcon className={styles.icon} />}
          placeHolderText={<ButtonOverlay label={i18n.t('Add your logo')} />}
          store={reportsStore}
          model={report}
          onUploadSuccess={this.detectLogoColor}
          imageHeight={logoHeight}
          imageWidth={logoWidth}
          shouldSaveToDb
        />

        <div className={styles.companyInfo}>
          <div>
            <div className={styles.line + ' ' + styles.bold}>
              {isEditable
                ? (
                  <TextField
                    label={i18n.t('Company Name')}
                    className={styles.textField + ' ' + styles.companyName}
                    value={companyFields['companyName']}
                    onChange={handleChange(report, 'companyName')}
                    onBlur={this.saveReportToDb('companyName')}
                    InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                  />
                ) : companyFields['companyName']
              }
            </div>
            <div className={styles.line}>
              {isEditable
                ? (
                  <React.Fragment>
                    <TextField
                      label={i18n.t('Address')}
                      className={styles.textField}
                      value={companyFields['street']}
                      onChange={handleChange(report, 'street')}
                      onBlur={this.saveReportToDb('street')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                    />
                    <TextField
                      label={i18n.t('City')}
                      className={styles.textField}
                      value={companyFields['city']}
                      onChange={handleChange(report, 'city')}
                      onBlur={this.saveReportToDb('city')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                    />
                    <TextField
                      label={i18n.t('Province/State')}
                      className={styles.textField}
                      value={companyFields['province']}
                      onChange={handleChange(report, 'province')}
                      onBlur={this.saveReportToDb('province')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                    />
                    <TextField
                      label={i18n.t('Postal Code')}
                      className={styles.textField}
                      value={companyFields['postalCode']}
                      onChange={handleChange(report, 'postalCode')}
                      onBlur={this.saveReportToDb('postalCode')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                    />
                  </React.Fragment>
                ) : (
                  <span>{prepareLine1([companyFields['street'], companyFields['city'], companyFields['province'], companyFields['postalCode']])}</span>
                )}
            </div>
            <div className={styles.line}>
              {isEditable
                ? (
                  <React.Fragment>
                    <TextField
                      label={i18n.t('Phone')}
                      className={styles.textField}
                      value={companyFields['phoneNumber']}
                      onChange={handleChange(report, 'phoneNumber')}
                      onBlur={this.saveReportToDb('phoneNumber')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                      margin="none"
                    />
                    <TextField
                      label={i18n.t('Email')}
                      className={styles.textField}
                      value={companyFields['companyEmail']}
                      onChange={handleChange(report, 'companyEmail')}
                      onBlur={this.saveReportToDb('companyEmail')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                      margin="none"
                    />
                    <TextField
                      label={i18n.t('Website')}
                      className={styles.textField}
                      value={companyFields['website']}
                      onChange={handleChange(report, 'website')}
                      onBlur={this.saveReportToDb('website')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                      margin="none"
                    />
                    <TextField
                      label={i18n.t('Other')}
                      className={styles.textField}
                      value={companyFields['other']}
                      onChange={handleChange(report, 'other')}
                      onBlur={this.saveReportToDb('other')}
                      InputLabelProps={{ classes: { focused: styles.focusedLabel } }}
                      margin="none"
                    />
                  </React.Fragment>
                ) : (
                  <span>{prepareLine2([
                    [i18n.t('p'), companyFields['phoneNumber']],
                    [i18n.t('e'), companyFields['companyEmail']],
                    [i18n.t('w'), companyFields['website']],
                    ['', companyFields['other']]
                  ])}</span>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}