import { BuiltinCategories, BuiltinMeasurementSubcategories } from 'constants/BuiltinCategories';
import { CategoryType } from 'constants/CategoryType';
import { first, flow, groupBy, isArray, mapValues, sortBy, uniqBy } from 'lodash';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import ModelBaseWithCategory from 'models/ModelBaseWithCategories';
import * as React from 'react';
import ProvidingItemsStore from 'stores/ProvidingItemsStore';
import Stores from 'stores/Stores';
import { assignToExistingObject } from './StoreUtil';

export const STRING_SEPARATOR = '@@';

// only used by measurements
export function groupItemsByCategSubcategSubcateg<T extends ModelBaseWithCategory>(items: T[]) {
  const groupedItemsByCategSubcateg = groupItemsByCategSubcateg(items);

  return mapValues(
    groupedItemsByCategSubcateg,
    itemsForCategBySubcateg =>
      mapValues(
        itemsForCategBySubcateg,
        itemsForCategSubcateg => flow(
          items => sortBy(items, ['subsubcategory.index', 'subsubcategory.name', 'index', 'name']),
          items => groupBy(items, 'subsubcategory.id')
        )(itemsForCategSubcateg),
      )
  );
}

export function groupItemsByCategSubcateg<T extends ModelBaseWithCategory>(items: T[]) {
  const retval = mapValues(
    groupItemsByCateg(items),
    itemsForCateg => flow(
      // todo, sort name using localeCompare instead of basic sort
      items => sortBy(items, ['subcategory.index', 'subcategory.name', 'index', 'name']),
      items => groupBy(items, 'subcategory.id')
    )(itemsForCateg),
  );

  // move general (uncategorized) items to the bottom of the list
  /*const generalItem = retval[BuiltinCategories.General];
  if (generalItem) {
    delete retval[BuiltinCategories.General];
    retval[BuiltinCategories.General] = generalItem;
  }*/
  
  return retval;
}

export function groupItemsByCateg<T extends ModelBaseWithCategory>(items: T[]) {
  return flow(
    items => sortBy(items, ['category.index', 'category.name', 'index', 'name']),
    items => groupBy(items, 'category.id')
  )(items);
};

export function filterByCategory<T extends ModelBaseWithCategory>(items: T[], category: Category, subcategory?: Category, subsubcategory?: Category): T[] {
  return items.filter(item => (
    item.category === category &&
    (!subcategory || item.subcategory === subcategory) &&
    // only supported for measurements
    (!subsubcategory || item.subsubcategory === subsubcategory)
  ));
}

export interface IFlattenedItemsByCategSubcateg<T extends ModelBaseWithCategory> {
  category?: Category,
  subcategory?: Category,
  subsubcategory?: Category,
  item?: T
}

export function flattenItemsByCategSubcateg<T extends ModelBaseWithCategory>(
  itemsByCategSubcateg: { [x: string]: { [x: string]: T[] | { [x: string]: T[] } } },
  stores: Stores,
  shouldSkipCategoryRowIfSubcateg = false,
): IFlattenedItemsByCategSubcateg<T>[] {
  const result = [];
  const { categoriesStore } = stores;

  Object.keys(itemsByCategSubcateg).forEach(categKey => {
    const category = categoriesStore.getItem(categKey);

    const subcategKeys = Object.keys(itemsByCategSubcateg[categKey]);
    if (
      !shouldSkipCategoryRowIfSubcateg ||
      subcategKeys.length === 1 && subcategKeys[0] === BuiltinCategories.General
    ) {
      result.push({ category });
    }

    subcategKeys.forEach(subcategKey => {
      const subcategory = categoriesStore.getItem(subcategKey);
      if (
        subcategKeys.length > 1 ||
        subcategory !== categoriesStore.generalCategory
        // only use general subcategory when other subcategories exist
        // disabled for now because no way to differentiate general Subcategory from general Category
      ) {
        result.push({ category, subcategory });
      }

      if (isArray(itemsByCategSubcateg[categKey][subcategKey])) {
        itemsByCategSubcateg[categKey][subcategKey].forEach(item => {
          result.push({ category, subcategory, item });
        });
      } else {
        const subsubcategKeys = Object.keys(itemsByCategSubcateg[categKey][subcategKey]);
        // remove the subsubcateg when there is only one and it is outputmeasurement (?)
        if (
          subsubcategKeys.length === 1 &&
          subsubcategKeys[0] === BuiltinMeasurementSubcategories.OutputMeasurements
        ) {
          itemsByCategSubcateg[categKey][subcategKey][subsubcategKeys[0]].forEach(item => {
            result.push({ category, subcategory, item });
          })
        } else {
          Object.keys(itemsByCategSubcateg[categKey][subcategKey]).forEach(subsubcategKey => {
            const subsubcategory = categoriesStore.getItem(subsubcategKey);
            result.push({ category, subcategory, subsubcategory });

            itemsByCategSubcateg[categKey][subcategKey][subsubcategKey].forEach(item => {
              result.push({ category, subcategory, subsubcategory, item });
            });
          });
        }
      }
    });
  });

  return result;
}

export function mergeCategsAndSubcategsGroups<T extends ModelBaseWithCategory>(
  itemsByCategSubcateg: { [x: string]: { [x: string]: T[] | { [x: string]: T[] } } }
) {
  const itemsWithRenamedSubcategsKeys = mapValues(itemsByCategSubcateg, (subcategs, categId) => (
    Object.fromEntries(
      Object.entries(subcategs).map(([subcategId, measurements]) =>
        // Modify key here
        [`${categId}${STRING_SEPARATOR}${subcategId}`, measurements]
      )
    )
  ));

  const retval = {};

  Object.values(itemsWithRenamedSubcategsKeys).forEach(subcategsGroup => {
    Object.keys(subcategsGroup).forEach(subcategKey => {
      retval[subcategKey] = subcategsGroup[subcategKey];
    })
  });

  return retval;
}

export const getStoreForCategoryType = (categoryType: CategoryType, stores: Stores) => {
  switch (categoryType) {
    case CategoryType.Project:
      return stores.projectsStore;
    case CategoryType.Material:
    case CategoryType.Labour:
      return stores.providingItemsStore;
    case CategoryType.Measurement:
      return stores.measurementsStore;
    case CategoryType.Task:
      return stores.tasksStore;
    case CategoryType.TasksList:
      return stores.tasksListsStore;
    default:
      return null;
  }
}

export const getCategoryTypeForItem = (item: ModelBaseWithCategory) => {
  switch (item.type) {
    case ('ProvidingItem'):
      return item.subtype === 'Material'
        ? CategoryType.Material
        : CategoryType.Labour;
    default:
      return item.type as CategoryType;
  }
}

export const getNonEmptyCategoriesForCategoryTypes = (categoryTypes: CategoryType[], stores: Stores): Category[] => {
  // can only have multiple categoryTypes if they are all in the same store
  const store = getStoreForCategoryType(first(categoryTypes), stores);

  return uniqBy(
    categoryTypes.map(categoryType => {
      switch (categoryType) {
        case CategoryType.Material:
          return (store as ProvidingItemsStore).materialCategories;
        case CategoryType.Labour:
          return (store as ProvidingItemsStore).labourCategories;
        case CategoryType.Task:
          return stores.treeNodesStore.selectedTreeNode?.taskCategories || [];
        default:
          return store.itemsCategories;
      }
    }).flat(), 'id');
}

export const showCategoryEditDialog = (category: Category, stores: Stores) => {
  const CategoryEditDialog =require('components/common/CategoryEditDialog/CategoryEditDialog').default;

  const { categoriesStore, dialogsStore } = stores;
  const newDialog = new Dialog(stores);
  newDialog.dialogComponent = ({ open }) => (
    <CategoryEditDialog
      open={open}
      dialogId={newDialog.id}
      category={category}
      onClose={(shouldSave, modelCopies) => {
        if (shouldSave && modelCopies) {
          const newCategory = modelCopies[0] as Category;
          assignToExistingObject(category, newCategory);

          // save right away or wait to press ok button?
          categoriesStore.addEditItem(modelCopies[0] as Category);
        }
      }}
    />
  )
  dialogsStore.showDialog(newDialog);
}