import { observable } from "mobx";
import BaseStore from "./BaseStore";

export enum  DraggableTypes {
  Categories = 'Categories',
  Tasks = 'Tasks',
  Measurements = 'Measurements'
};

export default class DragAndDropStore extends BaseStore {
  @observable dragObject: Object;

  get isDragging() {
    return !!this.dragObject;
  }
}
