import { computed } from "mobx";
import { computedFn } from "mobx-utils";
import Fee from "models/Fee";
import { roundPrice, sumPrices } from "utils/CurrencyUtil";
import BaseStore from './BaseStore';

export default class TotalsStore extends BaseStore {
  @computed get feesTotalsByFee(): Map<Fee, number> {
    const { reportsStore, settingsStore } = this.stores;
    const { settings } = settingsStore;
    const { report } = reportsStore;

    const { areFeesRecursivelyCalculated } = settings || {};

    const feesTotals = new Map<Fee, number>();

    if (!settings || !report) {
      return feesTotals;
    }

    const { areFeesIncludedInItemPrice } = settings;
    // apply fees to subtotals instead of by individual task 
    // (almost the same, but different due to when price rounding is done)
    // only works for the simpler cases, otherwise we still calculate fees by task even if we show them at the end.
    if (
      !areFeesIncludedInItemPrice && (
        !areFeesRecursivelyCalculated ||
        !settings.fees.some(fee => fee.hasFilter)
      )
    ) {
      settings.fees.forEach(fee => {
        const tasksTotalsForFee = sumPrices(
          report.tasks.filter(task => task.providingItem.applicableFees.includes(fee))
            .map(task => task.price)
        );

        const subtotalWithPreviousFees = sumPrices([
          tasksTotalsForFee,
          sumPrices(Array.from(feesTotals.values()))
        ])

        const feeValue = roundPrice(
          areFeesRecursivelyCalculated
            ? (fee.percentage / 100) * subtotalWithPreviousFees
            : (fee.percentage / 100) * tasksTotalsForFee
        );

        feesTotals.set(fee, feeValue);
      });

      return feesTotals;
    }
    // -----

    // NORMAL CASE
    // more complex fees need to be calculated at task level
    report.tasks.forEach(task => {
      Array.from(task.feesValues.keys()).forEach((fee: Fee) => {
        feesTotals.set(fee, sumPrices([
          feesTotals.get(fee),
          task.feesValues.get(fee),
        ]) || 0);
      });
    });

    return feesTotals;
  }

  // when fees are applied to item unit prices
  @computed get feesTotal(): number {
    return sumPrices(Array.from(this.feesTotalsByFee.values()));
  }

  @computed get taxesTotal(): number {
    const { settingsStore, reportsStore } = this.stores;

    if (!settingsStore.settings || !reportsStore.report) {
      return 0;
    }

    const { taxes } = settingsStore.settings;
    const { subtotalWithFees, subtotalMaterialWithFees } = reportsStore.report;

    return sumPrices(taxes.map(tax => (tax.shouldTaxExcludeLabour ? subtotalMaterialWithFees : subtotalWithFees) * tax.percentage / 100));
  }

  @computed get totalBeforeTaxes(): number {
    const { reportsStore } = this.stores;

    if (!reportsStore.report) {
      return 0;
    }

    const { subtotalBeforeFees } = reportsStore.report;

    return sumPrices([subtotalBeforeFees, this.feesTotal]);
  }

  @computed get allIncludedTotal(): number {
    const { reportsStore } = this.stores;

    if (!reportsStore.report) {
      return 0;
    }

    const { subtotalBeforeFees } = reportsStore.report;

    return sumPrices([subtotalBeforeFees, this.feesTotal, this.taxesTotal]);
  }

  // deprecated, used for old projects
  @computed get legacyEffectiveFeesPercentage(): number {
    const { settingsStore } = this.stores;

    if (!settingsStore.settings) {
      return 0;
    }

    const { fees } = settingsStore.settings;
    return sumPrices(
      fees.map(fee => this.legacyGetFeeValue(fee, 100))
    );
  }

  // deprecated, used for old projects
  legacyGetFeeValue = computedFn((fee: Fee, priceBeforeFee: number) => {
    const { settingsStore } = this.stores;
    const { fees, areFeesRecursivelyCalculated } = settingsStore.settings;
    const feeIndex = fees.indexOf(fee);

    if (feeIndex === 0 || !areFeesRecursivelyCalculated) {
      return priceBeforeFee * (fee.percentage || 0) / 100;
    }

    const previousFeesTotal = fees
      .filter((fee, feeIndexToFilter) => feeIndexToFilter < feeIndex)
      .map(fee => this.legacyGetFeeValue(fee, priceBeforeFee))
      .reduce((previousValue, currentValue) => sumPrices([previousValue, currentValue]), 0);

    return sumPrices([priceBeforeFee, previousFeesTotal]) * (fee.percentage || 0) / 100;
  });
}
