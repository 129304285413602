
import { Avatar, Checkbox, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Globals from 'Globals';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { BuiltinCategories } from 'constants/BuiltinCategories';
import { defer } from 'lodash';
import Category from 'models/Category';
import * as React from 'react';
import { handleCategoryToggle, isCategoryChecked } from 'utils/GroupedListUtil';
import { handleChangeActiveMeasurements, showActiveMeasurementsDialog } from 'utils/MeasurementUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';

const styles = require('./MeasurementsSimpleList.module.scss');

interface MeasurementsSimpleListProps {
  isToolbar?: boolean,
  hidden?: boolean,
}

export default class MeasurementsSimpleList extends ObserverComponent<MeasurementsSimpleListProps> {
  scrollDiv;

  handleCloseToolbar = () => {
    const { measurementsStore } = this.context;

    measurementsStore.isCategoriesToolbarOpen = false;
    //measurementsStore.toolbarSelectedCategory = null;

    measurementsStore.categoryFilter = null;
    measurementsStore.subcategoryFilter = null;
  }

  resetScroll = () => defer(() => {
    if (this.scrollDiv) {
      this.scrollDiv.scrollLeft = 0;
    }
  })

  handleClickParentCategory = (category: Category) => () => {
    const { measurementsStore } = this.context;
    measurementsStore.toolbarSelectedCategory = category;

    this.resetScroll();
  }

  handleClickBack = () => {
    const { measurementsStore } = this.context;
    measurementsStore.toolbarSelectedCategory = null;

    this.resetScroll();
  }

  _render() {
    const { isToolbar, hidden } = this.props;
    const { measurementsStore } = this.context;
    const selectedCategory = measurementsStore.toolbarSelectedCategory;
    let { itemsCategories, subcategoriesByCategoryShownInToolbar: subcategoriesByCategory } = measurementsStore;

    const categoriesToShow = (
      selectedCategory
        ? subcategoriesByCategory[selectedCategory.id]
        : itemsCategories
    ) || [];


    // this is the way we currently keep a list of checked measurements
    // beside the ones in the main selected measurements list
    const checkboxContext = Globals.getTemporaryStores(this.context);
    const checkboxMeasurementsStore = checkboxContext.measurementsStore;

    return (
      <div className={classnames(styles.root, { [styles.isToolbar]: isToolbar, [styles.isHidden]: hidden })}>
        <div className={styles.subtitle}>
          {selectedCategory
            ? (
              <>
                <IconButton className={styles.backButton} onClick={this.handleClickBack}>
                  <BackIcon />
                </IconButton>
                <div>{selectedCategory.name}</div>
              </>
            ) : <div>{i18n.t('Choose applicable measurements categories')}</div>
          }

          <IconButton onClick={() => {
            this.handleCloseToolbar();
            showActiveMeasurementsDialog(this.context)();
          }}>
            <MoreVertIcon />
          </IconButton>

          <IconButton onClick={this.handleCloseToolbar}>
            <CloseIcon />
          </IconButton>
        </div>

        <List ref={ref => this.scrollDiv = ref}>
          {categoriesToShow
            .filter(category => selectedCategory || category.id !== BuiltinCategories.General)
            .map(categOrSubcateg => {
              const { checked, indeterminate } = isCategoryChecked(
                checkboxMeasurementsStore,
                checkboxMeasurementsStore.items,
                selectedCategory || categOrSubcateg,
                selectedCategory && categOrSubcateg
              );

              const hasSubcategories = !selectedCategory && subcategoriesByCategory[categOrSubcateg.id]?.length > 1;

              const { legendColor } = categOrSubcateg;

              return (
                // validate !indeterminate experience
                <ListItem key={categOrSubcateg.id} id={'msl' + categOrSubcateg.id} className={classnames({ [styles.isChecked]: checked })}>
                  <ListItemAvatar className={styles.avatarContainer}>
                    <Avatar className={styles.avatar}>
                      <AvatarImageWithFallback model={categOrSubcateg} />

                      {hasSubcategories ? (
                        <IconButton className={styles.moreIcon} onClick={this.handleClickParentCategory(categOrSubcateg)} >
                          <MoreIcon />
                        </IconButton>
                      ) : (
                        <Checkbox
                          className={styles.checkbox}
                          checked={checked}
                          indeterminate={indeterminate}
                          onChange={(event) => {
                            handleCategoryToggle(
                              checkboxMeasurementsStore,
                              checkboxMeasurementsStore.itemsAndHiddenItems,
                              selectedCategory || categOrSubcateg,
                              selectedCategory && categOrSubcateg
                            )(event);

                            defer(() => {
                              handleChangeActiveMeasurements(checkboxContext);

                              if (categOrSubcateg.id === 'Window' || categOrSubcateg.id === '1ce2947c-4480-4e87-adf7-874cd49833e0' /* Doors */) {
                                trackInteraction(TrackedInteractions.ApplyWindowOrDoorMeasurements);
                              }
                            })
                          }}
                        />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText className={styles.name}>
                    {legendColor && categOrSubcateg.parentCategory && <div className={styles.legend} style={{ backgroundColor: legendColor }} />}
                    {categOrSubcateg.name}
                  </ListItemText>
                </ListItem>
              );
            })}
        </List>

      </div>
    )
  }
}