export enum BuiltinCategories {
  General = 'General',
}

export enum BuiltinTaskCategories {
  NewCategory = 'NewCategory',
}

export enum BuiltinMeasurementCategories {
  // these are the basics for every other calculation
  ReferenceMeasurements = 'Drawing',

  General = 'General',
  FloorOrCeiling = 'FloorOrCeiling',
  Walls = 'Walls',
  Room = 'Room',
  GeneralSurface = 'GeneralSurface',
  GeneralLength = 'GeneralLength',
  GeneralPerimeter = 'GeneralPerimeter',
  Windows = 'Windows',
  Roof = 'Roof',
  Foundation = 'Foundation',
  Excavation = 'Excavation',
}

export enum BuiltinMeasurementSubcategories {
  InputMeasurements = 'InputMeasurements',
  OutputMeasurements = 'OutputMeasurements',
  RoofRidge = 'RoofRidge',
  RoofSurface = 'RoofSurface',
  RoofHip = 'RoofHip',
  RoofValley = 'RoofValley',
  RoofRake = 'RoofRake',
  RoofEave = 'RoofEave',
}
