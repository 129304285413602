import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { find, first, isArray, last, sortBy } from 'lodash';
import Merchant from 'models/Merchant';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import { getBaseQuantities } from './ProvidedQuantityUtil';
import { getSafe } from './Utils';
import i18n from './i18n';

export function getPackCount(productInfo): { result: number, confidence: number } {
  const fromPackAfter = productInfo.match(
    /[^\d]+([\d.,]+)(( *Pcs)|( *ct)|( *units)|([ -]*Pack)|([ -]*piece)|( *nail))/i
  );
  if (fromPackAfter) {
    return { result: parseFloat(fromPackAfter[1]), confidence: 70 };
  }

  const fromPackBefore = productInfo.match(
    // caution: special character (not space) after box count used by home depot,
    // maybe a better regex is possible
    /((Case Of *)|(box count[ ]*))([\d., ]+)/i
  );
  if (fromPackBefore) {
    return { result: parseFloat(fromPackBefore[4]), confidence: 70 };
  }

  const fromPerCase = productInfo.match(
    /(([\d., ]+) (planks|units) per case)/i
  );
  if (fromPerCase) {
    return { result: parseFloat(fromPerCase[2]), confidence: 70 };
  }


  return { result: 1, confidence: 50 };
}

function getFeatureLike(features, nameRegex) {
  const retval =
    find(
      features,
      (feat, key) => nameRegex.test(key),
    );

  if (isArray(retval)) {
    return first(retval)
  } else {
    return retval;
  }
}

// INCHES
// this should return confidence level
export function getDimensions_in(productInfo, name, features, merchant: Merchant): GuesserDimensions {
  // In lumber, they write the length in feet where they say inches...
  // and sometimes the opposite
  let lumberLength = (
    parseFloat(getFeatureLike(features, new RegExp(merchant.lumberLengthRegex, 'i'))) * 12 ||
    getSafe(() => parseInt(name.match(/[^x()]+x[^x()]+x ?([\d]+)/)[1]) * 12) ||
    getSafe(() => parseInt(name.match(/" ([\d]+)'/)[1]) * 12)
  );

  // sometimes they write in inches where it says feet :(
  if (lumberLength > (30 * 12)) {
    lumberLength /= 12;
  }


  let featureLength = parseFloat(getFeatureLike(features, new RegExp(merchant.featureLengthRegex, 'i')));

  let dimensions = [merchant.depthRegex, merchant.widthRegex, merchant.heightRegex].map(
    regex => getSafe(() => parseFloat(getFeatureLike(features, new RegExp(regex, 'i')))) || 0
  )

  // Dimensions (width vs height vs depth) seem a bit random on homedepot site.
  // This sorting ensure it's more consistent
  dimensions = sortBy(dimensions);

  dimensions[2] = lumberLength || featureLength || dimensions[2];

  return {
    thickness_in: dimensions[0], // in.
    width_in: dimensions[1], // in.
    length_in: dimensions[2], // in.
  }
}

interface GuesserDimensions {
  thickness_in: number,
  width_in: number,
  length_in: number
}

// SQ FT
// PROBLEM identify when to multiply by pack count and when not to
export function getProvideSurface_sqft(productInfo: string, features: { [key: string]: string }, dimensions: GuesserDimensions, unitString: string, language: LanguageKey, merchant: Merchant): { result: number, confidence: number } {
  if (merchant.surfaceCoverageRegex) {
    const surfaceCoverage = productInfo.match(new RegExp(merchant.surfaceCoverageRegex))
    const result = parseFloat(surfaceCoverage?.[1]?.replace?.(',','.') || '0');
    return { result, confidence: result ? 80 : 0 };
  }

  // Best case scenario: a feature called Coverage exists
  const coverageFeature = getFeatureLike(features, new RegExp(i18n.t('coverage'), 'i'));

  if (coverageFeature) {
    return { result: parseFloat(coverageFeature), confidence: 75 };
  }

  // carpet hd calculated in yard squared YD2
  if (unitString.toLowerCase() === i18n.tAll('yd2')[language]) {
    return { result: 9, confidence: 90 }; // 1 sq yard = 9 sq ft.
  }

  // shingles hd calculated in yard squared YD2
  if (productInfo.match(/3 bundles per square/i)) {
    return { result: 100 / 3, confidence: 90 }; // means 3 bundles for 100 square feet
  }

  // inch x inch
  const fromInchInch = productInfo.match(new RegExp(`([\\d]+\\.?[\\d]+) *(${i18n.t('in')}\.?|${i18n.t('inch(es)')}) *x *([\\d]+\.?[\\d]+) *(${i18n.t('in')}\.?|${i18n.t('inch(es)')}?)`, 'i'));
  if (fromInchInch) {
    return { result: parseFloat(fromInchInch[1]) * parseFloat(fromInchInch[4]) / 144, confidence: 70 };
  }

  // feet x feet
  const fromFeetFeet = productInfo.match(new RegExp(`([\\d]+\\.?[\\d]*) *(${i18n.t('ft')}\\.?|${i18n.t('feet')}|'|′|’) *x *([\\d]+\\.?[\\d]*) *(${i18n.t('ft')}\\.?|${i18n.t('feet')}|'|′|’)`, 'i'));
  const fromFeetFeet1 = parseFloat(fromFeetFeet?.[1] || '0');
  const fromFeetFeet2 = parseFloat(fromFeetFeet?.[3] || '0');
  if (fromFeetFeet1 && fromFeetFeet2) {
    return { result: fromFeetFeet1 * fromFeetFeet2, confidence: 81 };
  }

  // From a feature that says Width x Length
  const fromWidthLengthFeat = getFeatureLike(features, /Width \(ft\) x Length \(ft\)/i);
  if (fromWidthLengthFeat) {
    const widthLengthMatches = fromWidthLengthFeat.match(/([\d]+) *x *([\d]+)/i);
    if (widthLengthMatches) {
      return { result: parseFloat(widthLengthMatches[1]) * parseFloat(widthLengthMatches[2]), confidence: 60 };
    }
  }

  // from paint can size (not really precise)
  const fromPaintCanVolume = getFeatureLike(features, /Can Size \(L\)/i);
  if (fromPaintCanVolume) {
    return { result: parseFloat(fromPaintCanVolume) * 80, confidence: 60 };
  }

  // 2nd best, sq. ft. is found product string
  // was commmented out? why?
  const fromSqFt = productInfo.match(/[^\d]+([\d]+[.,]*\d*) *sq[u .]/i)
  if (fromSqFt) {
    return { result: parseFloat(fromSqFt[1]), confidence: 75 };
  }

  // length * width
  // If dimensions width < .5 feet, probably it's a length, not a surface
  const fromLengthWidth = dimensions.width_in / 12. * dimensions.length_in / 12.;
  if (fromLengthWidth && dimensions.width_in / 12 >= 4) {
    // we don't set the guessed type because we are not 100% sure the item provides mainly surface
    return { result: fromLengthWidth, confidence: 81 };
  }

  if (
    unitString.toLowerCase() === i18n.tAll('sq. ft.')[language] ||
    unitString.toLowerCase() === i18n.tAll('sq. ft.')[language]?.replace(/ /g, '')
  ) {
    return { result: 1, confidence: 90 }; // 1 sq yard = 9 sq ft.
  }

  return { result: 0, confidence: 0 };
}

export function getProvideLength_ft(productInfo: string, features: { [key: string]: string }, dimensions: GuesserDimensions, unitString: string, language: LanguageKey, merchant: Merchant): { result: number, confidence: number } {
  if (merchant.lengthCoverageRegex) {
    const lengthCoverage = productInfo.match(new RegExp(merchant.lengthCoverageRegex))

    if (merchant.lengthCoverageUnit === Unit.Inch) {
      const inchesAndFraction = (lengthCoverage?.[1] || '0').split(' ');
      const fraction = inchesAndFraction[1];
      const inchesValue = parseInt(inchesAndFraction[0] || '0') + (fraction ? (parseInt(fraction.split('/')?.[0] || '0') / parseInt(fraction.split('/')?.[1] || '1')) : 0);
      return { result: inchesValue / 12, confidence: inchesValue ? 80 : 0 };
    }

    const result = parseFloat(lengthCoverage?.[1] || '0');
    return { result, confidence: result ? 80 : 0 };
  }

  if (unitString.toLowerCase() === i18n.tAll('foot')[language]) {
    return { result: 1, confidence: 90 };
  }

  // from 25 Linear Feet per
  const fromFeetPer = productInfo.match(/([\d]+\.?[\d]+) *(ft\.?|feet|linear feet) per/i);
  if (fromFeetPer) {
    return { result: parseFloat(fromFeetPer[1]), confidence: 70 };
  }

  const length_ft = dimensions.length_in / 12.;
  if (length_ft < .5) {
    // not enough length to provide, probably it's not a length providing item
    return { result: 0, confidence: 0 };
  }

  // will get first occurence, should be last
  const fromXftNotX = Array.from(productInfo.matchAll(new RegExp(`x ([\\d]+) (${i18n.t('ft')}\.?) [^x]`, 'ig')));
  if (fromXftNotX) {
    return { result: parseFloat(last(fromXftNotX)?.[1] || ''), confidence: 70 };
  }

  return { result: length_ft, confidence: 60 };
}


// guess and assign to item
export const guessProvidedQuantities = async (item: ProvidingItem, features: { [key: string]: string }, productInfo: string, language: LanguageKey, merchant: Merchant) => {
  const quantities = getBaseQuantities();

  // remove weird whitespace that wont match regex correctly, 
  // can't use becauses it messes up the accents matching regex
  //productInfo = (productInfo || '').normalize('NFKD');
  productInfo = productInfo.replace(/\s/g, ' ');

  if (merchant.isMetric) {
    return;
  }

  const { result: packCount, confidence: packConfidence } = getPackCount(productInfo);

  const dimensions = getDimensions_in(productInfo, item.name, features, merchant);

  let { result: coverageSurfaceSqft, confidence: confidenceSurface } = getProvideSurface_sqft(productInfo, features, dimensions, item.unitString, language, merchant);
  const { result: coverageLengthFt, confidence: confidenceLength } = getProvideLength_ft(productInfo, features, dimensions, item.unitString, language, merchant);

  // last ditch attempt
  if (!coverageSurfaceSqft && productInfo.match(/(pi2)|(pi.?.?ca)|(pied carré)|( p2 )|(sq.?.?ft)|(ft2)/)) {
    coverageSurfaceSqft = 1;
  }

  quantities.set(UnitType.Unit, new ProvidedQuantity(UnitType.Unit, packCount, Unit.Unit, packConfidence));

  if (item.name.match(/(verge cube)|(cubic yard)/)) {
    // could do much better for volume
    quantities.set(UnitType.Volume, new ProvidedQuantity(UnitType.Volume, 0, Unit.CubicYard, 80));
  } else if (
    !coverageLengthFt && coverageSurfaceSqft ||
    !coverageLengthFt && item.name.toLowerCase().includes(i18n.t('paint')) ||
    confidenceSurface > confidenceLength
  ) {
    quantities.set(UnitType.Surface, new ProvidedQuantity(UnitType.Surface, coverageSurfaceSqft, Unit.SquareFoot, confidenceSurface));
  } else {
    quantities.set(UnitType.Length, new ProvidedQuantity(UnitType.Length, coverageLengthFt, Unit.Foot, confidenceLength));
  }

  item.providedQuantities = quantities;
}
