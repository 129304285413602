import BuiltinSubscriptions from 'constants/BuiltinSubscriptions';
import { computed, observable } from 'mobx';
import moment from 'moment';
import { serializable } from 'serializr';
import i18n from 'utils/i18n';
import { localized, localizedList } from 'utils/localized';
import ModelBase from './ModelBase';

export default class Subscription extends ModelBase {
  type = 'Subscription';

  @localized description = '';
  @localizedList features = [];

  @localized offerName = '';

  @observable @serializable stripePriceId = '';

  @observable @serializable isWeekly = false;
  @observable @serializable isYearly = false;

  @observable @serializable monthlyPrice = 0;
  @observable @serializable regularMonthlyPrice = 0;

  @observable @serializable yearlyPrice = 0;
  @observable @serializable regularYearlyPrice = 0;

  @observable @serializable weeklyPrice = 0;
  @observable @serializable regularWeeklyPrice = 0;

  @observable @serializable _expirationDate = '';
  @observable @serializable _startDate = '';

  @computed get price() {
    return (
      this.isWeekly && this.weeklyPrice || 
      this.isYearly && this.yearlyPrice ||
      this.monthlyPrice
    ); 
  }

  @computed get regularPrice() {
    return (
      this.isWeekly && this.regularWeeklyPrice || 
      this.isYearly && this.regularYearlyPrice ||
      this.regularMonthlyPrice
    ); 
  }

  @computed get periodName() {
    return (
      this.isWeekly && i18n.t('week') || 
      this.isYearly && i18n.t('year') ||
      i18n.t('month')
    ); 
  }

  @computed get expirationDate() {
    return moment(this._expirationDate);
  }

  @computed get isTrial() {
    return this.id === BuiltinSubscriptions.Trial;
  }
}