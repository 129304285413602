import { noop } from 'lodash';
import { ClazzOrModelSchema, deserialize, serializable, serialize } from 'serializr';
import Stores from 'stores/Stores';

export default abstract class SerializableBase {
  @serializable abstract /*static*/ type: string;

  stores: Stores;

   /* abstract*/ afterDeserialize() {

  }

  constructor(stores: Stores) {
    if (!stores) {
      debugger;
    }
    this.stores = stores;
  }

  // because when observing something in another store, an object
  // gets added to the "observing" table, and can't be freed
  dispose() {
    // ideally clear this everywhere, but for now do it for most problematic objects only
    //this.stores = null;
  }


  // semi-shallow clone!! deep clones everything except sub models
  clone(stores = this.stores): this {
    const serializedData = serialize(this);
    const item = deserialize(this.constructor as ClazzOrModelSchema<this>, serializedData, noop, stores);
    item.afterDeserialize();

    return item;
  }
}
