
// MAYBE SEPARATE APP SETTINGS FROM USER, no neeed for user object....

import BuiltinSubscriptions from 'constants/BuiltinSubscriptions';
import { DbLocationType } from 'constants/DbLocationType';
import { computed } from 'mobx';
import Subscription from 'models/Subscription';
import moment from 'moment';
import FirebaseStore from 'stores/FirebaseStore';
import { CollectionReference, WriteBatch } from 'utils/FirebaseInitializedApp';
import { modelDescSortFunction } from 'utils/Utils';

export default class SubscriptionsStore extends FirebaseStore<Subscription>   {
  storeKey = 'subscriptions';

  dbLocationsTypes = new Set([
    DbLocationType.Master, 
    DbLocationType.User, // should be readonly
  ]);

  hasCategories = false;
  hasLocalization = false;

  sortField = 'index';

  @computed get activeSubscription() {
    return (
      // not sure why sorting descending
      this.items.slice(0).sort(modelDescSortFunction).find(item => item.expirationDate?.diff?.(moment(), 'days') > 0) ||
      this.itemsMap.get(BuiltinSubscriptions.Trial)
    );
  }

  @computed get isTrial() {
    const { userInfoStore } = this.stores;
    if (
      userInfoStore.firebaseUser?.email === 'louisp.tremblay@gmail.com' ||
      // should add another check that google authentification was used
      userInfoStore.firebaseUser?.email?.includes('@evalumo.com')
    ) {
      return false;
    }

    return this.activeSubscription?.isTrial ?? false;
  }

  saveToDb(items: Subscription[], collections: CollectionReference[], fieldsToUpdate, batchParam: WriteBatch = null) {
    // subscriptions are not modifiable by user (need to enforce backend)
    if (this.userEmail !== 'master') {
      // return; // disabled for temp hack
    }

    super.saveToDb(items, collections, fieldsToUpdate, batchParam);
  }
}
