import ObserverComponent from 'components/common/ObserverComponent';
import { isEmpty } from 'lodash';
import TreeNode from 'models/TreeNode';
import * as React from "react";
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { getSafe, trySetTimeout } from "utils/Utils";
import i18n from 'utils/i18n';
import * as uuidv4 from 'uuid/v4';

export class GroupShapesTool extends ObserverComponent/* implements Add ? */ {
  svgTag: SVGSVGElement;

  componentDidMount() {
    //this.svgTag.addEventListener('click', this.onClick);
    this.context.drawToolsStore.addEventListener('click', this.onShapeClick);
    this.context.drawToolsStore.addEventListener('doubleclick', this.onShapeClick);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.context.drawToolsStore.removeEventListener('click', this.onShapeClick);
    this.context.drawToolsStore.removeEventListener('doubleclick', this.onShapeClick);

    //this.svgTag.removeEventListener('click', this.onClick);
  }

  onShapeClick = (event: CustomEvent) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;
    const { shape, originalEvent } = event.detail;

    const node = shape.treeNode as TreeNode;
    let { selectedTreeNode } = treeNodesStore;

    if (shapesStore.isDragPanningOnDrawing) {
      return;
    }

    if (false /* multiselect disabled for now */ && (originalEvent.metaKey || originalEvent.shiftKey || originalEvent.ctrlKey)) {
      treeNodesStore.selectedTreeNodes = [...treeNodesStore.selectedTreeNodes, node];
    } else {
      let batch = firestoreBatch();
      const nodeCopy = new TreeNode(this.context);
      nodeCopy._name = node._name;
      const shapeCopy = shape.clone(this.context, uuidv4());
      shapesStore.batchAddEditItem(shapeCopy, batch);
      nodeCopy.shape = shapeCopy;

      if (!isEmpty(selectedTreeNode.ownShapes)) {
        selectedTreeNode = treeNodesStore.selectedTreeNode = selectedTreeNode.parent;
      }

      if (selectedTreeNode.isRootNode) {
        const newGroupNode = new TreeNode(this.context, i18n.t('Elements Group'));
        treeNodesStore.appendNode(newGroupNode, selectedTreeNode, undefined, batch);
        treeNodesStore.batchAddEditItem(nodeCopy, batch);
        batch.commit();
        batch = firestoreBatch();

        selectedTreeNode = treeNodesStore.selectedTreeNode = newGroupNode;
      }

      selectedTreeNode.isExpanded = false;
      treeNodesStore.appendNode(nodeCopy, selectedTreeNode, undefined, batch);
      treeNodesStore.batchAddEditItem(nodeCopy, batch);

      batch.commit();

      trySetTimeout(() => {
        treeNodesStore.treeComponentRef.scrollTo({ key: selectedTreeNode?.id });
      }, 150)
    }
  }

  public _render() {
    return (
      <g id="GroupShapesTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement)} />
    );
  }
}
