
import { Button, Checkbox, ClickAwayListener, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as classnames from 'classnames';
import { CategoryType } from 'constants/CategoryType';
import { defer, isEmpty, maxBy, remove } from 'lodash';
import { action } from 'mobx';
import Category from 'models/Category';
import Measurement from 'models/Measurement';
import ProvidingItem from 'models/ProvidingItem';
import Task, { TaskSubtypes } from 'models/Task';
import TasksList from 'models/TasksList';
import * as React from 'react';
import { getMeasurementsWithDependencies } from 'utils/MeasurementUtil';
import { formatCurrency } from 'utils/NumberFormatter';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import DroppableDiv from '../DroppableDiv/DroppableDiv';
import ObserverComponent from '../ObserverComponent';
import { copyTasksFromListToProject } from '../TasksListsList/TasksListUtil';

const styles = require('./TaskCategoryComponent.module.scss');

interface TaskCategoryComponentProps {
  category: Category,
  tasks: Task[],
  childTasks: Task[],
  hasOnlyOneCategory?: boolean,
  className: string,
  isExpanded: boolean,
}

interface TaskCategoryComponentState {
  // this doesn't exactly follow editable patterns of other components
  // because here we don't actually edit the category object,
  // we edit the parent node categories list
  selectedCategory: Category,
  shouldFullyRender: boolean,
}

export default class TaskCategoryComponent extends ObserverComponent<TaskCategoryComponentProps, TaskCategoryComponentState> {
  static defaultProps = {
    tasks: [],
    childTasks: [],
  }

  state = {
    selectedCategory: null,
    shouldFullyRender: false,
  }

  elementRef;
  renderTimeout;

  componentDidMount() {
    this.renderTimeout = setTimeout(() => this.elementRef && this.setState({ shouldFullyRender: true }), 300);
  }

  toggleExpanded = (event) => {
    if (
      event.target.id !== 'expand-button' &&
      event.target.tagName !== 'DIV'
    ) {
      return;
    }

    const { categoriesStore } = this.context;
    const { category } = this.props;

    categoriesStore.collapsedTaskCategories.has(category)
      ? categoriesStore.collapsedTaskCategories.delete(category)
      : categoriesStore.collapsedTaskCategories.add(category);
  }

  addTask = (subtype = TaskSubtypes.Default) => action(() => {
    const { category } = this.props;
    const { treeNodesStore, dragAndDropStore, tasksStore, categoriesStore, providingItemsStore, measurementsStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    const { dragObject } = dragAndDropStore;
    const newTask = new Task(this.context);
    newTask.subtype = subtype;
    newTask.category = category;
    newTask.index = (getSafe(() => maxBy(this.props.tasks, task => task.index).index) || 0) + 1;

    if (subtype === TaskSubtypes.Separator) {
      newTask.description = i18n.t('Nouveau séparateur');
    }

    if (dragObject instanceof Measurement) {
      newTask.measurement = dragObject as Measurement;
      measurementsStore.ensureSavedInProjectCollection(getMeasurementsWithDependencies([newTask.measurement]));
    }

    if (dragObject instanceof ProvidingItem) {
      const providingItem = dragObject as ProvidingItem;
      newTask.providingItem = providingItem;
      providingItemsStore.ensureSavedInProjectCollection([providingItem]);
    }

    tasksStore.addEditItem(newTask);
    selectedTreeNode.ownTasksIds.push(newTask.id);
    treeNodesStore.addEditItem(selectedTreeNode);

    categoriesStore.collapsedTaskCategories.delete(category);

    const newTaskIndex = treeNodesStore.selectedNodeOwnExpandedTasksByCategFlattened.findIndex(row => row.item?.id === newTask.id);

    dragAndDropStore.dragObject = null;

    defer(() => { 
      tasksStore.tasksComponentRef.scrollToItem(newTaskIndex);
    });
  })

  handleDropTasksList = () => {
    const { dragAndDropStore, treeNodesStore } = this.context;
    copyTasksFromListToProject(
      dragAndDropStore.dragObject as TasksList,
      treeNodesStore.selectedTreeNode,
      this.context,
      this.props.category
    );
  }

  enterEditMode = () => {
    const { categoriesStore } = this.context;
    categoriesStore.taskCategoryBeingEdited = this.props.category;
  }

  exitEditMode = () => {
    const { categoriesStore } = this.context;
    categoriesStore.taskCategoryBeingEdited = null;
    this.setState({ selectedCategory: null });
  }

  saveCategory = (event) => {
    const { treeNodesStore, tasksStore } = this.context;
    if (getSafe(() => event.target.type === 'button')) {
      return; // don't exit edit mode when managing categories, but should be a better selector
    }

    const newCategory = this.state.selectedCategory;
    const previousCategory = this.props.category;

    if (!newCategory) {
      this.exitEditMode();
      return;
    }

    const tasks = treeNodesStore.selectedTreeNode.ownTasks.filter(task => task.category === previousCategory);
    tasks.forEach(task => task.category = newCategory);
    tasksStore.addEditItems(tasks);

    const categoryIndex = treeNodesStore.selectedTreeNode.ownTaskCategoriesIds.indexOf(previousCategory.id);
    if (categoryIndex >= 0) {
      treeNodesStore.selectedTreeNode.ownTaskCategoriesIds.splice(categoryIndex, 1, newCategory.id);
    } else {
      treeNodesStore.selectedTreeNode.ownTaskCategoriesIds.push(newCategory.id);
    }

    treeNodesStore.addEditItem(treeNodesStore.selectedTreeNode);

    this.exitEditMode();
  }

  removeCategoryFromNode = () => {
    const { treeNodesStore } = this.context;
    remove(treeNodesStore.selectedTreeNode.ownTaskCategoriesIds, categoryId => categoryId === this.props.category.id)

    treeNodesStore.addEditItem(treeNodesStore.selectedTreeNode);
  }

  onChangeCategoryName = (newCategory: Category) => {
    const previousCategory = this.state.selectedCategory || this.props.category;

    if (previousCategory.id === newCategory.id) {
      return;
    }

    this.setState(
      { selectedCategory: newCategory },
      this.saveCategory
    );
  }

  handleSelectedChange = (event, checked) => {
    const { tasksStore } = this.context;
    this.props.tasks.forEach(
      task => checked
        ? tasksStore.selectedItems.add(task)
        : tasksStore.selectedItems.delete(task)
    );
  }

  _render() {
    const category = this.state.selectedCategory || this.props.category;

    const { dragAndDropStore, tasksStore, categoriesStore, treeNodesStore } = this.context;
    const { className, tasks } = this.props;
    const { shouldFullyRender } = this.state;
    const { dragObject } = dragAndDropStore;

    const { categoryBeingFilteredIn } = categoriesStore;
    const categories = categoryBeingFilteredIn
      ? [categoryBeingFilteredIn]
      : treeNodesStore.selectedNodeCategories;

    const hasOnlyOneCategory = categories.length === 1;

    const price = tasks.reduce((total, task) => (
      total + task.price
    ), 0);

    const isAlwaysExpanded = hasOnlyOneCategory;
    const canBeToggled = !isAlwaysExpanded;
    const isExpanded = isAlwaysExpanded ? true : !categoriesStore.collapsedTaskCategories.has(category);

    const numberOfItems = tasks.length;
    const hasNoTasks = numberOfItems === 0;
    const hasTasks = !hasNoTasks;
    const hasOwnTasks = !isEmpty(tasks);
    const shouldShowInstructions = hasOnlyOneCategory && hasNoTasks;

    const isEditMode = categoriesStore.taskCategoryBeingEdited === this.props.category;

    const displayedCategory = false //todo
      ? i18n.t(category.name)
      : category.name;

    const isChecked = tasks.length > 0 && tasks.every(task => tasksStore.selectedItems.has(task));
    const isIndeterminate = !isChecked && tasks.some(task => tasksStore.selectedItems.has(task));

    return (
      <DroppableDiv
        ref={ref => this.elementRef = ref}
        className={classnames(styles.root, className, {
          [styles.hasOnlyOneCategory]: hasOnlyOneCategory,
          [styles.hasNoTasks]: hasNoTasks,
          [styles.hasItemsChecked]: tasksStore.selectedItems.size > 0,
        })}
        //classNameIsDragging={hasOnlyOneCategory ? styles.isDraggingBigBorder : ''}
        shouldEnable={!isEditMode && (
          dragObject instanceof ProvidingItem ||
          dragObject instanceof Measurement ||
          dragObject instanceof TasksList && !(dragObject as TasksList).isMultiCategs
        )}
        onDrop={dragObject instanceof TasksList ? this.handleDropTasksList : this.addTask(TaskSubtypes.Default)}
        onClick={(canBeToggled && !isEditMode) ? this.toggleExpanded : () => null}
      >
        {canBeToggled && /*!hasOnlyOneCategory &&*/ (
          <IconButton id="expand-button" className={classnames(
            styles.expandButton,
            { [styles.isExpanded]: isExpanded })
          }>
            <ExpandMoreIcon />
          </IconButton>
        )}

        {hasOwnTasks && !isEditMode && shouldFullyRender && (
          <div className={styles.hoverButtonsLeft}>
            <Checkbox
              className={styles.checkbox}
              //hidden={!isSelectingWithCheckboxes}
              // weird material UI feature that indeterminate needs to also be checked
              checked={isChecked || isIndeterminate}
              indeterminate={isIndeterminate}
              onChange={this.handleSelectedChange}
            />
          </div>
        )}
        {isEditMode ? (
          <ClickAwayListener onClickAway={this.saveCategory}>
            <div className={styles.rootEdit}>
              <CategoriesCombobox
                shouldFocusOnMount
                categoryTypes={[CategoryType.Task]}
                onChange={this.onChangeCategoryName}
                category={category}
              />
            </div>
          </ClickAwayListener>
        ) : (
          <>
            <AvatarImageWithFallback model={category} />
            <div
              className={styles.name}
              //onClick={this.enterEditMode}
              style={{ opacity: hasOwnTasks ? 1 : 0.7 }}
            >
              {displayedCategory}
            </div>
            {shouldFullyRender && (
              <IconButton
                className={styles.button + ' ' + styles.editButton}
                onClick={() => categoriesStore.taskCategoryBeingEdited = this.props.category}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}

        <div style={{ flex: 1 }} />

        {shouldShowInstructions ? (<>
          {/*<Button className={styles.button} onClick={this.addTask(TaskSubtypes.Separator)}>
            <TitleIcon />
            {i18n.t('Add a separator')}
        </Button>*/}
          <Button className={styles.button + ' ' + styles.buttonWithInstructions} onClick={this.addTask(TaskSubtypes.Default)} key="1">
            <AddIcon />
            {i18n.t('Add a task')}
          </Button>
        </>
        ) : (
          <>

            {/*<Button className={styles.button} onClick={this.addTask(TaskSubtypes.Separator)}>
                <TitleIcon />
            </Button>*/}
            <IconButton className={styles.button + ' ' + styles.addButton} onClick={this.addTask(TaskSubtypes.Default)}>
              <AddIcon />
            </IconButton>

            <div className={styles.details}>
              {formatCurrency(price || 0)}
            </div>
          </>
        )}

        {shouldFullyRender && (
          <div className={styles.hoverButtonsRight}>
            {!numberOfItems &&
              (
                <IconButton onClick={this.removeCategoryFromNode}>
                  <DeleteIcon />
                </IconButton>
              )}
          </div>
        )}

      </DroppableDiv>
    )
  }
}