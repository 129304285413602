
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';
import ObserverComponent from 'components/common/ObserverComponent';
import Subscription from 'models/Subscription';
import * as React from 'react';

const styles = require('./SubscriptionFeatures.module.scss');

interface SubscriptionFeaturesProps {
  subscription: Subscription
}

export default class SubscriptionFeatures extends ObserverComponent<SubscriptionFeaturesProps> {
  _render() {
    const { subscription } = this.props;

    return (
      <ul className={styles.root}>
        {subscription.features.map((feature, i) => (
          <li key={i}>{subscription.isTrial ? <CrossIcon className={styles.redCross} /> : <CheckIcon />} {feature}</li>
        ))}
      </ul>
    )
  }
}