// overlaps with CategoryType
export enum ProvidingItemSubtype {
    Material = 'Material',
    Labour = 'Labour',
}

export enum ProvidingItemQuantityBehaviour {
    Default = 'Default',
    AlwaysHide = 'AlwaysHide',
    AlwaysShow = 'AlwaysShow'
}

export enum ProvidingItemTaskBehaviour {
    Default = 'Default',
    AlwaysHide = 'AlwaysHide',
    AlwaysShow = 'AlwaysShow'
}