
import { Paper, Switch } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';

const styles = require('./ReportEditableSection.module.scss');


interface ReportEditableSectionProps {
  title: string,
  children: JSX.Element | JSX.Element[],
  className: string,
  isFirstSection?: boolean,
  isToggled?: boolean,
  onToggleChange?: (event, checked) => void,
};

export default class ReportEditableSection extends React.Component<ReportEditableSectionProps> {
  render() {
    const { title, children, className, isFirstSection, isToggled, onToggleChange } = this.props;

    return (<>
      <div className={styles.designSectionSeparator} />
      <div className={styles.designSectionHeader} >
        {title}
        {onToggleChange && (
          <Switch
            checked={isToggled}
            onChange={onToggleChange}
          />
        )}
      </div>
      {!isFirstSection && (
        <div className={classnames(
          styles.designSectionSeparator,
          styles.reverse,
          { [styles.isHiddenSection]: onToggleChange && !isToggled }
        )}
        />
      )
      }

      {(!onToggleChange || isToggled) && (
        <Paper elevation={3} className={classnames(
          className,
          styles.paper,
        )} >
          {children}
        </Paper>
      )}
    </>);
  }
}