
import classnames from 'classnames';
import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

const styles = require('./OrderableItemsContainer.module.scss');

interface OrderableItemsContainerProps {
  id: string,
  className?: string,
  children: JSX.Element[] | JSX.Element,
  type: string,
  style? : any,
  isDropDisabled?: boolean,
}

export default class OrderableItemsContainer extends React.Component<OrderableItemsContainerProps> {
  render() {
    const { className, children, id, type, style, isDropDisabled } = this.props;

    return (
      <Droppable droppableId={id} type={type} isDropDisabled={isDropDisabled}>
        {(provided, snapshot) => (
          <ul
            className={classnames(className, styles.root, { isDraggingOver : snapshot.isDraggingOver })}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={style}
          >
            {children}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    );
  }
}