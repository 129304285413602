import { DbLocationType } from 'constants/DbLocationType';
import { groupBy } from 'lodash';
import { computed, observable, reaction } from 'mobx';
import Task from 'models/Task';
import SearchableFirebaseStore from './SearchableFirebaseStore';

// not really searchable for now, would need to search by measurement.name & item.name
// but useful to get items by categs
export default class TasksStore extends SearchableFirebaseStore<Task> {
  storeKey = 'tasks';

  dbLocationsTypes = new Set([
    DbLocationType.MasterProject,
    DbLocationType.Project
  ]);

  @observable taskBeingEdited: Task = null;
  @observable taskToHighlight: Task = null;
  @observable isSelectingWithCheckboxes = false;
  @observable isTaskMeasurementColumnVisible = true;

  tasksComponentRef = null;

  @computed get roundableTasksByProvidingItem() {
    return groupBy(this.items.filter(task => task.shouldRound && task.providingItem), 'providingItem.id');
  }

  @computed get providingItemsIdsUsedInProject(): Set<string> {
    return new Set(this.items.map(task => task.providingItem?.id));
  }

  // problem is tasks are sorted globally instead of by node/category
  sortField = 'index';

  resetScrollOnNodeChange = reaction(() => (
    this.stores?.treeNodesStore?.selectedTreeNodeId
  ), () => {
    if (this.tasksComponentRef) {
      this.tasksComponentRef.scrollTo(0);
    }
  });

  /* override */ attemptLoadItems() {
    if (this.isLoading) {
      return;
    }

    if (
      !this.stores.treeNodesStore.isReady ||
      //  !this.stores.measurementsStore.isReady ||
      !this.stores.commonStore.selectedProjectId
    ) {
      return;
    }

    super.attemptLoadItems();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.treeNodesStore?.isReady &&
    //this.stores?.measurementsStore?.isReady &&
    this.stores?.commonStore.selectedProjectId
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}

