
import { FilledInput, FormControl, InputLabel, Select } from '@material-ui/core';
import * as classnames from 'classnames';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { compact, isEmpty } from 'lodash';
import * as React from 'react';
import i18n from 'utils/i18n';
import { formatUnit, formatUnitType, getUnitsByUnitType } from 'utils/UnitFormatter';

const styles = require('./UnitComboBox.module.scss');

interface IUnitComboBoxProps {
  withLabel?: boolean,
  value: any,
  onChange: (event: any) => void,
  unitTypeOptions?: UnitType[],
  unitsByUnitType?: Map<UnitType, Unit[]>,
  className?: string,
  disabled?: boolean,
  margin: 'none' | 'normal',
};

export default class UnitComboBox extends React.Component<IUnitComboBoxProps> {
  static defaultProps = {
    unitTypeOptions: [],
    unitsByUnitType: null,
    className: '',
    disabled: false,
    label: '',
    margin: 'normal',
  }

  render() {
    const { value, onChange, className, disabled, withLabel, margin } = this.props;
    const unitsByUnitType = isEmpty(this.props.unitsByUnitType)
      ? getUnitsByUnitType()
      : this.props.unitsByUnitType;

    // Setting default value here, because we need to know when unitTypes are passed as props vs using default value
    const unitTypeOptions = isEmpty(this.props.unitTypeOptions)
      ? Object.values(UnitType).filter(unitType => unitType !== UnitType.Unknown)
      : this.props.unitTypeOptions;

    return (
      <FormControl
        className={classnames(styles.root, className)}
        variant="filled"
        margin={margin}
      >
        {withLabel && <InputLabel htmlFor="filled-age-simple">{i18n.t('Unité de mesure')}</InputLabel>}
        <Select
          native
          value={value}
          onChange={onChange}
          input={<FilledInput className={withLabel ? '' : styles.textFieldNoLabel} />}
          disabled={disabled}
        >
          {
            compact(unitTypeOptions.map(unitType => !!formatUnitType(unitType) && (
              <optgroup key={unitType} label={formatUnitType(unitType)}>
                {
                  unitsByUnitType.get(unitType).map(unit => (
                    <option value={unit} key={unit}>{formatUnit(unit)}</option>
                  ))
                }
              </optgroup>
            )))
          }
          {isEmpty(this.props.unitTypeOptions) && <option value={Unit.Unknown} key={Unit.Unknown}>{formatUnit(Unit.Unknown)}</option>}
        </Select>
      </FormControl>
    )
  }
}