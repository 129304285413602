import { ConfirmationBehavior } from 'constants/ConfirmationBehavior';
import { first, last } from 'lodash';
import { computed, observable } from 'mobx';
import ModelBase from 'models/ModelBase';
import { list, map, object, primitive, serializable } from 'serializr';
import { UserInfo } from 'utils/FirebaseInitializedApp';
import { TrackedInteractions } from 'utils/TrackingUtil';
import UserColors from './UserColors';
export default class User extends ModelBase implements UserInfo {
  @serializable type = 'User';

  @serializable displayName = '';

  /* For mailchimp */
  @serializable(list(primitive())) activity = ['trial'];

  @serializable @computed get firstName() {
    return first((this.displayName || '').split(' '));
  }
  set firstName(unused) {
    return; // unused but crashes if not there
  }

  @serializable @computed get lastName() {
    return last((this.displayName || '').split(' '));
  }
  set lastName(unused) {
    return; // unused but crashes if not there
  }


  /* end for mailchimp */

  @serializable email = '';
  // user photo
  @serializable photoURL = '';

  // do not use directly, used for mailchimp and backups
  @serializable activeSubscriptionId = 'Trial';

  // deprecated, use report logoColors
  @serializable(object(UserColors)) @observable logoColors: UserColors = new UserColors();


  @serializable @observable logoHeight = 100;
  @serializable @observable logoWidth = 260;
  @serializable @observable reportHeaderPaddingTop = 85;

  @serializable @observable street = '';
  @serializable @observable city = '';
  @serializable @observable province = '';
  @serializable @observable postalCode = '';
  @serializable @observable phoneNumber = '';
  @serializable @observable companyEmail = '';
  @serializable @observable website = '';
  @serializable @observable companyName = '';
  @serializable @observable other = '';

  @serializable @observable currentBuild = '0';

  @serializable @observable defaultWastePercent = 5;

  @serializable @observable shouldBlockAfterPaymentFailed = false;
  @serializable @observable shouldWarnAfterPaymentFailed = false;
  @serializable @observable quadernoUserId = '';

  // more of a settings, but user settings keeps getting overwritten
  @serializable @observable canExportExcelPriceChanges = false;
  @serializable @observable shouldAllowWasteForLabour = false;
  @serializable @observable shouldShowVisibilityIcon = false;

  @serializable @observable shouldShowFooterTimeTotals = false;
  @serializable @observable reportThumbsSize = 30;
  @serializable @observable shouldIncludeTaxInFooterTotal = true;
  @serializable @observable shouldShowUserPriceUpdates = false;
  @serializable @observable shouldShowTaxesFilter = false;
  @serializable @observable canManuallyChangeLogoColor = false;
  @serializable @observable canHardReload = false;
  @serializable @observable canChangeReportFontSize = false;
  @serializable @observable canExportProvidingItems = false;
  @serializable @observable canImportProvidingItems = false;
  @serializable @observable canEditProvidingItemsIds = false;
  @serializable @observable shouldCopyNotesFromTasksLists = false;
  @serializable @observable canHighlightPriceChanges = false;
  @serializable @observable shouldShowAncestorsInReportTitles = true;
  @serializable @observable hasUsedFreeFullVersionPeriod = false;
  @serializable @observable shouldBreakBeforeTerms = false;
  @serializable @observable shouldShowReportGenerationTime = true;
  @serializable @observable shouldPreferNonUnitUnitTypes = false;
  @serializable @observable shouldDisableClickAway = false;
  @serializable @observable shouldNeverInlineNotesInReport = false;
  @serializable @observable canCompressDrawing = false;
  @serializable @observable shouldShowDrawingsInInches = false;
  @serializable @observable shouldMergeTasksWithSameLabourOrFee = true;
  @serializable @observable shouldHide0DollarTasksInReports = false;

  @serializable @observable canShowReportTimeTotals = false;

  @observable _shouldAutoSyncProvidingItems = true;

  @serializable @computed set shouldAutoSyncProvidingItems(value: boolean) {
    const { providingItemsStore } = this.stores;
    this._shouldAutoSyncProvidingItems = value;
    providingItemsStore.shouldSearchUserItems = !value;
  }
  get shouldAutoSyncProvidingItems(): boolean {
    return this._shouldAutoSyncProvidingItems;
  }

  // dont check directly, use store for extra logic
  @serializable @observable canChangeEvalumoItems = true;
  
  // used to completely hide quantity of time
  @serializable @observable shouldShowTimeInReports = true;
  // used to always show time, but would be better as a separate column
  @serializable @observable shouldAlwaysShowTimeInReports = false;

  // valid in admin account only, add backend checks to prevent modifying from subusers
  @serializable @observable isAdvancedSharingEnabled = false;

  // list of allowed users and their roles ex: {'abc@def.com': ['restricted']}
  // BUT admin by default unless setting restricted role. Because many people already using 2nd accounts as admin,
  // ideally properly reconfigure everyone
  @observable @serializable(map(list(primitive()))) subusers = new Map<string /*email*/, string[]>();

  // for highlighting, but can cause cycle dependencies errors
  @serializable @observable canTrackRelatedShapes = false;

  // drawing settings
  @serializable @observable subtractSurfacesBehavior = ConfirmationBehavior.Ask;
  @serializable @observable isDrawingSnapPointEnabled = true;
  @serializable @observable isDragMomentumEnabled = true;

  @serializable referral = '';

  @serializable(list(primitive())) @observable interactionsCompleted: TrackedInteractions[] = [];
  @serializable @observable isInteractionsSyncSuccess = false;

  // to delete if nobody complains about this feature in the next few weeks
  @serializable @observable shouldShowReportNumberInFooter = true;

  @serializable @observable hasHiddenMerchantInstructions = false;

  @serializable @observable reportFontSizeScale = 1;

  @serializable @observable customCss = '';

  @serializable @observable customHtmlInReportFooter = '';
  // lang, key, value
  @serializable(map(map(primitive()))) @observable localeOverrides = {};

  // stores the x in 10^x because logaritmic scale or else it's too hard to finely ajust slower zoom
  // 10^-0.82 = 0.15 (average), 10^-2 = 0.01 minimum, 10^0.48 = 3 max
  @serializable @observable mouseWheelSensitivity = -1.18;

  @serializable @observable versionOnCreation = 0;
  @serializable @observable version = 0;

  // needed by interface but not used in app
  providerId = '';
  uid = '';

  // extra info
  /* @serializable projects: Project[]; */
}