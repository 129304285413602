const prodLogo = require('../../../assets-sw/images/logo.svg');
import { CircularProgress, LinearProgress } from '@material-ui/core';
import classnames from 'classnames';
import * as React from 'react';

const styles = require('./LoadingOverlay.module.scss');

interface LoadingOverlayProps {
  message?: string,
  isTranslucent?: boolean,
  withTransition?: boolean,
  isVisible?: boolean,
  progress?:number,
}

export default class LoadingOverlay extends React.Component<LoadingOverlayProps> {
  render() {
    const { message, isTranslucent, isVisible, withTransition,progress } = this.props;
    return (
      <div className={classnames(styles.root, {
        [styles.isTranslucent]: isTranslucent,
        [styles.withTransition]: withTransition,
        [styles.isVisible]: isVisible,
        [styles.isHidden]: isVisible === false, // when explicitly specified
      })}>
        {isTranslucent
          ? <img src={prodLogo} className={styles.progress} width={40} height={40} />
          : <CircularProgress className={styles.progress} />
        }

        <div className={styles.message}>{message}</div>
        {isTranslucent && <LinearProgress variant="determinate" value={progress} className={styles.linearProgress} />}
      </div>
    )
  }
}