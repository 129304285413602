
import { Tab, Tabs, Tooltip } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/PhotoAlbum';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { TreeNodePropertiesTabs } from 'constants/TreeNodePropertiesTabs';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { onAddOrDeleteImage } from 'utils/ProjectUtils';
import { getDescriptionRichText } from 'utils/RichTextUtil';
import i18n from 'utils/i18n';
import DrawingButton from '../DrawingButton/DrawingButton';
import { SquareIcon, StickyNote } from '../Icons';
import ImageBox from '../ImageBox/ImageBox';
import Panel from '../Panel/Panel';
import RichText2 from '../RichText2/RichText2';

const styles = require('./TreeNodePropertiesPanel.module.scss');


interface TreeNodePropertiesPanelProps {
  shouldShowDrawingTab: boolean,
}
export default class TreeNodePropertiesPanel extends ObserverComponent<TreeNodePropertiesPanelProps> {
  static defaultProps = {
    shouldShowDrawingTab: true
  }

  handleChangeTab = (event, selectedTab) => {
    const { settingsStore } = this.context;
    settingsStore.settings.selectedTreeNodePropertiesTab = selectedTab;
    settingsStore.addEditItemDebounced(settingsStore.settings, true, undefined, false);
  };

  _render() {
    const { settingsStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    const { shouldShowDrawingTab } = this.props;

    if (!settingsStore.settings) {
      return null;
    }

    let { selectedTreeNodePropertiesTab: selectedTab } = settingsStore.settings;
    const isDrawingTabDisabled = (
      false //selectedTreeNode?.shouldBubbleMeasurements //||
      //   selectedTreeNode?.nonDrawingChildren?.some(node => node.shouldBubbleMeasurements)
    );

    if (isDrawingTabDisabled && selectedTab === TreeNodePropertiesTabs.DRAWING) {
      selectedTab = TreeNodePropertiesTabs.PHOTOS;
    }

    return (selectedTreeNode || null) && (
      <Panel
        className={styles.root}
        header={
          <Tabs
            className={styles.tabs}
            value={selectedTab}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {shouldShowDrawingTab && (
              <Tooltip disableHoverListener={!isDrawingTabDisabled} title={i18n.t('Drawing not supported when results are transferred to parent')} enterDelay={200}>
                <Tab value={TreeNodePropertiesTabs.DRAWING} disabled={isDrawingTabDisabled} icon={<SquareIcon />} className={classnames(styles.tab, { [styles.isEmpty]: isEmpty(selectedTreeNode.shapes) })} label={i18n.t('Drawing')} />
              </Tooltip>
            )}
            <Tab value={TreeNodePropertiesTabs.PHOTOS} icon={<PhotoIcon />} className={classnames(styles.tab, { [styles.isEmpty]: !selectedTreeNode.imageUrl })} label={i18n.t('Photos')} />
            <Tab value={TreeNodePropertiesTabs.NOTES} icon={<StickyNote />} className={classnames(styles.tab, { [styles.isEmpty]: selectedTreeNode.description === getDescriptionRichText(i18n.language) })} label={i18n.t('Notes')} />
          </Tabs>
        }
        content={<>
          {/* this particular tab cannot be hidden, only mounted/unmounted or else svg getBBox() returns empty rectangle */
            selectedTab === TreeNodePropertiesTabs.DRAWING && (
              <div className={styles.tabContent}>
                {selectedTreeNode && <DrawingButton />}
              </div>
            )}

          {selectedTab === TreeNodePropertiesTabs.PHOTOS && (
            <div className={styles.tabContent}>
              <ImageBox
                model={selectedTreeNode}
                store={treeNodesStore}
                shouldSaveToDb
                className={styles.imageBox}
                imageHeight={'100%'}
                placeHolderIcon={<PhotoIcon />}
                onUploadSuccess={() => {
                  selectedTreeNode.shouldSkipImageInReport = false;
                  treeNodesStore.addEditItem(selectedTreeNode);
                  onAddOrDeleteImage(this.context);
                }}
                onDeleteImage={() => onAddOrDeleteImage(this.context)}
              />
            </div>
          )}

          {/* slow to mount */}
          {selectedTab === TreeNodePropertiesTabs.NOTES && (
            <div className={styles.tabContent}>
              <RichText2
                model={selectedTreeNode}
                property="description2"
                store={treeNodesStore}
                isEditable
              />
            </div>
          )}
        </>}
      />
    )
  }
}