import { observable } from "mobx";
import ModelBase from "models/ModelBase";

export class ActionInProgress extends ModelBase {
  type = 'ActionInProgress';
  message: string;
  dialogId: ModelId;
  timeStarted: number = new Date().getTime();

  @observable percentCompleted: number = 0;

  hasRenamed: boolean = false;
}

export class CopyActionInProgress extends ActionInProgress {
  constructor() {
    // doesn't need stores
    super({});
  }
  renamingTable = new Map<ModelId, ModelId>();
}