
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import Cookies from 'js-cookie';
import * as React from 'react';
import i18n from 'utils/i18n';
import { ChromeIcon, SafariIcon } from '../Icons';

const styles = require('./SafariErrorDialog.module.scss');

export interface ErrorDialogProps extends IDialogProps {
  error: string,
}

export default class SafariErrorDialog extends ObserverComponent<IDialogProps> {
  _render() {
    const { open, error } = this.props;
    return (
      <Dialog
        className={styles.root}
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={styles.title}>
          <SafariIcon />
          <div>{i18n.t('Safari browser is not supported yet 😢')}</div>
        </DialogTitle>

        <DialogContent>
          <h1>{i18n.t('Safari, phones and tablets will be supported soon. But in the meantime, please use a computer with Google Chrome or MS Edge.')}</h1>
        </DialogContent>

        <DialogActions>
          <span style={{fontSize:13, marginRight: 4}} onClick={() => {
          Cookies.set('forceSafari', 'true');
            debugger;
            window.location.reload();
          }} color="primary">
            {i18n.t('Continue anyway (not recommended)')}
          </span>

          <a href="https://www.google.com/intl/fr_ca/chrome/">
            <Button variant="contained" color="primary">
              <ChromeIcon />&nbsp;&nbsp;
              {i18n.t('Install Google Chrome')}

            </Button>
          </a>
        </DialogActions>

      </Dialog>
    )
  }
}