import { CategoryType } from 'constants/CategoryType';
// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { IDialogProps } from 'constants/CommonProps';
import Category from 'models/Category';
import * as React from 'react';
import i18n from 'utils/i18n';
import CategoriesListEditDetailsForm from '../CategoriesListEditDetailsForm/CategoriesListEditDetailsForm';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import ObserverComponent from '../ObserverComponent';

interface ICategoriesListEditDialog extends IDialogProps {
  parentCategory: Category,
  categoryType?: CategoryType, // needed only when no parentCategory
}

export default class CategoriesListEditDialog extends ObserverComponent<ICategoriesListEditDialog> {
  onClose = (shouldSave: boolean, modelCopies: Category[]) => {
    if (!shouldSave) {
      return;
    }

    // won't have to save here cause we will save at each step
    const { categoriesStore } = this.context;
    //categoriesStore.addEditItems(modelCopies, true, ['isDeleted', 'index', '_name']);
  }

  _render() {
    const { open, dialogId, categoryType, parentCategory } = this.props;
    const { categoriesStore } = this.context;

    return (
      <EditDialogComponent
        dialogId={dialogId}
        open={open}
        // don't use copies.. too many changes to not save every time
        modelCopies={categoriesStore.items}
        onClose={this.onClose}
        FormComponent={CategoriesListEditDetailsForm}
        FormComponentProps={{ /*initialCategoryType: categoryType,*/ initialParentCategory: parentCategory }}
        title={parentCategory ? i18n.t('Manage Subcategories') : i18n.t('Manage Categories')}
        shouldHideCancel
        saveButtonLabel={i18n.t('Close')}
      />
    );
  }
}