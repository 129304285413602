import { compact, first, isEmpty, isUndefined, omitBy } from "lodash";
import { computed, decorate, observable } from "mobx";
import { custom, serializable } from "serializr";
import { sortObjectKeys } from "./Utils";
import i18n from "./i18n";

// takes property and create localized object and computed get set
// e.g.  @localized test = 'value' becomes
// @serializable @observable _test { en: 'value' }
// @computed get test, set test
// NOTE: do not use @localized with other mobx decorators
export function localized(target: any, key: string | symbol, descriptor?: PropertyDescriptor): any {
  const privateProp = `_${key as string}`;

  Object.defineProperty(target, privateProp, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
      //[i18n.language]: descriptor.initializer(),
    }
  });

  Object.defineProperty(target, key, {
    enumerable: true,
    configurable: true,
    set: function (value) {
      this[privateProp][i18n.language || 'en'] = value;
    },
    get: function () {
      const value = this[privateProp][i18n.language || 'en'];
      return isUndefined(value)
        ? (
          this[privateProp]['en'] ||
          first(compact(Object.values(this[privateProp]))) ||
          ''
        ) : value;
    },
  });

  decorate(target, {
    [privateProp]: [serializable(custom(
      mobxObject => {
        return omitBy(sortObjectKeys(mobxObject), (value, key) => key === 'undefined' && !value);
      },
      serializedObject => {
        return omitBy(sortObjectKeys(serializedObject), (value, key) => key === 'undefined' && !value);
      })
    ), observable],
    [key]: computed,
  });

  return Object.getOwnPropertyDescriptor(target, key);
};

export function localizedList(target: any, key: string | symbol, descriptor?: PropertyDescriptor): any {
  const privateProp = `_${key as string}`;

  Object.defineProperty(target, privateProp, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
      'en': [],
    }
  });

  Object.defineProperty(target, key, {
    enumerable: true,
    configurable: true,
    set: function (value) {
      this[privateProp][i18n.language || 'en'] = value?.slice?.(0) || [] // in case it is the array coming from another language;
    },
    get: function () {
      const value = this[privateProp][i18n.language || 'en'];
      if (!value?.length) {
        const existingValue = Object.values(this[privateProp]).find(translatedList => translatedList?.length);

        if (existingValue) {
          // will require a set to properly copy this array, cannot push directly to it
          return Object.freeze(existingValue.slice(0));
        }
      }
      return value || [];
    },
  });

  decorate(target, {
    [privateProp]: [serializable(custom(
      mobxObject => {
        return omitBy(sortObjectKeys(mobxObject), (value) => isEmpty(value));
      },
      serializedObject => {
        return omitBy(sortObjectKeys(serializedObject), (value) => isEmpty(value));
      })
    ), observable],
    [key]: computed,
  });

  return Object.getOwnPropertyDescriptor(target, key);
};

