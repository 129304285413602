const styles = require('./SnapToggledPoints.module.css');
import ObserverComponent from 'components/common/ObserverComponent';
import Point from 'models/Point';
import * as React from 'react';

const SQUARE_SIZE = 5;

interface SnapToggledPointsProps {
  points: Point[];
}

export default class SnapToggledPoints extends ObserverComponent<SnapToggledPointsProps> {
  public _render(): JSX.Element[] {
    const { points } = this.props;

    // don't use DimensionLine on snapline, instead add alternative view to angle, that shows dx dy as inputs
    // for example to draw a triangle or parallelogram


    return points.map((pt, index) => (
      <rect
        key={index + '' + pt.x + '' + pt.y}
        className={styles.default}
        x={pt.x - SQUARE_SIZE / 2}
        y={pt.y - SQUARE_SIZE / 2}
        width={SQUARE_SIZE}
        height={SQUARE_SIZE}
      />
    ));

  }
}
