
// not quite the right place for this
export enum DrawToolType {
  Select = 'Select',
  Move = 'Move',
  Wall = 'Walls',
  Excavation = 'Excavation',
  Foundation = 'Foundation',
  Doors = 'Doors',
  Windows = 'Windows',
  Roof = 'Roof',
  GeneralDraw = 'GeneralDraw',
  GeneralCount = 'GeneralCount',
  PredefinedShapeRectangle = 'PredefinedShapeRectangle',
  PredefinedShapeTriangle = 'PredefinedShapeTriangle',
  Hole = 'Hole',
  RemoveDuplicates = 'RemoveDuplicates',
  BackgroundImage = 'BackgroundImage',
  BackgroundImageSetScale = 'BackgroundImageSetScale',
  BackgroundImageCrop = 'BackgroundImageCrop',
  SlopeStreetView = 'SlopeStreetView',
  GroupShapes = 'GroupShapes',
  ImportDrawing = 'ImportDrawing'
}
