import { DbLocationType } from 'constants/DbLocationType';
import Test from 'models/Test';
import FirebaseStore from 'stores/FirebaseStore';

class TestStore extends FirebaseStore<Test>   {
  storeKey = 'tests';

  dbLocationsTypes = new Set([
    DbLocationType.Project
  ]);

}

export default TestStore;
