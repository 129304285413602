import BuiltinMeasurements from 'constants/BuiltinMeasurements';
import { MeasurementType } from "constants/MeasurementType";
import { Unit } from "constants/Unit";
import { UnitType } from "constants/UnitType";
import { Fraction } from "fractional";
import { deburr, every, first, isEmpty } from 'lodash';
import Adjustment from 'models/Adjustment';
import Measurement from "models/Measurement";
import MeasurementValue from "models/MeasurementValue";
import TreeNode from "models/TreeNode";
import MeasurementConverter from "utils/MeasurementConverter";
import i18n from 'utils/i18n';
import { formatCurrency } from './NumberFormatter';
import { formatUnit } from "./UnitFormatter";
import { floorRounded, getSafe, round } from "./Utils";

// might be unused stuff here, was imported from previous project

interface IFeetInches {
  ft: number,
  in: number,
}

const ARCHITECT_SCALE_SUFFIX = '" = 1\'-0"';
const MAX_INCHES_PRECISION = 16;
const USE_DECIMAL_INCHES = false; // settings?
const VAR_ENQUOTE_SYMBOL = "'";

// function definitions probably belong somewhere else
export const SUM_CHILDREN_FUNCTION = 'SUM_CHILDREN';
export const SAME_AS_CHILDREN_FUNCTION = 'SAME_AS_CHILDREN';
export const OPTIONAL_FUNCTION = 'OPTIONAL';
export const SURFACE_FUNCTION = 'SURFACE';
export const LENGTH_FUNCTION = 'LENGTH';
export const PERIMETER_FUNCTION = 'PERIMETER';
export const SURFACES_PERIMETER_FUNCTION = 'SURFACES_PERIMETER';
export const COUNT_FUNCTION = 'COUNT';
export const BBOX_WIDTH_FUNCTION = 'BBOX_WIDTH';
export const BBOX_HEIGHT_FUNCTION = 'BBOX_HEIGHT';
export const BBOX_MIN_LENGTH_FUNCTION = 'BBOX_MIN_LENGTH';
export const BBOX_MAX_LENGTH_FUNCTION = 'BBOX_MAX_LENGTH';
export const GUESS_WINDOW_OR_DOOR_WIDTH_FUNCTION = 'GUESS_WINDOW_OR_DOOR_WIDTH';
export const GUESS_WINDOW_OR_DOOR_HEIGHT_FUNCTION = 'GUESS_WINDOW_OR_DOOR_HEIGHT';
export const CORNERS_QUANTITY_FUNCTION = 'CORNERS_QUANTITY';
export const SURFACES_QUANTITY_FUNCTION = 'SURFACES_QUANTITY';
export const RECTANGLES_TOPS_FUNCTION = 'RECTANGLES_TOPS';
export const RECTANGLES_BOTTOMS_FUNCTION = 'RECTANGLES_BOTTOMS';
export const RECTANGLES_SIDES_FUNCTION = 'RECTANGLES_SIDES';
export const SHAPES_TOPS_FUNCTION = 'SHAPES_TOPS';
export const SHAPES_BOTTOMS_FUNCTION = 'SHAPES_BOTTOMS';
export const SHAPES_SIDES_FUNCTION = 'SHAPES_SIDES';
export const OPENINGS_QUANTITY_FUNCTION = 'OPENINGS_QUANTITY';
export const OPENINGS_SURFACE_FUNCTION = 'OPENINGS_SURFACE';
export const OPENINGS_TOTAL_WIDTH_FUNCTION = 'OPENINGS_TOTAL_WIDTH';
export const OPENINGS_TOTAL_PERIMETER_FUNCTION = 'OPENINGS_TOTAL_PERIMETER';
export const OPENINGS_TOTAL_SIDES_FUNCTION = 'OPENINGS_TOTAL_SIDES';
export const OPENINGS_TOTAL_TOP_BOTTOM_FUNCTION = 'OPENINGS_TOTAL_TOP_BOTTOM';
export const AVERAGE_HEIGHT_FUNCTION = 'AVERAGE_HEIGHT';
export const EXTREMITIES_QUANTITY_FUNCTION = 'EXTREMITIES_QUANTITY';
export const GUESS_SLOPE_DIRECTION_FUNCTION = 'GUESS_SLOPE_DIRECTION';
export const OFFSET_SURFACE_FUNCTION = 'OFFSET_SURFACE';
export const OFFSET_LENGTH_FUNCTION = 'OFFSET_LENGTH';
export const REPEAT_LINE_FUNCTION = 'REPEAT_LINE';
export const REPEATED_LINES_QUANTITY_FUNCTION = 'REPEATED_LINES_QUANTITY';

// for storing in our in-house formula syntax
export function enquoteVar(variableName) {
  return (
    VAR_ENQUOTE_SYMBOL +
    variableName.replace(new RegExp(VAR_ENQUOTE_SYMBOL, 'g'), "`") +
    VAR_ENQUOTE_SYMBOL
  );
}

// for parsing using 3rd party excel like formula
export function escapeVar(variableName) {
  // not a lot of options for variable name, can only take alphanumeric and underscore
  return deburr(variableName)
    .toUpperCase()
    .replace(/[^A-Z_0-9]/g, '_')
    // letter directly followed by number not supported (AAA1 -> AAA_1)
    .replace(/([A-Z])([0-9])/, '$1_$2')
    // number as first character not supported
    .replace(/^([0-9].+)$/, 'A_$1');
}

export function getFormulaVariables(formula: string, leftEnquoteSymbol = VAR_ENQUOTE_SYMBOL, rightEnquoteSymbol = VAR_ENQUOTE_SYMBOL) {
  return Array.from(
    formula.matchAll(
      new RegExp(`${leftEnquoteSymbol}([^${leftEnquoteSymbol}]+)${rightEnquoteSymbol}`, 'g')
    )).map(match => match[1]);
}

// doing the same thing as hot formula parser but badly
export function getFunctionParameters(formula: string, functionName: string) {
  return getSafe(() => new RegExp(`${functionName}(_ON_DRAWING)?(_FROM_DRAWING)?\\(([^)]+)\\)`)
    .exec(formula)[3].trim()
    .replace(/'/g, "")
    .split(/\s*,\s*/)
  );
}

export function getFunctionParameter(formula: string, functionName: string) {
  return first(getFunctionParameters(formula, functionName));
}

function getEffectiveScale(unitType: UnitType, baseScale: number): number {
  switch (unitType) {
    case UnitType.Surface:
      return Math.pow(baseScale, 2);
    case UnitType.Volume:
      return Math.pow(baseScale, 3);
    default:
      return baseScale;
  }
}

export function getFormulaToKeepValueButChangeUnit(
  measurement: Measurement,
  measurementValue: MeasurementValue,
  newUnit: Unit
) {
  if (formulaHasVariables(measurementValue.formula)) {
    return measurementValue.formula; //not supported
  }

  const currentDisplayedValue = MeasurementConverter.convert(
    // consider never using '' in formulas
    parseFloat(measurementValue.formula),
    Unit.DefaultMetric,
    measurement.displayUnit
  );

  const newDefaultMetricValue = MeasurementConverter.convert(
    currentDisplayedValue,
    newUnit,
    Unit.DefaultMetric,
  );

  return '' + newDefaultMetricValue;
}

export function i18nVar(measurementType: MeasurementType) {
  return i18n.t(measurementType.toUpperCase());
}

export function isEveryNodeFormulaSameValue(measurement: Measurement, nodes: TreeNode[]): boolean {
  if (isEmpty(nodes)) {
    return false;
  }

  const firstChildNode = first(nodes);
  const firstChildNodeFormula = firstChildNode.measurementValues.get(measurement.id);
  const firstNodeFormulaValue = firstChildNodeFormula.metricValue;

  return every(
    nodes,
    childNode => {
      const childFormula = childNode.measurementValues.get(measurement.id);
      const childFormulaValue = childFormula.metricValue;
      return childFormulaValue === firstNodeFormulaValue;
    }
  );
}

export const getFeetPortion = (dimension: number): number => {
  return floorRounded(dimension);
}

export const getNumInchesPortion = (dimension: number): number => {
  return floorRounded((dimension - getFeetPortion(dimension)) * 12);
}

export const getFractionPortion = (dimension: number): Fraction => {
  const decimalValue = dimension - getFeetPortion(dimension) - getNumInchesPortion(dimension) / 12;

  // print fractional inches
  let inchesFraction = new Fraction(
    Math.round(decimalValue * MAX_INCHES_PRECISION),
    MAX_INCHES_PRECISION
  );

  return inchesFraction;
}

export function architectStrToFtIn(architectStr: string): IFeetInches {

  // if contains both ' and -, split on '... otherwise split on either one
  if (architectStr.match(/-|−/) && architectStr.match(/'/)) {
    architectStr = architectStr.replace(/-|−/g, "");
  }

  const [feetStr, inchesStr] = architectStr.split(/'|-|−/);

  let feet = parseFloat(feetStr);
  let inches = inchesStr && parseFloat(inchesStr);

  if (feet && !inches) {
    inches = (feet - floorRounded(feet)) * 12;
  }

  return { ft: floorRounded(feet), in: inches };
}

// normalize format to 1' - 2"  If invalid return null
function normalizeArchitectStr(architectStr: string): string {
  let ftIn = architectStrToFtIn(architectStr);
  if (ftIn.ft + ftIn.in == 0)
    return null;

  return ftIn.ft + "'-" + ftIn.in.toString() + "\"";
}

function ftInToArchitectStr(ftIn: IFeetInches): string {
  if (USE_DECIMAL_INCHES) {
    return ftIn.ft + "'-" + ftIn.in.toFixed(2) + "\"";
  }

  // print fractional inches
  let inchesFraction = new Fraction(
    Math.round(ftIn.in * MAX_INCHES_PRECISION),
    MAX_INCHES_PRECISION
  );

  if (inchesFraction.numerator / inchesFraction.denominator == 12) {
    ftIn.ft += 1;
    inchesFraction = new Fraction(0, 1);
  }

  return ftIn.ft + "'-" + inchesFraction.toString() + "\"";
}

export function parseDegreeAngle(degreeAngle: string): number { // radians
  // notice the minus, because displayed angle to user is reverse as the angle used by SVG
  // (maybe could cleanup with transformation scale -1)
  return -parseFloat(degreeAngle) * Math.PI / 180;
}

export function architectStrToDecimal(architectStr: string): number {
  let ftIn = architectStrToFtIn(architectStr);

  return ftInToDecimal(ftIn);
}

function normalizeFtIn(feetStr: string, inchesStr: string): IFeetInches {
  return { ft: parseInt(feetStr), in: parseInt(inchesStr) };
}

export function normalizeAngle(minusPiToPi: number): number {
  return minusPiToPi < 0 ? minusPiToPi + 2 * Math.PI : minusPiToPi;
}

function ftInToDecimal(ftIn: IFeetInches) {
  return (ftIn.ft + ftIn.in / 12);
}

function decimalToFtIn(decimal: number): IFeetInches {
  let feet = floorRounded(decimal);
  let inches = (decimal - feet) * 12;

  return { ft: feet, in: inches };
}

function decimalToArchitectStr(decimal: number): string {
  const ftIn = decimalToFtIn(decimal);
  return ftInToArchitectStr(ftIn);
}

function scaleDenomToArchitectScale(scaleDenom) {
  // pass a ratio denominator (ex: 2 for 1:2) and return architect scale such as 1/4" = 1'-0"
  let retval = new Fraction(12, scaleDenom).toString();

  if (retval == "NaN") return "0/0";

  return retval;
}

function architectScaleToScaleDenom(archScale: string, removeSuffix = false): number /* integer */ {
  if (removeSuffix) {
    archScale = archScale.replace(ARCHITECT_SCALE_SUFFIX, "");
  }

  let scaleFraction = new Fraction(archScale); // the direct fraction in the scale such as 1/4 in 1/4" = 1'-0"

  if (isNaN(scaleFraction.numerator))
    return null;

  let ratioFraction = (new Fraction(12)).divide(scaleFraction) // the fraction used to recover the denominator such as 2 in 1:2 scale

  if (ratioFraction.denominator == 0)
    return null;

  return ratioFraction.numerator / ratioFraction.denominator; // but is actually the denominator in the real scale 1:3 (3 in this case)
}

export function preparestring(formula: string): string {
  const variableRegex = /'([^']+)'/g;
  let matches;
  let iterCount = 0;
  do {
    matches = variableRegex.exec(formula);
    if (matches) {
      formula = formula.replace(matches[0], escapeVar(matches[1]));
    }
    iterCount++;
  } while (matches && iterCount < 100);

  formula = formula.replace(/OPTIONAL\(([^)]+)\)/gi, '($1)');

  if (!formula) {
    //debugger;
    formula = '0';
  }

  if (formula[0] === '.') {
    formula = '0' + formula;
  }

  return deburr(formula.toUpperCase().replace('_FROM_DRAWING', '').replace('_ON_DRAWING', ''));
}

// probably would be better to check for math operations too
// better name should mean hasVariablesOrFunction
export const formulaHasVariables = (formula: string): boolean => (
  formula &&
  //formula !== `${SAME_AS_CHILDREN}()` &&
  //formula !== `${SUM_CHILDREN_FUNCTION}()` &&
  /[a-zA-Z]/.test(formula)
);

export const formulaIsNumber = (formula: string): boolean => (
  formula &&
  //formula !== `${SAME_AS_CHILDREN}()` &&
  //formula !== `${SUM_CHILDREN_FUNCTION}()` &&
  !/[^0-9.\-]/.test(formula)
);

export const formulaIsFromDrawing = (formula: string): boolean => {
  formula = formula.toUpperCase()
    .replace('_FROM_DRAWING', '')
    .replace('_ON_DRAWING', '');

  // putting constant here, so i18n has time to load
  const FROM_DRAWING_FUNCTIONS = [
    SURFACE_FUNCTION,
    LENGTH_FUNCTION,
    COUNT_FUNCTION,
    PERIMETER_FUNCTION,
    CORNERS_QUANTITY_FUNCTION,
    SURFACES_QUANTITY_FUNCTION,
    SURFACES_PERIMETER_FUNCTION,
    OPENINGS_QUANTITY_FUNCTION,
    OPENINGS_SURFACE_FUNCTION,
    OPENINGS_TOTAL_WIDTH_FUNCTION,
    OPENINGS_TOTAL_PERIMETER_FUNCTION,
    OPENINGS_TOTAL_SIDES_FUNCTION,
    OPENINGS_TOTAL_TOP_BOTTOM_FUNCTION,
    AVERAGE_HEIGHT_FUNCTION,
    EXTREMITIES_QUANTITY_FUNCTION,
    GUESS_SLOPE_DIRECTION_FUNCTION,
    OFFSET_SURFACE_FUNCTION,
    OFFSET_LENGTH_FUNCTION,
    REPEAT_LINE_FUNCTION,
    RECTANGLES_BOTTOMS_FUNCTION,
    RECTANGLES_SIDES_FUNCTION,
    RECTANGLES_TOPS_FUNCTION,
    SHAPES_BOTTOMS_FUNCTION,
    SHAPES_SIDES_FUNCTION,
    SHAPES_TOPS_FUNCTION,

    i18n.t(BuiltinMeasurements.Length).toLocaleUpperCase(),
    i18n.t(BuiltinMeasurements.Surface).toLocaleUpperCase(),
  ];

  return (
    formula && FROM_DRAWING_FUNCTIONS.some(fromDrawingFunction => (
      formula
        .replace(/[' ]/g, '')
        .replace(/\([^)]*\)/g, '')
        .toLocaleUpperCase() === fromDrawingFunction
    )));
}

export const formulaIsBubbleFunction = (formula: string): boolean => (
  formula && (
    formula == `${SAME_AS_CHILDREN_FUNCTION}()` ||
    formula == `${SUM_CHILDREN_FUNCTION}()`
  )
)

// cumbersome metricValue carrying... might be better to move some to Measurement.ts
export default class MeasurementFormatter {
  static getValueWithoutUnit(measurementValue: MeasurementValue, adjustment: Adjustment = null): number {
    if (!measurementValue || !measurementValue.measurement) {
      return 0;
    }
    return round(MeasurementConverter.convert(
      // applies task adjustment to metric value that already contains tree node adjustment (a bit confusing...)
      measurementValue.applyAdjustment(measurementValue.metricValue, adjustment),
      Unit.DefaultMetric,
      measurementValue.measurement.displayUnit
    ), 9);
  }

  // optionnally pass the value directly if we already know it, so we can skip evaluating the formula
  static format(measurementValue: MeasurementValue, adjustment: Adjustment = null): string {
    if (!measurementValue?.measurement?.stores) {
      return '';
    }

    const { settingsStore } = measurementValue.measurement.stores;
    const { measurement } = measurementValue;

    const shouldUseFeetInches = (
      settingsStore.isImperial &&
      measurement.unitType === UnitType.Length &&
      measurement.displayUnit === Unit.Foot
    );

    let convertedValue = MeasurementFormatter.getValueWithoutUnit(measurementValue, adjustment);

    if (measurement.displayUnit === Unit.CurrencySign) {
      return formatCurrency(convertedValue);
    }

    let decimalScale = 2;

    // if (measurement.displayUnit === Unit.Unit || measurement.displayUnit === Unit.Pack /* need improvement */) {
    //   return Math.ceil(convertedValue) + ' ' + formatUnit(measurement.displayUnit);
    // }

    if (shouldUseFeetInches) {
      return decimalToArchitectStr(convertedValue);
    }

    if (
      measurement.displayUnit === Unit.Ratio || (
        convertedValue - floorRounded(convertedValue) < 0.0001) &&
      (
        measurement.displayUnit === Unit.Unit ||
        measurement.displayUnit === Unit.Pack ||
        measurement.displayUnit === Unit.Truck ||
        measurement.displayUnit === Unit.Step ||
        measurement.displayUnit === Unit.Box ||
        measurement.displayUnit === Unit.TimeBlock ||
        measurement.displayUnit === Unit.Budget ||
        measurement.displayUnit === Unit.Allocation
      )
    ) {
      decimalScale = 0;
    }

    if (convertedValue > 100) {
      decimalScale = 1;
    }

    if (convertedValue === null) {
      //  console.log('bug?');
      convertedValue = 0;
    }

    return convertedValue.toLocaleString(i18n.locale, {
      maximumFractionDigits: decimalScale
    }) + ' ' + formatUnit(measurement.displayUnit);
  }
}
