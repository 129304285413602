
import { IEditableProps, IReportProps } from 'constants/CommonProps';
import Report from 'models/Report';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./ReportClientInfo.module.scss');

export default class ReportClientInfo extends ObserverComponent<IReportProps & IEditableProps> {
  changeClientInfo = property => ({ target: { value } }) => {
    const { report } = this.props;
    report[property] = value;
  }

  saveToDb = (fieldName: keyof Report) => () => {
    const { reportsStore } = this.context;
    const { report } = this.props;
    reportsStore.addEditItem(report, true, [fieldName]);
  }

  _render() {
    const { isEditable, report, className } = this.props;
    const { clientName, clientAddress } = report;

    return isEditable ? (
      <div className={styles.root + ' ' + styles.isEditable}>
        <TextField
          label={i18n.t('Client Name')}
          value={clientName}
          onChange={this.changeClientInfo('clientName')}
          onBlur={this.saveToDb('clientName')}
        />
        <TextField
          label={i18n.t('Client Contact Info')}
          multiline
          rows="4"
          rowsMax="4"
          value={clientAddress}
          onChange={this.changeClientInfo('clientAddress')}
          onBlur={this.saveToDb('clientAddress')}
        />
      </div>
    ) : clientAddress && (
        <div className={styles.root + ' ' + className}>
          <div className={styles.title}>{i18n.t('Client')}</div>
          <div className={styles.content}>
            <div className={styles.firstLine}>{clientName}</div>
            <div className={styles.address}>
              {clientAddress}
            </div>
          </div>
        </div>
      );
  }
}