import { FeeCategoriesOptions } from "constants/FeeCategoriesOptions";
import { ProvidingItemSubtype } from "constants/ProvidingItemConstants";
import { isEmpty } from "lodash";
import { computed, observable } from "mobx";
import { list, primitive, serializable } from "serializr";
import Stores from "stores/Stores";
import { localized } from "utils/localized";
import Category from "./Category";
import SerializableBase from "./SerializableBase";

export default class Fee extends SerializableBase {
  @serializable type = 'Fee';

  @localized name: string = '';
  @serializable @observable percentage: number = 0;

  // if not empty, apply only to items matching this filter
  @serializable(list(primitive())) @observable providingItemsSubtypes = [];

  @serializable(list(primitive())) @observable providingItemsCategoryIds = [];
  @serializable(list(primitive())) @observable excludedProvidingItemsCategoryIds = [];

  @computed get providingItemsCategories(): Category[] {
    const {categoriesStore} = this.stores;
    return (this.providingItemsCategoryIds || []).map(id => categoriesStore.getItem(id));
  }

  @computed get excludedProvidingItemsCategories(): Category[] {
    const {categoriesStore} = this.stores;
    return (this.excludedProvidingItemsCategoryIds || []).map(id => categoriesStore.getItem(id));
  }

  @observable _categorySelectedOption = FeeCategoriesOptions.All;

  @computed get categorySelectedOption() {
    if (!isEmpty(this.providingItemsCategoryIds)) {
      return FeeCategoriesOptions.ChooseIncluded;
    }
    if (!isEmpty(this.excludedProvidingItemsCategoryIds)) {
      return FeeCategoriesOptions.ChooseExcluded;
    }
    return this._categorySelectedOption;
  }

  set categorySelectedOption(categoriesOption: FeeCategoriesOptions) {
    switch (categoriesOption) {
      case FeeCategoriesOptions.All:
        this.providingItemsCategoryIds = [];
        this.excludedProvidingItemsCategoryIds = [];
        break;
      case FeeCategoriesOptions.ChooseIncluded:
        this.excludedProvidingItemsCategoryIds = [];
        break;
      case FeeCategoriesOptions.ChooseExcluded:
        this.providingItemsCategoryIds = [];
        break;
    }

    this._categorySelectedOption = categoriesOption;
  }

  @computed get expandedProvidingItemsSubtypes(): ProvidingItemSubtype[] {
    if (isEmpty(this.providingItemsSubtypes)) {
      return Object.values(ProvidingItemSubtype);
    } else {
      return this.providingItemsSubtypes;
    }
  }

  @computed get hasFilter() {
    return (
      !isEmpty(this.excludedProvidingItemsCategories) ||
      !isEmpty(this.providingItemsCategories) ||
      !isEmpty(this.providingItemsSubtypes)
    );
  }

  constructor(
    stores: Stores,
    nameAll: LocalizedString = undefined,
    percentage: number = undefined
  ) {
    super(stores);

    if (nameAll) {
      this._name = nameAll;
    }
    if (percentage) {
      this.percentage = percentage;
    }
  }

  clearFilters() {
    this.providingItemsSubtypes = [];
    this.providingItemsCategoryIds = [];
    this.excludedProvidingItemsCategoryIds = [];
  }
}