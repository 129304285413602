export enum Unit {
  Unknown = 'Unknown',
  DefaultMetric = 'DefaultMetric', // default unit for a certain UnitType
  Meter = 'Meter',
  Milimeter = 'Milimeter', // typo but unsafe to correct
  Centimeter = 'Centimeter',
  Kilometer = 'Kilometer',
  Foot = 'Foot',
  SquareMeter = 'SquareMeter',
  SquareFoot = 'SquareFoot',
  SquareYard = 'SquareYard',
  SquareInch = 'SquareInch',
  CubicMeter = 'CubicMeter',
  CubicFoot = 'CubicFoot',
  CubicYard = 'CubicYard',
  Liter = 'Liter',
  USGallon = 'USGallon',
  US5Gallon = 'US5Gallon',
  Inch = 'Inch',
  Unit = 'Unit',
  Pack = 'Pack',
  Percent = 'Percent',
  Box = 'Box',
  Truck = 'Truck',
  Step = 'Step',
  // add centimeters etc
  Kilogram = 'Kilogram',
  Pound = 'Pound',
  BritTon = 'BritTon',
  USTon = 'USTon',
  MetricTon = 'MetricTon',
  Hour = 'Hour',
  Minute = 'Minute',
  Day = 'Day',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year',
  Budget = 'Budget',
  Allocation = 'Allocation',
  TimeBlock = 'TimeBlock',
  Radian = 'Radian',
  Degree = 'Degree',
  Ratio = 'Ratio',
  SlopePercentage = 'SlopePercentage',
  Unitless = 'Unitless',
  CurrencySign = 'CurrencySign'
}