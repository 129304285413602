
import * as React from 'react';

interface AvatarProps {
  className: string,
  style: any,
  children: any,
}

export const Avatar = React.memo(({ className, style, children }: AvatarProps) => (
  <div className={(className || '') + ' ' + 'MuiAvatar-root MuiAvatar-circle MuiAvatar-colorDefault'} style={style}>{children}</div>
));