
import ObserverComponent from 'components/common/ObserverComponent';
import { throttle } from 'lodash';
import { action } from 'mobx';
import BackgroundImageScale from 'models/BackgroundImageScale';
import * as React from 'react';
import { cursorToLocalPoint } from 'utils/Coords';
import { drawToolMouseMoveThrottledBase, MOUSE_MOVE_THROTTLE_TIME } from 'utils/DrawToolsUtils';
import { getSafe } from 'utils/Utils';

const styles = require('./AddBackgroundImageScaleTool.module.scss');

export default class AddBackgroundImageScaleTool extends ObserverComponent {
  svgTag: SVGSVGElement;

  componentDidMount() {
    this.svgTag.addEventListener('click', this.mouseDown);
    window.document.addEventListener('mousemove', this.mouseMove);

    const { shapesStore } = this.context;
    shapesStore.backgroundImageScaleShape = null;
    shapesStore.shouldShowBackgroundImageScaleInput = false;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.svgTag.removeEventListener('click', this.mouseDown);
    window.document.removeEventListener('mousemove', this.mouseMove);
  }

  @action.bound
  mouseDown(ev: MouseEvent) {
    const { shapesStore } = this.context;
    if (
      // duplicate
      ev.ctrlKey ||
      ev.button !== 0 ||
      shapesStore.isDragPanningOnDrawing ||
      (ev?.target as HTMLElement)?.tagName === 'INPUT'
    ) {
      return;
    }

    if (shapesStore.shouldShowBackgroundImageScaleInput) {
      return;
    }

    const localPt = cursorToLocalPoint(ev, this.svgTag);

    if (shapesStore.backgroundImageScaleShape) {
      shapesStore.backgroundImageScaleShape.endPt = localPt.clone();
      shapesStore.shouldShowBackgroundImageScaleInput = true;
      return;
    }

    const newScale = shapesStore.createItem(BackgroundImageScale);
    newScale.startPt = localPt.clone();
    newScale.endPt = localPt.clone();
    shapesStore.backgroundImageScaleShape = newScale;
  }


  @action.bound
  mouseMove(ev: MouseEvent) {
    this.mouseMoveThrottled(ev);
  }

  @action
  mouseMoveThrottled = throttle((ev: MouseEvent) => {
    const { shapesStore } = this.context;

    if (
      !shapesStore.backgroundImageScaleShape ||
      shapesStore.shouldShowBackgroundImageScaleInput
    ) {
      return;
    }

    drawToolMouseMoveThrottledBase(ev, this.svgTag, this.context);

    const localPt = cursorToLocalPoint(ev, this.svgTag);
    shapesStore.backgroundImageScaleShape.endPt = localPt.clone();

  }, MOUSE_MOVE_THROTTLE_TIME)

  _render() {
    return (
      <g id="AddScaleTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement) || this.svgTag} />
    );
  }
}