import Color from "color";
import { computed, observable } from "mobx";
import { serializable } from "serializr";

export default class UserColors {
  constructor(color1 = 'rgb(24,39,53)') {
    this.color1 = color1;
  }

  @serializable @observable color1: string = '';

  @computed get colorPale() {
    let colorObject = Color('rgb(24,39,53)');
    try {
      colorObject = Color(this.color1);
    } catch (e) {}

    const hue = colorObject.hue();
    const saturation = colorObject.isLight() ? colorObject.saturationl() : colorObject.saturationl() * .5;
    const lightness = 95;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  @computed get colorText() {
    let colorObject = Color('rgb(24,39,53)');
    try {
      colorObject = Color(this.color1);
    } catch (e) {}
    
    const isLogoColorLight = colorObject.luminosity() > 0.45;
    return isLogoColorLight
      ? 'black'
      : 'white';
  }
}