import { Button } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./PaymentFailedWarningBanner.module.scss');

export default class PaymentFailedWarningBanner extends ObserverComponent {
  _render() {
    const { userInfoStore } = this.context;
    const { user } = userInfoStore;

    return (
      <div className={styles.root}>
        <WarningIcon className={styles.icon} />
        <div>{i18n.t('There was a problem receiving your last payment. Please update your billing information to avoid an interruption of service.')}</div>
        <div style={{ flex: '1' }} />
        <a className={styles.link} href={`https://evalumo.com/zone-client/${user.quadernoUserId}/edit`} target="_blank" rel="noopener noreferrer">
          <Button variant="contained" className={styles.button} color="primary">
            <UpdateIcon />&nbsp;
            {i18n.t('Update Billing Information')}
          </Button>
        </a>
      </div>
    )
  }
}