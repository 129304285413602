import MomentUtils from '@date-io/moment';
import classnames from 'classnames';
import { IClassNameProps, IEditableProps, IReportProps } from 'constants/CommonProps';
import { ReportSubtypes } from 'constants/ReportOptionsConstants';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

const styles = require('./ReportDate.module.scss');

interface ReportDateProps {
  dateField: string,
  disableFuture?: boolean,
}

export default class ReportDate extends ObserverComponent<ReportDateProps & IReportProps & IEditableProps & IClassNameProps> {
  static defaultProps = {
    dateField: 'date'
  }

  pickerRef;

  changeDate = (newDate: moment.Moment) => {
    const { reportsStore } = this.context;
    const { report, dateField } = this.props;
    report[dateField] = newDate ? new Date(newDate.valueOf()) : new Date();  
    report.areDatesLocked = !!newDate;
  
    reportsStore.addEditItem(report, true, [dateField, '_' + dateField, 'areDatesLocked']);
  }

  setToday = () => {
    this.pickerRef.close();
    this.changeDate(null);
  }

  componentDidMount(): void {
    const { report, dateField } = this.props;
    if (report && !report.areDatesLocked) {
      report[dateField] = new Date();
    }
  }

  _render() {
    const { isEditable, className, report, dateField, disableFuture } = this.props;
    let date = report[dateField];

    const { areDatesLocked } = report;
  
    const momentDate = moment(date)
    let label = i18n.t('Date');

    if (report.subtype === ReportSubtypes.Invoice) {
      label = i18n.t('Invoice Date');
    }

    // hack
    if (dateField === 'dueDate') {
      label = i18n.t('Due Date');
    }

    if (!areDatesLocked && isEditable) {
      label += ` (${i18n.t('Today')})`;
    }

    return isEditable ? (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          ref={ref => this.pickerRef = ref}
          className={classnames(styles.root, styles.datePicker, className)}
          variant="filled"
          keyboard
          label={label}
          margin="none"
          format="DD/MM/YYYY" // should come from moment after setting correct language
          //placeholder="10/10/2018"
          mask={value =>
            // handle clearing outside if value can be changed outside of the component
            value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
          }
          value={momentDate}
          onChange={this.changeDate}
          onSetToday={this.setToday}
          disableOpenOnEnter
          animateYearScrolling={false}
          showTodayButton
          todayLabel={i18n.t('Today')}
          cancelLabel={i18n.t('Cancel')}
          disableFuture={disableFuture}
        />
      </MuiPickersUtilsProvider>
    ) : (
      <div className={styles.root + ' ' + className}>
        <div>{label}</div>
        <div>{momentDate.format("LL")}</div>
      </div>
    );
  }
}