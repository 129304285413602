
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { DrawToolType } from 'constants/DrawToolType';
import { computed } from 'mobx';
import CountPoint from 'models/CountPoint';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import InlineSvg from 'react-inlinesvg';

const styles = require('./CountPointComponent.scss');

interface CountPointComponentProps {
  countPoint: CountPoint,
  readonly?: boolean,
}

const DOUBLE_CLICK_THRESHOLD = 200;

export default class CountPointComponent extends ObserverComponent<CountPointComponentProps> {
  private queuedOnClicks: number/*NodeJS.Timeout*/[] = [];

  @computed get node(): TreeNode {
    const { treeNodesStore } = this.context;
    const { countPoint } = this.props;
    const node = treeNodesStore.getNodeForShape(countPoint);
    return node;
  }

  onClickDelayed = (event: React.MouseEvent<SVGPathElement>) => {
    event.stopPropagation();
    event.preventDefault();
    event.persist();
    this.queuedOnClicks.push(setTimeout(this.onClick, DOUBLE_CLICK_THRESHOLD, event));
  }

  // duplicate
  onClick = (event: React.MouseEvent<SVGPathElement>) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;

    this.queuedOnClicks.forEach(timer => (
      clearTimeout(timer)
    ));
    this.queuedOnClicks = [];

    drawToolsStore.dispatchEvent(new CustomEvent('click', { detail: { shape: this.props.countPoint, originalEvent: event } }));
  }

  // duplicate
  onDoubleClick = (event: React.MouseEvent<SVGPathElement>) => {
    const { drawToolsStore, treeNodesStore } = this.context;
    this.queuedOnClicks.forEach(timer => (
      clearTimeout(timer)
    ));
    this.queuedOnClicks = [];

    drawToolsStore.dispatchEvent(new CustomEvent('doubleclick', { detail: { shape: this.props.countPoint, originalEvent: event } }));
  }

  _render() {
    const { routerStore, drawToolsStore } = this.context;
    const { isReportPage } = routerStore;
    const { countPoint, readonly } = this.props;
    const { point } = countPoint;
    const { legendColor } = countPoint;


    const isSelected = readonly || countPoint.isSelected;

    const isSelectOrGroup = drawToolsStore.selectedTool === DrawToolType.Select || drawToolsStore.selectedTool === DrawToolType.GroupShapes;

    return point && (
      <g
        id={countPoint.id}
        className={classnames(
          'CountPointComponent',
          {
            isSelected: isSelected,
            isShapeHidden: !(readonly || countPoint.isVisible),
            shouldHover: !readonly && isSelectOrGroup
          })}
        style={(!isReportPage && legendColor) ? { color: legendColor } : {}}
        onClick={readonly ? undefined : this.onClickDelayed}
        onDoubleClick={readonly ? undefined : this.onDoubleClick}
        transform={`translate(${point.x} ${point.y})`}
      >
        {
          countPoint.iconUrl
            ? (
              countPoint.iconUrl.includes(".svg")
                ? (
                  <g style={{ transform: `scale(${isSelected ? 2 : 1.33}) translate(-11px, -11px)` }}>
                    <rect x="0" y="0" width="22" height="22" stroke="none" fill="rgba(255,255,255,0.01)" />
                    <InlineSvg
                      src={countPoint.iconUrl}
                      // weirdest memory leak if uncommenting this
                      style={{ fill: countPoint.legendColor, /*stroke: countPoint.legendColor*/ }}
                      width={22}
                      height={22}
                      preProcessor={(svgString: string) => (
                        svgString
                          .replace(/fill="[^"]+"/g, '')
                          .replace(/stroke="[^"]+"/g, '')
                      )}
                    />
                  </g>
                ) : <image href={countPoint.iconUrl} image-rendering="optimizeSpeed" style={{ transform: `scale(${isSelected ? 2 : 1.33}) translate(-11px, -11px)` }} width="22" height="22" />
            ) : <use href="#countPoint" style={{ transform: `scale(${isSelected ? 2 : 1.33}) translate(-12px, -22px)` }} />
        }

      </g>
    );
  }
}