import Dialog from 'models/Dialog';
import React from 'react';
import Stores from 'stores/Stores';

export const showUpgradeSubscriptionDialog = (stores: Stores) => () => {
  const { dialogsStore } = stores;

  const newDialog = new Dialog(stores);
  // dynamically load stripe library that takes a lot of memory
  const UpgradeSubscriptionDialog = require('components/common/UpgradeSubscriptionDialog/UpgradeSubscriptionDialog').default;

  newDialog.dialogComponent = ({ open }) => (
    <UpgradeSubscriptionDialog
      open={open}
      dialogId={newDialog.id}
    />
  );

  dialogsStore.showDialog(newDialog);
}