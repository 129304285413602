import { Unit } from "constants/Unit";
import { UnitType } from "constants/UnitType";
import { computed, observable } from "mobx";
import { custom, serializable, SKIP } from "serializr";

export class ProvidedQuantity {
  // not implemented yet
  // should only support material items
  @serializable @observable quantityFormula: string = '';

  @serializable(custom(
    () => SKIP,
    (jsonValue, context, oldValue, callback) => {
      if (!context.json.quantityFormula) {
        callback(null, jsonValue);
      }
    }))
  @computed get quantity(): number {
    return parseFloat(this.quantityFormula) || 0;
  }
  set quantity(value: number) {
    this.quantityFormula = value?.toString() || '';
  }

  @computed get quantityString(): string {
    // quantity formula is not implemented
    // it should be parse(this.quantityFormula)
    return this.quantityFormula;
  }

  @observable @serializable unitType: UnitType = UnitType.Unknown;
  @observable @serializable unit: Unit = Unit.Unknown;
  @observable @serializable confidencePercentage: number = 10; // not a percentage!
  rowIndex: number = 0; // to changing order when editing

  constructor(unitType?: UnitType, quantity?: number, unit?: Unit, confidencePercentage?: number) {
    if (unitType) {
      this.unitType = unitType;
    }
    if (quantity) {
      this.quantity = quantity;
    }
    if (unit) {
      this.unit = unit;
    }
    if (confidencePercentage) {
      this.confidencePercentage = confidencePercentage;
    }
  }
}