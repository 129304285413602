import { AdjustmentType } from 'constants/AdjustmentType';
import { observable } from 'mobx';
import { serializable } from 'serializr';
import SerializableBase from './SerializableBase';

export default class Adjustment extends SerializableBase {
  type = 'Adjustment';
  @observable @serializable subtype = AdjustmentType.Add;

  // added directly to result (using same unit as measurement display unit
  @serializable measurementId = '';
  @observable @serializable value = 0;
}