import BackgroundImagesStore from './BackgroundImagesStore';
import CategoriesStore from "./CategoriesStore";
import ClientsStore from "./ClientsStore";
import CommonStore from "./CommonStore";
import CopiedTasksStore from "./CopiedTasksStore";
import DbCacheStore from './DbCacheStore';
import DialogsStore from "./DialogsStore";
import DragAndDropStore from "./DragAndDropStore";
import DrawToolsStore from "./DrawToolsStore";
import MeasurementsStore from "./MeasurementsStore";
import MerchantsStore from './MerchantsStore';
import PriceUpdateStore from './PriceUpdateStore';
import ProgressStore from "./ProgressStore";
import ProjectsStore from "./ProjectsStore";
import ProvidingItemsStore from "./ProvidingItemsStore";
import ReportsStore from "./ReportsStore";
import RouterStore from './RouterStore';
import SettingsStore from "./SettingsStore";
import ShapesStore from "./ShapesStore";
import SnapStore from "./SnapStore";
import SubscriptionsStore from './SubscriptionsStore';
import TasksListsStore from "./TasksListsStore";
import TasksStore from "./TasksStore";
import TestStore from "./TestStore";
import TotalsStore from "./TotalsStore";
import TreeNodesStore from "./TreeNodesStore";
import UndoStore from './UndoStore';
import UserInfoStore from "./UserInfoStore";

// This is often passed as context
// because we can reuse the same component while loading different groups of stores.
// E.g. main project vs external projects vs tasks lists
// ORDER OF VARIABLES IS IMPORTANT
export default class Stores {
  name = '';

  subscriptionsStore = new SubscriptionsStore(this);

  commonStore = new CommonStore(this);
  dbCacheStore = new DbCacheStore(this);
  userInfoStore = new UserInfoStore(this);
  projectsStore = new ProjectsStore(this);
  settingsStore = new SettingsStore(this);
  categoriesStore = new CategoriesStore(this);
  routerStore = new RouterStore(this);
  shapesStore = new ShapesStore(this);
  treeNodesStore = new TreeNodesStore(this);
  
  clientsStore = new ClientsStore(this);
  copiedTasksStore = new CopiedTasksStore(this);
  dialogsStore = new DialogsStore(this);
  dragAndDropStore = new DragAndDropStore(this);
  drawToolsStore = new DrawToolsStore(this);
  measurementsStore = new MeasurementsStore(this);
  progressStore = new ProgressStore(this);
  reportsStore = new ReportsStore(this);
  snapStore = new SnapStore(this);
  tasksStore = new TasksStore(this);
  providingItemsStore = new ProvidingItemsStore(this);
  tasksListsStore = new TasksListsStore(this);
  testStore = new TestStore(this);
  totalsStore = new TotalsStore(this);
  priceUpdateStore = new PriceUpdateStore(this);
  backgroundImagesStore = new BackgroundImagesStore(this);
  merchantsStore = new MerchantsStore(this);
  undoStore = new UndoStore(this);
};
