export enum Currency {
  CanadianDollar = 'CAD',
  MexicanPeso = 'MXN',
  Euro = 'EUR',
  Pound = 'GBP',
  Yen = 'JPY'
}

export const CurrencySigns = {
  'USD': '$',
  'CAD': '$',
  'MXN': '$',
  'EUR': '€',
  // todo
}
