import { DbLocationType } from 'constants/DbLocationType';
import { FilterDateOptions } from 'constants/FilterConstants';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { compact, uniq, uniqBy } from 'lodash';
import { computed, observable, reaction } from 'mobx';
import Category from 'models/Category';
import Merchant from 'models/Merchant';
import ProvidingItem from 'models/ProvidingItem';
import { roundPrice } from 'utils/CurrencyUtil';
import DbItemFactory from 'utils/DbItemFactory';
import { applyFilterDateOption } from 'utils/FilterUtil';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { fetchHiddenIFrame } from 'utils/IFrameUtil';
import { getSafe, shouldUseCache, sleep } from 'utils/Utils';
import SearchableFirebaseStore from './SearchableFirebaseStore';
;

const HOME_DEPOT_STORE_ID_COOKIE_NAME = 'homedepotStoreId';

// NEED TO STORE FRENCH ENGLISH ETC
export default class ProvidingItemsStore extends SearchableFirebaseStore<ProvidingItem> {
  storeKey = 'providingItems';
  subtypes = Object.values(ProvidingItemSubtype);

  dbLocationsTypes = new Set(compact([
    DbLocationType.Master, 
    DbLocationType.User, 
    this.accountParam && DbLocationType.ExternalUser,
    DbLocationType.Project
  ]));

  shouldTrackCreationDate = true;
  shouldTrackModifDate = true;
  shouldKeepUserItems = true;
  shouldTrackOwner = true;

  shouldSearchId = true;

  // keep this line committed
  shouldHideUnnamedItems = true;

  shouldWaitOnAllCollectionsLoaded = true; //false; // Experimental

  // shouldSearchUserItems = true // will be set after userinfostore load

  // default to master price data if merchants is unavailable
  masterPriceData = new Map<string, { price: number, priceUpdatedMiliseconds: number }>();
  // to check if project prices are up to date with user prices
  @observable userPriceData = new Map<string, { price: number, priceUpdatedMiliseconds: number }>();

  @observable _subtypeFilter: ProvidingItemSubtype = ProvidingItemSubtype.Material;

  @observable merchantFilter: Merchant = null;
  @observable ownerFilter: string = null;
  @observable priceUpdatedFilter = 'All' as FilterDateOptions;

  @observable lastUsedFilter = 'All' as FilterDateOptions;

  @computed get filterableMerchants() {
    return compact(uniqBy(this.items.map(i => i.merchant), 'id'));
  }

  @computed get filterableOwners() {
    return compact(uniq(
      this.items.map(i => i.owner)
    ));
  }

  @observable providingItemBeingEdited: ProvidingItem = null;

  @computed get materialItems(): ProvidingItem[] {
    return this.items.filter(item => item.subtype === ProvidingItemSubtype.Material);
  }

  @computed get labourItems(): ProvidingItem[] {
    return this.items.filter(item => item.subtype === ProvidingItemSubtype.Labour);
  }

  @computed get materialCategories(): Category[] {
    return uniq(compact(
      this.items
        .filter(item => item.subtype === ProvidingItemSubtype.Material)
        .map(item => item.category)
    ));
  }

  @computed get labourCategories(): Category[] {
    return uniq(compact(
      this.items
        .filter(item => item.subtype === ProvidingItemSubtype.Labour)
        .map(item => item.category)
    ));
  }

  @computed get itemsWithMerchant(): ProvidingItem[] {
    return this.itemsAndHiddenItems.filter(item => item.merchant);
  }

  // master and unused by user yet
  @computed get nonMasterItemsWithMerchant(): ProvidingItem[] {
    if (this.userEmail === 'master') {
      return this.itemsWithMerchant;
    }
    return this.itemsWithMerchant.filter(item => item.cascadeOrders.size > 1 || item.cascadeOrder !== 0);
  }

  @computed get userItemsWithMerchant(): ProvidingItem[] {
    return this.itemsWithMerchant.filter(item => item.cascadeOrders.has(this.userCollectionIndex));
  }

  /* override */ onItemChangeFromDb(itemId: ModelId, itemData: ProvidingItem, changeType: string, cascadeOrder: number) {
    itemData = this.ensureType(itemData);

    if (cascadeOrder === this.collections.indexOf(this.userCollection) && this.stores.userInfoStore.user?.shouldShowUserPriceUpdates) {
      // to make the @localized decorator work on price
      // VERY SLOW to deserialize just for that!!
      const tempItem = DbItemFactory.create(itemData, this.stores, () => { ; }) as ProvidingItem;
      this.userPriceData.set(itemId, { price: tempItem.price, priceUpdatedMiliseconds: tempItem.priceUpdatedMiliseconds });
    }

    super.onItemChangeFromDb(itemId, itemData, changeType, cascadeOrder);
  }

  extraFilterFunction = () => item => item && (
    (!this.merchantFilter || item.merchant === this.merchantFilter) &&
    (!this.ownerFilter || item.owner === this.ownerFilter) &&
    (this.priceUpdatedFilter === FilterDateOptions.All || applyFilterDateOption(item.priceUpdatedMiliseconds, this.priceUpdatedFilter))
  );

  public reloadPricing = async (item: ProvidingItem) => {
    const { providingItemsStore } = this.stores;

    const newPrice = await this.getUpdatedPrice(item);

    if (newPrice) {
      item.price = newPrice;
      item.priceUpdatedMiliseconds = (new Date()).getTime();

      if (item.isLabour && item.merchant) {
        item.labourRate = newPrice;
      }
      providingItemsStore.addEditItem(item);
    }
  }

  public reloadItem = async (item: ProvidingItem) => {
    const updatedItem = await this.getUpdatedItem(item);
    if (!updatedItem) {
      return;
    }

    item.previousPrice = item.price;

    // ONLY reload these fields
    // note: can only reload one language, need to reload the other one separately
    const keysToReload = ['_priceFormula', 'priceUpdatedMiliseconds', 'name', '_imageUrls', '_thumbUrls', '_sku', '_url'] as (keyof ProvidingItem)[];

    keysToReload.forEach(key => {
      item[key] = updatedItem[key];
    })

    if (updatedItem.imageUrls?.[0]) {
      // remove legacy data
      item.__imageUrl = { en: '' };
      item.__thumbUrl = { en: '' };
    }

    await item.waitOnMetaReady();
    await updatedItem.waitOnMetaReady();

    item.metaCached.description = updatedItem.metaCached.description;
  }

  // call after changing language to the one we want to update
  public DEBUG__reloadItemsNeedingTranslation = async () => {
    const { settingsStore } = this.stores;
    const { language } = settingsStore;

    const otherLanguage = language === 'en' ? 'fr' : 'en';

    const itemsNeedingTranslation = this.items
      .filter(i => (
        !i._name[language] || i._name[language] === i._name[otherLanguage]) &&
        i._url[language] && i._url[language] !== i._url[otherLanguage]
      );

    await itemsNeedingTranslation.reduce(async (previousTask, item) => {
      await previousTask;

      await this.reloadItem(item);

      this.addEditItem(item);

      await sleep(5000);
    }, Promise.resolve(''))
  }

  public DEBUG__removeUnusedItemsFromProject = async () => {
    const {treeNodesStore} = this.stores;
    const usedItemsIds = new Set(treeNodesStore.rootNode.tasks.map(t => t.providingItemId));
    const itemsToRemove = this.items.filter(i => i.cascadeOrders.has(this.projectCollectionIndex) && !usedItemsIds.has(i.id));

    const batch = firestoreBatch();
    itemsToRemove.forEach(i => {
      batch.delete(this.projectCollection.doc(i.id));
    });
    
    batch.commit();
  }

  public DEBUG_resetLabourItemsToMaster = () => {
    const itemsToReset = this.items.filter(i => i.isLabour && i.cascadeOrders.has(0));

    debugger;
    console.log('items to reset', itemsToReset.map(i => i.id));

    this.resetToMasterItems(itemsToReset);
  }


  public getUpdatedItem = async (item: ProvidingItem) => {
    const { merchantsStore } = this.stores;

    if (!item) {
      return;
    }

    const url = item.url;
    const merchant = merchantsStore.getMerchantByUrl(url);

    if (!merchant) {
      return null;
    }

    const iframe = await fetchHiddenIFrame(url);

    const detectedItem = await merchantsStore.createDetectedItemWithRetry(iframe.contentDocument, merchant, true, 10, item);

    document.body.removeChild(iframe);

    return detectedItem;
  }

  public getUpdatedPrice = async (item: ProvidingItem) => {
    if (!item) {
      return 0;
    }

    const updatedItem = await this.getUpdatedItem(item);

    return updatedItem?.price || 0;
  }

  /* override */ addCachedMasterItems() {
    this.cachedMasterData = require('assets-sw/db-cache/providingItems.json');

    if (shouldUseCache()) {
      this.applyCachedData(this.cachedMasterData);
    }

    this.masterPriceData = new Map(Object.entries(this.cachedMasterData)
      .map(entry => [
        entry[0], {
          price: getSafe(() => parseFloat(Object.values(entry[1]._priceFormula)[0])) || 0,
          priceUpdatedMiliseconds: entry[1].priceUpdatedMiliseconds
        }]));
  }

  // not perfect, but better than nothing
  updateLabourRate(currentRate: number, newRate: number): number {
    const itemsToSave = new Set<ProvidingItem>();
    const itemsToReplace = new Set<ProvidingItem>();

    this.labourItems.forEach(item => {
      // fix labourRate inconsistencies
      if (
        item.providedQuantities.size === 1 &&
        item.providedQuantities.get(UnitType.Time)?.quantity === 1 &&
        item.providedQuantities.get(UnitType.Time)?.unit === Unit.Hour &&
        item.labourRate !== item.price
      ) {
        item.labourRate = item.price;
        itemsToSave.add(item);
      }

      for (let numWorkers = 1; numWorkers <=1 /*< 5*/; numWorkers++) {
        if (item.labourRate !== currentRate * numWorkers) {
          continue;
        }

        // what if no labourDuration
        if (item.labourDuration) {
          item.price = roundPrice(newRate * numWorkers * item.labourDuration);
          itemsToSave.add(item);
          itemsToReplace.add(item);
        }

        if (item.labourRate) {
          item.labourRate = newRate * numWorkers;
          itemsToSave.add(item);
          itemsToReplace.add(item);
        }
      }
    });

    console.log(`Updating labor cost for ${itemsToReplace.size} items.`)

    let itemsToSaveToUserCollectionOnly = [];
    let itemsToSaveToProjectAndUserCollection = [];

    itemsToSave.forEach(item => {
      if (item.cascadeOrders.has(this.projectCollectionIndex)) {
        itemsToSaveToProjectAndUserCollection.push(item);
      } else {
        itemsToSaveToUserCollectionOnly.push(item);
      }
    });

    const batch = firestoreBatch();
    this.addEditItems(itemsToSaveToUserCollectionOnly, false);
    this.saveToDb(itemsToSaveToUserCollectionOnly, [this.userCollection], undefined, batch);

    this.addEditItems(itemsToSaveToProjectAndUserCollection, false);
    this.saveToDb(itemsToSaveToProjectAndUserCollection, [this.userCollection, this.projectCollection], undefined, batch);

    batch.commit();

    return itemsToReplace.size;
  }

  // duplicate
  updateWasteRate(wasteToSearch: number, wasteToReplace: number): number {
    const itemsToSave = this.items.filter(item => item.wastePercent === wasteToSearch);

    let itemsToSaveToUserCollectionOnly = [];
    let itemsToSaveToProjectAndUserCollection = [];


    itemsToSave.forEach(item => {
      item.wastePercent = wasteToReplace;

      if (item.cascadeOrders.has(this.projectCollectionIndex)) {
        itemsToSaveToProjectAndUserCollection.push(item);
      } else {
        itemsToSaveToUserCollectionOnly.push(item);
      }
    });

    const batch = firestoreBatch();
    this.addEditItems(itemsToSaveToUserCollectionOnly, false);
    this.saveToDb(itemsToSaveToUserCollectionOnly, [this.userCollection], ['_wastePercent'], batch);

    this.addEditItems(itemsToSaveToProjectAndUserCollection, false);
    this.saveToDb(itemsToSaveToProjectAndUserCollection, [this.userCollection, this.projectCollection], ['_wastePercent'], batch);

    batch.commit();

    return itemsToSave.length;
  }

  emptySet: Set<ModelId> = new Set();

  @computed get trialItems(): Set<ModelId> {
    const { subscriptionsStore } = this.stores;
    if (!subscriptionsStore.isTrial) {
      return this.emptySet;
    }

    const allOrderedItems = this.allItemsByCategSubcategFlattened;

    return new Set(
      compact(
        allOrderedItems
          .filter((unused, index) => index < 200)
          .map(item => item.item?.id)
      ));
  }

  @computed get indexByItem() {
    return new Map(
      this.allItemsByCategSubcategFlattened
        .filter(({ item }) => item)
        .map(({ item }, index) => [item.id, index])
    );
  }

  // changer pour migrer meta vers pas de meta
  migrateMeta = () => {
    const itemsToMigrate = [];
    this.items.forEach(item => {
      let imageUrl = item.imageUrl;
      if (!imageUrl && item.id.includes('HOME_DEPOT')) {
        item.imageUrls = [`https://homedepot.scene7.com/is/image/homedepotcanada/p_${item.id.replace("HOME_DEPOT:", '')}.jpg`];
        itemsToMigrate.push(item);
      };
    });

    debugger;
    this.addEditItems(itemsToMigrate);
  }

  /* override */ reset() {
    this.userPriceData = new Map();
    super.reset();
  }

  // this ensures this store loads last (long to load)
  /* override */ attemptLoadItems() {
    if (this.isLoading) {
      return;
    }

    if (
      !this.stores.treeNodesStore.isReady ||
      !this.stores.userInfoStore.isReady
    ) {
      return;
    }

    this.shouldSearchUserItems = !this.stores.userInfoStore.nonImpersonatedUser.shouldAutoSyncProvidingItems;

    super.attemptLoadItems();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.treeNodesStore?.isReady &&
    this.stores?.userInfoStore?.isReady
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}
