import Globals from 'Globals';
import Point from "models/Point";
import Shape from "models/Shape";
import { serializable } from 'serializr';
import Stores from 'stores/Stores';

export class Rectangle extends Shape {
  @serializable type = "Rectangle";

  constructor(
    stores: Stores = Globals.defaultStores,
    topLeft: Point = new Point(),
    bottomRight: Point = new Point()
  ) {
    super(stores, [
      topLeft,
      new Point(bottomRight.x, topLeft.y),
      bottomRight,
      new Point(topLeft.x, bottomRight.y),
    ]);
  }

  get topLeft() {
    return this.points[0];
  }

  set topLeft(value) {
    this.points[0] = value;
    this.bottomLeft.x = value.x;
    this.topRight.y = value.y;
  }

  get bottomRight() {
    return this.points[2];
  }

  set bottomRight(value) {
    this.points[2] = value;
    this.topRight.x = value.x;
    this.bottomLeft.y = value.y;
  }

  get width() {
    return Math.abs(this.topRight.x - this.topLeft.x);
  }

  get height() {
    return Math.abs(this.topRight.y - this.bottomRight.y);
  }

  get centerPoint() {
    return new Point(
      this.topLeft.x + this.width / 2,
      this.topLeft.y + this.height / 2
    );
  }

  private get topRight() {
    return this.points[1];
  }

  private set topRight(value) {
    this.points[1] = value;
  }

  private get bottomLeft() {
    return this.points[3];
  }

  private set bottomLeft(value) {
    this.points[3] = value;
  }
}