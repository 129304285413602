import { Button, IconButton, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import SecureIcon from '@material-ui/icons/Lock';
import RefreshIcon from '@material-ui/icons/Refresh';
import RadarIcon from '@material-ui/icons/RssFeed';
import { isProduction } from 'environment';
import { first } from 'lodash';
import Merchant from 'models/Merchant';
import * as React from 'react';
import sanitizeHtml from 'sanitize-html';
import { ensureProxified, getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import MerchantConfigurator from '../MerchantConfigurator/MerchantConfigurator';
import MerchantItemDetector from '../MerchantItemDetector/MerchantItemDetector';
import ObserverComponent from '../ObserverComponent';
const prodLogo = require('../../../assets-sw/images/logo.svg');
const devLogo = require('../../../assets-sw/images/logo-dev.svg');

const styles = require('./MerchantIframe.module.scss');

interface IMerchantIframeState {
  selectedMerchant: Merchant,
  isLoading: boolean,
  iframeRef: HTMLIFrameElement,
  // the landing page after initial redirects
  resolvedLandingProxyUrl: string,
  currentUrl: string,
  errorMessage: string,
}

const ABOUT_BLANK = 'about:blank';

export default class MerchantIframe extends ObserverComponent<{}, IMerchantIframeState> {
  state = {
    isLoading: true,
    selectedMerchant: null,
    iframeRef: null,
    resolvedLandingProxyUrl: '',
    currentUrl: '',
    errorMessage: '',
  };

  urlChangePoller;

  componentDidMount(): void {
    this.urlChangePoller = setInterval(() => {
      const { iframeRef, currentUrl } = this.state;
      if (!iframeRef?.contentWindow) {
        return;
      }

      if (
        iframeRef.contentWindow.location.href !== 'about:blank' &&
        currentUrl !== iframeRef.contentWindow.location.href
      ) {
        this.onFrameLoad();
      }
    }, 1000)
  }

  componentWillUnmount(): void {
    if (!this.urlChangePoller) {
      clearInterval(this.urlChangePoller);
      this.urlChangePoller = null;
    }
  }

  getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message
    return String(error)
  }

  onFrameLoad = () => {
    let { resolvedLandingProxyUrl, selectedMerchant, currentUrl, iframeRef } = this.state;

    if (!iframeRef) {
      return;
    }

    let url = resolvedLandingProxyUrl;
    let errorMessage = '';

    try {
      url = iframeRef?.contentWindow?.location?.href;
    } catch (e) {
      errorMessage = this.getErrorMessage(e);
    }

    if (url && !resolvedLandingProxyUrl) {
      resolvedLandingProxyUrl = url;
    }
    currentUrl = url

    const { contentDocument } = iframeRef;

    try {
      // for shopify
      if (selectedMerchant.selectToRefreshXpath && !url.includes('variant')) {
        setTimeout(() => {
          contentDocument.querySelectorAll(selectedMerchant.selectToRefreshXpath).forEach(elem => {
            if (!elem.value && elem.options) {
              elem.value = first([...elem.options].filter(o => !o.disabled))?.value;
            }

            elem.click?.();
            elem.dispatchEvent(new Event('change'))
          });
        }, 2000);
      }

      if (selectedMerchant.customCss && !contentDocument.querySelector('#evalumo-custom-css')) {
        var styleElement = document.createElement('style');
        styleElement.id = 'evalumo-custom-css';
        styleElement.innerHTML = sanitizeHtml(selectedMerchant.customCss, { allowedTags: [] });
        contentDocument.head.appendChild(styleElement)
      }
    } catch (e) { console.log('merchant iframe error', e); }

    this.setState({
      isLoading: false,
      resolvedLandingProxyUrl,
      currentUrl,
      errorMessage,
    });

    // could be used to allow dragging of items from search results instead of item page
    //const frameDocument = event.target.contentDocument;
    //const { dragAndDropStore } = this.context;
    /*frameDocument.addEventListener('dragstart', (event) => {
      dragAndDropStore.onDragStart(event);
    });*/
  }

  onClickHomepageIcon = (merchant: Merchant) => event => {
    this.setState({ selectedMerchant: merchant, resolvedLandingProxyUrl: '', currentUrl: '' });

    event.preventDefault();
  }

  get iframeLocation() {
    const { iframeRef, selectedMerchant } = this.state;
    const url = ensureProxified(selectedMerchant?.landingProxyUrl, selectedMerchant?.domain) || ABOUT_BLANK;

    return getSafe(() => (
      iframeRef.contentWindow.location.href ||
      iframeRef.src
    ).replace(ABOUT_BLANK, '')) ||
      url;
  }

  reloadFrame = () => {
    const { iframeRef, selectedMerchant } = this.state;

    const location = this.iframeLocation;

    this.setState({ isLoading: true });

    iframeRef.src = "";
    iframeRef.src = location;
  }

  hardReloadFrame = async () => {
    const { iframeRef } = this.state;
    const location = this.iframeLocation;

    const { cookieStore } = iframeRef.contentWindow;

    // clear cookies
    const cookies = await cookieStore.getAll();

    await Promise.all(
      cookies
        .filter(c => c.domain !== 'evalumo.com')
        .map(c => cookieStore.delete(c))
    );

    this.reloadFrame();
  }

  _render() {
    const { merchantsStore, settingsStore, priceUpdateStore, userInfoStore } = this.context;
    const { userSettings } = settingsStore;
    const { user } = userInfoStore;
    const { isLoading, selectedMerchant, iframeRef, resolvedLandingProxyUrl } = this.state;
    const url = ensureProxified(selectedMerchant?.landingProxyUrl, selectedMerchant?.domain) || ABOUT_BLANK;

    const canGoBack = (
      selectedMerchant &&
      resolvedLandingProxyUrl &&
      iframeRef?.contentDocument?.location?.href !== resolvedLandingProxyUrl
    );

    return (
      <div className={styles.root}>
        <div className={styles.browserHeader}>
          <div className={styles['UrlBar']} id="UrlBar">
            <div className={styles['UrlBar-controls']}>
              {<IconButton className={styles['UrlBar-controlBtn']} disabled={!canGoBack} onClick={() => {
                history.go(-1);
              }}>
                <BackIcon />
              </IconButton>
              /*
              <button className={styles['UrlBar-controlBtn']} disabled>
                <ForwardIcon />
              </button>*/}
              <IconButton className={styles['UrlBar-controlBtn']} disabled={!selectedMerchant} onClick={() => this.setState({ selectedMerchant: null, iframeRef: null })}>
                <HomeIcon />
              </IconButton>
              <IconButton className={styles['UrlBar-controlBtn']} onClick={this.reloadFrame}>
                <RefreshIcon />
              </IconButton>
              {user?.canHardReload && (
                <IconButton className={styles['UrlBar-controlBtn']} onClick={this.hardReloadFrame}>
                  <RefreshIcon style={{ color: 'red' }} />
                </IconButton>
              )}
            </div>
            <div className={styles['UrlBar-searchWrapper']}>
              <div className={styles['BrowserUrlField-wrapper']}>
                {url !== ABOUT_BLANK && <SecureIcon className={styles['secure-icon']} />}
                <div id="BrowserUrlField" className="">
                  <div className={styles['DraftEditor-root']}>
                    <div className={styles['DraftEditor-editorContainer']}>
                      <div aria-describedby="placeholder-6jk00" className={styles['notranslate public-DraftEditor-content']} role="textbox" >
                        <div data-contents="true">
                          <div className="" data-block="true" data-editor="6jk00" data-offset-key="98uif-0-0">
                            <div data-offset-key="98uif-0-0" className={styles['public-DraftStyleDefault-block public-DraftStyleDefault-ltr']}>
                              <span data-offset-key="98uif-0-0">
                                <span data-offset-key="98uif-0-0">
                                </span></span><span data-offset-key="98uif-1-0">
                              </span><span data-offset-key="98uif-2-0" >
                                <span data-offset-key="98uif-2-0"><span data-text="true">{url === ABOUT_BLANK ? '' : selectedMerchant.domain}</span></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.iframeContainer}>
          {isLoading && ABOUT_BLANK !== url && <LoadingOverlay />}

          {ABOUT_BLANK === url ? (
            <div className={styles.homepage}>
              <div className={styles.homepageWrapper}>
                <div className={styles.homepageHeader}>
                  <img src={isProduction() ? prodLogo : devLogo} />

                  <div className={styles.homepageHeaderText}>
                    <span className={styles.appName}>{i18n.t('Evalumo')}</span>
                    <span className={styles.browserText}>{i18n.t('Browser')}</span>
                  </div>
                </div>

                {/*<div className={styles.middleSearchBox}>
                  <div className={styles.inputWrapper}>
                    <SearchIcon />
                    <input id="input" type="search" autocomplete="off" spellcheck="false" role="combobox" placeholder={i18n.t('hello')} aria-live="polite" />
                  </div>
          </div>*/}

                <div className={styles.instructions}>
                  <Typography variant="subtitle2" component="div">
                    {i18n.t('Use this browser to shop for an item to use in your project.')}
                  </Typography>
                  <div className={styles.smallText}>{i18n.t("The browser is provided for your convenience only. Evalumo is not affiliated with the website you choose to visit and is not responsible for your usage. Content remains the property of the website's owner at all time.")}</div>
                </div>

                <div className={styles.tiles}>
                  {merchantsStore.items.map(merchant => (
                    <a className={styles.tile} title={merchant.name} href={`https://${merchant.domain}`} onClick={this.onClickHomepageIcon(merchant)} key={`merchant${merchant.id}`}>
                      {/* tmp! DEBUG 
                      <div>{merchant.index}</div>*/}
                      <div className={styles.iconWrapper} style={{
                        width: merchant.iconWidth ? merchant.iconWidth : undefined,
                        height: merchant.iconHeight ? merchant.iconHeight : undefined,
                        marginBottom: merchant.iconHeight ? (65 - merchant.iconHeight) / 2 : undefined,
                        marginTop: merchant.iconHeight ? (65 - merchant.iconHeight) / 2 : undefined
                      }}>
                        <div className={styles.icon} style={{ backgroundImage: `url(${merchant.thumbUrl})` }} />
                      </div>
                      {merchant.isNew && (
                        <div className={styles.badge} title={i18n.t('New')}>
                          {i18n.t('New')}
                        </div>
                      )}
                      <div className={styles.title}>{merchant.domain}</div>
                    </a>
                  ))}
                </div>

                <div className={styles.instructions} style={{ marginBottom: 5 }}>
                  <div className={styles.smallText}>{i18n.t("The browser is provided for your convenience only. Evalumo is not affiliated with the website you choose to visit and is not responsible for your usage. Content remains the property of the website's owner at all time.")}</div>

                  <div className={styles.smallText}>{merchantsStore.items.length} {i18n.t('merchants available')}.&nbsp;<span className={styles.linkButton} role="button" onClick={() => { window.Intercom?.('showSpace', 'messages'); }}>{i18n.t('Suggest another merchant')}</span></div>
                </div>

                {userSettings?.shouldAllowClearPriceUpdate && (
                  <div className={styles.clearPriceUpdate}>
                    <div>{i18n.t('If you change your preferred store for a merchant, you might want to manually launch a new price check.')}</div>
                    <Button onClick={priceUpdateStore.launchNewUpdate}>
                      <RadarIcon />
                      {i18n.t('Launch new update')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <iframe
              id="merchantIframe"
              ref={ref => !iframeRef && ref && this.setState({ iframeRef: ref })}
              onLoad={this.onFrameLoad}
              src={/*window.location.origin.includes('local') ? ABOUT_BLANK :*/ url} style={{ width: '100%', height: '100%', borderStyle: 'none', minWidth: '360px' }}
            />
          )}
        </div>

        {iframeRef && <MerchantConfigurator iframeElement={iframeRef} />}
        {iframeRef && <MerchantItemDetector iframeElement={iframeRef} />}

        {<div className={styles.mouseOverOverlay} />}
      </div >
    )
  }
}