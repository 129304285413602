// changing this won't work when calling backend functions that use firebase-admin
export const isProduction = () => process.env.REACT_APP_IS_PRODUCTION === 'true';

export const environment = {
  APP_NAME: 'Evalumo',
  proxySuffix: '.merchants.' + (window.location.host.includes('evalumodev') ? 'evalumodev.ca' : 'evalumo.com'),
  firebase: isProduction()
    ? {
      apiKey: 'AIzaSyBOK0SjoPPxFKicRgNLodSalFQtHOaorSI',
      authDomain: 'auth.evalumo.com',
      databaseURL: 'https://evalumo-prod.firebaseio.com',
      projectId: 'evalumo-prod',
      storageBucket: 'evalumo-prod.appspot.com',
      messagingSenderId: '663211318889'
    } : {
      apiKey: 'AIzaSyA4NbNWdAh1ucAZgMDAk74dNBsNSiLTpCw',
      //authDomain: 'auth-test.evalumo.com',
      authDomain: 'estimo-cb0b2.firebaseapp.com',
      databaseURL: 'https://estimo-cb0b2.firebaseio.com',
      projectId: 'estimo-cb0b2',
      storageBucket: 'estimo-cb0b2.appspot.com',
      messagingSenderId: '261994850344',
    },
};
