import { Button, Checkbox, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Info';
import CheckedListIcon from '@material-ui/icons/PlaylistAddCheck';
import Globals from 'Globals';
import classnames from 'classnames';
import { CategoryType } from 'constants/CategoryType';
import { isEmpty, uniq } from 'lodash';
import { reaction } from 'mobx';
import Measurement from 'models/Measurement';
import * as React from 'react';
import { formulaHasVariables, formulaIsBubbleFunction } from 'utils/MeasurementFormatter';
import { duplicateMeasurement } from 'utils/MeasurementUtil';
import { getSafe, modelSortFunction } from 'utils/Utils';
import i18n from 'utils/i18n';
import GroupedList from '../GroupedList/GroupedList';
import MeasurementComponent from '../MeasurementComponent/MeasurementComponent';
import MeasurementsSimpleList from '../MeasurementsSimpleList/MeasurementsSimpleList';
import ObserverComponent from '../ObserverComponent';
import SearchToolbar from '../SearchToolbar/SearchToolbar';

// TODO: warning dialog when removing or overwriting children measurements

const styles = require('./SelectedMeasurements.module.scss');

interface SelectedMeasurementsProps {
  shouldAllowCheckboxes?: boolean,
  activeMeasurementsCount?: number;
}

class MeasurementItemRenderer extends ObserverComponent<{ item: Measurement }> {
  _render() {
    const { item } = this.props;
    const { selectedTreeNode } = this.context.treeNodesStore;
    if (!item || !selectedTreeNode) {
      return null;
    }

    const measurementValue = selectedTreeNode.measurementValues.get(item.id);


    const onMeasurementDragStart = (measurement: Measurement) => {
      const { dragAndDropStore } = this.context;
      dragAndDropStore.dragObject = measurement;
    }

    const onMeasurementDragEnd = (measurement: Measurement) => {
      const { dragAndDropStore } = this.context;
      dragAndDropStore.dragObject = null;
    }

    return (
      <div
        key={'sm_'+item.id}
        draggable
        onDragStart={() => onMeasurementDragStart(item)}
        onDragEnd={() => onMeasurementDragEnd(item)}
        // somehow prevents groupedlist checkbox from appearing over count button
        style={{ display: 'flex' }}
      >
        <MeasurementComponent
          measurement={item}
          measurementValue={measurementValue}
          isEditable={
            !!measurementValue &&
            !measurementValue.adjustmentValue && (
              !measurementValue.metricValue ||
              !formulaHasVariables(measurementValue.formula) || (
                formulaIsBubbleFunction(measurementValue.formula) &&
                measurementValue.areDescendantsSameFormula &&
                !formulaHasVariables(measurementValue.firstDescendantFormula)
              )
            )}
          isValueEditableOnly
          treeNode={selectedTreeNode}
        />
      </div>
    );
  }
}

// should be called measurements for selected node
export default class SelectedMeasurements extends ObserverComponent<SelectedMeasurementsProps> {
  inactiveHeaderRef;
  scrollContainerRef;

  syncActiveMeasurementsSelectionUnsubscribe;

  componentDidMount() {
    this.syncActiveMeasurementsSelectionUnsubscribe = reaction(
      () => this.context?.treeNodesStore?.selectedTreeNode?.measurementsToShow || [],
      (activeMeasurements) => {
        Globals.getTemporaryStores(this.context).measurementsStore.selectedItems = new Set(activeMeasurements);
      }
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.syncActiveMeasurementsSelectionUnsubscribe();
  }

  _render() {
    const { treeNodesStore, measurementsStore } = this.context;

    const { selectedTreeNode } = treeNodesStore;
    const { selectedItems } = measurementsStore;

    const { shouldAllowCheckboxes } = this.props;

    const { activeMeasurements, inactiveMeasurements, isCategoriesToolbarOpen } = measurementsStore;
    const groupedActiveMeasurementsFlattened = measurementsStore.groupedActiveMeasurementsFlattenedForSelectedNode;

    if (!selectedTreeNode) {
      return (
        <div className={styles.noSelection}>
          <div>
            {i18n.t('Select a shape to display its dimensions')}&nbsp;<IconButton className={styles.infoButton}>
              <HelpIcon />
            </IconButton>
          </div>
        </div>
      );
    }

    const { measurementsToShow } = selectedTreeNode;
    const hasInactiveMeasurements = inactiveMeasurements.length > 0;

    const activeMeasurementsCategories = uniq(
      measurementsToShow
        .map(measurement => measurement.category)
    ).sort(modelSortFunction);

    const activeMeasurementsSubcategories = getSafe(() => (
      // O(2)
      (measurementsStore.subcategoriesByCategory[measurementsStore.categoryFilter.id] || []).filter(subcategory => (
        measurementsToShow
          .map(measurement => measurement.subcategory)
          .includes(subcategory)
      ))
    ));

    return (
      <div className={styles.cardContent + ' ' + styles.root}>
        <MeasurementsSimpleList isToolbar hidden={!isCategoriesToolbarOpen} />

        {isEmpty(measurementsToShow) && !isCategoriesToolbarOpen && (
          <Button
            onClick={() => measurementsStore.isCategoriesToolbarOpen = true}
            className={styles.button}>
            <CheckedListIcon />
            {i18n.t('Select applicable measurements')}
          </Button>
        )}

        {!isEmpty(measurementsToShow) && !isCategoriesToolbarOpen && (
          <SearchToolbar
            categoryType={CategoryType.Measurement}
            store={measurementsStore}
            shouldFilterBySubcategories
            categories={activeMeasurementsCategories}
            subcategories={activeMeasurementsSubcategories}
            optionsMenuItems={[{
              icon: <Checkbox checked={measurementsStore.shouldShowHiddenItems} />,
              text: i18n.t('Show hidden measurements'),
              handler: () => { measurementsStore.shouldShowHiddenItems = !measurementsStore.shouldShowHiddenItems },
            }, {
              icon: <Checkbox checked={measurementsStore.shouldMarkMasterItems} />,
              text: i18n.t('Identify original Evalumo measurements'),
              handler: () => { measurementsStore.shouldMarkMasterItems = !measurementsStore.shouldMarkMasterItems },
            },
            {
              icon: <Checkbox checked={measurementsStore.shouldMarkReadonlyItems} />,
              text: i18n.t('Identify read-only measurements'),
              handler: () => { measurementsStore.shouldMarkReadonlyItems = !measurementsStore.shouldMarkReadonlyItems },
            },]}
          />
        )}

        {!isEmpty(measurementsToShow) && (
          <GroupedList
            ungroupedItems={activeMeasurements}
            itemsByCategSubcategFlattened={groupedActiveMeasurementsFlattened}
            shouldAllowCheckboxes={shouldAllowCheckboxes}
            className={classnames(
              styles.activeList,
              {
                [styles.hasInactiveMeasurements]: hasInactiveMeasurements,
              }
            )}
            store={measurementsStore}
            onDuplicateItem={item => duplicateMeasurement(item, this.context)()}
            hidePopupMenu
            itemHeight={40}
            hasSubsubcategories
            shouldAllowReorderItems={false}
            ItemRenderer={MeasurementItemRenderer}
          />
        )}

      </div>
    );
  }
}