
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import UploadFileIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';
import { ExcelIcon, FunnelIcon, FunnelRemoveIcon, SortIcon, SortRemoveIcon } from 'components/common/Icons';
import { CategoryType } from 'constants/CategoryType';
import { SortField } from 'constants/SortField';
import { isEmpty, noop } from 'lodash';
import Category from 'models/Category';
import ModelBaseWithCategory from 'models/ModelBaseWithCategories';
import * as React from 'react';
import SearchableFirebaseStore from 'stores/SearchableFirebaseStore';
import i18n from 'utils/i18n';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import MenuPopupButton, { MenuItem } from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./SearchToolbar.module.scss');

// SEARCH QUERY SHOULD COME FROM STORE NOT LOCAL STATE
interface ISearchToolbarProps {
  className?: string,
  onAdd?: () => void,
  onBatchImport?: () => void,
  onBatchExport?: () => void,
  componentsBeforeSearch?: JSX.Element,
  categoryType?: CategoryType,
  store?: SearchableFirebaseStore<ModelBaseWithCategory>,
  shouldFilterBySubcategories?: boolean,
  optionsMenuItems?: MenuItem[],
  shouldFocusOnMount?: boolean,
  categories?: Category[],
  subcategories?: Category[],
  onSearchChange?: (value: string) => void,
  extraFilters?: JSX.Element | JSX.Element[],
}

interface ISearchToolbarState {
  isFilterVisible: boolean,
  isSortVisible: boolean,
  shouldFocusCategoryOnMount: boolean,
}

// DOIT METTRE PLUS GÉNÉRIQUE POUR POUVOIR FILTRER MEASUREMENTS

// should change to Controlled component to be more uniform with other components
export default class SearchToolbar extends ObserverComponent<ISearchToolbarProps, ISearchToolbarState> {
  static defaultProps = {
    onSearchChange: noop,
    className: '',
    componentsBeforeSearch: null,
  };

  state = {
    isFilterVisible: false,
    isSortVisible: false,
    shouldFocusCategoryOnMount: false,
    searchQuery: '',
  };

  componentDidMount() {
    if (!this.isFilterVisible) {
      this.setState({ shouldFocusCategoryOnMount: true });
    }
  }

  get isFilterVisible() {
    const { store } = this.props;
    return this.state.isFilterVisible || store.categoryFilter || store.subcategoryFilter;
  }

  get isSortVisible() {
    const { store } = this.props;
    return this.state.isSortVisible || !isEmpty(store.sortFields);
  }

  toggleFilter = () => {
    const newIsFilterVisible = !this.isFilterVisible;
    if (!newIsFilterVisible) {
      this.onCategoryChange(null);
    }

    this.setState({ isFilterVisible: newIsFilterVisible });
  }

  toggleSort = () => {
    const newIsSortVisible = !this.isSortVisible;
    if (!newIsSortVisible) {
      this.onSortChange([]);
    }

    this.setState({ isSortVisible: newIsSortVisible });
  }


  onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { store, onSearchChange } = this.props;
    store.searchQuery = event.target.value;
    onSearchChange?.(store.searchQuery);
  }

  clearSearch = () => {
    const { store } = this.props;
    store.searchQuery = '';
  }

  onCategoryChange = (category: Category) => {
    const { store } = this.props;
    store.categoryFilter = category;
    store.subcategoryFilter = null;
  }

  onSubcategoryChange = (subcategory: Category) => {
    const { store } = this.props;
    store.subcategoryFilter = subcategory;
  }

  onSortChange = (sortFields: SortField[]) => {
    const { store } = this.props;
    store.sortFields = sortFields;
  }

  _render() {
    const { categoryType, shouldFilterBySubcategories, optionsMenuItems, onAdd, onBatchImport, onBatchExport, className, shouldFocusOnMount, categories, subcategories, componentsBeforeSearch, store, extraFilters } = this.props;
    const { shouldFocusCategoryOnMount } = this.state;
    const { searchQuery } = store;
    const { canExportProvidingItems, canImportProvidingItems } = this.context.userInfoStore?.user || {};

    return (
      <div className={styles.root + ' ' + className}>
        <div className={styles.row}>
          <div className={styles.beforeSearch}>
            {componentsBeforeSearch}
          </div>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <TextField
              className={styles.textField}
              margin="none"
              shouldFocusOnMount={shouldFocusOnMount}
              value={searchQuery}
              placeholder={i18n.t('Search') + '...'}
              InputProps={{
                classes: {
                  root: styles.inputRoot,
                  input: styles.inputInput,
                },
                disableUnderline: true,
                endAdornment: searchQuery && (
                  <IconButton className={styles.clearIcon} onClick={this.clearSearch}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              onChange={this.onSearchChange}
            />
          </div>

          {store && store.hasCategories && (
            <IconButton className={styles.funnelIcon} onClick={this.toggleFilter}>
              {this.isFilterVisible
                ? <FunnelRemoveIcon className={store.categoryFilter ? styles.funnelRemove : ''} />
                : <FunnelIcon />
              }
            </IconButton>
          )}

          {/* inconvenient because we might click it by mistake, since 99% of the time only filter is needed */}
          {false && (
            <IconButton className={styles.funnelIcon} onClick={this.toggleSort}>
              {this.isSortVisible
                ? <SortRemoveIcon className={store.categoryFilter ? styles.funnelRemove : ''} />
                : <SortIcon />
              }
            </IconButton>
          )}

          {onBatchImport && (
            <IconButton className={styles.addButton} style={ canImportProvidingItems ? {} : { display: 'none' } } component="label">
              <UploadFileIcon />
              <input
                type="file"
                hidden
                onChange={onBatchImport}
              />
            </IconButton>
          )}

          {onBatchExport && (
            <IconButton className={styles.addButton} onClick={onBatchExport} style={canExportProvidingItems ? {} : { display: 'none' }} component="label">
              <ExcelIcon />
            </IconButton>
          )}

          {onAdd && (
            <IconButton className={styles.addButton} onClick={onAdd}>
              <AddIcon />
            </IconButton>
          )}
        </div>

        {store && this.isFilterVisible && (
          <div className={styles.filters}>
            <CategoriesCombobox
              categoryTypes={[categoryType]}
              category={store.categoryFilter}
              categories={categories}
              store={store}
              onChange={this.onCategoryChange}
              shouldFocusOnMount={shouldFocusCategoryOnMount}
            // makes adding categories a bit hard
            //isFilter
            />
            {shouldFilterBySubcategories && store.categoryFilter && (
              <CategoriesCombobox
                parentCategory={store.categoryFilter}
                category={store.subcategoryFilter}
                categoryTypes={[categoryType]}
                store={store}
                categories={subcategories}
                onChange={this.onSubcategoryChange}
                //isFilter
                disabled={!store.categoryFilter}
              />
            )}

            {extraFilters}

            {optionsMenuItems && (
              <MenuPopupButton
                className={styles.optionsButton}
                menuItems={optionsMenuItems}
              //icon={
              //  <SettingsIcon />
              //}
              />
            )}
          </div>
        )}

        {store && this.isSortVisible && (
          <div className={styles.filters}>
            uygih
          </div>
        )}
      </div>
    )
  }
}