import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import SurfaceComponent from 'components/drawing/SurfaceComponent/SurfaceComponent';
import WallComponent from 'components/drawing/WallComponent/WallComponent';
import { isEmpty } from 'lodash';
import CountPoint from 'models/CountPoint';
import Shape from 'models/Shape';
import Surface from 'models/Surface';
import TreeNode from 'models/TreeNode';
import Wall from 'models/Wall';
import * as React from 'react';
import CountPointComponent from '../CountPointComponent/CountPointComponent';

interface IShapesComponentProps {
  node?: TreeNode,
  shapes?: Shape[],
  shouldShowDimensions?: boolean;
  readonly?: boolean,
  containerWidth?: number,
  containerHeight?: number,
  shapesType?: 'ownSurfaceShapes' | 'ownLineShapes' | 'ownCountPointShapes',
}

// TODO group by tree node (add <g>)
export default class ShapesComponent extends ObserverComponent<IShapesComponentProps> {
  componentDidMount(): void {
    const test1 = 0;
  }
  componentDidUpdate(prevProps: Readonly<IWallComponentProps & ObserverComponentProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const test = 0;
  }

  _render() {
    const { drawToolsStore, shapesStore, settingsStore, treeNodesStore } = this.context;
    // will be correct once we change context for draw dialog
    const { readonly, shouldShowDimensions, containerWidth, containerHeight, forceObserve, node, shapesType } = this.props;

    if (!settingsStore) {
      return null;
    }

    const shouldUseTreeHierarchy = !this.props.shapes;

    
    let shapes = shouldUseTreeHierarchy ? node[shapesType || 'ownShapes'] : this.props.shapes;

    if (shouldUseTreeHierarchy && node.isRootNode && !shapesType) {
      return (
        <g
          className={classnames(
            'ShapesComponent',
            'RootShapesComponent', {
            nodeIsSelected: node.isSelected || readonly
          })}
          key={'sc' + node.id}
          id={node.id}
        >
          {!isEmpty(node.surfaceShapes) && <ShapesComponent key={'surfaces'+node.id} node={node} shapesType="ownSurfaceShapes" containerWidth={containerWidth} containerHeight={containerHeight} shouldShowDimensions={shouldShowDimensions} readonly={readonly} forceObserve />}
          {!isEmpty(node.lineShapes) && <ShapesComponent key={'lines'+node.id} node={node} shapesType="ownLineShapes" containerWidth={containerWidth} containerHeight={containerHeight} shouldShowDimensions={shouldShowDimensions} readonly={readonly} forceObserve />}
          {!isEmpty(node.countPointShapes) && <ShapesComponent key={'countpoints'+node.id} node={node} shapesType="ownCountPointShapes" containerWidth={containerWidth} containerHeight={containerHeight} shouldShowDimensions={shouldShowDimensions} readonly={readonly} forceObserve />}
        </g>
      );
    }

    if (
      shapesType == 'ownLineShapes' && isEmpty(node.lineShapes) || 
      shapesType == 'ownSurfaceShapes' && isEmpty(node.surfaceShapes) || 
      shapesType == 'ownCountPointShapes' && isEmpty(node.countPointShapes)
    ) {
      return null;
    }

    return (
      <g
        className={classnames(node.isRootNode && shapesType, {
          RootShapesComponent: !shouldUseTreeHierarchy,
          nodeIsSelected: (!shouldUseTreeHierarchy || !node.isRootNode) && (node.isSelected || readonly)
        })}
        key={'sc' + (shapesType ||'') + node.id}
        id={'sc' + (shapesType ||'') + node.id}
      >
        {!isEmpty(shapes) && (
          shapes.map(shape => {
            if (shape instanceof CountPoint) {
              return <CountPointComponent key={shape.id} countPoint={shape as CountPoint} readonly={readonly} forceObserve />;
            } else if (shape instanceof Wall) {
              return (
                <WallComponent
                  key={shape.id}
                  wall={shape as Wall}
                  readonly={readonly}
                  shouldShowDimensions={shouldShowDimensions}
                  containerWidth={containerWidth}
                  containerHeight={containerHeight}
                  forceObserve
                />
              );
            } else if (shape instanceof Surface) {
              return <SurfaceComponent key={shape.id} surface={shape as Surface} readonly={readonly} forceObserve shouldShowDimensions={shouldShowDimensions} containerWidth={containerWidth} containerHeight={containerHeight} />;
            } else {
              return null;
            }
          })
        )}

        {shouldUseTreeHierarchy && !isEmpty(node.children) && (
          node.children.map(childNode => (
            <ShapesComponent key={childNode.id} node={childNode} containerWidth={containerWidth} containerHeight={containerHeight} shouldShowDimensions={shouldShowDimensions} readonly={readonly} forceObserve shapesType={shapesType}/>
          ))
        )}
      </g>
    )
  }
}