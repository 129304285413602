import { observable } from 'mobx';
import { serializable } from 'serializr';

export default class Point {
  @observable @serializable x: number;
  @observable @serializable y: number;

  @observable @serializable isSnappable = true;

  constructor (x: number = 0, y: number = 0, isSnappable = true) {
    this.x = x;
    this.y = y;
    this.isSnappable = isSnappable;
  }

  isAlmostEqual(pt: Point): boolean {
    return pt && Math.abs(pt.x - this.x) + Math.abs(pt.y - this.y) < 10; //0.00001;
  }

  distancePixels(pt: Point): number {
    const distanceSquared = (pt.x - this.x) * (pt.x - this.x) + (pt.y - this.y) * (pt.y - this.y);
    return Math.pow(distanceSquared, 0.5);
  }

  // in meters
  distance(pt: Point, scale: number): number {
    return this.distancePixels(pt) / scale;
  }

  clone(): Point {
    return new Point(this.x, this.y, this.isSnappable);
  }

  toString(): string {
    return this.x + ',' + this.y;
  }

  toSimpleObject(): {x:number, y:number} {
    return {x: this.x, y: this.y};
  }
}
