
import { IClassNameProps, IEditableProps, IReportProps } from 'constants/CommonProps';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./ReportNumber.module.scss');

export default class ReportNumber extends ObserverComponent<IReportProps & IEditableProps & IClassNameProps> {
  changeNumber = ({ target: { value } }) => {
    const { reportsStore } = this.context;
    const { report } = this.props;
    report.number = value;
    reportsStore.addEditItem(report, true, ['number']);
  }

  _render() {
    const { isEditable, report, className } = this.props;
    const label = i18n.t('{{ReportSubtype}} Number', { ReportSubtype: i18n.t(report.subtype) });

    return isEditable ? (
      <TextField
        label={label}
        className={styles.root + ' ' + className}
        value={report.number}
        onChange={this.changeNumber}
      />
    ) : (
        <div className={styles.root + ' ' + className}>
          <div>{label}</div>
          <div>{report.number}</div>
        </div>
      );
  }
}