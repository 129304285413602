
import ObserverComponent from 'components/common/ObserverComponent';
import { range } from 'lodash';
import User from 'models/User';
import moment from 'moment';
import * as React from 'react';
import { firestore } from 'utils/FirebaseInitializedApp';
import { sleep } from 'utils/Utils';

const styles = require('./DownloadsComponent.module.scss');

interface DownloadsComponentState {
  hasDoorFrameBug: Map<User, 'yes' | 'no' | '?'>,
  updatedDate: Map<User, number | undefined>
}

export default class DownloadsComponent extends ObserverComponent<DownloadsComponentState> {
  state = {
    hasDoorFrameBug: new Map<User, 'yes' | 'no' | '?'>(),
    updatedDate: new Map<User, number | undefined>(),
  }

  async componentDidUpdate() {
    const db = firestore();

    const { userInfoStore } = this.context;
    const { hasDoorFrameBug, updatedDate } = this.state;

    const users = userInfoStore.items.filter(u => !hasDoorFrameBug.has(u) && !updatedDate.has(u));

    if (users.length) {
      await Promise.all(
        users.map(user => {
          hasDoorFrameBug.set(user, '?');

          return Promise.resolve();
          // is for door bug with bad calculation, but called window by mistake
          /*return db.doc(`/users/${user.id}/measurements/WindowFraming`).get().then(d => {
            hasDoorFrameBug.set(user,
              (d.exists && d.data().__defaultFormula?.fr !== "('Porte : Largeur' + 2 * 'Porte : Hauteur') * 'Portes : Quantité'") ? 'yes' : 'no'
          )});
          */
        })
      );

      const chunksCount = 5;
      const chunkSize = Math.floor(users.length / chunksCount);

      await range(chunksCount).reduce(async (previousTask, unused, index) => {
        await previousTask;

        const usersChunk = users.slice(
          index * chunkSize, 
          index < chunksCount -1
            ? (index + 1) * chunkSize - 1
            : (index + 1) * chunkSize + chunksCount
        );

        await Promise.all(
          usersChunk.map(user => {
            return db.collection(`/users/${user.id}/projects`).orderBy('updatedMiliseconds', 'desc').limit(1).get().then(c => {
              const project = c.docs?.[0]?.data() || {};
              updatedDate.set(user, project.updatedMiliseconds);
            });
          })
        )

        await sleep(1000);
        return '';
      }, Promise.resolve(''));

      this.setState({ hasDoorFrameBug: new Map(hasDoorFrameBug), updatedDate: new Map(updatedDate) });
    }
  }

  _render() {
    const { userInfoStore } = this.context;
    const { hasDoorFrameBug, updatedDate } = this.state;

    return (
      <div className={styles.root}>
        <style>{`
          body {
            overflow: auto !important;
          }
        `}</style>
        <table>
          <thead>
            <tr>
              <th>photo</th>
              {['displayName', 'email', 'phoneNumber', 'website', 'companyName', 'street', 'city', 'other'].map(field => (
                <th>
                  {field}
                </th>
              ))}
              <th>date utilisation</th>
              <th>date utilisation (approx)</th>
              <th>prix payé</th>
              <th>version</th>
            </tr>
          </thead>
          <tbody>
            {userInfoStore.items
              .sort((a, b) => (updatedDate.get(b) || b.updatedMiliseconds) - (updatedDate.get(a) || a.updatedMiliseconds))
              .map(user => (
                <tr>
                  <td>{user.photoURL && <img src={user.photoURL} width="50" />}</td>
                  {['displayName', 'email', 'phoneNumber', 'website', 'companyName', 'street', 'city', 'other'].map(field => (
                    <td>
                      {user[field]}
                    </td>
                  ))}
                  <td>
                    {updatedDate.get(user) ? moment(updatedDate.get(user)).format("LLL") : '?'}
                  </td>
                  <td>
                    {moment(user.updatedMiliseconds).format('LL')}
                  </td>
                  <td>
                    à venir
                  </td>
                  <td>
                    {user.currentBuild}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}