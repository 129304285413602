import { Avatar, Checkbox, IconButton, ListItemAvatar, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import CategoryIcon from '@material-ui/icons/Category';
import UploadFileIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import ProjectIcon from '@material-ui/icons/Image';
import LockIcon from '@material-ui/icons/Lock';
import ShareIcon from '@material-ui/icons/Share';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import classnames from 'classnames';
import AvatarImage from 'components/common/AvatarImage/AvatarImage';
import CategoryMoveDialog from 'components/common/CategoryMoveDialog/CategoryMoveDialog';
import Editable from 'components/common/Editable/Editable';
import GroupedList from 'components/common/GroupedList/GroupedList';
import MenuPopupButton from 'components/common/MenuPopupButton/MenuPopupButton';
import ObserverComponent from 'components/common/ObserverComponent';
import ProgressDialog from 'components/common/ProgressDialog/ProgressDialog';
import SearchToolbar from 'components/common/SearchToolbar/SearchToolbar';
import { CategoryType } from 'constants/CategoryType';
import { LATEST_PROJECT_VERSION } from 'constants/Constants';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { Unit } from 'constants/Unit';
import { compact, first, isEmpty, isString, last, remove, zipObject } from 'lodash';
import { action } from 'mobx';
import { ActionInProgress } from 'models/ActionInProgress';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import MeasurementValue from 'models/MeasurementValue';
import Project from 'models/Project';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Stores from 'stores/Stores';
import { parseCsvString } from 'utils/CSVUtils';
import { archiveItems, deleteItem, deleteItems, unarchiveItems } from 'utils/DeleteUtils';
import { functions } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { addNewMeasurementToTask } from 'utils/MeasurementUtil';
import { encodeProjectName } from 'utils/ProjectUtils';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { showUpgradeSubscriptionDialog } from 'utils/SubscriptionUtil';
import { getUnitTypeForUnit } from 'utils/UnitFormatter';
import { sleep } from 'utils/Utils';
import { stox } from 'utils/XLSXUtils';
import i18n from 'utils/i18n';
import XLSX from 'xlsx';
import './ProjectsListView.css';

const colors = require('../../Colors.scss');
const styles = require('./ProjectsListView.module.scss');


const handleOpenChangeCategoryDialog = (context: Stores, projects: Project[] = context.projectsStore.selectedItemsArray) => {
  const { dialogsStore } = context;

  const newDialog = new Dialog(context);
  newDialog.dialogComponent = ({ open }) => (
    <CategoryMoveDialog
      open={open}
      dialogId={newDialog.id}
      models={projects}
    />
  )
  dialogsStore.showDialog(newDialog);
}



class ProjectListItem extends ObserverComponent<{ item: Project }> {
  duplicateProject = async (project: Project) => {
    const { dialogsStore, projectsStore, userInfoStore } = this.context;

    const actionInProgress = new ActionInProgress(this.context);
    actionInProgress.message = i18n.t('Duplicating project...');

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ProgressDialog
        open={open}
        dialogId={newDialog.id}
        actionInProgress={actionInProgress}
      />
    )
    dialogsStore.showDialog(newDialog);

    const duplicateProjectBackend = functions.httpsCallable('duplicateProject');
    const { data: { error, success } } = await duplicateProjectBackend({
      sourceProjectId: project.id,
      targetName: projectsStore.getNextTemporaryProjectName(project.name),
      email: userInfoStore.userEmail
    });

    if (error) {
      console.error(error);
    }

    dialogsStore.hideDialog(newDialog.id);
  }

  renameProject = (project: Project) => {
    const { projectsStore } = this.context;
    projectsStore.projectBeingRenamed = project;
  }


  // need this because popup needs mouse click event as parameter
  handleOpenChangeCategoryDialog = () => {
    handleOpenChangeCategoryDialog(this.context, [this.props.item]);
  }

  toggleEditor = (project: Project, subuserEmail: string) => () => {
    const { projectsStore } = this.context;
    const shouldAdd = !project.editors.includes(subuserEmail);

    if (shouldAdd) {
      project.editors.push(subuserEmail);
    } else {
      remove(project.editors, editor => editor === subuserEmail)
    }

    projectsStore.addEditItem(project, true, ['editors']);
  }

  _render() {
    const { projectsStore, userInfoStore } = this.context;
    const { item } = this.props;
    //const { match, location } = this.props;

    const { user } = userInfoStore;

    const isEditMode = projectsStore.projectBeingRenamed?.id === item?.id;

    let projectUrl = `${window.location.pathname}/${item.encodedName}${window.location.search}`;
    const projectsWithEncodedName = projectsStore.filterByEncodedName(item.encodedName);
    if (projectsWithEncodedName.length > 1) {
      projectUrl += (window.location.search ? '&' : '?') + `index=${projectsWithEncodedName.findIndex(p => p.id === item.id)}`;
    }

    // some users add icons for subcategories and want to see it on the project thumb
    const itemForImage = item.subcategory?.imageUrl ? item.subcategory : item;

    return (
      <div
        className={classnames(styles.listItem, { [styles.isHidden]: item.isHidden })}
      >
        <ListItemAvatar>
          <Avatar>
            {itemForImage.imageUrl
              ? <AvatarImage model={itemForImage} />
              : <ProjectIcon />
            }
          </Avatar>
        </ListItemAvatar>
        {isEditMode
          ? (
            <Editable
              classNameEditMode={styles.projectNameField}
              editLabel={i18n.t('Project name')}
              model={item}
              store={projectsStore}
              propertyName="name"
              shouldEditOnMount
              onEditModeChange={(newEditMode) => {
                if (!newEditMode) {
                  projectsStore.projectBeingRenamed = null;
                }
              }}
              onSave={() => projectsStore.projectBeingRenamed = null}
            />
          ) : (
            <>
              <Link
                id={item.id}
                className={styles.clickablePart}
                to={projectUrl}
              >
                <ListItemText
                  primary={
                    <div className={styles.projectName}>{item.name} {
                      (!isEmpty(item.editors) || [...user.subusers.keys()].includes(item.owner)) && <ShareIcon />
                    }</div>
                  }
                  secondary={i18n.t('Updated {{date}}', { date: item.updated.format('LL') })}
                />

                <div style={{ flex: 1 }} />

                <Button className={styles.openProjectButton} color="primary">
                  {i18n.t('Open')}
                </Button>
              </Link>

              <MenuPopupButton className={styles.menuPopupButton} menuItems={compact([
                { icon: <CopyIcon />, text: i18n.t('Duplicate'), handler: () => this.duplicateProject(item) },
                { icon: <EditIcon />, text: i18n.t('Rename'), handler: () => this.renameProject(item) },
                //{ icon: <ShareIcon />, text: i18n.t('Envoyer une copie...'), handler: () => this.shareProject(project)},
                item.isHidden
                  ? { icon: <UnarchiveIcon />, text: i18n.t('Unarchive'), handler: () => unarchiveItems([item], projectsStore) }
                  : { icon: <ArchiveIcon />, text: i18n.t('Archive'), handler: () => archiveItems([item], projectsStore) },
                { icon: <CategoryIcon />, text: i18n.t('Move to a different category'), handler: this.handleOpenChangeCategoryDialog },
                user.isAdvancedSharingEnabled && {
                  icon: <ShareIcon />,
                  text: i18n.t('Collaborators') + '...',
                  subItems: [...user.subusers.keys()]
                    .filter(subuser => subuser !== userInfoStore.nonImpersonatedEmail)
                    .map(subuser => (
                      {
                        icon: (
                          <Checkbox
                            disabled={item.owner === subuser}
                            checked={item.owner === subuser || item.editors.includes(subuser)}
                          />
                        ),
                        text: subuser,
                        handler: this.toggleEditor(item, subuser),
                        shouldStayOpenOnItemClick: true,
                      }
                    ))
                },
                userInfoStore.isAdmin && { icon: <DeleteIcon />, text: i18n.t('Delete'), handler: () => deleteItem(item, projectsStore), danger: true },
              ])} />
            </>
          )}
      </div>
    );
  }
}

class ProjectsListView extends ObserverComponent<RouteComponentProps<{}>, {}> {
  componentDidMount() {
    const { commonStore } = this.context;
    commonStore.selectedProjectId = null;
  }

  createProjectAndOpen = () => {
    const { projectsStore, userInfoStore } = this.context;
    const { url } = this.props.match;
    const { history } = this.props;

    const newProject = new Project(this.context);
    newProject.version = newProject.versionOnCreation = LATEST_PROJECT_VERSION;
    newProject.name = projectsStore.getNextTemporaryProjectName();

    const batch = firestoreBatch();
    batch.isUndoable = false;
    projectsStore.addEditItem(newProject, true, undefined, batch);
    batch.commit();

    history.push(`${url}/${encodeProjectName(newProject.name)}` + this.props.location.search);
  }

  shareProject = (project: Project) => {
    //copyModelWithProgress(project);
    debugger;
  }

  // need this because popup needs mouse click event as parameter
  handleOpenChangeCategoryDialog = () => {
    handleOpenChangeCategoryDialog(this.context);
  }

  importProjectFromExcel = async (e) => {
    const { commonStore } = this.context;

    var f = e.target.files[0];

    var reader = new FileReader();
    const _this = this;

    const isCsv = f.type === 'text/csv';


    reader.onload = async function (e) {
      if (typeof console !== 'undefined') console.log("onload", new Date());
      var data = e.target.result;
      let rows;
      const objectRows = [];

      if (isCsv) {
        rows = await parseCsvString(data, { delimiter: ';' });
        let columnsNames;

        function parseFrenchNumber(cell) {
          if (cell.match(/^[0-9,]+$/)) {
            return parseFloat(cell.replace(',', '.'));
          } else {
            return cell;
          }
        }

        compact(Object.values(rows)).forEach((row, index) => {
          console.log(`importing ${index}`)

          if (index == 0) {
            columnsNames = row.map(header => header.trim());
            return;
          }

          objectRows.push(zipObject(columnsNames, row.map(cell => parseFrenchNumber(cell))));
        })

      } else {
        data = new Uint8Array(data);
        rows = stox(XLSX.read(data, { type: 'array' }), true)[0].rows;

        let columnsNames;

        compact(Object.values(rows)).forEach((row, index) => {
          console.log(`importing ${index}`)
          let { cells } = row;
          // cells is object indexed by column index and missing empty cells
          // so it looks like {0: {text: 'allo'}, 2: {text: 'bye'}}. convert to
          // proper array [{text: allo}, undefined, {text: 'bye'}
          const cellsArray = new Array(parseInt(last(Object.keys(cells))) + 1);

          Object.keys(cells).forEach(key => {
            cellsArray[parseInt(key)] = cells[key].text;
          });

          if (index == 0) {
            columnsNames = cellsArray.map(header => header.trim());
            return;
          }

          objectRows.push(zipObject(columnsNames, cellsArray.map(cell => cell[0] == "{" ? JSON.parse(cell) : cell)))
        })
      }

      _this.createProjectAndOpen();
      await waitOnStoresReady(commonStore.importantStores);
      await sleep(4000);

      _this.importTakeoffFromExcelFauteux(objectRows);
    };

    if (isCsv) {
      reader.readAsText(f);
    } else {
      reader.readAsArrayBuffer(f);
    }
  }


  @action importTakeoffFromExcelBim5d(data) {
    function getBim5dCodeFromName(name) {
      return (
        // treenodes
        name.match(/([A-Za-z0-9.]+) /)?.[1] ||
        // tasks
        name.match(/([0-9][0-9] [0-9][0-9] [0-9][[0-9]\.]+) /)?.[1]
      );
    }

    console.log('called');
    const { commonStore, categoriesStore, measurementsStore, treeNodesStore, providingItemsStore } = this.context

    commonStore.isBigUpdateOngoing = true;

    let columnsNames = [];

    const batch = firestoreBatch();
    batch.isUndoable = false;

    let lastRowName = '';
    let parentNode = treeNodesStore.rootNode;

    compact(Object.values(data[0].rows)).forEach(async (row, index) => {

      if (index > 2) {
        //return; //tmp
      }
      console.log(`importing ${index}`)
      let { cells } = row;
      // cells is object indexed by column index and missing empty cells
      // so it looks like {0: {text: 'allo'}, 2: {text: 'bye'}}. convert to
      // proper array [{text: allo}, undefined, {text: 'bye'}
      const cellsArray = new Array(parseInt(last(Object.keys(cells))) + 1);

      Object.keys(cells).forEach(key => {
        cellsArray[parseInt(key)] = cells[key].text;
      });

      if (index == 0) {
        columnsNames = cellsArray.map(header => header.trim());
        return;
      }

      const objectRow = zipObject(columnsNames, cellsArray.map(cell => cell[0] == "{" ? JSON.parse(cell) : cell));

      const name = objectRow["Désignation"];
      const code = getBim5dCodeFromName(name);

      const isTreeNode = !!name?.[0]?.match?.(/[A-Za-z]/);

      if (isTreeNode) {
        // TREENODE
        while (
          !code.includes(getBim5dCodeFromName(parentNode.name)) && !parentNode.isRootNode) {
          parentNode = parentNode.parent;
        }

        const newNode = new TreeNode(this.context, name);
        newNode.shouldBubbleMeasurements = false;
        treeNodesStore.batchAddEditItem(newNode, batch);
        treeNodesStore.appendNode(newNode, parentNode, index, batch);

        parentNode = newNode;
      } else {
        // TASK
        const unit = {
          'tm': Unit.MetricTon,
          'm': Unit.Meter,
          'm2': Unit.SquareMeter,
          'm3': Unit.CubicMeter,
          'un': Unit.Unit,
        }[last((objectRow["QUANTITE"] || '').trim().split(' ')) as string] || Unit.Unit;

        const unitType = getUnitTypeForUnit(unit);

        const providingItemName = name?.trim?.();
        const providingItemNameWithoutCode = name.replace(code, '').replace(' - ', '');
        const id = new MD5().update(providingItemNameWithoutCode).digest('hex');

        let providingItem = providingItemsStore.getItem(id);

        if (true || !providingItem) {
          providingItem = new ProvidingItem(this.context);
          providingItem.name = providingItemName;
          providingItem.subtype = ProvidingItemSubtype.Material;

          // todo: category, unit
          providingItem.price = 0; // not provided yet
          providingItem.providedQuantities.set(unitType, new ProvidedQuantity(unitType, 1, unit, 90));
          providingItemsStore.batchAddEditItem(providingItem, batch);
        }

        const materialTask = new Task(this.context);
        materialTask.providingItem = providingItem;
        // also saves the task
        addNewMeasurementToTask(materialTask, this.context, batch, parentNode);

        parentNode.ownMeasurementValues.set(
          materialTask.measurementId,
          new MeasurementValue(this.context, parentNode, materialTask.measurement, first((objectRow["QUANTITE"] || '').trim().split(' ')) || '0')
        );

        materialTask.measurement._displayUnit = unit;
        materialTask.measurement.unitType = unitType;
        measurementsStore.batchAddEditItem(materialTask.measurement, batch);

        parentNode.ownTasksIds.push(materialTask.id);
        treeNodesStore.batchAddEditItem(parentNode, batch);
      }
    });

    batch.commit();

    commonStore.isBigUpdateOngoing = false;
  }

  @action importTakeoffFromExcelFauteux(rows) {
    console.log('called');
    const { commonStore, categoriesStore, measurementsStore, treeNodesStore, providingItemsStore } = this.context

    commonStore.isBigUpdateOngoing = true;

    let columnsNames = [];

    const batch = firestoreBatch();
    batch.isUndoable = false;

    rows.forEach((row, index) => {

      let parentNode = treeNodesStore.rootNode;

      row['Calque'] = row['Calque'].replace(/^[0-9]+-/, '').trim();

      if (isEmpty(row['Calque'])) {
        return;
      }

      row['Objet'] = row['Objet'].trim();

      if (row['Calque'].toLowerCase() !== row['Objet'].toLowerCase()) {
        parentNode = treeNodesStore.getItemByName(row['Calque']);

        if (!parentNode) {
          parentNode = new TreeNode(this.context, row['Calque']);
          parentNode.shouldBubbleMeasurements = false;
          treeNodesStore.batchAddEditItem(parentNode, batch);
          treeNodesStore.appendNode(parentNode, treeNodesStore.rootNode, index, batch);
        }
      }

      const baseMeasurementsCategory = categoriesStore.getItemByName('Mesures de base');
      const baseMeasurements = measurementsStore.items.filter(m => m.categoryId === baseMeasurementsCategory.id);

      let takeoffNode = treeNodesStore.getItemByName(row['Objet']);

      if (!takeoffNode) {
        takeoffNode = new TreeNode(this.context, row['Objet']);
        takeoffNode.shouldBubbleMeasurements = false;
        treeNodesStore.batchAddEditItem(takeoffNode, batch);
        treeNodesStore.appendNode(takeoffNode, parentNode, index, batch);

        treeNodesStore.toggleNodeMeasurements(
          baseMeasurements,
          true,
          takeoffNode,
          false,
          batch
        );
      }

      const bluebeamFieldsToEvalumo = {
        Zone: 'Surface',
        Longueur: 'Longueur',
        'Paroi': 'Surface paroi'
      };

      Object.keys(bluebeamFieldsToEvalumo).forEach(bbmeasurementName => {
        const measurement = baseMeasurements.find(m => m.name === bluebeamFieldsToEvalumo[bbmeasurementName]);

        const currentValue = parseFloat(takeoffNode.ownMeasurementValues.get(measurement.id)?.formula || '0') || 0;

        takeoffNode.ownMeasurementValues.set(
          measurement.id,
          new MeasurementValue(this.context, takeoffNode, measurement, (currentValue + row[bbmeasurementName]).toString())
        );
      })

      treeNodesStore.batchAddEditItem(takeoffNode, batch);
      treeNodesStore.batchAddEditItem(parentNode, batch);
    });

    batch.commit();

    commonStore.isBigUpdateOngoing = false;
  }

  @action importProjectFromExcelFauteux(data) {
    console.log('called');
    const { commonStore, categoriesStore, measurementsStore, treeNodesStore, providingItemsStore } = this.context

    commonStore.isBigUpdateOngoing = true;

    let columnsNames = [];

    const batch = firestoreBatch();
    batch.isUndoable = false;

    compact(Object.values(data[0].rows)).forEach((row, index) => {
      const categoryName = row['Catégorie']?.trim?.();
      if (!categoryName) {
        return;
      }

      let category = categoriesStore.items.find(item => item.name.trim() === categoryName.trim());
      if (!category) {
        category = new Category(this.context, categoryName);
        categoriesStore.batchAddEditItem(category, batch);
      }

      const treeNodeName = row['Lieu']?.trim?.();
      let treeNode = [treeNodesStore.rootNode, ...treeNodesStore.rootNode.children].find(node => node.name.trim() === treeNodeName.trim());
      if (!treeNode) {
        treeNode = new TreeNode(this.context, treeNodeName);
        treeNode.shouldBubbleMeasurements = false;
        treeNodesStore.batchAddEditItem(treeNode, batch);
        treeNodesStore.appendNode(treeNode, treeNodesStore.rootNode, undefined, batch);
      }

      const subTreeNodeName = row['Sous-lieu']?.trim?.();
      let subTreeNode: TreeNode;

      if (subTreeNodeName) {
        subTreeNode = treeNode.children.find(node => node.name.trim() === subTreeNodeName.trim());
        if (!subTreeNode) {
          subTreeNode = new TreeNode(this.context, subTreeNodeName);
          subTreeNode.shouldBubbleMeasurements = false;
          treeNodesStore.batchAddEditItem(subTreeNode, batch)
          treeNodesStore.appendNode(subTreeNode, treeNode, undefined, batch);
        }
      }

      const nodeWithTasks = subTreeNode || treeNode;

      const fixNumber = (cellValue) => {
        return isString(cellValue)
          ? parseFloat(cellValue.replace?.(' ', ''))
          : cellValue;
      }

      let labourUnitCost = fixNumber(row['Prix pose']);
      const materialUnitCost = fixNumber(row['Prix achat']);
      const equipmentUnitCost = fixNumber(row['Prix équip.']);
      const subcontractorUnitCost = fixNumber(row['Prix entr. spé.']);

      const quantity = fixNumber(row['Quantité']);

      // no difference for now
      labourUnitCost = labourUnitCost || equipmentUnitCost || subcontractorUnitCost;

      const hasBothMaterialAndLabourCost = labourUnitCost && materialUnitCost;
      // unit
      const unit = {
        'HRE': Unit.Hour,
        'T.M.': Unit.MetricTon,
        'BOITE': Unit.Box,
        'JOUR': Unit.Day,
        'M³': Unit.CubicMeter,
        'PAQUET': Unit.Pack,
        'PIED.L': Unit.Foot,
        'PIED²': Unit.SquareFoot
      }[row['Unité']?.trim?.()] || Unit.Unit;

      const unitType = getUnitTypeForUnit(unit);

      if (materialUnitCost) {
        const providingItemName = row['Description du produit']?.trim?.();
        let providingItem = providingItemsStore.materialItems.find(item => item.name.trim() === providingItemName.trim());

        if (!providingItem) {
          providingItem = new ProvidingItem(this.context);
          providingItem.name = providingItemName;
          providingItem.subtype = ProvidingItemSubtype.Material;

          // todo: category, unit
          providingItem.price = materialUnitCost;

          providingItemsStore.batchAddEditItem(providingItem, batch);
        }

        providingItem.providedQuantities.set(unitType, new ProvidedQuantity(unitType, 1, unit, 90));

        const materialTask = new Task(this.context);
        materialTask.category = category;
        materialTask.providingItem = providingItem;
        //tasksStore.batchAddEditItem(materialTask, batch);
        addNewMeasurementToTask(materialTask, this.context, batch, nodeWithTasks);

        nodeWithTasks.ownMeasurementValues.set(
          materialTask.measurementId,
          new MeasurementValue(this.context, nodeWithTasks, materialTask.measurement, quantity.toString())
        );

        materialTask.measurement._displayUnit = unit;
        materialTask.measurement.unitType = unitType;
        measurementsStore.batchAddEditItem(materialTask.measurement, batch);

        nodeWithTasks.ownTasksIds.push(materialTask.id);
        treeNodesStore.batchAddEditItem(nodeWithTasks, batch);

        // todo quantity other than 1!
      }

      if (labourUnitCost) {
        const providingItemName = row['Description du produit']?.trim?.() + (hasBothMaterialAndLabourCost ? ' : Installation' : '');
        let providingItem = providingItemsStore.labourItems.find(item => item.name.trim() === providingItemName.trim());

        if (!providingItem) {
          providingItem = new ProvidingItem(this.context);
          providingItem.name = providingItemName;
          providingItem.subtype = ProvidingItemSubtype.Labour;

          // todo: category, unit
          providingItem.price = labourUnitCost;
          providingItemsStore.batchAddEditItem(providingItem, batch);
        }

        providingItem.providedQuantities.set(unitType, new ProvidedQuantity(unitType, 1, unit, 90));

        const labourTask = new Task(this.context);
        labourTask.category = category;
        labourTask.providingItem = providingItem;

        //tasksStore.batchAddEditItem(labourTask, batch);
        addNewMeasurementToTask(labourTask, this.context, batch, nodeWithTasks);
        nodeWithTasks.ownMeasurementValues.set(
          labourTask.measurementId,
          new MeasurementValue(this.context, nodeWithTasks, labourTask.measurement, quantity.toString())
        );

        labourTask.measurement._displayUnit = unit;
        labourTask.measurement.unitType = unitType;
        measurementsStore.batchAddEditItem(labourTask.measurement, batch);

        nodeWithTasks.ownTasksIds.push(labourTask.id);
        treeNodesStore.batchAddEditItem(nodeWithTasks, batch);
      }
    });

    commonStore.isBigUpdateOngoing = false;
    batch.commit();
  }


  public _render() {
    const { projectsStore, subscriptionsStore } = this.context;

    return (
      <div className={styles.container}>
        <div id="dashboard-projects" className={`container noselect ${styles.root}`}>
          <div className="dashboard-top clearfix">
            <h1 className="dashboard-title">{i18n.t('Projects')}</h1>
            {projectsStore.isReady && projectsStore.selectedItems.size ? (
              <div className={styles.buttons}>
                <Button className={styles.cancelButton} onClick={() => projectsStore.selectedItems.clear()}>
                  {i18n.t('Cancel')}
                </Button>

                {projectsStore.selectedItemsArray.every(project => project.isHidden) ? (
                  <Button
                    onClick={() => unarchiveItems(projectsStore.selectedItemsArray, projectsStore)}
                    className={styles.archiveButton}
                  >
                    <UnarchiveIcon />
                    {i18n.t('Unarchive {{numProjects}} project(s)', { numProjects: projectsStore.selectedItemsArray.length })}
                  </Button>
                ) : (
                  <Button
                    onClick={() => archiveItems(projectsStore.selectedItemsArray, projectsStore)}
                    className={styles.archiveButton}
                  >
                    <ArchiveIcon />
                    {i18n.t('Archive {{numProjects}} project(s)', { numProjects: projectsStore.selectedItemsArray.length })}
                  </Button>
                )}
                <MenuPopupButton
                  className={styles.moreButton}
                  menuItems={[
                    { icon: <CategoryIcon />, text: i18n.t('Move to a different category'), handler: this.handleOpenChangeCategoryDialog },
                    { icon: <DeleteIcon />, text: i18n.t('Delete'), handler: () => deleteItems(projectsStore.selectedItemsArray, projectsStore), danger: true },
                  ]}
                />
              </div>
            ) : (
              <div className="dashboard-right-buttons">
                {projectsStore.isReady && (
                  (subscriptionsStore.isTrial && projectsStore.items.filter(i => i.cascadeOrder).length > 0) ? (
                    <Button onClick={showUpgradeSubscriptionDialog(this.context)} variant="contained" color="primary" className="mat-button">
                      <LockIcon />&nbsp; {i18n.t('Upgrade to add more projects')}
                    </Button>
                  ) : (
                    <Button onClick={this.createProjectAndOpen} variant="contained" color="primary" className="mat-button">
                      <Add />&nbsp; {i18n.t('New Project')}
                    </Button>
                  )
                )}

                <IconButton className={styles.addButton} style={{ display: 'none' }} component="label">
                  <UploadFileIcon />
                  <input
                    type="file"
                    accept=".xlsx, .csv"
                    hidden
                    onChange={this.importProjectFromExcel}
                  />
                </IconButton>
              </div>
            )}
          </div>

          {isEmpty(projectsStore.items)
            ? (
              <div id="create-first-project">
                <img id="no-project-img" src="/images/create-project-illustration.png" />
                <h2>{i18n.t('Start your first project!')}</h2>
                <p>
                  <span className={styles.linkButton} role="button" onClick={this.createProjectAndOpen}>
                    {i18n.t('Start here')}
                  </span>&nbsp;
                  {i18n.t('or click the blue button to get going.')}
                </p>
                <div id="tut-arrow">
                  <img src="/images/tut-arrow.png" style={{ width: 68 }} />
                </div>
              </div>
            ) : (
              <div className={styles.listWithSearch}>
                <div>
                  <SearchToolbar
                    store={projectsStore}
                    categoryType={CategoryType.Project}
                    shouldFilterBySubcategories
                    optionsMenuItems={[{
                      icon: <Checkbox checked={projectsStore.shouldShowHiddenItems} />,
                      text: i18n.t('Show archived projects'),
                      handler: () => { projectsStore.shouldShowHiddenItems = !projectsStore.shouldShowHiddenItems },
                    }]}
                  />
                </div>

                <GroupedList
                  ungroupedItems={projectsStore.searchedItems}
                  itemsByCategSubcategFlattened={projectsStore.itemsByCategSubcategFlattened}
                  className={styles.list}
                  store={projectsStore}
                  ItemRenderer={ProjectListItem}
                  shouldAllowCheckboxes
                  hidePopupMenu
                  itemHeight={58}
                />
              </div >
            )}

          <div className={styles.version}>
            <div>
              {i18n.t('Evalumo, version updated on ')} {moment(process.env.PUBLISH_DATE).format('LL')}
            </div>
            &nbsp;
            <div> — <a href={`https://evalumo.com/fr/changelog?cb=${Date.now()}`} target="_blank" rel="noopener noreferrer">{i18n.t('View changes')}</a></div>
          </div>

        </div>
      </div >
    );
  }
}

export default ProjectsListView;