import Globals from "Globals";
import { ProvidingItemSubtype } from "constants/ProvidingItemConstants";
import { TreeNodeStatus } from "constants/TreeNodeStatus";
import { UnitType } from "constants/UnitType";
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from "draft-js";
import { compact, every, flatten, groupBy, isEmpty, last, maxBy, minBy, orderBy, some, sumBy, uniqBy, uniqWith } from 'lodash';
import { action, computed, observable } from "mobx";
import { computedFn } from 'mobx-utils';
import Line from "models/Line";
import ModelBase from "models/ModelBase";
import Shape from "models/Shape";
import { custom, list, map, mapAsArray, object, primitive, serializable } from "serializr";
import Stores from 'stores/Stores';
import { flattenItemsByCategSubcateg, groupItemsByCategSubcateg } from "utils/CategoryUtil";
import { sumPrices } from 'utils/CurrencyUtil';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import { getMeasurementsWithDependenciesIncludingOptional } from "utils/MeasurementUtil";
import { processTasks } from "utils/ReportUtil";
import { getDescriptionRichText, getEmptyRichText } from 'utils/RichTextUtil';
import { getBoundingBox } from "utils/ShapeUtil";
import { getSafe, modelSortFunction } from "utils/Utils";
import i18n from 'utils/i18n';
import { localized } from 'utils/localized';
import * as uuidv4 from 'uuid/v4';
import Adjustment from './Adjustment';
import BackgroundImage from './BackgroundImage';
import Category from "./Category";
import CountPoint from "./CountPoint";
import Measurement from "./Measurement";
import MeasurementValue from "./MeasurementValue";
import Point from './Point';
import { Rectangle } from "./Rectangle";
import SimpleSurface from "./SimpleSurface";
import Surface from './Surface';
import Task from "./Task";

// cannot use @serializable decorator for this class since refers to itself for child nodes
// go to createModelSchema section instead
export default class TreeNode extends ModelBase {
  type = 'TreeNode';

  @observable @serializable _isVisible?: boolean = true;

  @observable @serializable _status: TreeNodeStatus = TreeNodeStatus.None;

  // should bubble UP
  @observable @serializable shouldBubbleMeasurements?: boolean = true;

  @observable @serializable isDrawingNode = false;

  @computed get text(): string {
    return this.name;
  };
  set text(value: string) {
    this.name = value;
  }

  // allows to skip auto added images like when coming from tasks lists
  @observable @serializable shouldSkipImageInReport = false;

  @observable @serializable satelliteImageAddress = '';
  @observable @serializable(map(primitive())) satelliteImageCoords = { lat: 0, lng: 0 };
  @observable @serializable satelliteImageUrl = '';
  @observable @serializable satelliteImageRotation = 0;

  @observable @serializable backgroundImageId = '';

  @computed get backgroundImage(): BackgroundImage {
    return this.stores.backgroundImagesStore.getItem(this.backgroundImageId);
  }
  set backgroundImage(value: BackgroundImage) {
    this.backgroundImageId = value?.id;
  }

  @computed get backgroundImages(): BackgroundImage[] {
    return compact([this.backgroundImage, ...this.descendants.map(d => d.backgroundImage)]);
  }

  @observable @serializable backgroundImageScale = 1;

  @observable _isExpanded = undefined;

  @computed get isExpanded() {
    return this._isExpanded === undefined
      ? this.isRootNode
      : this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
  }

  // when showing 2 trees at once
  @observable isExpanded2 = true;

  @localized _description = '';
  @computed get description() {
    return this._description || getDescriptionRichText(i18n.language);
  }
  set description(value: string) {
    this._description = value;
  }
  @computed get hasDescription() {
    return this.description !== getDescriptionRichText(i18n.language) && this.description !== getEmptyRichText();
  }

  @localized _description2 = '';

  @computed get description2() {
    return (this.hasDescription && !this._description2)
      ? convertToHTML(convertFromRaw(JSON.parse(this.description))) // convert
      : this._description2;
  }

  set description2(value: string) {
    this._description2 = value;
    if (value && this.hasDescription) {
      this._description = '';
    }
  }

  @computed get textDescription2() {
    return this.description2.replace(/(<((br)|(p)|(li))([^>]*)>)/gi, '\n\n').replace(/(<([^>]+)>)/gi, ' ').trim();
  }

  // children
  @observable @serializable(list(primitive())) childrenIds: ModelId[] = [];

  @computed get children(): TreeNode[] {
    return getSafe(() => compact(
      this.childrenIds
        .map(childrenId => this.stores.treeNodesStore.getItem(childrenId))
    )) || [];
  }

  set children(value: TreeNode[]) {
    this.childrenIds = compact(value).map(node => node.id);
  }

  @computed get allChildren(): TreeNode[] {
    return getSafe(() => compact(
      this.childrenIds
        .map(childrenId => this.stores.treeNodesStore.allItems.find(i => i.id == childrenId))
    )) || [];
  }

  @computed get bubbleChildren(): TreeNode[] {
    return this.children.filter(node => node.shouldBubbleMeasurements);
  }

  @computed get parent(): TreeNode {
    const { treeNodesStore } = this.stores;
    return treeNodesStore.getParentNode(this);
  }

  @computed get parentDrawingNode(): TreeNode {
    return this.ancestors.find(node => node.isDrawingNode);
  }

  @computed get childDrawingNode(): TreeNode {
    return this.children.find(node => node.isDrawingNode);
  }

  @computed get doesNotHaveChildDrawingNode(): boolean {
    return !this.childDrawingNode;
  }

  @computed get hasDrawing(): boolean {
    return !isEmpty(this.childDrawingNode?.shapes);
  }

  // for kendo tree, children needs to be named items
  @computed get items(): TreeNode[] {
    const isProjectTree = this.stores === Globals.defaultStores;

    return this.children.filter(node => !isProjectTree || !node.isDrawingNode);
  }

  set items(value: TreeNode[]) {
    // LOSS OF DATA
    this.children = compact([
      this.childDrawingNode,
      ...value
    ]);
  }

  @observable @serializable(custom(
    m => Array.from(m),
    jsonArray => new Set(jsonArray))) ownShapesIds: Set<ModelId> = new Set();

  @computed get ownShapesIdsArray() {
    return [...this.ownShapesIds];
  }

  set ownShapesIdsArray(value) {
    this.ownShapesIds = new Set(value);
  }

  @computed get ownShapes(): Shape[] {
    return compact(this.ownShapesIdsArray.map(shapeId => this.stores.shapesStore.getItem(shapeId)))
  }

  set ownShapes(shapes: Shape[]) {
    this.ownShapesIds = new Set(shapes.map(shape => shape.id));
  }

  @computed get ownLineShapes() {
    return this.ownShapes.filter(s => s instanceof Line);
  }

  @computed get ownSurfaceShapes() {
    return this.ownShapes.filter(s => s instanceof Surface);
  }

  @computed get ownCountPointShapes() {
    return this.ownShapes.filter(s => s instanceof CountPoint);
  }

  @serializable @computed get shapeId() {
    if (this.ownShapesIdsArray.length == 1) {
      return this.ownShapesIdsArray[0];
    }
    return '';
  }

  set shapeId(value) {
    this.ownShapesIds.add(value);
  }

  @computed get shape(): Shape {
    if (this.ownShapesIdsArray.length == 1) {
      return this.stores.shapesStore.getItem(this.ownShapesIdsArray[0]);
    }
    return null;
  }

  set shape(value: Shape) {
    this.shapeId = getSafe(() => value.id) || '';
  }

  @serializable @observable isRootNode = false;

  @computed get measurements(): Measurement[] {
    const { measurementsStore } = this.stores;

    return this.measurementValuesArray
      .filter(measurementValue => measurementValue.measurement)
      .filter(measurementValue => measurementsStore.getItem(measurementValue.measurement.id))
      .map(measurementValue => measurementValue.measurement)
  }

  // here filter out "quantité type" measuremnets
  @computed get measurementsToShow(): Measurement[] {
    const { measurementsStore } = this.stores;

    return this.measurements
      .filter(measurement => (
        !measurement.isOneTimeUse
        //measurement.subsubcategory?.id !== BuiltinMeasurementSubcategories.OutputMeasurements
      ));
  }

  @computed get childNonZeroMeasurementValuesArray() {
    return this.childMeasurementValuesArray.filter(
      measurementValue => measurementValue.metricValue > 0
    );
  }

  @computed get unneededMeasurements(): Measurement[] {
    const taskMeasurementsFunction = task => [
      task.measurement,
      task.dynamicPriceMeasurementValue?.measurement,
      ...task.dynamicNameMeasurementValues.map(mv => mv.measurement)
    ];

    const neededMeasurements = uniqBy(compact([
      ...this.bubbleAncestors.map(ancestor => ancestor.tasks.map(taskMeasurementsFunction).flat()).flat(),
      ...this.bubbleChildren.map(child => child.tasks.map(taskMeasurementsFunction).flat()).flat(),
      ...this.ownTasks.map(taskMeasurementsFunction).flat(),
    ]), 'id');

    const neededMeasurementsIdsWithDependencies = compact(getMeasurementsWithDependenciesIncludingOptional(neededMeasurements).map(m => m?.id));

    return this.measurementValuesArray.filter(mv => mv?.measurement && !neededMeasurementsIdsWithDependencies.includes(mv.measurement.id)).map(mv => mv.measurement);
  }

  @computed get nonDefaultMeasurementValues(): MeasurementValue[] {
    return [this, ...this.descendants]
      .map(node => node.ownMeasurementValuesArray.filter(mv => mv.measurement && mv._formula !== mv.measurement.defaultFormula))
      .flat();
  }

  @observable @serializable(mapAsArray(object(MeasurementValue), 'measurementId')) ownMeasurementValues = new Map<ModelId /*measurement id*/, MeasurementValue>();

  // not clear that it's actually just bubble children values
  @computed get childMeasurementValues(): Map<ModelId /*measurement id*/, MeasurementValue> {
    const retval = new Map<ModelId, MeasurementValue>();
    this.bubbleChildren.forEach(child => {
      child.measurementValuesArray.forEach(measurementValue => {
        const measurementId = getSafe(() => measurementValue.measurement.id);
        if (
          measurementId &&
          !retval.get(measurementId) &&
          !this.ownMeasurementValues.get(measurementId)
        ) {
          retval.set(
            measurementId,
            new MeasurementValue(this.stores, this, measurementValue.measurement),
          );
        }
      });
    });

    return retval;
  }

  // here child referes to bubble children only (childs that transfer to parent)
  @computed get measurementValues(): Map<ModelId /*measurement id*/, MeasurementValue> {
    const retval = new Map(this.ownMeasurementValues);

    this.childMeasurementValuesArray.forEach(measurementValue => {
      if (!measurementValue?.measurement) {
        return;
      }

      const measurementId = measurementValue.measurement.id;
      retval.set(
        measurementId,
        new MeasurementValue(this.stores, this, measurementValue.measurement),
      );
    });

    return retval;
  }

  // compact?
  @computed get ownMeasurementValuesArray(): MeasurementValue[] {
    return Array.from(this.ownMeasurementValues.values()).filter(mv => mv.measurement);
  }

  @computed get measurementValuesArray(): MeasurementValue[] {
    return Array.from(this.measurementValues.values()).filter(mv => mv.measurement);
  }

  @computed get oneTimeUseMeasurementValuesArray(): MeasurementValue[] {
    return Array.from(this.measurementValues.values()).filter(mv => mv.measurement && mv.measurement.isOneTimeUse);
  }

  @computed get nonOneTimeUseMeasurementValuesArray(): MeasurementValue[] {
    return Array.from(this.measurementValues.values()).filter(mv => mv.measurement && !mv.measurement.isOneTimeUse);
  }

  @computed get childMeasurementValuesArray(): MeasurementValue[] {
    return Array.from(this.childMeasurementValues.values());
  }

  @computed get ancestorOrNodeMeasurementValuesArray(): MeasurementValue[] {
    return uniqBy(compact([
      ...this.bubbleAncestors.map(ancestor => ancestor.ownMeasurementValuesArray).flat(),
      ...this.ownMeasurementValuesArray
    ]), 'measurement.id')
  }

  @observable @serializable(mapAsArray(object(Adjustment), 'measurementId'))
  ownMeasurementAdjustments = new Map<ModelId /*measurement id*/, Adjustment>();

  @observable @serializable(list(primitive())) ownTaskCategoriesIds: ModelId[] = [];

  @computed get ownTaskCategories(): Category[] {
    const { categoriesStore } = this.stores;

    return uniqBy(flatten(compact([
      ...this.ownTaskCategoriesIds.map(categId => this.stores.categoriesStore.getItem(categId)),
      ...this.ownTasks.map(t => t.category),
    ])), 'id')
      .map(categ => categoriesStore.getItem(categ.id))
      .sort(modelSortFunction);
  }

  set ownTaskCategories(value: Category[]) {
    this.ownTaskCategoriesIds = value.map(categ => categ.id);
  }

  @computed get taskCategories(): Category[] {
    const { categoriesStore } = this.stores;
    // duplicate
    return uniqBy(flatten(compact([
      ...this.ownTaskCategories,
      ...this.tasks.map(t => t.category),
    ])), 'id')
      .map(categ => categoriesStore.getItem(categ.id))
      .sort(modelSortFunction);
  }

  @computed get nonEmptyOwnTaskCategories(): Category[] {
    // uses taskCategories instead of ownTaskCategories, to have the correct sort order
    // and include General category
    const { reportsStore } = this.stores;

    return this.taskCategories.filter(category => (
      !isEmpty(processTasks(this.ownTasks.filter(task => task.category === category), undefined, reportsStore.report?.tasksFilter, true))
    ));
  }

  @computed get nonEmptyTaskCategories(): Category[] {
    const { reportsStore } = this.stores;

    return this.taskCategories.filter(category => (
      !isEmpty(processTasks(this.tasks.filter(task => task.category === category), undefined, reportsStore.report?.tasksFilter, true))
    ));
  }

  @observable @serializable(list(primitive())) ownTasksIds: ModelId[] = [];

  @computed get ownTasks(): Task[] {
    let tasks = getSafe(() => compact(
      this.ownTasksIds
        .map(taskId => this.stores.tasksStore.getItem(taskId))
    )) || [];

    // go to an extra step to sort them
    tasks = flattenItemsByCategSubcateg(groupItemsByCategSubcateg(tasks), this.stores)
      .filter(i => i.item).map(i => i.item);

    return tasks;
  }

  set ownTasks(value: Task[]) {
    this.ownTasksIds = value.map(task => task.id);
  }

  @computed get childTasks(): Task[] {
    return compact(flatten(this.children.map(d => d.tasks)));
  }

  @computed get tasks(): Task[] {
    return [...this.ownTasks, ...this.childTasks];
  }

  @computed get tasksByCategoryId() {
    return groupBy(this.tasks, 'category.id');
  }

  @computed get ownTasksByCategoryId() {
    return groupBy(this.ownTasks, 'category.id');
  }


  @computed get areChildrenAllowed(): boolean { // for compatibility with react-ui-tree
    return !this.shape;
  }

  @computed get hasChildren(): boolean {
    return !isEmpty(this.children);
  }

  @computed get hasNonDrawingRootChildren(): boolean {
    // not counting drawing nodes because invisible in tree
    return !isEmpty(this.children.filter(node => !node.isDrawingNode));
  }

  @computed get hasNonDrawingRootBubbleChildren(): boolean {
    // not counting drawing nodes because invisible in tree
    return !isEmpty(this.bubbleChildren.filter(node => !node.isDrawingNode));
  }

  @computed get hasBubbleChildren(): boolean {
    return !isEmpty(this.bubbleChildren);
  }

  @computed get ancestors(): TreeNode[] {
    return this.parent ? [this.parent, ...this.parent.ancestors] : [];
  }

  @computed get path(): string {
    return this.stores.treeNodesStore.getPath(this);
  }

  @computed get bubbleAncestors(): TreeNode[] {
    const retval = [];
    let ancestor = this.shouldBubbleMeasurements && this.parent;
    while (ancestor) {
      retval.push(ancestor);
      ancestor = ancestor.shouldBubbleMeasurements && ancestor.parent;
    }
    return retval;
  }

  @computed get bubbleRoot(): TreeNode {
    return last(this.bubbleAncestors) || this;
  }

  // undefined as to not override local change when creating object from db change
  // this probably shouldn't be observable here because it causes unneeded recalculation on change
  @observable _isSelected = undefined;
  @computed get isSelected() {
    return this._isSelected;
  }
  set isSelected(value) {
    this._isSelected = value;
  }

  // need to rethink this, should hold this state outside treenode and set it before assigning tree
  // data to tree view
  @observable isSelected2 = false;

  @computed get collapsed(): boolean {
    return !this.isExpanded;
  }

  set collapsed(value) {
    this.isExpanded = !value;
  }

  // a bit complicated because 3 state visible (false true undefined)
  @computed get isVisible(): boolean | undefined {
    return isEmpty(this.children)
      ? this._isVisible
      : (
        every(this.children, node => node.isVisible) ||
        some(this.children, node => !node.isVisible) && undefined ||
        !every(this.children, node => !node.isVisible)
      );
  }

  set isVisible(value: TreeNodeStatus) {
    this._isVisible = !!value;
    this.children.forEach(node => node.isVisible = value);
  }

  // slightly different than isVisible, because incomplete status takes precedence instead of undefined
  @computed get status(): TreeNodeStatus {
    return isEmpty(this.nonDrawingChildren)
      ? this._status
      : (
        every(this.nonDrawingChildren, node => node.status === TreeNodeStatus.Verified) && TreeNodeStatus.Verified ||
        some(this.nonDrawingChildren, node => node.status === TreeNodeStatus.Incomplete) && TreeNodeStatus.Incomplete ||
        every(this.nonDrawingChildren, node => node.status === TreeNodeStatus.None) && TreeNodeStatus.None
      );
  }

  set status(value: TreeNodeStatus) {
    this._status = value;
    this.nonDrawingChildren.forEach(node => node.status = value);
  }

  @observable @serializable shouldExcludeFromReports = false;

  @computed get isParentExcludedFromReports() {
    return this.ancestors.find(ancestor => ancestor.shouldExcludeFromReports);
  }

  @computed get drawingRoot(): TreeNode {
    // when in drawing dialog, always show all children shapes
    // when in project tree, only show own drawing, not children nodes drawings
    return this.childDrawingNode
      ? this.childDrawingNode
      : (this.parentDrawingNode || this.isDrawingNode) ? this : null;
  }

  // current shape + descendants
  @computed get shapes(): Shape[] {
    return this.drawingRoot
      ? orderBy(
        compact([this.drawingRoot, ...this.drawingRoot.bubbleDescendants]
          .map(d => d.ownShapes).flat()),
        ['index']
      )
      : [];
  }

  //only use when cannot access get shapes because rescue mode (low memory)
  @computed get shapesIds(): string[] {
    return this.drawingRoot
      ? [this.drawingRoot, ...this.drawingRoot.bubbleDescendants]
          .map(d => d.ownShapesIdsArray).flat()
      : [];
  }

  @computed get indentLevel() {
    return (
      this.isRootNode
        ? 0
        : this.ancestors.length
    );
  }

  @computed get isLeaf() {
    return this.shape || isEmpty(this.nonDrawingChildren);
  }

  // to check if a certain types of shape (ex. rectangle, we check for 4 points)
  // we need to exclude the counter markers that are not part of shapes
  @computed get pointsExceptCountPoints(): Point[] {
    return uniqWith(
      this.shapes.filter(shape => shape.type !== 'CountPoint').map(shape => shape.points).flat(),
      (ptA, ptB) => Math.abs(ptA.x - ptB.x) < 0.0001 && Math.abs(ptA.y - ptB.y) < 0.0001
    );
  }

  @computed get points(): Point[] {
    return this.shapes.map(shape => shape.points).flat();
    /*
    // too slow with lots of shapes
    return uniqWith(
      this.shapes.map(shape => shape.points).flat(),
      (ptA, ptB) => Math.abs(ptA.x - ptB.x) < 0.0001 && Math.abs(ptA.y - ptB.y) < 0.0001
    );
    */
  }

  @computed get boundingBox(): Rectangle {
    return getBoundingBox(this.points, false);
  }

  @computed get viewBoxRectWithoutDimensions() {
    const backgroundImage = this.childDrawingNode?.backgroundImage

    if (isEmpty(this.points)) {
      return backgroundImage?.cropRectangle 
      ? { x: backgroundImage.cropRectangle.topLeft.x, y: backgroundImage.cropRectangle.topLeft.y, width: backgroundImage.cropRectangle.width || 0, height: backgroundImage.cropRectangle.height || 0 }
      : { x: 0, y: 0, width: backgroundImage?.width || 0, height: backgroundImage?.height || 0 };
    }

    let topLeft = new Point(minBy(this.points, 'x').x, minBy(this.points, 'y').y);
    let bottomRight = new Point(maxBy(this.points, 'x').x, maxBy(this.points, 'y').y);


    return {
      x: topLeft.x,
      y: topLeft.y,
      width: bottomRight.x - topLeft.x,
      height: bottomRight.y - topLeft.y
    }
  }

  @computed get surfaceShapes(): Surface[] {
    return this.shapes.filter(shape => shape instanceof Surface) as Surface[];
  }

  @computed get surfaceHoles(): SimpleSurface[] {
    return this.surfaceShapes.map(shape => shape.holes).flat();
  }

  @computed get lineShapes(): Line[] {
    return this.shapes.filter(shape => shape instanceof Line) as Line[];
  }

  @computed get lineShapesExceptBeingAdded(): Line[] {
    const { drawToolsStore } = this.stores;
    return this.lineShapes.filter(shape => shape !== drawToolsStore.lineBeingAdded) as Line[];
  }

  @computed get countPointShapes(): CountPoint[] {
    return this.shapes.filter(shape => shape instanceof CountPoint) as CountPoint[];
  }

  @computed get shapeSurface(): number {
    return sumBy(this.surfaceShapes, 'surface') || 0;
  }

  @computed get shapeLength(): number {
    return sumBy(this.lineShapes, 'length') || 0;
  }

  @computed get unitMeasurements(): Measurement[] {
    return this.measurements.filter(measurement => measurement?.unitType === UnitType.Unit);
  }

  getShapeLengthWithSlope = computedFn((slopeRatio, slopeDirection, drawingProjection) => {
    return sumBy(this.lineShapes, shape => shape.getLengthWithSlope(slopeRatio, slopeDirection, drawingProjection)) || 0;
  });

  getShapeSurfaceWithSlope = computedFn((slopeRatio, slopeDirection, drawingProjection) => {
    return sumBy(this.surfaceShapes, shape => shape.getSurfaceWithSlope(slopeRatio, slopeDirection, drawingProjection)) || 0;
  });

  getShapeSurfaceWithOffset = computedFn((offsetMetricValue) => {
    return sumBy(this.surfaceShapes, shape => shape.getAreaWithOffset(offsetMetricValue)) || 0;
  });

  getShapeLengthWithOffset = computedFn((leftOffsetMetricValue, rightOffsetMetricValue) => {
    return sumBy(this.lineShapes, shape => shape.getAreaWithOffset(leftOffsetMetricValue, rightOffsetMetricValue)) || 0;
  });

  getShapeRepeatedLinesLength = computedFn((spacingMetricValue, angleMetricValue, shouldConsiderOpenings) => {
    return sumBy(this.surfaceShapes, shape => shape.getSurfaceRepeatedLinesLength(spacingMetricValue, angleMetricValue, shouldConsiderOpenings)) || 0;
  })

  getShapeRepeatedLinesQuantity = computedFn((spacingMetricValue, angleMetricValue, shouldConsiderOpenings) => {
    return sumBy(this.surfaceShapes, shape => shape.getSurfaceRepeatedLinesQuantity(spacingMetricValue, angleMetricValue, shouldConsiderOpenings)) || 0;
  })

  // very slow :( 
  // try to do async somehow or
  // add /remove corners manually when drawing instead of computing afterwards
  @computed get shapesCornersQuantity(): { cornersQuantity: number, extremitiesQuantity: Number } {
    if (isEmpty(this.lineShapes)) {
      return { cornersQuantity: 0, extremitiesQuantity: 0 };
    }

    let cornersQuantity = 0;
    let extremitiesQuantity = 0;

    this.lineShapesExceptBeingAdded.forEach(lineShape => {
      const currentLine = lineShape;
      // so that it can work in both bubble down mode and regular mode:
      // corner is counted for current line when another line contains the same point,
      // and current line is higher in hierarchy
      // Uses grand parent node so will work for usual case like:
      // group: [line, line line] and
      // group: [group: [line, line line], group2: [line, line]]
      const grandParentNode = this.parent?.parent || this.parent || this;
      const allLines: Line[] =
        this.lineShapesExceptBeingAdded.length === 1
          ? grandParentNode.lineShapesExceptBeingAdded
          : this.lineShapesExceptBeingAdded;
      const allLinesButCurrent = allLines.filter(line => line !== currentLine);
      const lineIndex = allLines.indexOf(currentLine);

      const otherLineWithSameFirstPoint = allLinesButCurrent.find(shape => (
        shape.startPt.isAlmostEqual(currentLine.startPt) ||
        shape.endPt.isAlmostEqual(currentLine.startPt)
      ));

      if (otherLineWithSameFirstPoint && allLines.indexOf(otherLineWithSameFirstPoint) > lineIndex) {
        cornersQuantity++;
      }
      if (!otherLineWithSameFirstPoint) {
        extremitiesQuantity++;
      }

      const otherLineWithSameEndPoint = allLinesButCurrent.find(shape => (
        shape.startPt.isAlmostEqual(currentLine.endPt) ||
        shape.endPt.isAlmostEqual(currentLine.endPt)
      ));

      if (otherLineWithSameEndPoint && allLines.indexOf(otherLineWithSameEndPoint) > lineIndex) {
        cornersQuantity++;
      }
      if (!otherLineWithSameEndPoint) {
        extremitiesQuantity++;
      }
    });

    return { cornersQuantity, extremitiesQuantity };
  }

  // not much faster :'(
  @computed get shapesCornersQuantityNew(): { cornersQuantity: number, extremitiesQuantity: Number } {
    if (this.lineShapesExceptBeingAdded.length < 2) {
      return { cornersQuantity: 0, extremitiesQuantity: 0 };
    }

    let cornersQuantity = 0;
    let extremitiesQuantity = 0;

    // oldschool for loops, because O2 and previous algorithm with filters was very slow
    for (let i = 0; i < this.lineShapesExceptBeingAdded.length; i++) {
      const line = this.lineShapesExceptBeingAdded[i];
      let isCornerStartFound = false;
      let isCornerEndFound = false;

      for (let j = 0; j < this.lineShapesExceptBeingAdded.length; j++) {
        if (i == j) {
          continue;
        }

        const line2 = this.lineShapesExceptBeingAdded[j];

        if (
          !isCornerStartFound && (
            line.startPt.isAlmostEqual(line2.startPt) ||
            line.startPt.isAlmostEqual(line2.endPt)
          )) {
          isCornerStartFound = true;

          if (j > i) {
            cornersQuantity++;
          }
        }

        if (
          !isCornerEndFound && (
            line.endPt.isAlmostEqual(line2.startPt) ||
            line.endPt.isAlmostEqual(line2.endPt)
          )) {
          isCornerEndFound = true;
          if (j > i) {
            cornersQuantity++;
          }
        }

        if (isCornerStartFound && isCornerEndFound) {
          break;
        }
      }


      extremitiesQuantity += Number(!isCornerStartFound) + Number(!isCornerEndFound);
    }

    return { cornersQuantity, extremitiesQuantity };
  }

  @computed get descendants(): TreeNode[] {
    const nonObservableChildren = Array.from(this.children);
    return nonObservableChildren.reduce<TreeNode[]>((prev, current) => (
      prev.concat(current.descendants)
    ), nonObservableChildren);
  }

  @computed get allDescendants(): TreeNode[] {
    const nonObservableChildren = Array.from(this.allChildren);
    return nonObservableChildren.reduce<TreeNode[]>((prev, current) => (
      prev.concat(current.allDescendants)
    ), nonObservableChildren);
  }

  @computed get bubbleDescendants(): TreeNode[] {
    const nonObservablebubbleChildren = Array.from(this.bubbleChildren);
    return nonObservablebubbleChildren.reduce<TreeNode[]>((prev, current) => (
      prev.concat(current.bubbleDescendants)
    ), nonObservablebubbleChildren);
  }

  getBubbleAncestorsWithOwnMeasurement = computedFn((measurement: Measurement) => {
    return this.bubbleAncestors
      .filter(node => node.ownMeasurementValues.get(measurement.id));
  })

  getBubbleDescendantsWithOwnMeasurement = computedFn((measurement: Measurement) => {
    return this.bubbleDescendants
      .filter(node => node.ownMeasurementValues.get(measurement.id));
  })

  @computed get nonDrawingDescendants(): TreeNode[] {
    return this.descendants.filter(
      // drawing is always bubble up, never change it
      descendant => !descendant.isDrawingNode &&
        !descendant.ancestors.some(ancestor => ancestor.isDrawingNode)
    );
  }

  @computed get nonDrawingChildren(): TreeNode[] {
    return this.children.filter(
      // drawing is always bubble up, never change it
      child => !child.isDrawingNode
    );
  }

  @computed get measurementsToDisplay() {
    return uniqBy([
      ...compact(
        [
          // room measurements categ
          '0a7166cc-b2ec-49f4-b336-b5adff2efaca', 'WallLength', 'WallSurface', 'e2cf094c-f45b-45ff-af7a-4f1986e334b2', 'CeilingSurface', 'CeilingAndWallSurfaces',
          // exterior walls
          '16b567eb-3589-4cb3-aee1-b6b1e213e2b0', '5001f803-cb58-4568-a4be-6f025c6a771d', 'dbd2cd25-fa92-4e23-bf01-9e3be02284b4',
          // interior wall
          '818ceb65-7706-47a9-8282-84c91c9ee88d', 'da743959-c6ed-4361-92ea-cdaa7473aa62',
          // floor
          '42613b17-54dd-4df2-82b9-24bd71768f11', '02523728-261d-4aed-a66e-d64fd2ca7a8b', '2ee6b75a-77c3-47b3-96e8-9a019a142a3c'
        ]
          .map(measurementId => this.measurementValues.get(measurementId))
      ),
      ...this.measurementValuesArray.filter(mv => mv.measurement?.shouldShowInReportHeaders)
    ], 'measurement.id');
  }

  @computed get hasNonEmptyTasks() {
    return !!this.tasks.find(
      task => task.description || task.providingItem
    )
  }

  // none of these really work because they show the totals for tasks in project
  // not for the resulting sometimes merged tasks that appear in the report
  getSubtotal = (
    subtype: ProvidingItemSubtype = null,
    category: Category = null,
    shouldIncludeChildTasks = true,
    shouldIncludeFees = this.stores.commonStore.shouldIncludeFeesInTasks, // fees only included in report page....
  ) => this.computedGetSubtotal(subtype, category, shouldIncludeChildTasks, shouldIncludeFees);

  // computed function doesn't allow for default params
  // CHECK ABOVE for params default values!
  computedGetSubtotal = computedFn((
    subtype: ProvidingItemSubtype,
    category: Category,
    shouldIncludeChildTasks,
    shouldIncludeFees,
  ): number => {
    return sumPrices(
      (shouldIncludeChildTasks ? this.tasks : this.ownTasks)
        .filter(task => (
          (!subtype || getSafe(() => task.providingItem.subtype === subtype)) &&
          (!category || getSafe(() => task.category === category))
        ))
        .map(task => shouldIncludeFees ? task.priceWithFees : task.price)
    );
  })

  @computed get subtotalLabourBeforeFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Labour, null, true, false);
  }

  @computed get subtotalMaterialBeforeFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Material, null, true, false);
  }

  @computed get subtotalBeforeFees(): number {
    return this.getSubtotal(null, null, true, false);
  }

  @computed get subtotalLabourWithFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Labour, null, true, true);
  }

  @computed get subtotalMaterialWithFees(): number {
    return this.getSubtotal(ProvidingItemSubtype.Material, null, true, true);
  }

  @computed get subtotalWithFees(): number {
    const { settingsStore, totalsStore } = this.stores;
    return settingsStore.settings?.areFeesIncludedInItemPrice
      ? this.subtotalLabourWithFees + this.subtotalMaterialWithFees
      : this.subtotalBeforeFees + totalsStore.feesTotal;
  }

  @computed get subtotalLabour(): number {
    return this.getSubtotal(ProvidingItemSubtype.Labour);
  }

  @computed get subtotalMaterial(): number {
    return this.getSubtotal(ProvidingItemSubtype.Material);
  }

  @computed get subtotal(): number {
    return this.getSubtotal();
  }

  @computed get subtotalHours(): number {
    return sumBy(this.tasks, task => task.hours);
  }

  // doesn't seem to work, called to early, before measurementValues exist
  afterDeserialize = () => {
    this.measurementValues.forEach((measurementValue, measurementId) => {
      measurementValue.stores = this.stores;
      measurementValue.treeNode = this;
    });

    // would seem faster to load shapes after treenodes (can render part of project)
    // and run a single pass through all treeNodes to stamp their shapes
    this.ownShapes.forEach(shape => {
      shape.stores = this.stores;
      shape.treeNode = this;
    })
  }

  // also saves sub objects to db because that's the only way they will load
  // responsibility of saving object returned by this function is in the caller
  @action
  cloneDeep(
    batchParam: WriteBatch,
    targetStores = this.stores,
    newId: ModelId = uuidv4(),
    quantityMeasurementsCopyMap = new Map<ModelId, ModelId>(),
    selectedTasksIds: ModelId[] = null,
    skipMeasurements = false,
    skipProvidingItems = false,
    shouldKeepSameTasksIds = false,
  ): this {
    const nodeCopy = super.clone(targetStores, newId);

    // ownMeasurementValues
    const ownMeasurementValuesCopy = new Map();
    this.ownMeasurementValuesArray.forEach(measurementValue => {
      const { measurementId } = measurementValue;
      const { measurement } = measurementValue;

      if (!measurement) {
        // when measurement has been deleted
        // make sure measurements store has loaded first
        return;
      }

      const measurementValueCopy = measurementValue.clone(targetStores);
      measurementValueCopy.treeNodeId = nodeCopy.id;

      // semi duplicate with Task cloneDeep
      if (
        !skipMeasurements && (
          measurement.isOneTimeUse ||
          // no usecase to overwrite an existing measurement in target store, only copy if doesn't exist
          !targetStores.measurementsStore.getItem(measurement.id)
        )
      ) {
        if (quantityMeasurementsCopyMap.has(measurementId)) {
          measurementValueCopy.measurementId = quantityMeasurementsCopyMap.get(measurementId);
        } else {

          const measurementCopy = measurement.clone(
            targetStores,
            measurement.isOneTimeUse ? uuidv4() : measurement.id
          );

          if (measurementCopy.id !== measurement.id) {
            // keep track of measurement new id, to update task measurement reference, not just node measurementvalues
            quantityMeasurementsCopyMap.set(measurement.id, measurementCopy.id);
          }

          targetStores.measurementsStore.batchAddEditItem(measurementCopy, batchParam);
          measurementValueCopy.measurement = measurementCopy;
        }
      }

      ownMeasurementValuesCopy.set(measurementValueCopy.measurementId, measurementValueCopy);
    });
    nodeCopy.ownMeasurementValues = ownMeasurementValuesCopy;

    // Tasks
    const tasks = this.ownTasks.filter(t => isEmpty(selectedTasksIds) || selectedTasksIds.includes(t.id));

    // hack (tasks get deleted by dialog closing),
    // should copy to project before closing, but the action of closing copies taskListDraft to tasksLift
    tasks.forEach(task => {
      task.isDeleted = false;
    });

    const ownTasksCopy = tasks
      .map(task => task.cloneDeep(
        batchParam,
        targetStores,
        // passing as a reference for tasks to decide which measurements need duplicating
        quantityMeasurementsCopyMap,
        skipMeasurements,
        skipProvidingItems,
        shouldKeepSameTasksIds,
      ));

    targetStores.tasksStore.batchAddEditItems(ownTasksCopy, batchParam);
    nodeCopy.ownTasks = ownTasksCopy;


    // children node (recursive)
    const childrenCopy = this.children.slice(0);
    childrenCopy.forEach((childNode, index) => {
      childrenCopy[index] = childNode.cloneDeep(batchParam, targetStores, uuidv4(), quantityMeasurementsCopyMap, null, skipMeasurements, skipProvidingItems, shouldKeepSameTasksIds);
    });

    targetStores.treeNodesStore.batchAddEditItems(childrenCopy, batchParam);
    nodeCopy.children = childrenCopy;

    // Shape
    // we only duplicate shape when duplicating node in same store OR
    // if target stores have its dedicated shapesStore
    // For example in Tasks List we don't need shapes copy so we use the default project stores for shapes
    if ((
      targetStores === this.stores ||
      targetStores.shapesStore !== this.stores.shapesStore
    )) {
      if (!isEmpty(this.ownShapes)) {
        const shapesCopy = this.ownShapes.map(shape => shape.clone(targetStores, uuidv4()));
        targetStores.shapesStore.batchAddEditItems(shapesCopy, batchParam);
        nodeCopy.ownShapes = shapesCopy;
      } else if (this.shape) {
        const shapeCopy = this.shape.clone(targetStores, uuidv4());
        targetStores.shapesStore.batchAddEditItem(shapeCopy, batchParam);
        nodeCopy.shape = shapeCopy;
      }
    }

    return nodeCopy;
  }

  constructor(stores: Stores, text: string = '') {
    super(stores);
    if (text) {
      this.text = text;
    }
  }
}
