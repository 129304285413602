import { FilledInput, FormControl, IconButton, MenuItem, Select, Slider, Switch, Tooltip } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import RedoIcon from '@material-ui/icons/Redo';
import SettingsIcon from '@material-ui/icons/Settings';
import UndoIcon from '@material-ui/icons/Undo';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { ConfirmationBehavior } from 'constants/ConfirmationBehavior';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { handleChangeAndSave, handleCheckboxChangeAndSave, handleSliderChangeAndSave } from 'utils/FormUtils';
import i18n from 'utils/i18n';
import { DragClickIcon, HoleIcon, MeasuringTapeIcon2, SnapIcon } from '../Icons';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';

const styles = require('./DrawingHeader.module.scss');

export default class DrawingHeader extends ObserverComponent {
  toggleFullScreen = () => {
    const { drawToolsStore } = this.context;
    drawToolsStore.isFullscreen = !drawToolsStore.isFullscreen;
  }

  _render() {
    const { treeNodesStore, drawToolsStore, shapesStore, userInfoStore, undoStore, snapStore } = this.context;
    const backgroundImage = treeNodesStore?.rootNode?.backgroundImage;
    const satelliteImageUrl = treeNodesStore?.rootNode?.satelliteImageUrl;
    const selectedNode = treeNodesStore.selectedTreeNode;
    const { isFullscreen } = drawToolsStore;
    const { user } = userInfoStore;


    const drawingSettingsMenu = [
      {
        icon: (
          <div className={styles.menuItem + ' ' + styles.slider}>
            <ZoomInIcon />
            <div className={styles.menuText}>{i18n.t('Zoom Speed')}</div>
            {/* logarithmic because seems easier to fine tune this way, all values come from trial and error */}
            <Slider
              value={user.mouseWheelSensitivity}
              onChange={handleSliderChangeAndSave(user, 'mouseWheelSensitivity', userInfoStore)}
              max={-0.10}
              min={-2.4}
              step={0.01}
              scale={value => 10 ** value}
            />
          </div>
        ),
      },
      {
        icon: (
          <div
            className={styles.menuItem + ' ' + styles.switch}
            onClick={() => {
              user.isDrawingSnapPointEnabled = !user.isDrawingSnapPointEnabled;
              userInfoStore.addEditItem(user, true, ['isDrawingSnapPointEnabled']);
            }}>
            <SnapIcon />
            <div className={styles.menuText}>{i18n.t('Enable Snap')}</div>
            <div>
              <Switch
                checked={user.isDrawingSnapPointEnabled}
                onChange={(event, checked) => {
                  handleCheckboxChangeAndSave(user, 'isDrawingSnapPointEnabled', userInfoStore)(event, checked);
                  if (!checked) {
                    snapStore._snapPointsMap.clear();
                  }
                }}
              />
            </div>
          </div>
        ),
      },
      {
        icon: (
          <div
            className={styles.menuItem + ' ' + styles.switch}
            onClick={() => {
              user.isDragMomentumEnabled = !user.isDragMomentumEnabled;
              userInfoStore.addEditItem(user, true, ['isDragMomentumEnabled']);
            }}>
            <DragClickIcon />
            <div className={styles.menuText}>{i18n.t('Continue moving the drawing after releasing the mouse')}</div>
            <div>
              <Switch
                checked={user.isDragMomentumEnabled}
                onChange={(event, checked) => {
                  handleCheckboxChangeAndSave(user, 'isDragMomentumEnabled', userInfoStore)(event, checked);
                }}
              />
            </div>
          </div>
        ),
      },
      {
        icon: (
          <div
            className={styles.menuItem + ' ' + styles.switch}
          >
            <HoleIcon />
            <div className={styles.menuText}>{i18n.t('Subtract new surfaces from underlying surfaces')}&nbsp;</div>
            <div>
              <FormControl className={styles.textFieldControl} margin="normal">
                <Select
                  className={styles.textFieldNoLabel}
                  value={user.subtractSurfacesBehavior}
                  onChange={handleChangeAndSave(user, 'subtractSurfacesBehavior', userInfoStore)}
                  input={<FilledInput />}
                >
                  <MenuItem value={ConfirmationBehavior.Ask}>{i18n.t("Ask each time")}</MenuItem>
                  <MenuItem value={ConfirmationBehavior.Always}>{i18n.t("Always subtract")}</MenuItem>
                  <MenuItem value={ConfirmationBehavior.Never}>{i18n.t('Never subtract')}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        ),
      },
      {
        icon: (
          <div
            id="shouldShowDrawingsInInches"
            className={styles.menuItem + ' ' + styles.switch}
            style={{ display: 'none' }}
          >
            <MeasuringTapeIcon2 />&nbsp;
            <div className={styles.menuText}>{i18n.t('Show all measurements in inches')}</div>
            <div>
              <Switch
                checked={user.shouldShowDrawingsInInches}
                onChange={(event, checked) => {
                  handleCheckboxChangeAndSave(user, 'shouldShowDrawingsInInches', userInfoStore)(event, checked);
                }}
              />
            </div>
          </div>
        ),
      },
    ];

    return (
      <div className={styles.root} >
        <div>{i18n.t('Drawing')}</div>
        <div className={styles.buttons}>
          <div style={{ marginRight: '30px', display: 'none' /* close to work, but needs to prevent undoing project, just drawing. Also buggy when in the middle of drawing */ }}>
            <Tooltip title={i18n.t('Undo')}>
              <span> {/* required by tooltip if button can be disabled */}
                <IconButton className={styles.actionButton} disabled={!undoStore.canUndo} onClick={undoStore.undo}>
                  <UndoIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={i18n.t('Redo')}>
              <span>
                <IconButton className={styles.actionButton} disabled={!undoStore.canRedo} onClick={undoStore.redo} >
                  <RedoIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          <MenuPopupButton icon={<SettingsIcon />} className={styles.settingsButton} menuItems={drawingSettingsMenu} />
          <IconButton
            disabled={isEmpty(shapesStore.items) && !backgroundImage && !satelliteImageUrl}
            onClick={() => shapesStore.zoomController.zoomCompletelyOut()}
          >
            <HomeIcon />
          </IconButton>
          <IconButton onClick={() => shapesStore.zoomController.zoomIn()}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={() => shapesStore.zoomController.zoomOut()}>
            <ZoomOutIcon />
          </IconButton>
          {/*<IconButton onClick={this.toggleFullScreen}>
            {isFullscreen
              ? <FullScreenExitIcon />
              : <FullScreenIcon />
            }
          </IconButton>*/}
        </div>

      </div>
    );
  }
}