import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from 'stores/StoresContext';
import i18n from "utils/i18n";

const IntlApp = observer(() => {
  let App;
  const { userInfoStore } = useStores();
  const { user } = userInfoStore;
  const isLoggedOut = user === null;

  if (isLoggedOut) {
    const langFromQueryParam = (new URL(document.URL)).searchParams.get('lang');
    i18n.language = (
      (langFromQueryParam === 'en' && 'en') ||
      'fr' //navigator.language  // put back once English version is better supported
    ) as LanguageKey;
  }

  const { language } = i18n;

  if (language) {
    App = require('App').default;
  }

  if (App) {
    return <App />
  } else {
    return <LoadingOverlay />
  }
});

export default IntlApp;
