import ImageIcon from '@material-ui/icons/Image';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import { Controlled as Zoom } from 'react-medium-image-zoom';
import ObserverComponent from '../ObserverComponent';

const styles = require('./AvatarImage.module.css');

interface IAvatarImageProps {
  model: ModelBase,
  className?: string,
  style?: any,
  withZoom?: boolean,
  withImageDelay?: boolean,
}

interface IAvatarImageState {
  isZoomed: boolean,
  shouldShowImage: boolean,
}

export default class AvatarImage extends ObserverComponent<IAvatarImageProps, IAvatarImageState> {
  static defaultProps = {
    className: '',
  };

  state = { isZoomed: false, shouldShowImage: false };

  imageTimeout;

  handleZoomChange = (isZoomed: boolean) => {
    this.setState({ isZoomed: isZoomed && this.props.withZoom });
  }

  // shouldnt be needed because same component should be reused inside
  componentDidMount() {
    if (!this.props.withImageDelay) {
      this.setState({ shouldShowImage: true });
    }
    this.imageTimeout = setTimeout(() => this.setState({ shouldShowImage: true }), 400);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (!this.props.withImageDelay) {
      return;
    }

    clearTimeout(this.imageTimeout);
  }

  _render() {
    const { isZoomed } = this.state;
    const { model, style, className, withZoom, withImageDelay } = this.props;
    const { shouldShowImage } = this.state;
    const { thumbUrl, imageUrl } = model;

    const innerHtml = (
      <div
        style={{
          ...style,
          ...{
          transition: withImageDelay ? 'opacity 300ms ease-in 200ms' : '',
          opacity: shouldShowImage ? 0.8 : 0,
          backgroundImage: shouldShowImage ? `url(${isZoomed ? imageUrl : thumbUrl})` : ''
        }}}
        className={styles.root + ' ' + className}
      />
    );

    return thumbUrl
      ? (
        withZoom ? (
          <Zoom isZoomed={isZoomed} onZoomChange={this.handleZoomChange}>
            {innerHtml}
          </Zoom>
        ) : innerHtml
      )
      : <ImageIcon />;
  }
}