import { DbLocationType } from 'constants/DbLocationType';
import Client from 'models/Client';
import FirebaseStore from 'stores/FirebaseStore';

// de quessé?
export default class ClientsStore extends FirebaseStore<Client>   {
  storeKey = 'clients';

  dbLocationsTypes = new Set([
    DbLocationType.User, 
  ]);
}
