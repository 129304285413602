import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./UpgradeSubscriptionSuccessDialog.module.scss');

export default class UpgradeSubscriptionSuccessDialog extends ObserverComponent<IDialogProps> {
  _render() {
    const { dialogsStore } = this.context;
    const { open, dialogId } = this.props;
    return (
      <Dialog
        className={styles.root}
        open={open}
      >

        <DialogTitle>
          🎉 {i18n.t('Subscription started successfully')}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText className={styles.content}>
            {i18n.t('Your subscription is now active. Your satisfaction is very important for us. Please let us know of any comments or questions that you might have. You can reach us at 1-866-766-3313 or by pressing the chat button at the top right of the application')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dialogsStore.hideDialog(dialogId)}
            className={styles.actionButton}
            color="primary"
            variant="contained">
            {i18n.t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}