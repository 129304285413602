import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from "draft-js";
import { computed, observable } from "mobx";
import { serializable } from "serializr";
import i18n from 'utils/i18n';
import { localized } from "utils/localized";
import { getDescriptionRichText, getEmptyRichText } from "utils/RichTextUtil";
import Project from './Project';

// TasksList is a special type of Project
export default class TasksList extends Project {
  @serializable type = 'TasksList';


  // because hacked in Project parent class
  @serializable @observable index = 0;

  // doesnt work correctly
  @localized name = 'List';

  shouldCheckMinMaxUserVersion = true;

  // todo: make searchable

  @localized _description = '';
  @computed get description() {
    return this._description || getDescriptionRichText(i18n.language);
  }
  set description(value: string) {
    this._description = value;
  }

  @computed get hasDescription() {
    return this.description !== getDescriptionRichText(i18n.language) && this.description !== getEmptyRichText();
  }

  @localized _description2 = '';

  @computed get description2() {
    return (this.hasDescription && !this._description2)
      ? convertToHTML(convertFromRaw(JSON.parse(this.description))) // convert
      : this._description2;
  }

  set description2(value: string) {
    this._description2 = value;
    if (value && this.hasDescription) {
      this._description = '';
    }
  }


  @serializable @observable _isMultiCategs = false;

  // disabled for now to see if we really need single categ type drag
  @computed get isMultiCategs() {
    return true; //this._isMultiCategs;
  }
  set isMultiCategs(value) {
    this._isMultiCategs = value;
  }
}