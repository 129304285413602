import { DbLocationType } from 'constants/DbLocationType';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { environment } from 'environment';
import { compact, deburr, isEmpty, last, zipObject } from 'lodash';
import MD5 from 'md5.js';
import { action, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import Merchant from 'models/Merchant';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import ProvidingItemMeta from 'models/ProvidingItemMeta';
import FirebaseStore from 'stores/FirebaseStore';
import i18n from 'utils/i18n';
import { guessProvidedQuantities } from 'utils/MaterialCoverageGuesser';
import { getBaseQuantities } from 'utils/ProvidedQuantityUtil';
import { getUnitsByUnitType, getUnitTypeForUnit } from 'utils/UnitFormatter';
import { getElementsFromXpath, getElementsFromXpaths, getInnerTextFromFirstFoundXpath, getInnerTextFromXpath, getInnerTextFromXpaths, getSmallestImageFromSrcSet, waitForXSeconds } from 'utils/Utils';
//import uuidv4 from 'uuid/v4';

export default class MerchantsStore extends FirebaseStore<Merchant> {
  storeKey = 'merchants';
  sortField = 'index';

  dbLocationsTypes = new Set([
    DbLocationType.Master,
    DbLocationType.User,
  ]);

  hasCategories = false;

  @observable isConfiguratorActive = false;

  @observable detectedItem: ProvidingItem = null;

  @observable isIframeReady = false;

  @observable rentalTimeUnit = Unit.Day;

  getMerchantByDomain = computedFn((domain: string) => (
    // allow access to hidden merchants, not deleted ones
    // so we can keep updating prices for merchants that are not supported anymore for new users
    this.allItems.find(item => domain.includes(item.domain) && !item.isDeleted)
  ))

  getMerchantByUrl = computedFn((url: string) => (
    this.getMerchantByDomain(url.match(/https:\/\/w?w?w?\.?([^/]+)/)?.[1] || '')
  ))

  // should use flow instead of async, but need to upgrade to mobx6
  @action
  async createDetectedItemWithRetry(document: HTMLDocument, merchant: Merchant, forceReload = false, numRetries = 10, existingItem: ProvidingItem = null): Promise<ProvidingItem> {
    let item = null;

    for (let i = 0; i < numRetries; i++) {
      item = await this.createDetectedItem(document, merchant, forceReload, existingItem);

      if (item && item.price) {
        return item;
      }

      await waitForXSeconds(1);
    }

    return item;
  }

  // should use flow instead of async, but need to upgrade to mobx6
  @action
  async createDetectedItem(document: HTMLDocument, merchant: Merchant, forceReload = false, existingItem: ProvidingItem = null): Promise<ProvidingItem> {
    const { providingItemsStore } = this.stores;

    if (!merchant || !document || !providingItemsStore.isReady) {
      return null;
    }

    let newItem = new ProvidingItem(this.stores);
    newItem.metaCached = new ProvidingItemMeta(this.stores);

    // language guessing doesn't work for homedepot
    const language = merchant.domain === 'homedepot.ca'
      ? i18n.language
      : (document.documentElement.lang?.substr(0, 2) || i18n.language) as LanguageKey;

    let itemId = getInnerTextFromXpaths(
      document,
      isEmpty(merchant.idXpaths) ? [merchant.idXpath] : merchant.idXpaths,
      undefined,
      undefined,
      false
    )

    if (!itemId) {
      // last resort when site is not very popular and id isnt easily found on page
      itemId = new MD5().update(document.location.pathname).digest('hex'); //uuidv4();
    } else if (merchant.idRegex) {
      // should convert to use capturing parenthesis instead of full match :(
      itemId = new RegExp(merchant.idRegex).exec(itemId)?.[0] || itemId;
    }

    itemId = itemId.replace(/\//g, '%2F').trim();
    if (merchant.isRental) {
      itemId = itemId += '_' + this.rentalTimeUnit;
    }

    const shopifyVariantIndex = itemId.indexOf('variant=');
    if (shopifyVariantIndex > 0) {
      itemId = itemId.slice(shopifyVariantIndex + 'variant='.length);
    }

    itemId = itemId.toLowerCase();

    // homedepot.ca for backward compatibility
    newItem.id = (merchant.domain === 'homedepot.ca' ? 'HOME_DEPOT' : merchant.domain) + ':' + itemId;

    const pageUrl = document.location?.href?.replace(environment.proxySuffix, '') || '';
    existingItem = existingItem || providingItemsStore.getItem(newItem.id) || providingItemsStore.items.find(i => i._url[language] === pageUrl);

    if (existingItem) {
      if (!forceReload) {
        // edge case
        // if item exists but URL was deleted (probably by mistake), restore it 
        if (!existingItem.url) {
          existingItem.url = pageUrl;
        }

        return existingItem;
      }

      await existingItem.waitOnMetaReady();
    }

    // not sure if cloning here is good.. could it create multiple instances of same item?
    // can't use ? null operator because item can be false if deleted
    const item = existingItem && existingItem.clone() || newItem;

    if (merchant.isRental) {
      item.subtype = ProvidingItemSubtype.Labour;
    }

    ['en', 'fr', 'es'].forEach(altLanguage => {
      if (language === altLanguage) {
        item._url[language] = pageUrl;

        // bad
        if (merchant.domain === 'schluter.ca') {
          item._url[language] = item._url[language].replace(/\/p\/(.*)$/, '/p/product?productCode=' + itemId);
        }
      } else {
        let altLanguageUrl = (
          last(getElementsFromXpath(document, `//*[contains(@hreflang, "${altLanguage}")]`) || [])?.href ||
          last(getElementsFromXpath(document, merchant.languageLinkXpath) || [])?.href
        );
        if (altLanguageUrl) {
          item._url[altLanguage] = altLanguageUrl.replace(environment.proxySuffix, '');
        }
      }
    });

    let itemName = getInnerTextFromXpaths(document, merchant.nameXpaths, undefined, undefined, true);
    if (itemName === itemName.toUpperCase() && !merchant.shouldKeepUpperCaseName) {
      const words = itemName.split(' ');
      let lowerCaseName = '';
      // example : KERDI-BOARD MEMBRANE becomes KERDI-BOARD Membrane
      while (words[0]?.includes('-') || words[0]?.match(/[0-9]/)) {
        lowerCaseName += words[0] + ' ';
        words.shift();
      }

      if (words.length) {
        lowerCaseName += words[0].charAt(0) + words[0].slice(1).toLowerCase() + ' ';
        words.shift();
      }

      lowerCaseName += words.map(word => word.toLowerCase()).join(' ');

      itemName = lowerCaseName.trim();
    } else {
      //itemName = itemName.charAt(0) + itemName.slice(1).toLowerCase();
    }

    const suffixXpaths = merchant.nameSuffixXpath ? [merchant.nameSuffixXpath] : merchant.nameSuffixXpaths;
    if (!isEmpty(suffixXpaths) && itemName) {
      const suffix = getInnerTextFromXpaths(document, suffixXpaths, true, '\n', false);
      if (suffix) {
        itemName += ' - ' + suffix.split('\n').filter(token => token.trim()).join(' - ');
      }
    }

    item._name[language] = itemName;
    item.merchantSku = itemId;

    let { priceXpaths } = merchant;


    if (merchant.isRental) {
      item.rentalTimeUnit = existingItem?.rentalTimeUnit || this.rentalTimeUnit;
      const priceXPathIndex = [Unit.Day, Unit.Week, Unit.Month, Unit.Year].indexOf(item.rentalTimeUnit);

      // for rental, need to specify exactly one xpath for day, week and month
      if (priceXpaths.length !== 3) {
        return null;
      }

      priceXpaths = [priceXpaths[priceXPathIndex]];
    }

    const originalPriceString = getInnerTextFromFirstFoundXpath(document, priceXpaths) || '';
    let priceString = originalPriceString;

    if (merchant.priceSearch) {
      priceString = priceString.replace(new RegExp(merchant.priceSearch, 'msg'), merchant.priceReplace);
    }
    priceString = priceString.replace(/,/g, '.')?.replace(/[^0-9.–\-]+/g, '');

    item.price = priceString.includes('–') ? 0 : parseFloat(priceString || '0');

    item.price = item.price / merchant.priceDivider;

    item.priceUpdatedMiliseconds = Date.now();

    const imageElement = getElementsFromXpaths(document, isEmpty(merchant.imageXpaths) ? [merchant.imageXpath] : merchant.imageXpaths)?.[0];

    let imageUrl = (
      imageElement?.src || 
      imageElement?.content || 
      imageElement?.href || 
      imageElement?.style?.backgroundImage?.match?.(/url\("?'?([^\)"']+)"?'?\)/)?.[1] ||
      ''
    );

    if (merchant.imageSearch && imageUrl) {
      imageUrl = imageUrl.replace(new RegExp(merchant.imageSearch), merchant.imageReplace);
    }

    const thumbUrl = (
      (merchant.thumbXpath && getElementsFromXpath(document, merchant.thumbXpath)?.[0]?.src) ||
      (merchant.thumbXpath && getElementsFromXpath(document, merchant.thumbXpath)?.[0]?.content) ||
      (merchant.thumbSearch && imageUrl?.replace(new RegExp(merchant.thumbSearch), merchant.thumbReplace)) ||
      (imageElement?.srcset && getSmallestImageFromSrcSet(imageElement.srcset)) ||
      imageUrl
    );

    if (imageUrl) {
      imageUrl = imageUrl.replace(/\?.*$/, '');
      item._imageUrls[language] = [imageUrl];
    }

    if (thumbUrl) {
      item._thumbUrls[language] = [thumbUrl];
    }

    item.metaCached._description[language] = merchant.descriptionXpaths.map(xpath => getInnerTextFromXpath(document, xpath)).join('\n');

    item.unitString = getInnerTextFromXpath(document, merchant.unitStringXpath)?.replace("/", '')?.trim() || i18n.tAll('Each')[language] || '';

    // launch providing quantities detection (async)

    let specsString = merchant.specsXpaths
      .map(xpath => getInnerTextFromXpath(document, xpath, true, false))
      .join('\n');

    const tokens = compact(specsString.split(/\n|:/).map(t => t.trim()));

    const specs = zipObject(
      tokens.filter((token, index) => index % 2 == 0),
      tokens.filter((token, index) => (index + 1) % 2 == 0)
    );

    if (merchant.isRental) {
      const providedQuantities = getBaseQuantities();
      providedQuantities.set(UnitType.Time, new ProvidedQuantity(UnitType.Time, 1, item.rentalTimeUnit, 90));
      item.providedQuantities = providedQuantities;
      item.labourRate = item.price;
    } else {
      guessProvidedQuantities(item, specs, item._name[language] + '\n' + item.metaCached._description[language] + '\n' + specsString, language, merchant);
    }

    const surfaceProvidedQuantity = item.providedQuantities.get(UnitType.Surface);
    if (merchant.shouldMultiplyPriceByQuantityInRegex && surfaceProvidedQuantity?.confidencePercentage >= 80) {
      item.price = item.price * surfaceProvidedQuantity.quantity
    }

    const unit = [
      ...getUnitsByUnitType().get(UnitType.Unit),
      ...getUnitsByUnitType().get(UnitType.Pack)
    ].find(u => {
      const translatedStrings = Object.values(i18n.tAll(u)).map(u => deburr(u).replace(/[^a-zA-Z]/g, '').replace(/s$/, ''));
      return translatedStrings.includes(deburr(item.unitString));
    });

    if (unit) {
      const providedQuantity = item.providedQuantities.get(getUnitTypeForUnit(unit)) || new ProvidedQuantity(getUnitTypeForUnit(unit), 1);
      providedQuantity.unit = unit;
      item.providedQuantities.set(getUnitTypeForUnit(unit), providedQuantity);
    }

    // dirty hack for converting maconnex inches measurements
    if (merchant.lengthCoverageUnit === Unit.Inch && item.providedQuantities.get(UnitType.Length)) {
      item.providedQuantities.get(UnitType.Length).unit = Unit.Inch;
      item.providedQuantities.get(UnitType.Length).quantity *= 12;
    }


    if (item.name && item.price && !item.url?.includes(item?.id?.split(':')?.[1]?.replace('-', '') || '')) {
      //debugger;
    }

    if (item.name) {
      return item;
    }

    return null;
  }
}

