
import { IconButton } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import * as React from 'react';
import { InputNumberFormatInteger } from 'utils/NumberFormatter';
import TextField, { ITextFieldProps } from '../TextField/TextField';

const styles = require('./IntegerField.module.scss');

// BAD NAME, not always integer, can be decimal, but has the + - buttons to increment by 1

// Input field with plus and minus buttons
export default class IntegerField extends React.Component<ITextFieldProps> {
  handleChange = (changeAmount: number) => (event: React.MouseEvent<HTMLElement>) => {
    const { onChange, value } = this.props;
    const newValue = (parseInt(value as string) || 0) + changeAmount;
    // @ts-ignore
    onChange && onChange({ target: { value: newValue, formattedValue: newValue.toString() } } );
  }

  render() {
    return (
      <div className={this.props.className + ' ' + styles.root}>
        <TextField
          shouldSelectAllOnFocus
          {...this.props}
          className={styles.integerField}
          InputProps={{
            inputComponent: InputNumberFormatInteger,
            startAdornment: <IconButton className={styles.minusButton} onClick={this.handleChange(-1)}><MinusIcon /></IconButton>,
            endAdornment: <IconButton className={styles.plusButton} onClick={this.handleChange(+1)}><PlusIcon /></IconButton>
          }}
        />
      </div>
    );
  }
}