import { Button, Checkbox, IconButton } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/LibraryBooksOutlined';
import ListIcon from '@material-ui/icons/List';
import Globals from 'Globals';
import classnames from 'classnames';
import { BuiltinTaskCategories } from 'constants/BuiltinCategories';
import { CategoryType } from 'constants/CategoryType';
import { compact, isEmpty } from 'lodash';
import { computed } from 'mobx';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import Task from 'models/Task';
import * as React from 'react';
import { formatCurrency } from 'utils/NumberFormatter';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import ConfirmDeleteButton from '../ConfirmDeleteButton/ConfirmDeleteButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { CollapseAllIcon, ExpandAllIcon } from '../Icons';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import { copyTasksFromProjectToList } from '../TasksListsList/TasksListUtil';
;

const colors = require('../../../Colors.scss');
const styles = require('./TasksHeader.module.scss');

interface TasksHeaderState {
  isCategoryFilterEnabled: boolean,
}

export default class TasksHeader extends ObserverComponent<{}, TasksHeaderState> {
  state = {
    isCategoryFilterEnabled: false,
  }

  componentDidMount(): void {
      window.document.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount(): void {
      window.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.target.tagName !== 'BODY') {
      return;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
      this.handleSelectedChange(null, true);
    } else if (e.key === 'Escape') {
      this.handleSelectedChange(null, false);
    }
  }

  toggleCheckboxes = () => {
    const { tasksStore } = this.context;
    tasksStore.isSelectingWithCheckboxes = !tasksStore.isSelectingWithCheckboxes;
  }

  toggleDimensionsColumn = () => {
    const { tasksStore } = this.context;
    tasksStore.isTaskMeasurementColumnVisible = !tasksStore.isTaskMeasurementColumnVisible
  }

  // duplicate
  handleSelectedChange = (event, checked) => {
    const { tasksStore, treeNodesStore } = this.context;

    const tasks = getSafe(() => treeNodesStore.selectedTreeNode.ownTasks);
    tasks.forEach(
      task => checked
        ? tasksStore.selectedItems.add(task)
        : tasksStore.selectedItems.delete(task)
    );
  }

  deleteZeroValueTasks = () => {
    this.askToDeleteTasks(this.zeroValueTasks, i18n.t('Do you really want to delete all tasks with a quantity of 0?'));
  }

  deleteTasks = (tasks: Task[]) => () => {
    const { treeNodesStore, tasksStore } = this.context;
    const { ownTasks } = treeNodesStore.selectedTreeNode;
    const taskIdsToDelete = [];
    tasks.forEach(task => {
      taskIdsToDelete.push(task.id);
      const taskIndexInSelectedNode = ownTasks.indexOf(task);

      if (taskIndexInSelectedNode >= 0) {
        ownTasks.splice(taskIndexInSelectedNode, 1);
      }
    });

    treeNodesStore.addEditItem(treeNodesStore.selectedTreeNode);
    tasksStore.deleteItems(taskIdsToDelete);

    tasksStore.selectedItems.clear();
  }

  askToDeleteTasks = (tasks: Task[], title = i18n.t('Do you really want to delete all selected tasks?')) => {
    const { treeNodesStore, tasksStore, dialogsStore } = this.context;
    // TODO confirm dialog until we have undo feature

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        open={open}
        dialogId={newDialog.id}
        onConfirm={this.deleteTasks(tasks)}
        title={title}
        actionButtonColor={colors.red}
        yesLabel={i18n.t('Delete')}
      />
    );
    dialogsStore.showDialog(newDialog);
  }

  toggleFilter = () => {
    const { categoriesStore } = this.context;
    const isCategoryFilterEnabled = !this.state.isCategoryFilterEnabled;
    this.setState({
      isCategoryFilterEnabled
    });

    if (!isCategoryFilterEnabled) {
      categoriesStore.categoryBeingFilteredIn = null;
    }
  }

  addCategoryToTasksList = () => {
    const { categoriesStore, treeNodesStore } = this.context;
    const newCateg = categoriesStore.getItem(BuiltinTaskCategories.NewCategory);
    newCateg.index = -1; // make sure always first;
    const categoriesIds = treeNodesStore.selectedTreeNode.ownTaskCategoriesIds;

    if (!categoriesIds.includes(newCateg.id)) {
      categoriesIds.unshift(newCateg.id);
    }

    treeNodesStore.addEditItem(treeNodesStore.selectedTreeNode);
    categoriesStore.taskCategoryBeingEdited = newCateg;
  }

  onChangeCategoryFilter = (category: Category) => {
    const { categoriesStore } = this.context;
    categoriesStore.categoryBeingFilteredIn = category;
  }

  @computed get zeroValueTasks() {
    const { treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    return selectedTreeNode?.ownTasks?.filter(task => selectedTreeNode.measurementValues.get(task.measurementId)?.metricValue === 0) || [];
  }

  _render() {
    const { tasksStore, treeNodesStore, categoriesStore } = this.context;
    const { isSelectingWithCheckboxes } = tasksStore;
    const { selectedTreeNode } = treeNodesStore;
    const { selectedItems } = tasksStore;
    const { isCategoryFilterEnabled } = this.state;
    let numberOfItems = 0, price = 0;
    const tasks = getSafe(() => selectedTreeNode.ownTasks) || [];

    // duplicate
    numberOfItems = tasks.filter(task => task && !task.isSeparator).length;
    price = tasks.reduce((total, task) => (
      total + task.price
    ), 0);

    const isChecked = tasks.length > 0 && tasks.every(task => selectedItems.has(task));
    const isIndeterminate = !isChecked && tasks.some(task => selectedItems.has(task));

    const shouldShowBatchTasksTools = isChecked || isIndeterminate;

    const hasMoreThanOneTaskCategories = treeNodesStore.selectedNodeCategories.length > 1;


    const selectedTasksMenuItems = compact([
      !isEmpty(this.zeroValueTasks) && { icon: <DeleteIcon />, text: i18n.t('Delete tasks with a quantity of 0'), handler: this.deleteZeroValueTasks },
    ]);

    return (
      <div className={styles.root}>
        <div className={styles.title}>
          {selectedTreeNode && true /*isSelectingWithCheckboxes*/ && (
            <Checkbox
              className={styles.checkbox}
              // duplicate
              // weird material UI feature that indeterminate needs to also be checked
              checked={isChecked || isIndeterminate}
              indeterminate={isIndeterminate}
              onChange={this.handleSelectedChange}
            />
          )}

          <ListIcon className={styles.icon} />

          {i18n.t('Work To Do')}
          {/*<IconButton className={styles.button}>
          <FunnelIcon />
        </IconButton>*/}

          {/*<FormControl margin="normal">
                {//<InputLabel htmlFor="filled-age-simple">Type</InputLabel>
              }
                <Select
                  native
                  value={0}
                  //onChange={this.handleChange}
                  input={<FilledInput name="age" id="filled-age-simple" className={styles.textFieldNoLabel} />}
                >
                  {// todo: waste dropdown should include a button to waste by categories menu
                  }
                  <option value={0} key={0}>Toutes les tâches</option>
                </Select>
              </FormControl>*/}
        </div>

        {shouldShowBatchTasksTools ? (
          <div className={styles.buttons}>
            {this.context === Globals.defaultStores && (
              <Button onClick={() => copyTasksFromProjectToList(this.context)} className={styles.button}>
                <CopyIcon />
                {i18n.t('Copy to List')}
              </Button>
            )}

            <ConfirmDeleteButton
              onClick={this.deleteTasks(tasksStore.selectedItemsArray)}
              className={styles.button + ' ' + styles.deleteButton}
              text={i18n.t('deleteTask', { count: selectedItems.size })}>
              <DeleteIcon />
            </ConfirmDeleteButton>

            {!isEmpty(selectedTasksMenuItems) && (
              <MenuPopupButton
                className={styles.moreButton}
                menuItems={selectedTasksMenuItems}
              />
            )}
          </div>
        ) : (
          <>
            {isCategoryFilterEnabled && (
              <CategoriesCombobox
                className={styles.filterCombobox}
                categoryTypes={[CategoryType.Task]}
                category={categoriesStore.categoryBeingFilteredIn}
                onChange={this.onChangeCategoryFilter}
                shouldFocusOnMount
              />
            )}

            {/* disabled for now
            <IconButton className={styles.funnelIcon} onClick={this.toggleFilter}
            >
              {isCategoryFilterEnabled
                ? <FunnelRemoveIcon className={categoriesStore.categoryBeingFilteredIn ? styles.funnelRemove : ''} />
                : <FunnelIcon />
              }
            </IconButton>
  */}

            <div className={styles.buttons}>
              {/*<IconButton className={styles.button} onClick={this.toggleDimensionsColumn}>
                <SettingsIcon />
              </IconButton> */
                // should be replaced by clicking on separator bar
              }

              {/*<IconButton className={styles.button} onClick={this.toggleCheckboxes}>
                <CheckboxIcon />
            </IconButton>*/}

              {<IconButton className={styles.button} onClick={this.addCategoryToTasksList}>
                {/*<CategoryAddIcon />*/}
                {<GroupAddIcon />}
                {/*i18n.t('Add a category')*/}
              </IconButton>}

              {hasMoreThanOneTaskCategories && (
                <IconButton
                  className={classnames(
                    styles.button,
                    styles.expandAllButton,
                  )}
                  onClick={categoriesStore.toggleAllTaskCategories}
                >
                  {categoriesStore.areAllVisibleTaskCategoriesCollapsed ? <ExpandAllIcon /> : <CollapseAllIcon />}
                </IconButton>
              )}
            </div>

            {<div className={styles.details}>
              {formatCurrency(price || 0)}
            </div>}
          </>
        )}

      </div>
    );
  }
}