import { IconButton } from '@material-ui/core';
import { IDialogProps } from 'constants/CommonProps';
import { times } from 'lodash';
import ModelBase from 'models/ModelBase';
import ProvidingItem from 'models/ProvidingItem';
import moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import i18n from 'utils/i18n';
import XLSX from 'xlsx';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import { ExcelIcon } from '../Icons';
import ObserverComponent from '../ObserverComponent';
import PriceUpdateDialogDetailsForm from '../PriceUpdateDialogDetailsForm/PriceUpdateDialogDetailsForm';

const styles = require('./PriceUpdateDialog.module.scss');

export default class PriceUpdateDialog extends ObserverComponent<IDialogProps> {
  dialogRef;

  onClose = (shouldSave: boolean, modelCopies: ModelBase[]) => {
    if (!shouldSave) {
      return;
    }
    const { priceUpdateStore, providingItemsStore } = this.context;
    const { priceUpdate } = priceUpdateStore;
    const { priceMap, _priceMapUpdatedMiliseconds } = priceUpdate;

    (modelCopies as ProvidingItem[]).forEach(item => {
      if (!item.url) {
        return;
      }

      item.previousPrice = item.price;
      item.price = priceMap.get(item.id);
      item.priceUpdatedMiliseconds = _priceMapUpdatedMiliseconds.get(item.id) || 0;

      // special case, master item has a new URL
      
      /*
      // disabled because overwrites users changing the merchants for an item
      // should reactivate only for same merchant
      // but only applies to 1 or 2 items so not crucial
      const masterUrl = providingItemsStore.cachedMasterData[item.id]?._url;
      if (masterUrl?.fr && masterUrl?.fr !== item._url?.fr) {
        item._url = masterUrl;
        item._name = providingItemsStore.cachedMasterData[item.id]._name;
      }
      */
    });

    // editdialogcomponent will take care of saving each items

    trackInteraction(TrackedInteractions.ApplyPriceUpdate);
  }

  exportToExcel = () => {
    const table = ReactDOM.findDOMNode(this.dialogRef)?.querySelector('table#priceupdates');

    if (!table) {
      return;
    }

    const { projectsStore } = this.context;

    const workbook = XLSX.utils.table_to_book(table);
    workbook.Sheets.Sheet1['!cols'] = [{ wch: 100 }];

    XLSX.writeFile(workbook, `${projectsStore.selectedProject?.name} - ${i18n.t('Price Changes')} - ${moment().format("LL")}.xlsx`);
  }

  // NON REACTIVE, because of infinite loop
  render() {
    const { priceUpdateStore, providingItemsStore, userInfoStore } = this.context;
    const { open, dialogId } = this.props;

    const { priceMap } = priceUpdateStore.priceUpdate;
    const itemsIdsToUpdate = Array.from(priceMap.keys());

    const canExportExcelPriceChanges = (
      userInfoStore.user?.canExportExcelPriceChanges ||
      userInfoStore.user.email === 'batimenttricolor@hotmail.com' // can't figure out why his account keeps resetting the flag
    );

    return (
      <EditDialogComponent
        ref={ref => this.dialogRef = ref}
        dialogId={dialogId}
        open={open}
        modelCopies={priceUpdateStore.priceUpdate.outdatedItems.map(i => i.clone())}
        canModelsBeEmpty
        stores={times(itemsIdsToUpdate.length, () => providingItemsStore)}
        FormComponent={PriceUpdateDialogDetailsForm}
        saveButtonLabel={i18n.t('Update all prices')}
        extraButtons={canExportExcelPriceChanges && (
          <div className={styles.extraButtons}>
            <IconButton className={styles.excelButton} onClick={this.exportToExcel}>
              <ExcelIcon />
            </IconButton>
          </div>
        )}
        onClose={this.onClose}
        title={i18n.t('Price Changes')}
      />
    );
  }
}