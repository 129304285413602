import ModelBase from "models/ModelBase";
import { serializable } from "serializr";
import { observable } from "mobx";

// was gonna be used for transfering a task between projects
// keeping until sure this won't be used
export default class CopiedTask extends ModelBase {
  @serializable @observable type = 'CopiedTask';

  // storing the id instead of the object, because deserializing
  // task from a different project will not be possible with regular store
  // that points on selected project only

  @serializable @observable projectId;
  @serializable @observable taskId;
}