export enum CategoryType {
  Material = 'Material',
  Labour = 'Labour',
  Task = 'Task',
  Measurement = 'Measurement',
  TasksList = 'TasksList',
  Project = 'Project',
}

// used when creating new category
export function getDefaultCategoryTypes(categoryType?: CategoryType): CategoryType[] {
  switch (categoryType) {
    case CategoryType.Material:
    case CategoryType.Labour:
    case CategoryType.Project:
      return [categoryType];

    case CategoryType.Measurement:
    case CategoryType.TasksList:
      return [CategoryType.Measurement, CategoryType.TasksList];

    default:
      return Object.values(CategoryType);
  }
}