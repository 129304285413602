import { compact, first, isEmpty, last } from 'lodash';
import Category from 'models/Category';
import ModelBaseWithCategory from 'models/ModelBaseWithCategories';
import FirebaseStore from 'stores/FirebaseStore';
import SearchableFirebaseStore from 'stores/SearchableFirebaseStore';
import { IFlattenedItemsByCategSubcateg, filterByCategory } from './CategoryUtil';
import { modelSortFunction } from './Utils';


export function getKeyFromFlattenedItem(flattenedItemWithCategory: IFlattenedItemsByCategSubcateg<ModelBaseWithCategory>, index: number) {
  return (
    (flattenedItemWithCategory?.category?.id || '') +
    (flattenedItemWithCategory?.subcategory?.id || '') +
    (flattenedItemWithCategory?.item?.id || '') +
    index
  );
}


export function isCategoryChecked<T extends ModelBaseWithCategory>(
  store: FirebaseStore<T>,
  ungroupedItems: T[],
  category: Category,
  subcategory?: Category,
  subsubcategory?: Category,
): { checked: boolean, indeterminate: boolean } {
  const { selectedItems } = store;
  const itemsToCheck = filterByCategory(ungroupedItems, category, subcategory, subsubcategory);

  const checked = !isEmpty(itemsToCheck) && itemsToCheck.every(item => selectedItems.has(item));
  const indeterminate = !isEmpty(itemsToCheck) && itemsToCheck.some(item => selectedItems.has(item)) && !checked;

  return { checked: checked || indeterminate, indeterminate };
}

export function handleCategoryToggle<T extends ModelBaseWithCategory>(
  store: FirebaseStore<T>,
  ungroupedItems: T[],
  category: Category,
  subcategory?: Category,
  subsubcategory?: Category,
) {
  return (event) => {
    const { checked, indeterminate } = isCategoryChecked(store, ungroupedItems, category, subcategory, subsubcategory);
    const items = filterByCategory(ungroupedItems, category, subcategory, subsubcategory);

    items.forEach(item => (checked && !indeterminate)
      ? store.selectedItems.delete(item)
      : store.selectedItems.add(item)
    );
  }
}

export function handleCheckItem<T extends ModelBaseWithCategory>(store: SearchableFirebaseStore<T>, item: T) {
  return (event, isChecked) => {

    if (isChecked) {
      if (
        event.nativeEvent.shiftKey &&
        store.selectedItems.size > 0 &&
        store.selectedItemsArray.every(selectedItem => (
          selectedItem.categoryId === item.categoryId &&
          selectedItem.subcategoryId === item.subcategoryId
        ))
      ) {
        // duplicate 
        const itemsByCategSubcategFlattened = store.itemsByCategSubcategFlattened;
        const selecteditems = compact(store.selectedItemsArray.slice(0).sort(modelSortFunction));
        const firstSelectedItemIndex = itemsByCategSubcategFlattened.findIndex(row => row.item === first(selecteditems));
        const lastSelectedItemIndex = itemsByCategSubcategFlattened.findIndex(row => row.item === last(selecteditems));
        const currentTaskIndex = itemsByCategSubcategFlattened.findIndex(row => row.item === item);


        if (currentTaskIndex > firstSelectedItemIndex) {
          for (let i = firstSelectedItemIndex; i <= currentTaskIndex; i++) {
            itemsByCategSubcategFlattened[i].item && store.selectedItems.add(itemsByCategSubcategFlattened[i].item);
          }
        } else {
          for (let i = lastSelectedItemIndex; i >= currentTaskIndex; i--) {
            itemsByCategSubcategFlattened[i].item && store.selectedItems.add(itemsByCategSubcategFlattened[i].item);
          }
        }
      }
      store.selectedItems.add(item)
    } else {
      store.selectedItems.delete(item)
    }
  }
}
