
import { IconButton, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { UnitType } from 'constants/UnitType';
import { every } from 'lodash';
import { observer } from 'mobx-react';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import * as React from 'react';
import i18n from 'utils/i18n';
import { InputNumberFormatDecimal } from 'utils/NumberFormatter';
import { handleProvidedQuantityChange } from 'utils/ProvidedQuantityUtil';
import Button from '../Button/Button';
import Table from '../Table/Table';
import TextField from '../TextField/TextField';
import UnitComboBox from '../UnitComboBox/UnitComboBox';

const styles = require('./MaterialDetailsQuantityTable.module.scss');

interface IMaterialDetailsQuantityTableProps {
  material: ProvidingItem,
}

@observer
export default class MaterialDetailsQuantityTable extends React.Component<IMaterialDetailsQuantityTableProps> {
  addRow = () => {
    const { material } = this.props;
    if (material.providedQuantities.get(UnitType.Unknown)) {
      return;
    }

    const newProvidedQuantity = new ProvidedQuantity();
    newProvidedQuantity.rowIndex = material.providedQuantities.size;
    material.providedQuantities.set(UnitType.Unknown, new ProvidedQuantity());
  }

  deleteRow = (providedQuantity: ProvidedQuantity) => () => {
    const { material } = this.props;
    material.providedQuantities.delete(providedQuantity.unitType);
  }

  render() {
    const { material } = this.props;
    const { providedQuantities } = material;

    // Add a rowIndex to avoid rows moving while editing
    let providedQuantitiesArray = Array.from(providedQuantities.values());
    if (every(providedQuantitiesArray, quantity => quantity.rowIndex === 0)) {
      providedQuantitiesArray
        .sort((a, b) => a.confidencePercentage - b.confidencePercentage)
        .forEach((quantity, index) => {
          quantity.rowIndex = index;
        });
    }
    const sortedQuantities = providedQuantitiesArray
      .sort((a, b) => b.rowIndex - a.rowIndex);

    return (
      <div className={styles.root}>
        <Table>

          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell className={styles.tableHeaderQuantity} align="right">{i18n.t('Quantity provided')}</TableCell>
              <TableCell className={styles.tableHeaderUnit}>{i18n.t('Unit')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {
              sortedQuantities.map(providedQuantity => {
                // only allow to select unitTypes not already defined for material (ex: Area can only be select in one row)
                // todo: quantity variable/class name is confusing since it's an object not a quantity number
                // ignore own unittype
                const otherQuantitiesUnitTypes = sortedQuantities
                  .filter(quantity => quantity !== providedQuantity)
                  .map(qty => qty.unitType);
                const unitTypeOptions = Object.values(UnitType)
                  .filter(unitType => !otherQuantitiesUnitTypes.includes(unitType))

                return (
                  <TableRow
                    key={providedQuantity.unitType}
                  >
                    {/* convert to react-format ? */}
                    <TableCell align="right">
                      <TextField
                        id={`providing_item_quantity_${providedQuantity.unitType}`}
                        label=""
                        className={styles.textField + ' ' + styles.tableValueQuantity}
                        value={providedQuantity.quantity}
                        onChange={handleProvidedQuantityChange(material, providedQuantity, 'quantity')}
                        margin="none"
                        inputProps={{ style: { textAlign: 'right' } }}
                        InputProps={{
                          inputComponent: InputNumberFormatDecimal,
                        }}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <UnitComboBox
                        className={styles.unitComboBox}
                        value={providedQuantity.unit}
                        onChange={handleProvidedQuantityChange(material, providedQuantity, 'unit')}
                        margin="none"
                        unitTypeOptions={unitTypeOptions}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="rowHoverButtons">
                        <IconButton onClick={this.deleteRow(providedQuantity)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Button onClick={this.addRow} className={styles.addQuantityButton}>
          <AddIcon />&nbsp;
          {i18n.t('Add a quantity')}
        </Button>
      </div>

    )
  }
}