import classnames from 'classnames';
import ProvidingItem from 'models/ProvidingItem';
import * as React from 'react';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';
import ProvidingItemComponent from '../ProvidingItemComponent/ProvidingItemComponent';


const styles = require('./DraggableProvidingItem.module.scss');

// used as a list item renderer, so I think we are limited to one required param
interface IDraggableProvidingItem {
  item: ProvidingItem,
  isEditable?: boolean,
}

export default class DraggableProvidingItem extends ObserverComponent<IDraggableProvidingItem> {
  static defaultProps = {
    isEditable: true
  }

  onDragStart = (item: ProvidingItem) => {
    const { providingItemsStore, userInfoStore } = this.context;
    const { dragAndDropStore } = this.context;
    const { user } = userInfoStore;

    // ensure always item from user collection unless it doesnt exist
    // not great because can't use a project version item elsewhere in the project
    // it will always get overwritten by the user collection.
    // keeping it as is now, because asked by only customer that uses the feature
    dragAndDropStore.dragObject = (
      (!user.shouldAutoSyncProvidingItems && providingItemsStore.userCollectionItemsMap.get(item?.id)) ||
      providingItemsStore.getItem(item?.id) ||
      item
    );
  }

  onDragEnd = (event: React.DragEvent<HTMLElement>) => {
    const { dragAndDropStore } = this.context;
    dragAndDropStore.dragObject = null;
  }

  _render() {
    const { providingItemsStore } = this.context;
    const { item, isEditable } = this.props;

    const isEditing = getSafe(() => (
      providingItemsStore.providingItemBeingEdited.id === item.id
    ));

    return (
      <div
        className={classnames(
          styles.draggableItem, {
          [styles.isEditing]: isEditing,
          [styles.isTrialLocked]: item.isTrialLocked
        }
        )}
        draggable={!isEditing && !item.isTrialLocked}
        onDragStart={(event) => {
          this.onDragStart(item);
        }}
        onDragEnd={this.onDragEnd}
      >

        <div className={styles.lockOverlay} style={{display: item.isTrialLocked ? 'flex': 'none'}}>
          {i18n.t('Unavailable in trial version')}
        </div>

        <ProvidingItemComponent
          className={styles.item}
          providingItem={item}
          imageSize={{ w: 35, h: 35 }}
          isEditable={isEditable}
        />
      </div>
    );
  }
}
