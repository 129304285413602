
import { SvgIcon as MuiSvgIcon, SvgIconProps } from '@material-ui/core';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
const colors = require('Colors.scss');

const styles = require('./SvgIcon.module.scss');

// allows rendering to img tag instead of svg tag, because when exporting to PDF,
// only img tags are supported by kendow draw
export default class SvgIcon extends ObserverComponent<SvgIconProps> {
  _render() {
    const { component, children, className, style } = this.props;

    const svgComponent = <MuiSvgIcon {...this.props} component="svg" />;
    const svgString = btoa(unescape(encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${colors.gray3}">${
        ReactDOMServer.renderToStaticMarkup(children)
      }</svg>`
    )));

    if (component === 'img') {
      return (
        <img
          style={{
            width: 24,
            height: 24,
            ...style,
          }}
          className={className + ' ' + styles.root}
          src={'data:image/svg+xml;base64,' + svgString}
        />
      );
    }

    return svgComponent;
  }
}