
import DragHandleIcon from '@material-ui/icons/DragIndicator';
import * as classnames from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const styles = require('./OrderableItem.module.scss');

interface OrderableItemProps {
  id: string,
  index: number,
  children: JSX.Element[] | JSX.Element | ((dragHandle: JSX.Element) => JSX.Element),
  className?: string,
}

export default class OrderableItem extends React.PureComponent<OrderableItemProps> {
  render() {
    const { id, index, children, className } = this.props;

    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => {
          const dragHandle = (
            <div
              className={classnames('dragHandle', styles.dragHandle)}
              {...provided.dragHandleProps}
            >
              <DragHandleIcon />
            </div>
          );

          return (
            <li
              id={id}
              className={className + ' ' + styles.root}
              {...provided.draggableProps}
              ref={provided.innerRef}
            //isDragging={snapshot.isDragging}
            >
              {isFunction(children) && children(dragHandle)}

              {!isFunction(children) && dragHandle}
              {!isFunction(children) && children}
            </li>
          );
        }}
      </Draggable>
    );
  }
}