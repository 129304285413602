
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./ReportPhotos.module.scss');
const stylesContent = require('../ReportContentList/ReportContentList.module.scss');

interface ReportPhotosProps {
  nodesWithPhotos: TreeNode[]
}

export default class ReportPhotos extends ObserverComponent<ReportPhotosProps> {
  _render() {
    const { treeNodesStore } = this.context;
    const { nodesWithPhotos } = this.props;

    return (
      <div className={styles.root}>
        <div className="force-page-break" />

        <table className={classnames(
          'ReportContentList ',
          stylesContent.root,
        )}>
          <thead>
            <tr className={classnames(
              stylesContent.groupRow,
              stylesContent.indentLevel0,
              stylesContent.isEmphasized,
            )}>
              <th className={stylesContent.groupName}>{i18n.t('Photos')}</th>
            </tr>
            <tr className={stylesContent.spacingHeaderRow}><th>&nbsp;</th></tr>
          </thead>
          {nodesWithPhotos.map(node => (
            <React.Fragment key={`thead${node.id}`}>
              <tbody className="keep-together">
                <tr
                  className={classnames(
                    stylesContent.groupRow,
                    {
                      [stylesContent.isSemiEmphasized]: false,
                      [stylesContent.isEmphasized]: false,
                    })}>
                  <th className={stylesContent.groupName} >
                    {treeNodesStore.getPath(node)}
                  </th>
                </tr>
                <tr
                  className={classnames(
                    stylesContent.task,
                    stylesContent.indentLevel0,
                  )}
                >
                  <td className={stylesContent.providingItemCell} style={{ textAlign: 'center' }}>
                    <img className={styles.image} src={node.imageUrl} />
                  </td>
                </tr>
              </tbody>
            </React.Fragment>
          ))}

        </table>

      </div>
    )
  }
}