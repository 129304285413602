import { compact, deburr, first } from 'lodash';
import Stores from 'stores/Stores';
import { getSafe } from './Utils';

export function encodeProjectName(projectName) {
  return encodeURIComponent(
    deburr(projectName).replace(/[-%]/g, '_').replace(/[`‘’`´’‛‘"]/g, "'").replace(/[\s|/\\]/g, '-').toLowerCase()
  );
}

export function onAddOrDeleteImage(context: Stores) {
  const { treeNodesStore, projectsStore } = context;
  const { selectedProject } = projectsStore;

  const firstNodeWithImage = first(treeNodesStore.allNodes.filter(node => node.imageUrl && !node.shouldSkipImageInReport));

  selectedProject.__thumbUrl = {};
  selectedProject.__imageUrl = {};


  selectedProject.imageUrls = compact([getSafe(() => firstNodeWithImage.imageUrl)]);
  selectedProject.thumbUrls = compact([getSafe(() => firstNodeWithImage.thumbUrl)]);
  projectsStore.addEditItem(selectedProject, true, ['__imageUrl', '__thumbUrl', '_imageUrls', '_thumbUrls']);
}