import { memoize } from 'lodash';
import Line from "models/Line";
import Point from "models/Point";

export const offsetLine = memoize((line: Line, isClockwise = true, offsetDistance = 40.0): Line => {
  const dy = line.endPt.y - line.startPt.y
  const dx = line.endPt.x - line.startPt.x;
  const angle = Math.atan2(dy, dx) + (isClockwise ? 1 : -1) * Math.PI / 2.;

  return new Line(line.stores,
    new Point(
    line.startPt.x + offsetDistance * Math.cos(angle),
    line.startPt.y + offsetDistance * Math.sin(angle),
  ), new Point(
    line.endPt.x + offsetDistance * Math.cos(angle),
    line.endPt.y + offsetDistance * Math.sin(angle),
  ));
}, (line: Line, isClockwise = true, offsetDistance = 40.0) => {
  return [line.startPt.x, line.startPt.y, line.endPt.x, line.endPt.y, isClockwise, offsetDistance].join('#');
});

export function findCentroid(pts: Point[]) {
    var first = pts[0], last = pts[pts.length-1];
    if (first.x != last.x || first.y != last.y) pts.push(first);
    var twicearea=0,
    x=0, y=0,
    nPts = pts.length,
    p1, p2, f;
    for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
       p1 = pts[i]; p2 = pts[j];
       f = p1.x*p2.y - p2.x*p1.y;
       twicearea += f;
       x += ( p1.x + p2.x ) * f;
       y += ( p1.y + p2.y ) * f;
    }
    f = twicearea * 3;
    return new Point(x/f, y/f);
 }

export function isClockwise(pts: Point[]): boolean {
    let surface = 0;         // Accumulates area in the loop
    let j = pts.length - 1;  // The last vertex is the 'previous' one to the first

    for (let i = 0; i < pts.length; i++) {
      surface = surface +  (pts[j].x + pts[i].x) * (pts[j].y- pts[i].y);
        j = i;  //j is previous vertex to i
      }
    return surface/2 > 0;
}