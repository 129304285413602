
import * as React from 'react';

interface TypographyProps {
  className?: string,
  style?: any,
  children: any,
  variant: 'subtitle2'
}

export const Typography = React.memo(({ className, style, children, variant }: TypographyProps) => {
  switch (variant) {
    default:
      return (
        <h6 className={(className || '') + ' ' + 'MuiTypography-root MuiTypography-subtitle2'} style={style}>
          {children}
        </h6>
      );
  }
})
