import { copyTasksFromProjectToList } from 'components/common/TasksListsList/TasksListUtil';
import { DbLocationType } from 'constants/DbLocationType';
import { compact, isEmpty } from 'lodash';
import { observable, reaction } from 'mobx';
import TasksList from 'models/TasksList';
import { sleep } from 'utils/Utils';
import SearchableFirebaseStore from './SearchableFirebaseStore';

export default class TasksListsStore extends SearchableFirebaseStore<TasksList>{
  storeKey = 'projects';

  dbLocationsTypes = new Set(compact([
    DbLocationType.Master, 
    DbLocationType.User, 
    this.accountParam && DbLocationType.ExternalUser,
  ]));

  shouldTrackCreationDate = true;
  shouldTrackModifDate = true;

  queryFilters = new Map([['type', 'TasksList']])
  
  @observable tasksListBeingEdited: TasksList = null;

  listComponentRef = null;

  @observable isCopyingList = false;


  async DEBUG__getAllItemsUsedInTasksLists() {
    const itemsIds = new Set();

    await this.items.reduce(async (previousTask, tasksList) => {
      await previousTask;
      const tasks = await this.db.collection(`${this.dbLocation}/${tasksList.id}/tasks`).get();

      tasks.docs.forEach(task => {
        itemsIds.add(task.data().providingItemId);
      })

      sleep(100);
      return '';
    }, Promise.resolve(''))

    debugger;
    return Array.from(itemsIds);
  }

  async DEBUG___CopyAllProjectTasksToLists() {
    const { treeNodesStore } = this.stores;
      
     await [treeNodesStore.rootNode, ...treeNodesStore.rootNode.nonDrawingDescendants].reduce(async (previousTask, node) => {
      await previousTask;

      // using same id for projet node and taskslist, just easier to update tasklist this way from a project
      let targetTasksList = this.getItem(node.id);
      
      if (!targetTasksList) {
        targetTasksList = new TasksList(this.stores);
        targetTasksList.id = node.id;
        targetTasksList.name = node.name;
      }
      
      if (!isEmpty(node.ownTasks)) {
        await copyTasksFromProjectToList(this.stores,node, node.ownTasks, targetTasksList);
      }

      return '';
    }, Promise.resolve(''));
  }


  // this ensures this store loads last (long to load)
  attemptLoadItems() {
    if (!this.stores.providingItemsStore.isReady) {
      return;
    }

    super.attemptLoadItems();
  }

  // could be good but prevent  to load on projects list ;page
  priorityLoadCheck = reaction(() => (
    this.stores?.providingItemsStore?.isReady && 
    this.stores?.routerStore?.queryParamsSettings?.get?.('mode') !== 'rescue'
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}
