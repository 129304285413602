// TODO separate components

import { Checkbox, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragIndicator';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import HelpIcon from '@material-ui/icons/Info';
import InsertUnderIcon from '@material-ui/icons/PlaylistAdd';
import * as classnames from 'classnames';
import EmptyMaterialComponent from 'components/common/EmptyMaterialComponent/EmptyMaterialComponent';
import EmptyMeasurementComponent from 'components/common/EmptyMeasurementComponent/EmptyMeasurementComponent';
import MeasurementComponent from 'components/common/MeasurementComponent/MeasurementComponent';
import ProvidingItemComponent from 'components/common/ProvidingItemComponent/ProvidingItemComponent';
import { compact, first, last, omit } from 'lodash';
import { action } from 'mobx';
import Measurement from 'models/Measurement';
import ModelBase from 'models/ModelBase';
import ProvidingItem from 'models/ProvidingItem';
import Task, { TaskSubtypes } from 'models/Task';
import TasksList from 'models/TasksList';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { DragDropContext, DragStart, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as VirtualizedList } from "react-window";
import { DraggableTypes } from 'stores/DragAndDropStore';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { duplicateMeasurements } from 'utils/MeasurementUtil';
import { getSafe, modelSortFunction } from 'utils/Utils';
import i18n from 'utils/i18n';
import DroppableDiv from '../DroppableDiv/DroppableDiv';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import TaskCategoryComponent from '../TaskCategoryComponent/TaskCategoryComponent';
import TaskSeparator from '../TaskSeparator/TaskSeparator';
import { copyTasksFromListToProject } from '../TasksListsList/TasksListUtil';

const styles = require('./Tasks.module.scss');

// CLEANUP NEEDED

class EmptyListEntry2 extends ObserverComponent {

  _render() {
    const { dragAndDropStore } = this.context;
    const { dragObject } = dragAndDropStore;

    return (dragObject instanceof Measurement || dragObject instanceof ProvidingItem) ? (
      <div className={styles.emptyListEntry}>
        <div className={`${styles.arrow} ${styles.top}`}><img src="/images/tut-arrow.png" /></div>
        <div className={styles.emptyListText}>
          {i18n.t('Glisser vers la catégorie pour créer une nouvelle Tâche')}
        </div>
      </div>
    ) : (
      <div className={styles.emptyListEntry}>
        <img src="/images/tut-arrow.png" className={`${styles.arrow} ${styles.left}`} />
        <div className={styles.emptyListText}>
          <div>
            <span>
              {i18n.t('Drag a measurement from the left bar to create a task. You can also click on button "Add Task".')}
              <IconButton className={styles.infoButton}>
                <HelpIcon />
              </IconButton>
            </span>
          </div>
        </div>

        <img src="/images/tut-arrow.png" className={`${styles.arrow} ${styles.right}`} />
      </div>
    );
  }
}

const NUM_TASKS_TO_RENDER_PHASE1 = 99999;

interface IListEntryProps {
  task: Task;
  treeNode: TreeNode;
  taskIndex: number;
}

interface IListEntryState {
  shouldRenderPhase1: boolean,
  shouldRenderPhase2: boolean,
}

class ListEntry extends ObserverComponent<IListEntryProps, IListEntryState> {
  state = {
    shouldRenderPhase1: false,
    shouldRenderPhase2: false
  };

  timer1Id;
  timer2Id;

  elementRef;

  componentDidMount() {
    const { taskIndex } = this.props;

    // render first tasks first (still needed???)
    if (taskIndex > NUM_TASKS_TO_RENDER_PHASE1) {
      // seems faster to check ref, than to clearTimeout in willUnmount
      this.timer1Id = setTimeout(() => this.elementRef && this.setState({ shouldRenderPhase1: true }), (taskIndex % NUM_TASKS_TO_RENDER_PHASE1) * 30);
    }
    this.timer2Id = setTimeout(() => this.elementRef && this.setState({ shouldRenderPhase2: true }), 300 + (taskIndex % NUM_TASKS_TO_RENDER_PHASE1) * 40);
  }

  handleSelectedChange = (event, checked) => {
    const { tasksStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    const { task } = this.props;

    if (checked) {
      if (event.nativeEvent.shiftKey && tasksStore.selectedItems.size > 0) {
        const tasksByCategSubcategFlattened = treeNodesStore.selectedNodeOwnTasksByCategFlattened;
        const selectedTasks = compact(tasksStore.selectedItemsArray.slice(0).sort(modelSortFunction));
        const firstSelectedTaskIndex = tasksByCategSubcategFlattened.findIndex(row => row.item === first(selectedTasks));
        const lastSelectedTaskIndex = tasksByCategSubcategFlattened.findIndex(row => row.item === last(selectedTasks));
        const currentTaskIndex = tasksByCategSubcategFlattened.findIndex(row => row.item === this.props.task);


        if (currentTaskIndex > firstSelectedTaskIndex) {
          for (let i = firstSelectedTaskIndex; i <= currentTaskIndex; i++) {
            tasksByCategSubcategFlattened[i].item && tasksStore.selectedItems.add(tasksByCategSubcategFlattened[i].item);
          }
        } else {
          for (let i = lastSelectedTaskIndex; i >= currentTaskIndex; i--) {
            tasksByCategSubcategFlattened[i].item && tasksStore.selectedItems.add(tasksByCategSubcategFlattened[i].item);
          }
        }
      }
      tasksStore.selectedItems.add(this.props.task);
    } else {
      tasksStore.selectedItems.delete(this.props.task);
    }
  }

  duplicateTask = action(() => {
    const taskCopy = this.props.task.cloneWithNewId();
    if (taskCopy.measurement?.isOneTimeUse) {
      const measurementCopy = first(duplicateMeasurements([taskCopy.measurement], this.context)());
      taskCopy.measurement = measurementCopy;
    }
    this.insertTaskUnderExistingTask(taskCopy);
  })

  insertTaskUnderExistingTask = action((newTask = new Task(this.context)) => {
    const { tasksStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    const existingTask = this.props.task;
    newTask.category = existingTask.category;
    selectedTreeNode.ownTasksIds.push(newTask.id);

    const batch = firestoreBatch();
    tasksStore.addEditItem(newTask, true, undefined, batch);
    treeNodesStore.addEditItem(selectedTreeNode, true, undefined, batch);

    tasksStore.reorderItem(newTask, existingTask, selectedTreeNode.ownTasks.sort(modelSortFunction), true, batch);

    batch.commit();
  })

  _render() {
    const { tasksStore, providingItemsStore, treeNodesStore } = this.context;
    let { shouldRenderPhase1, shouldRenderPhase2 } = this.state;
    const { taskBeingEdited, isSelectingWithCheckboxes, isTaskMeasurementColumnVisible } = tasksStore;
    const { providingItemBeingEdited } = providingItemsStore;
    const { task, treeNode, taskIndex } = this.props;
    const otherProps = omit(this.props, ['task', 'treeNode', 'ref']);

    shouldRenderPhase1 = shouldRenderPhase1 || taskIndex <= NUM_TASKS_TO_RENDER_PHASE1;

    const measurement = task.measurement;
    //const measurementValue = measurement && treeNode.measurementValues.get(measurement);
    const measurementValue = measurement && treeNode.measurementValues.get(measurement.id);
    const isSeparator = task.subtype === TaskSubtypes.Separator;

    const menuItems = [
      { icon: <DuplicateIcon />, text: i18n.t('Duplicate'), handler: this.duplicateTask },
      { icon: <InsertUnderIcon />, text: i18n.t('Insert under this task'), handler: () => this.insertTaskUnderExistingTask() },
      {
        icon: <DeleteIcon />,
        text: i18n.t('Delete'),
        handler: () => {
          treeNodesStore.deleteSelectedNodeTask(this.props.task.id);
        },
        danger: true
      },
    ];

    return (
      // deleteItem temporary
      <div
        ref={ref => this.elementRef = ref}
        {...otherProps}
        className={classnames(
          styles.listItem, {
          [styles.separatorRow]: isSeparator,
          [styles.isMeasurementColumnVisible]: isTaskMeasurementColumnVisible,
          [styles.hasItemsChecked]: tasksStore.selectedItems.size > 0,
          [styles.highlightNewTask]: !task.measurement && !task.providingItem && (Date.now() - task.createdMiliseconds) < 1000
        })}
      >
        <div className={styles.hoverToolsLeft}>
          {shouldRenderPhase2 && <Checkbox className={styles.checkbox} checked={tasksStore.selectedItems.has(task)} onChange={this.handleSelectedChange} />}
        </div>
        {(isSeparator)
          ? <div className={styles.taskSeparator}><TaskSeparator task={task} /></div>
          : (<>
            {shouldRenderPhase1 && (
              measurement
                ? (
                  <MeasurementComponent
                    className={styles.measurement}
                    task={task}
                    measurement={measurement}
                    measurementValue={measurementValue}
                    treeNode={treeNode}
                    isEditable={!measurementValue?.adjustmentValue && !task?.adjustmentValue}
                  />
                ) : <EmptyMeasurementComponent className={styles.measurement} task={task} />
            )}
            <div className={styles.middleLineTd}><div /></div>
            <div className={styles.material}>
              {shouldRenderPhase1 && (
                task.providingItem
                  ? <ProvidingItemComponent providingItem={task.providingItem} parentTask={task} isEditable />
                  : <EmptyMaterialComponent task={task} />
              )}
            </div>
          </>)}
        {(task?.providingItem?.id !== providingItemBeingEdited?.id || taskBeingEdited?.id !== task?.id) && (
          <div className={styles.hoverToolsRight}>
            {shouldRenderPhase2 && <MenuPopupButton menuItems={menuItems} />}
          </div>
        )}
      </div>
    )

  }
}

function getId(object: ModelBase) {
  return object.type + '_' + object.id;
}

interface TasksProps {
  tasks?: Task[],
}

interface TasksState {
  isDraggingTasks: boolean
}

class DraggableRow extends ObserverComponent<{ data, index: number, style: any }> {
  _render() {
    const { style, index } = this.props;
    const { treeNodesStore } = this.context;
    const itemStruct = treeNodesStore.selectedNodeOwnExpandedTasksByCategFlattened[index];

    if (!itemStruct) {
      debugger;
      return null;
    }

    const { item: task, category } = itemStruct;
    const rowId = itemStruct.category?.id + '_' + treeNodesStore.selectedTreeNodeId + '_' + itemStruct.item?.id;
    const isDragDisabled = !itemStruct.item;

    return (
      <Draggable
        isDragDisabled={isDragDisabled}
        draggableId={rowId}
        index={index}
        key={rowId}
        type={DraggableTypes.Tasks}
      >
        {provided => (
          <Row isDragDisabled={isDragDisabled} provided={provided} index={index} style={style} />
        )}
      </Draggable>
    );
  }
}

class Row extends ObserverComponent<{ index: number, style: any, provided: any, isDragDisabled: boolean, isDragging: boolean }> {
  _render() {
    const { index, style, provided, isDragDisabled, isDragging } = this.props;
    const { treeNodesStore, tasksStore, categoriesStore } = this.context;
    const itemStruct = treeNodesStore.selectedNodeOwnExpandedTasksByCategFlattened[index];

    if (!itemStruct) {
      debugger;
      return null;
    }

    const { item: task, category } = itemStruct;

    const indexInWindow = style.top / style.height;

    return (
      <div
        className={styles.taskRow}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={{
          ...style,
          ...provided.draggableProps.style,
          ...(tasksStore.taskBeingEdited === task
            ? { zIndex: 2, height: 'auto' }
            : {}
          )
        }}
      >
        {!isDragDisabled && (
          <div
            className={classnames('dragHandle', styles.dragHandle)}
            {...provided.dragHandleProps}
          >
            <DragHandleIcon />
          </div>
        )}
        {categoriesStore.isReady && (
          itemStruct.item
            ? (
              <ListEntry
                //key={task.id}
                task={task}
                taskIndex={isDragging ? 0 : indexInWindow}
                treeNode={treeNodesStore.selectedTreeNode}
              />
            ) : itemStruct.category
              ? (
                <TaskCategoryComponent
                  category={category}
                  tasks={treeNodesStore.getSelectedNodeOwnTasksForCategory(category)}
                />
              ) : (
                <EmptyListEntry2 />
              )
        )}
      </div>
    );
  }
}

export default class Tasks extends ObserverComponent<TasksProps, TasksState> {
  state = {
    isDraggingTasks: false,
  }

  previousCategories = null;
  listRef;

  onDragStart = (event: DragStart) => {
    if (event.type === DraggableTypes.Tasks) {
      this.setState({ isDraggingTasks: true });
    }
  }

  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    this.setState({ isDraggingTasks: false });

    const { categoriesStore, treeNodesStore, tasksStore } = this.context;
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    const itemId = last(draggableId.split('_'));

    if (result.type === DraggableTypes.Tasks) {
      let tasksAndCategs = treeNodesStore.selectedNodeOwnExpandedTasksByCategFlattened;

      let previousTaskIndex = (
        destination.droppableId !== source.droppableId ||
        destination.index < source.index
      )
        ? destination.index - 1
        : destination.index;

      let destinationCategoryIndex = previousTaskIndex;

      let previousTaskStruct = getSafe(() => tasksAndCategs[previousTaskIndex]);
      let destinationCategoryStruct = getSafe(() => tasksAndCategs[previousTaskIndex]);

      // skip categories to find a task
      while (previousTaskStruct && !previousTaskStruct.item) {
        previousTaskIndex--;
        previousTaskStruct = getSafe(() => tasksAndCategs[previousTaskIndex]);
      }

      // skip tasks to find category
      while (destinationCategoryStruct && destinationCategoryStruct.item) {
        destinationCategoryIndex--;
        destinationCategoryStruct = getSafe(() => tasksAndCategs[destinationCategoryIndex]);
      }

      const task = tasksStore.getItem(itemId);

      const category = destinationCategoryStruct?.category || first(tasksAndCategs).category;

      let previousTask = previousTaskStruct?.item;

      task.category = category;

      if (previousTask?.category !== category) {
        // means task is going in first index of destination category
        previousTask = null;
      }

      if (categoriesStore.collapsedTaskCategories.has(category)) {
        categoriesStore.collapsedTaskCategories.delete(category);
      }

      tasksStore.reorderItem(
        task,
        previousTask,
        treeNodesStore.getSelectedNodeOwnTasksForCategory(category)
      );
    }
  }

  onDropTasksListWithMultiCategs = () => {
    const { dragAndDropStore, treeNodesStore } = this.context;
    copyTasksFromListToProject(
      dragAndDropStore.dragObject as TasksList,
      treeNodesStore.selectedTreeNode,
      this.context,
    );
  }

  _render() {
    const { treeNodesStore, dragAndDropStore, tasksStore, tasksListsStore } = this.context;
    const { isDraggingTasks } = this.state;
    const { selectedTreeNode } = treeNodesStore;
    const { dragObject } = dragAndDropStore;

    let tasksList: TasksList;
    if (dragObject instanceof TasksList) {
      tasksList = dragObject;
    }

    if (selectedTreeNode && getSafe(() => tasksList.isMultiCategs)) {
      // dragging taskslist with multiple categories
      return (
        <DroppableDiv
          className={styles.isDraggingTasksListWithMultiCategs + ' ' + styles.tip}
          onDrop={this.onDropTasksListWithMultiCategs}
          shouldEnable
        >
          {i18n.t('Glissez ici pour insérer cette liste')}
        </DroppableDiv>
      );
    }
    const itemsByCategSubcategFlattened = treeNodesStore.selectedNodeOwnExpandedTasksByCategFlattened;

    const { taskBeingEdited } = tasksStore;
    let taskBeingEditedIndex = -1, tempHeightIndex = -1;
    if (taskBeingEdited) {
      taskBeingEditedIndex = itemsByCategSubcategFlattened.findIndex(i => i.item === taskBeingEdited)
    }

    tempHeightIndex = Object.values(tasksStore.tasksComponentRef?._instanceProps?.itemMetadataMap || {}).findIndex(i => i.size !== 65);

    if (tempHeightIndex === -1 && taskBeingEditedIndex !== -1) {
      tasksStore.tasksComponentRef.resetAfterIndex(taskBeingEditedIndex);
    } else if (tempHeightIndex !== -1) {
      tasksStore.tasksComponentRef.resetAfterIndex(
        taskBeingEditedIndex === -1
          ? tempHeightIndex
          : Math.min(taskBeingEditedIndex, tempHeightIndex)
      );
    }

    // drag drop reorder inside virtual list
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/virtual-lists.md

    return selectedTreeNode
      ? (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div style={{ width: '100%', flex: 1 }} >
            <LoadingOverlay isVisible={tasksListsStore.isCopyingList} />

            <AutoSizer>
              {({ height, width }) => (
                <Droppable
                  droppableId="tasks"
                  mode="virtual"
                  renderClone={(provided, snapshot, rubric) => (
                    <Row isDragging={snapshot.isDragging} index={rubric.source.index} provided={provided} style={{ width: 300, height: 65 }} />
                  )}
                  type={DraggableTypes.Tasks}
                >{provided => (
                  <VirtualizedList
                    ref={ref => tasksStore.tasksComponentRef = ref}
                    //className={classnames(rootClasses, styles.isVirtualizedList)}
                    width={width}
                    height={height}
                    itemCount={itemsByCategSubcategFlattened.length}
                    itemSize={index => (
                      (taskBeingEdited && itemsByCategSubcategFlattened[index].item === taskBeingEdited)
                        ? 125
                        : 65
                    )
                    }
                    overscanCount={10}
                    outerRef={provided.innerRef}
                  >
                    {DraggableRow}
                  </VirtualizedList>
                )}
                </Droppable>
              )}
            </AutoSizer>
          </div>
        </DragDropContext>
      ) : (
        <div className={styles.tip}>
          {i18n.t('Select a shape to display work to do')}
          <IconButton className={styles.infoButton}>
            <HelpIcon />
          </IconButton>
        </div>
      );
  }
}