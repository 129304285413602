import { FilterDateOptions } from "constants/FilterConstants";

export function applyFilterDateOption(date: number, filterDateOption: FilterDateOptions) {
  const MILISECONDS_PER_MONTH = 30 * 24 * 60 * 60 * 1000;

  switch (filterDateOption) {
    case FilterDateOptions.MoreThanOneMonth:
      return (Date.now() - date) > MILISECONDS_PER_MONTH;
    case FilterDateOptions.MoreThanThreeMonths:
      return (Date.now() - date) > 3 * MILISECONDS_PER_MONTH;
    case FilterDateOptions.MoreThanSixMonths:
      return (Date.now() - date) > 6 * MILISECONDS_PER_MONTH;
    case FilterDateOptions.MoreThanOneYear:
      return (Date.now() - date) > 12 * MILISECONDS_PER_MONTH;
    default:
      return true;
  }
}