
import { Button } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ObserverComponent from 'components/common/ObserverComponent';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { RightBarTabs } from 'constants/RightBarTabs';
import * as React from 'react';
import { InputNumberFormatHourlyRate } from 'utils/NumberFormatter';
import i18n from 'utils/i18n';
import TextField from '../TextField/TextField';

const styles = require('./ReplaceLabourRatesComponent.module.scss');

interface ReplaceLabourRatesComponentState {
  isEditMode: boolean,
  currentRate: number,
  newRate: number,
}

export default class ReplaceLabourRatesComponent extends ObserverComponent<{}, ReplaceLabourRatesComponentState> {
  state = {
    isEditMode: false,
    numReplacementsMade: null,
    currentRate: null,
    newRate: null,
  }

  handleChange = (stateKey: keyof ReplaceLabourRatesComponentState) => (event) => {
    const value = event?.value ?? event?.target?.value;

    this.setState({ [stateKey]: value });
  };

  handleReplace = () => {
    const { providingItemsStore, settingsStore } = this.context;
    const { currentRate, newRate } = this.state;
    const numReplacementsMade = providingItemsStore.updateLabourRate(currentRate, newRate);

    this.setState({ numReplacementsMade });

    settingsStore.settings.selectedRightBarTab = RightBarTabs.MY_ITEMS;
    providingItemsStore.subtypeFilter = ProvidingItemSubtype.Labour;
  };

  _render() {
    const { isEditMode, numReplacementsMade, currentRate, newRate } = this.state;
    return (
      <div className={styles.root}>
        {isEditMode && (
          <div className={styles.editMode}>
            <div className={styles.title}>
              {i18n.t('Replace hourly rates')}
            </div>
            <div className={styles.instructions}>{i18n.t('Enter current rate to look for and the new rate to use as a replacement. If you have items that use multiple of the rates, make sure to update them as well. For example, if you replace 75$/h, you might want to also replace 150$/h (2 workers).')}</div>
            <div className={styles.components}>
              <TextField
                label={i18n.t('Current rate')}
                InputProps={{
                  inputComponent: InputNumberFormatHourlyRate
                }}
                value={currentRate}
                onChange={this.handleChange('currentRate')}
                shouldSelectAllOnFocus
              />
              <ArrowRightIcon />
              <TextField
                label={i18n.t('New rate')}
                InputProps={{
                  inputComponent: InputNumberFormatHourlyRate
                }}
                value={newRate}
                onChange={this.handleChange('newRate')}
                shouldSelectAllOnFocus
              />
              <Button
                className={styles.replaceAllButton}
                disabled={!currentRate || !newRate}
                onClick={this.handleReplace}
                variant="contained"
                color="primary"
              >
                {i18n.t('Replace')}<br />{i18n.t('all')}
              </Button>
            </div>

            {numReplacementsMade !== null && (
              <div className={styles.replacementsMade}>{numReplacementsMade}&nbsp;{i18n.t(' replacement(s) made.')}</div>
            )}
          </div>
        )}

        {!isEditMode && (
          <a
            className={styles.goToEditModeButton} onClick={() => { this.setState({ isEditMode: true }) }}>
            {i18n.t('Hourly rates replacement tool')}
          </a>
          /*
          <Button className={styles.goToEditModeButton} onClick={() => { this.setState({ isEditMode: true }) }} variant="contained">
            {i18n.t('Replace labour rates')}
          </Button>*/
        )
        }
      </div>
    )
  }
}