import { CategoryType } from "constants/CategoryType";
import { compact, uniqBy } from 'lodash';
import { action, computed, observable } from "mobx";
import ModelBase from "models/ModelBase";
import { list, primitive, serializable } from "serializr";
import Stores from 'stores/Stores';
import { getSafe } from "utils/Utils";

export default class Category extends ModelBase {
  @observable @serializable type = 'Category';
  @observable @serializable(list(primitive())) categoryTypes: CategoryType[] = Object.values(CategoryType);

  @observable @serializable  merchantUrl: string = '';

  // hack in order to avoid duplicating categories depending on which locale is currently loaded
  @observable @serializable  originalName: string = '';

  // only applies to measurement categories
  @observable @serializable _legendColor: string = '';
  @computed get legendColor(): string {
    return [...this.parentCategory?.categoryTypes || [], ...this.categoryTypes].includes(CategoryType.Measurement) && this._legendColor;
  }
  set legendColor(value: string) {
    this._legendColor = value;
  }

  @serializable(list(primitive()))
  @observable childrenIds: ModelId[] = [];

  @computed get children(): Category[] {
    const { categoriesStore } = this.stores;
    return uniqBy(compact([
      categoriesStore.generalCategory,
      ...this.childrenIds.map(subcategId => categoriesStore.getItem(subcategId))
    ]), 'id');
  }

  // problem with "general" subcategory and measurement dynamic subcategs which have many categs as parent
  @computed get parentCategory(): Category {
    const { categoriesStore } = this.stores;
    return getSafe(() => categoriesStore.items
      .find(category => category.children.map(categ => categ.id).indexOf(this.id) >= 0)
    ) || categoriesStore.getItem(this.parentCategoryId) || null;
  }

  @serializable @observable parentCategoryId: ModelId = '';

  constructor(stores: Stores, name?: string, categoryTypes?: CategoryType[], id?: ModelId, index = 0) {
    super(stores);

    if (categoryTypes) {
      this.categoryTypes = categoryTypes;
    }

    if (name) {
      this.name = name;
    }

    // allow setting id to null
    if (id !== undefined) {
      this.id = id;
    }

    if (index) {
      this.index = index;
    }
  }

  @action
  addSubcategoryIfNotExists = (subcategory: Category) => {
    if (!this.children.find(currentSubcateg => getSafe(() => currentSubcateg.id === subcategory.id))) {
      this.childrenIds.push(subcategory.id);
    }
  }
}
