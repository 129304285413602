
import { Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@material-ui/core';
import { IDialogProps } from 'constants/CommonProps';
import { ActionInProgress } from 'models/ActionInProgress';
import * as React from 'react';
import ObserverComponent from '../ObserverComponent';

interface IProgressDialogProps extends IDialogProps {
  actionInProgress: ActionInProgress,
}

export default class ProgressDialog extends ObserverComponent<IProgressDialogProps> {
  _render() {
    let { actionInProgress, open } = this.props;
    const { percentCompleted } = actionInProgress;

    return (
      <Dialog
        open={open}
      >
        <DialogTitle>
          {actionInProgress.message}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <LinearProgress variant={percentCompleted ? 'determinate' : 'indeterminate'} value={percentCompleted} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }
}