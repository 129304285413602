import { ProvidingItemSubtype } from "constants/ProvidingItemConstants";
import { sumBy } from "lodash";
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";
import Category from "models/Category";
import MeasurementValue from "models/MeasurementValue";
import Task from "models/Task";
import TreeNode from "models/TreeNode";
import Stores from "stores/Stores";
import { sumPrices } from "utils/CurrencyUtil";
import { getSafe } from "utils/Utils";

export default class ReportHeaderRow {
    stores: Stores;

    type = 'ReportHeaderRow';
    indentLevel: number;
    name: string;
    isEmphasized: boolean;
    isSemiEmphasized: boolean;
    measurementsToDisplay?: MeasurementValue[];
    node: TreeNode;
    category?: Category;
    
    @observable tasks: Task[] = [];

    // Duplicate from TreeNode, Report, how to have it in only one place?
    getSubtotal = (
        subtype: ProvidingItemSubtype = null,
        category: Category = null,
        shouldIncludeFees = this.stores.commonStore.shouldIncludeFeesInTasks, // fees only included in report page....
    ) => this.computedGetSubtotal(subtype, category, shouldIncludeFees);

    // computed function doesn't allow for default params
    // CHECK ABOVE for params default values!
    computedGetSubtotal = computedFn((
        subtype: ProvidingItemSubtype,
        category: Category,
        shouldIncludeFees,
    ): number => {
        return sumPrices(
            this.tasks.filter(task => (
                (!subtype || getSafe(() => task.providingItem.subtype === subtype)) &&
                (!category || getSafe(() => task.category === category))
            ))
                .map(task => shouldIncludeFees ? task.priceWithFees : task.price)
        );
    })

    @computed get subtotalLabourBeforeFees(): number {
        return this.getSubtotal(ProvidingItemSubtype.Labour, null, false);
    }

    @computed get subtotalMaterialBeforeFees(): number {
        return this.getSubtotal(ProvidingItemSubtype.Material, null, false);
    }

    @computed get subtotalBeforeFees(): number {
        return this.getSubtotal(null, null, false);
    }

    @computed get subtotalLabourWithFees(): number {
        return this.getSubtotal(ProvidingItemSubtype.Labour, null, true);
    }

    @computed get subtotalMaterialWithFees(): number {
        return this.getSubtotal(ProvidingItemSubtype.Material, null, true);
    }
    /*
        @computed get subtotalWithFees(): number {
            const { settingsStore, totalsStore } = this.stores;
            return settingsStore.settings?.areFeesIncludedInItemPrice
                ? this.subtotalLabourWithFees + this.subtotalMaterialWithFees
                : this.subtotalBeforeFees + totalsStore.feesTotal;
        }*/

    @computed get subtotalLabour(): number {
        return this.getSubtotal(ProvidingItemSubtype.Labour);
    }

    @computed get subtotalMaterial(): number {
        return this.getSubtotal(ProvidingItemSubtype.Material);
    }

    @computed get subtotal(): number {
        //return this.getSubtotal();
        return this.subtotalLabour + this.subtotalMaterial;
    }

    @computed get subtotalHours(): number {
        return sumBy(this.tasks, task => task.hours);
    }

    constructor(stores:Stores) {
        this.stores = stores;
    }
}