import ObserverComponent from 'components/common/ObserverComponent';
import * as React from "react";
import { getSafe, trySetTimeout } from "utils/Utils";

export class SelectShapeTool extends ObserverComponent/* implements Add ? */ {
  svgTag: SVGSVGElement;

  componentDidMount() {
    //this.svgTag.addEventListener('click', this.onClick);
    this.context.drawToolsStore.addEventListener('click', this.onShapeClick);
    this.context.drawToolsStore.addEventListener('doubleclick', this.onShapeDoubleClick);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.context.drawToolsStore.removeEventListener('click', this.onShapeClick);
    this.context.drawToolsStore.removeEventListener('doubleclick', this.onShapeDoubleClick);

    //this.svgTag.removeEventListener('click', this.onClick);
  }

  onShapeClick = (event: CustomEvent) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;
    const { shape, originalEvent } = event.detail;

    const node = treeNodesStore.getNodeForShape(shape);

    if (shapesStore.isDragPanningOnDrawing) {
      return;
    }

    if (false /* multiselect disabled for now */ && (originalEvent.metaKey || originalEvent.shiftKey || originalEvent.ctrlKey)) {
      treeNodesStore.selectedTreeNodes = [...treeNodesStore.selectedTreeNodes, node];
    } else {

      let timeout = 150;
      if (node.parent && !node.parent.isExpanded) {
        timeout = 400;
        node.parent.isExpanded = true;
      }
      treeNodesStore.selectedTreeNodes = [node];

      treeNodesStore.expandOrCollapseAllNodes(true);

      trySetTimeout(() => {
        treeNodesStore.treeComponentRef.scrollTo({ key: node?.id });
      }, timeout)
    }
  }

  onShapeDoubleClick = (event: CustomEvent) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;
    const { shape, originalEvent } = event.detail;

    const node = treeNodesStore.getNodeForShape(shape);

    if (
      // not possible in a real double click, but sometimes double click behavior is called from single click (BAD)
      shapesStore.isDragPanningOnDrawing
    ) {
      return;
    }

    const nodeToSelect = node.ancestors.find(ancestorNode => !ancestorNode.isRootNode && ancestorNode.nonOneTimeUseMeasurementValuesArray.length > 0) || node.parent;

    if (false /* multiselect disabled for now */ && originalEvent.metaKey || originalEvent.shiftKey || originalEvent.ctrlKey) {
      treeNodesStore.selectedTreeNodes = [...treeNodesStore.selectedTreeNodes, nodeToSelect];
    } else {
      treeNodesStore.selectedTreeNodes = [nodeToSelect];
    }

    if (!nodeToSelect.isRootNode) {
      nodeToSelect.isExpanded = false;
    }

    treeNodesStore.expandOrCollapseAllNodes(true);

    let timeout = 150;
    if (node.parent && !node.parent.isExpanded) {
      timeout = 400;
      node.parent.isExpanded = true;
    }

    trySetTimeout(() => {
      treeNodesStore.treeComponentRef.scrollTo({ key: nodeToSelect?.id });
    }, timeout)
  }

  public _render() {
    return (
      <g id="SelectShapeTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement)} />
    );
  }
}
