import ObserverComponent from 'components/common/ObserverComponent';
import TextField from 'components/common/TextField/TextField';
import { IClassNameProps, IEditableProps, IReportProps } from 'constants/CommonProps';
import * as React from 'react';
import { handleChange } from 'utils/FormUtils';
import i18n from 'utils/i18n';

const styles = require('./ReportPreparedBy.module.css');

export default class ReportPreparedBy extends ObserverComponent<IReportProps & IEditableProps & IClassNameProps> {
  saveToDb = () => {
    const { reportsStore } = this.context;
    const { report } = this.props;
    reportsStore.addEditItem(report, true, ['preparedBy']);
  }

  _render() {
    const { isEditable, report, className } = this.props;
    const { userInfoStore } = this.context;

    const preparedBy = report?.preparedBy === 'undefined'
      ? userInfoStore?.user?.displayName
      : report?.preparedBy;

    return (isEditable && userInfoStore.user) ? (
      <TextField
        className={className}
        label={i18n.t('Prepared by')}
        value={preparedBy}
        onChange={handleChange(report, 'preparedBy')}
        onBlur={this.saveToDb}
      />
    ) : (
      <div className={styles.root}>
        {preparedBy && (
          <>
            <span className={styles.label}>{i18n.t('Prepared by')}</span>
            &nbsp;
            <span className={styles.value}>{preparedBy}</span>
          </>
        )}
      </div>
    )
  }
}