import { compact, debounce, isEmpty, throttle } from 'lodash';
import { computed, observable } from "mobx";
import Point from 'models/Point';
import { Rectangle } from 'models/Rectangle';
import Shape from 'models/Shape';
import * as Rematrix from 'rematrix';
import Stores from 'stores/Stores';
import { screenToLocalPoint } from './Coords';
import { getScaleToFit } from './ShapeUtil';
import { getSafe } from "./Utils";

/**
 *
 * @param {type} scrollContainer
 * @param {type} zoomContainer
 * @param {type} objectToZoom
 * @returns {SZoom}
 */

export class SZoom {
  @observable _isDragPanningStarted = false;

  @computed get isDragPanningStarted() {
    return this._isDragPanningStarted;
  }

  set isDragPanningStarted(value: boolean) {
    if (this.stopDragPanningTimer && value) {
      clearTimeout(this.stopDragPanningTimer);
      this.stopDragPanningTimer = null;
    }

    if (!value) {
      this.stopDragPanningTimer = setTimeout(
        () => { this.isDragPanning = false; },
        300
      );
    }

    this._isDragPanningStarted = value;
  }


  @observable isDragPanning = false; // started and has moved a certain amount
  @observable _isZooming = false;

  @computed get isZooming() {
    return this._isZooming;
  }
  set isZooming(value) {
    if (this._isZooming !== value) {
      this._isZooming = value;
    }

    if (value) {
      this.autoResetZooming();
    }
  }
  autoResetZooming = debounce(
    () => {
      this._isZooming = false;
    },
    700,
    { leading: false, trailing: true }
  );

  @observable viewBoxRectX = 0;
  @observable viewBoxRectY = 0;

  @observable observableScale = 1;

  // scale seems to be reversed (higher scale number is smaller shape)
  // https://zellwk.com/blog/css-translate-values-in-javascript/
  get scale() {
    return this.transform[0];
  }

  get translateX() {
    return this.transform[12]
  }

  get translateY() {
    return this.transform[13]
  }

  paddingRight = 0;
  paddingBottom = 0;

  hasScrollbarX = false;
  hasScrollbarY = false;

  scaleFactor = 1.5;

  lastWheelEvent = new Date();
  isAnimating = false;
  lastKnownCursorPos = { x: null, y: null };

  lastMovementX = 0;
  lastMovementY = 0;

  momentumAnimationId = null;
  accelerationAnimationId = null;

  scrollContainer;
  objectToZoom: SVGSVGElement;

  transform = Rematrix.identity();

  get minimumScale() {
    /*const scale = 0.90 * Math.min(this.scrollContainer.clientWidth / this.objectToZoom.clientWidth, this.scrollContainer.clientHeight / this.objectToZoom.clientHeight);

      if (scale) {
        return scale;
      }*/

    return 0.08;
  }

  stopDragPanningTimer;

  resizeObserver;

  stores: Stores;

  previousPinchScale = 1;
  previousBoundingBox = null as Rectangle;

  constructor(scrollDiv, objToZoom, stores: Stores) {
    this.scrollContainer = scrollDiv;
    this.objectToZoom = objToZoom;
    this.stores = stores;

    this.adjustSvgSizeImmediate();

    // arrange css

    this.objectToZoom.style.transition = 'none';

    this.objectToZoom.style.transform = Rematrix.toString(this.transform);

    this.objectToZoom.addEventListener('touchstart', this.onTouchStart, { passive: false });
    this.objectToZoom.addEventListener('touchmove', this.onTouchMove, { passive: false });
    this.objectToZoom.addEventListener('gesturechange', this.onGestureChange, { passive: false });
    this.objectToZoom.addEventListener('gesturestart', this.onGestureStart, { passive: false });
    this.objectToZoom.addEventListener('gestureend', this.onGestureEnd, { passive: false });

    this.objectToZoom.addEventListener('mousewheel', this.onMouseWheel, { passive: false });
    this.objectToZoom.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('resize', this.onResize);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);

    // not needed when shown inside dialog
    /*
    this.resizeObserver = new ResizeObserver(entries => {
      this.onResize();
    });

    this.resizeObserver.observe(this.objectToZoom);*/

    //setTimeout(() => this.zoom(-20, this.objectToZoom.clientWidth / 2.0, this.objectToZoom.clientHeight / 2.0), 0);
  }

  onTouchStart = e => {
    this.isDragPanningStarted = true;
  }

  onTouchMove = e => {
    this.onMouseMove(e);
  }

  onGestureChange = e => {
    this.onMouseWheel(e);
  }

  onGestureStart = e => {
    this.lastKnownCursorPos = { x: e.pageX, y: e.pageY };
    this.previousPinchScale = 1;
  }

  onGestureEnd = e => {
    this.isDragPanningStarted = false;
  }

  centerOnShapes(shapes: Shape[]) {
    if (isEmpty(shapes) || !this.scrollContainer) {
      return;
    }

    const points = shapes.map(shape => shape.points).flat();
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    points.forEach(point => {
      minX = point.x < minX ? point.x : minX;
      minY = point.y < minY ? point.y : minY;
      maxX = point.x > maxX ? point.x : maxX;
      maxY = point.y > maxY ? point.y : maxY;
    });
    const shapeTopLeft = { x: minX, y: minY };
    const shapeBottomRight = { x: maxX, y: maxY };
    const shapeWidth = shapeBottomRight.x - shapeTopLeft.x;
    const shapeHeight = shapeBottomRight.y - shapeTopLeft.y;

    const scrollContainerHtmlRect = this.scrollContainer.getBoundingClientRect();
    const visibleRectTopLeft = this.cursorToSvgCoord(scrollContainerHtmlRect.left, scrollContainerHtmlRect.top);
    const visibleRectBottomRight = this.cursorToSvgCoord(scrollContainerHtmlRect.right, scrollContainerHtmlRect.bottom);
    const visibleRectWidth = visibleRectBottomRight.x - visibleRectTopLeft.x;
    const visibleRectHeight = visibleRectBottomRight.y - visibleRectTopLeft.y;

    if (shapeWidth > visibleRectWidth || shapeHeight > visibleRectHeight) {
      const neededSpace = Math.max(shapeWidth - visibleRectWidth, shapeHeight - visibleRectHeight) + 150 / this.scale;

      const newScaleFactor = visibleRectWidth / (neededSpace + visibleRectWidth);

      this.transform = Rematrix.scale(this.scale * newScaleFactor);
      // disable zooming out for now, because causing mobx crash
      //shapesStore.zoomController.zoom(null, visibleRectWidth / 2., visibleRectHeight / 2., false, this.scale);
      //this.centerOnSelectedShape();
      return;
    }

    const xScroll = (shapeTopLeft.x - visibleRectTopLeft.x - (visibleRectWidth - shapeWidth) / 2) * this.scale;
    const yScroll = (shapeTopLeft.y - visibleRectTopLeft.y - (visibleRectHeight - shapeHeight) / 2) * this.scale;
    setTimeout(() => this.scrollContainer.scrollBy({
      top: yScroll,
      left: xScroll,
      behavior: "smooth",
    }), 100);
  }

  isSvgPointVisible(point: Point) {
    // boundary of screen points
    const scrollRect = this.scrollContainer.getBoundingClientRect();

    const visibleTopLeft = screenToLocalPoint(scrollRect.left, scrollRect.top, this.objectToZoom);
    const visibleBottomRight = screenToLocalPoint(scrollRect.right, scrollRect.bottom, this.objectToZoom);

    return (
      point.x >= visibleTopLeft.x &&
      point.x <= visibleBottomRight.x &&
      point.y >= visibleTopLeft.y &&
      point.y <= visibleBottomRight.y
    )
  }

  getScaleToFit = (
    maxScale = 9999,
    svgBBox = this.getBBox(this.objectToZoom?.querySelector('.RootShapesComponent')),
    containerWidth = this.scrollContainer.clientWidth,
    containerHeight = this.scrollContainer.clientHeight
  ) => {
    return getScaleToFit(maxScale, svgBBox, containerWidth, containerHeight);
  }

  zoomOutOnObject = (maxScale = 9999, wantedCenter = null, object = this.objectToZoom?.querySelector?.('.RootShapesComponent')) => {
    if (!object) {
      return;
    }

    const newScale = this.getScaleToFit(maxScale, this.getBBox(object));

    setTimeout(() => {
      this.zoom(0, undefined, undefined, newScale);

      const svgBBox = this.getBBox(object);

      wantedCenter = wantedCenter || new Point(
        (svgBBox.x + svgBBox.width / 2),
        (svgBBox.y + svgBBox.height / 2),
      );

      this.centerOnPoint(wantedCenter.x, wantedCenter.y);

    }, 0);
  }


  changeScrollThrottled = throttle((scrollLeft, scrollTop) => {
    this.scrollContainer.scrollLeft = scrollLeft;
    this.scrollContainer.scrollTop = scrollTop;
  }, 200)

  centerOnPoint(localX, localY) {
    const svgViewBox = compact(this.objectToZoom.getAttribute('viewBox').split(/\s+|,/));
    const svgViewBoxTopLeft = new Point(parseInt(svgViewBox[0]), parseInt(svgViewBox[1]));
    const scroll0Center = new Point(
      svgViewBoxTopLeft.x + this.scrollContainer.clientWidth / 2 / this.scale,
      svgViewBoxTopLeft.y + this.scrollContainer.clientHeight / 2 / this.scale
    );

    this.scrollContainer.scrollLeft = (localX - scroll0Center.x) * this.scale;
    this.scrollContainer.scrollTop = (localY - scroll0Center.y) * this.scale;
  }

  zoomCompletelyOut = debounce((maxScale = 99999) => {
    this.zoomOutOnObject(maxScale, null, this.objectToZoom);
  }, 300)


  zoomOutOnShapes = debounce((maxScale = 99999, wantedCenter = null, object = this.objectToZoom?.querySelector?.('.RootShapesComponent')) => {
    this.zoomOutOnObject(maxScale, wantedCenter, object);
  }, 300)

  zoomOutOnShapesThrottled = throttle((maxScale = 99999, wantedCenter = null, object = this.objectToZoom.querySelector('.RootShapesComponent')) => {
    this.zoomOutOnObject(maxScale, wantedCenter, object);
  }, 1000, { leading: false })

  zoomIn = () => {
    this.isZooming = true;
    this.zoom(-20, undefined, undefined, null, true);
  }

  zoomOut = () => {
    this.isZooming = true;
    this.zoom(20, undefined, undefined, null, true);
  }

  zoom(deltaY, pointX = undefined, pointY = undefined, predefinedScale = null, forceAdjustSize = false) {
    if (predefinedScale) {
      pointX = this.lastKnownCursorPos.x;
      pointY = this.lastKnownCursorPos.y;
    }

    // simulate pagex pagey if zoomin without mouse
    const offset = this.scrollContainer.getBoundingClientRect();

    if (!pointX) {
      pointX = this.scrollContainer.clientWidth / 2 + offset.left;
    }
    if (!pointY) {
      pointY = this.scrollContainer.clientHeight / 2 + offset.top;
    }

    const localCoords = this.cursorToSvgCoord(pointX, pointY);

    // determine new scale
    let realScaleFactor = Math.min(this.scaleFactor, 1 + (this.scaleFactor - 1) * Math.abs(deltaY) / 16.);

    if (deltaY > 0) {
      realScaleFactor = 1 / realScaleFactor;
    }

    if (deltaY > 0 && this.scale * realScaleFactor < this.minimumScale * .95) {
      return false;
    }

    const previousScale = this.scale;

    let newScale = 1;
    if (predefinedScale) {
      newScale = predefinedScale;
    } else {
      newScale = previousScale * realScaleFactor;
    }

    // this part changes the view port and should be debounced
    if (predefinedScale) {
      this.transform = Rematrix.scale(newScale);
      this.applyTransform(false);
      this.adjustSvgSizeImmediate();

      const newLocalCoords = this.cursorToSvgCoord(pointX, pointY);

      const localScrollX = newLocalCoords.x - localCoords.x;
      const localScrollY = newLocalCoords.y - localCoords.y;

      this.scrollContainer.scrollLeft -= localScrollX * newScale;
      this.scrollContainer.scrollTop -= localScrollY * newScale;
    } else {
      const newTranslateX = pointX - offset.left + this.scrollContainer.scrollLeft;
      const newTranslateY = pointY - offset.top + this.scrollContainer.scrollTop;

      const newTransform = [
        Rematrix.translate(newTranslateX, newTranslateY),
        Rematrix.scale(newScale / previousScale),
        Rematrix.translate(-newTranslateX, -newTranslateY),
        this.transform,
      ].reduce(Rematrix.multiply);

      this.transform = newTransform;

      if (forceAdjustSize) {
        this.applyTransform(true, true);
      } else {
        requestAnimationFrame(() => this.applyTransform());
      }
    }
    return true;
  }

  getBBox(object) {
    const defaultRectangle = { width: 800, height: 600, x: 0, y: 0 };

    if (!object) {
      return defaultRectangle;
    }
    const cropRectangle = object.querySelector('#CropRectangle');
    const backgroundImage = object.querySelector('#BackgroundImage');

    if (cropRectangle) {
      cropRectangle.style.display = 'none';
    }
    if (backgroundImage) {
      backgroundImage.style.display = 'none';
    }

    const retval = getSafe(() => object.getBBox()) || defaultRectangle;

    if (cropRectangle) {
      cropRectangle.style.display = '';
    }
    if (backgroundImage) {
      backgroundImage.style.display = '';
    }

    return retval;
  }

  applyTransform = (shouldAdjustSize = true, shouldAdjustImmediate = false) => {
    this.objectToZoom.style.transform = Rematrix.toString(this.transform);

    const availableScrollLeft = this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth - this.scrollContainer.scrollLeft;
    const availableScrollTop = this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight - this.scrollContainer.scrollTop;

    this.objectToZoom.style.transform = Rematrix.toString(this.transform);

    if (shouldAdjustSize) {
      if (
        !shouldAdjustImmediate &&
        availableScrollLeft / this.scrollContainer.scrollWidth < 0.9 &&
        availableScrollLeft / this.scrollContainer.scrollWidth > 0.1 &&
        availableScrollTop / this.scrollContainer.scrollHeight < 0.9 &&
        availableScrollTop / this.scrollContainer.scrollHeight > 0.1
      ) {
        this.adjustSvgSize();
      } else {
        //this.adjustSvgSize();
        // could possibly bet better to update immediately when scrolling too close to end of scroll
        this.adjustSvgSizeImmediate();
      }
    }
  }

  adjustSvgSizeImmediate = () => {
    // force refresh because of chrome bug
    this.objectToZoom.style.left = Math.random() + 'px';

    this.applyTransform(false); // really needed?

    const { drawToolsStore, shapesStore } = this.stores;
    const scrollContainerRect = getSafe(() => this.scrollContainer.getBoundingClientRect()) || { width: 0, height: 0 };

    const scrollContainerScaledWidth = Math.max(0, (scrollContainerRect.width - 100)) / this.scale;
    const scrollContainerScaledHeight = Math.max(0, (scrollContainerRect.height - 100)) / this.scale;

    const htmlBoundingBox = this.getBBox(this.objectToZoom);
    const boundingBox = new Rectangle(this.stores, new Point(htmlBoundingBox.x, htmlBoundingBox.y), new Point(htmlBoundingBox.x + htmlBoundingBox.width, htmlBoundingBox.y + htmlBoundingBox.height));

    // if currently drawing first shape, don't remove empty space at the top left, only at the bottom right
    if (drawToolsStore.lineBeingAdded && drawToolsStore.isFirstDrawingBeingAdded) {
      if (boundingBox.topLeft.x > 0) {
        boundingBox.bottomRight.x += boundingBox.topLeft.x;
        boundingBox.topLeft.x = 0;
      }

      if (boundingBox.topLeft.y > 0) {
        boundingBox.bottomRight.y += boundingBox.topLeft.y;
        boundingBox.topLeft.y = 0;
      }
    }

    let svgWidth = boundingBox.width + 2 * scrollContainerScaledWidth;
    let svgHeight = boundingBox.height + 2 * scrollContainerScaledHeight;

    const previousBoundingBox = this.previousBoundingBox || boundingBox;

    const boundingBoxCenterOffset = new Point(
      boundingBox.centerPoint.x - previousBoundingBox.centerPoint.x,
      boundingBox.centerPoint.y - previousBoundingBox.centerPoint.y,
    );

    //console.log('center offset', boundingBoxCenterOffset.x, boundingBoxCenterOffset.y);

    const previousSvgWidth = parseFloat(this.objectToZoom.getAttribute('width')) || svgWidth;
    const previousSvgHeight = parseFloat(this.objectToZoom.getAttribute('height')) || svgHeight;

    if (svgWidth < 0 || svgHeight < 0) {
      debugger;
    }

    const additionalScrollLeft = (svgWidth - previousSvgWidth) / 2 * this.scale - boundingBoxCenterOffset.x * this.scale - this.translateX;
    const additionalScrollTop = (svgHeight - previousSvgHeight) / 2 * this.scale - boundingBoxCenterOffset.y * this.scale - this.translateY;

    const scrollLeftBeforeTransformChange = this.scrollContainer.scrollLeft;
    const scrollTopBeforeTransformChange = this.scrollContainer.scrollTop;

    // remove translate part of transform
    this.transform = Rematrix.scale(this.scale);
    this.applyTransform(false);

    // force refresh because of chrome bug
    this.objectToZoom.style.left = Math.random() + 'px';

    // adjust scroll before resize, if new size is smaller, because scrollwidth will become smaller and lose precision
    if (svgWidth < previousSvgWidth) {
      this.scrollContainer.scrollLeft = scrollLeftBeforeTransformChange + additionalScrollLeft;
      this.scrollContainer.scrollTop = scrollTopBeforeTransformChange + additionalScrollTop;
    }

    this.objectToZoom.setAttribute('width', svgWidth);
    this.objectToZoom.setAttribute('height', svgHeight);

    const viewBoxTopLeft = new Point(boundingBox.topLeft.x - scrollContainerScaledWidth, boundingBox.topLeft.y - scrollContainerScaledHeight);
    shapesStore.viewBoxRectangle = new Rectangle(
      this.stores,
      viewBoxTopLeft,
      new Point(viewBoxTopLeft.x + svgWidth, viewBoxTopLeft.y + svgHeight),
    );

    // add some padding to svg..
    // but if person draws more to the right or down that it will give more padding than this
    this.objectToZoom.setAttribute('viewBox', `
      ${shapesStore.viewBoxRectangle.topLeft.x}
      ${shapesStore.viewBoxRectangle.topLeft.y}
      ${svgWidth}
      ${svgHeight}
    `);

    this.viewBoxRectX = shapesStore?.viewBoxRectangle?.topLeft?.x || 0;
    this.viewBoxRectY = shapesStore?.viewBoxRectangle?.topLeft?.y || 0;

    // adjust scroll after resize, if new size is bigger, because scrollwidth will become bigger and allow to add bigger number
    if (svgWidth >= previousSvgWidth) {
      this.scrollContainer.scrollLeft = scrollLeftBeforeTransformChange + additionalScrollLeft;
      this.scrollContainer.scrollTop = scrollTopBeforeTransformChange + additionalScrollTop;
    }

    this.observableScale = this.scale;
    this.previousBoundingBox = boundingBox;
  }

  adjustSvgSize = debounce(this.adjustSvgSizeImmediate, 300)

  adjustSvgSizeThrottled = throttle(this.adjustSvgSizeImmediate, 500)

  cursorToSvgCoord = (clientX, clientY) => {
    return screenToLocalPoint(clientX, clientY, this.objectToZoom);
  }


  beginMomentumTracking = () => {
    this.cancelMomentumTracking();
    this.momentumAnimationId = requestAnimationFrame(this.momentumLoop);
  }

  beginAccelerationTracking = () => {
    this.cancelAccelerationTracking();
    this.accelerationAnimationId = requestAnimationFrame(this.accelerationLoop);
  }

  accelerationLoop = () => {
    this.lastMovementX *= 1.015;
    this.lastMovementY *= 1.015;

    const MAX_SPEED_CONSTANT = 0.005;

    // maximum acceleration
    if (this.lastMovementX && Math.abs(this.lastMovementX)) {
      this.lastMovementX = this.lastMovementX / Math.abs(this.lastMovementX) * Math.min(Math.abs(this.lastMovementX), this.scrollContainer.scrollWidth * MAX_SPEED_CONSTANT);
    }

    if (this.lastMovementY) {
      this.lastMovementY = this.lastMovementY / Math.abs(this.lastMovementY) * Math.min(Math.abs(this.lastMovementY), this.scrollContainer.scrollHeight * MAX_SPEED_CONSTANT);
    }

    this.scrollContainer.scrollLeft -= this.lastMovementX;
    this.scrollContainer.scrollTop -= this.lastMovementY;

    this.accelerationAnimationId = requestAnimationFrame(this.accelerationLoop);
  }

  momentumLoop = () => {
    this.lastMovementX *= 0.95;
    this.lastMovementY *= 0.95;

    if (Math.abs(this.lastMovementX) > 0.5) {
      this.scrollContainer.scrollLeft -= this.lastMovementX;
    }

    if (Math.abs(this.lastMovementY) > 0.5) {
      this.scrollContainer.scrollTop -= this.lastMovementY;
    }

    if (Math.abs(this.lastMovementX) > 0.5 || Math.abs(this.lastMovementY) > 0.5) {
      this.momentumAnimationId = requestAnimationFrame(this.momentumLoop);
    } else {
      this.isDragPanningStarted = false;
      this.momentumAnimationId = null;
    }
  }

  cancelMomentumTracking = () => {
    if (this.momentumAnimationId) {
      cancelAnimationFrame(this.momentumAnimationId);
      this.momentumAnimationId = null;
    }
    this.isDragPanningStarted = false;
  }

  cancelAccelerationTracking = () => {
    if (this.accelerationAnimationId) {
      cancelAnimationFrame(this.accelerationAnimationId);
      this.accelerationAnimationId = null;
    }
  }

  // events
  onMouseDown = (e) => {
    this.cancelMomentumTracking();

    this.isDragPanningStarted = true;

    this.lastKnownCursorPos.x = e.pageX;
    this.lastKnownCursorPos.y = e.pageY;
  }

  onMouseUp = (e) => {
    const { userInfoStore } = this.stores;
    if (this.isDragPanningStarted && userInfoStore.user?.isDragMomentumEnabled) {
      this.beginMomentumTracking();
    } else {
      this.isDragPanningStarted = false;
    }

    // isDrag panning for a real distance
    if (this.isDragPanning) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onMouseMove = (e) => {
    if (
      (e.target as HTMLElement).tagName === 'INPUT' ||
      (e.target as HTMLElement).closest('.MuiInputBase-root')
    ) {
      return;
    }

    const { drawToolsStore } = this.stores;

    const movementX = (e.pageX - this.lastKnownCursorPos.x);
    const movementY = (e.pageY - this.lastKnownCursorPos.y);

    this.lastKnownCursorPos.x = e.pageX;
    this.lastKnownCursorPos.y = e.pageY;

    if (
      !this.isDragPanningStarted ||
      this.momentumAnimationId ||
      drawToolsStore.isDrawingWithTouch && e.touches?.length !== 2
    ) {
      return;
    }

    e.preventDefault(); // prevents native autoscrolling when near the scrollbar

    this.scrollContainer.scrollTop -= movementY;
    this.scrollContainer.scrollLeft -= movementX;

    if (Math.abs(movementX) + Math.abs(movementY) > 1) {
      this.isDragPanning = true;
      this.lastMovementX = movementX;
      this.lastMovementY = movementY;
    } else {
      this.lastMovementX = 0;
      this.lastMovementY = 0;
    }

    return;
  }

  // can't throttle this or will look like it's lagging hard
  onMouseWheel = (e) => {
    this.cancelMomentumTracking();
    //if (!e.altKey && !e.ctrlKey && !e.metaKey) {
    //  return; //only do the scroll
    //}

    // this.stores.commonStore.setIsBigUpdateOngoing(true, true);
    this.isZooming = true;


    e.preventDefault();

    let pinchDelta = 0;
    if (e.scale) {
      pinchDelta = ((this.previousPinchScale - e.scale) * 50);
      this.previousPinchScale = e.scale;

      // console.log(e.scale, pinchDelta);

      if (Math.abs(pinchDelta) < 0.2) {
        return;
      }
    }

    const { userInfoStore } = this.stores;
    const { mouseWheelSensitivity } = userInfoStore.user;

    const delta = e.scale
      ? pinchDelta
      : e.deltaY * (10 ** mouseWheelSensitivity);

    this.zoom(delta * .7, e.pageX, e.pageY);
  }

  onResize = debounce(this.zoomCompletelyOut, 1000)

  dispose() {
    this.objectToZoom?.removeEventListener?.('mousewheel', this.onMouseWheel);
    this.objectToZoom?.removeEventListener?.('mousedown', this.onMouseDown);

    this.objectToZoom?.removeEventListener?.('touchstart', this.onTouchStart);
    this.objectToZoom?.removeEventListener?.('touchmove', this.onTouchMove);
    this.objectToZoom?.removeEventListener?.('gesturechange', this.onGestureChange);
    this.objectToZoom?.removeEventListener?.('gesturestart', this.onGestureStart);
    this.objectToZoom?.removeEventListener?.('gestureend', this.onGestureEnd);

    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);

    if (this.scrollContainer && this.resizeObserver) {
      this.resizeObserver.unobserve(this.scrollContainer);
    }

    this.scrollContainer = null;
    this.objectToZoom = null;
  }
}

