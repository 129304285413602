
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./ReportThankYou.module.css');

export default class ReportThankYou extends ObserverComponent {
  _render() {
    const { reportsStore } = this.context;
    // duplicate
    const logoColor = reportsStore.report?.logoColors?.color1 || 'rgb(24,39,53)';

    return (
      <div className={styles.root} style={{ color: logoColor }} >
        {i18n.t('Thank you for your business')}
      </div>
    )
  }
}