
import ObserverComponent from 'components/common/ObserverComponent';
import { throttle } from 'lodash';
import { action } from 'mobx';
import * as React from 'react';
import { MOUSE_MOVE_THROTTLE_TIME } from 'utils/DrawToolsUtils';

const styles = require('./MerchantConfigurator.module.scss');

interface MerchantConfiguratorProps {
  iframeElement: HTMLIFrameElement,
}

export default class MerchantConfigurator extends ObserverComponent<MerchantConfiguratorProps> {
  componentDidMount() {
    const { iframeElement } = this.props;
    iframeElement.addEventListener('load', this.onIframeLoad);
    iframeElement.addEventListener('beforeunload', this.onIframeUnload);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { iframeElement } = this.props;
    iframeElement.removeEventListener('load', this.onIframeLoad);
    iframeElement.removeEventListener('beforeunload', this.onIframeUnload);

  }

  onIframeLoad = () => {
    const { iframeElement } = this.props;
    iframeElement.contentDocument.addEventListener('mousemove', this.onMouseMove);
    iframeElement.contentDocument.addEventListener('mousedown', this.onMouseDown);
  }

  onIframeUnload = () => {
    const { iframeElement } = this.props;
    iframeElement.contentDocument.removeEventListener('mousemove', this.onMouseMove);
    iframeElement.contentDocument.removeEventListener('mousedown', this.onMouseDown);
  }

  @action.bound
  onMouseDown(ev: MouseEvent) {

  }

  @action.bound
  onMouseMove(ev: MouseEvent) {
    this.onMouseMoveThrottled(ev);
  }

  @action
  onMouseMoveThrottled = throttle((ev: MouseEvent) => {
    const { merchantsStore } = this.context;
    let target = ev.target as HTMLElement;
    if (!merchantsStore.isConfiguratorActive || !target) {
      return;
    }

    while (target.children.length === 1) {
      target = target.children[0] as HTMLElement;
    }

    //console.log(getXPathFromElement(target));

    target.ownerDocument.querySelectorAll('.merchantConfiguratorBorder').forEach(
      elem => elem.classList.remove('merchantConfiguratorBorder')
    );
    target.classList.add('merchantConfiguratorBorder');



  }, MOUSE_MOVE_THROTTLE_TIME)

  _render() {
    return (
      <div className={styles.root}>
      </div>
    )
  }
}