
import { BottomNavigation, Menu } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import { DrawToolType } from 'constants/DrawToolType';
import { first } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import DrawToolButton, { DrawToolButtonProps } from '../DrawToolButton/DrawToolButton';
import ObserverComponent from '../ObserverComponent';

const styles = require('./DrawToolsButtonWithSubButtons.module.scss');

type DrawToolButtonWithSubButtonsProps = DrawToolButtonProps & { subButtons: DrawToolType[] };

interface DrawToolButtonWithSubButtonsState {
  selectedTool?: DrawToolType,
}

class DrawToolButtonWithSubButtons extends ObserverComponent<
DrawToolButtonWithSubButtonsProps,
DrawToolButtonWithSubButtonsState
> {
  state = {
    selectedTool: null
  }

  handleSubButtonsSelectedChange = (newSelectedTool, popupState) => {
    const { drawToolsStore } = this.context;
    drawToolsStore.selectedTool = newSelectedTool;
    this.setState({ selectedTool: newSelectedTool });
    popupState.close();
  };

  _render() {
    const { drawToolsStore } = this.context;
    const { subButtons, onChange } = this.props;
    const { selectedTool } = this.state;
    const toolType = selectedTool || first(subButtons);

    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => {
          return (
            <React.Fragment>
              <div className={styles.root}>
                <DrawToolButton
                  {...bindTrigger(popupState)}
                  onChange={onChange}
                  toolType={toolType}
                  showLabel
                />
                <ChevronRightIcon className={classnames(styles.chevronRightIcon, { [styles.isSelected]: drawToolsStore.selectedTool === toolType })} />
              </div>
              <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                getContentAnchorEl={null}
                PaperProps={{
                  elevation: 1,
                  className: styles.menu
                }}
              >
                <BottomNavigation
                  value={subButtons[0]}
                  onChange={(event, newSelectedTool) => (
                    this.handleSubButtonsSelectedChange(newSelectedTool, popupState)
                  )}
                  showLabels
                >
                  {subButtons.map((subButton, index) => (
                    <DrawToolButton toolType={subButton} key={index} placement="bottom" />
                  ))}
                </BottomNavigation>
              </Menu>
            </React.Fragment>
          );
        }}
      </PopupState >
    );
  }
}

export default DrawToolButtonWithSubButtons;
