import Globals from 'Globals';
import { Currency, CurrencySigns } from 'constants/Currency';
import i18next, { TFunction, i18n as i18nType } from 'i18next';
import { memoize } from 'lodash';
import { computed, observable } from 'mobx';
import moment from 'moment';

// Currently very hardcoded and embeds the language files
// Could improve once we need more than 3 languages
class ObservableI18n {
  @observable i18n: i18nType;

  @observable countryCode: CountryCode = 'CA';
  @observable currency: Currency;

  @computed get currencySign() {
    return CurrencySigns[this.currency];
  }

  @observable _language: LanguageKey;

  @computed get language(): LanguageKey {
    return this._language;
  }

  @computed get locale(): string {
    // needs to be done better
    return this.language + '-' + this.countryCode;
  }

  englishT: TFunction;
  frenchT: TFunction;
  spanishT: TFunction;

  set language(value) {
    if (!this._language && value) {
      this.initialize(value);
    } else if (value && value !== this._language) {
      this.i18n.changeLanguage(value, () => {
        this._language = value;
        moment.locale(value);
      })
    }
  }

  // saw a bug so disabling
  fasterI18nt = memoize((key: string, params?: { [key: string]: string | number }, options?: any): string => {
    return this.i18n.t(key, params, options);
  }, (key, params, options) => (
    [key, this.i18n || false, !params || JSON.stringify(params), !options || JSON.stringify(options)].join('#')
  ));


  overridableT = (key: string, params?: { [key: string]: string | number }, options: any): string => {
    const overridedValue = Globals.defaultStores.userInfoStore?.user?.localeOverrides?.[this.language]?.[key];
    return overridedValue === undefined
      ? this.i18n.t.bind(this.i18n)(key, params, options)
      : overridedValue;
  }

  @computed get t(): (key: string, params?: { [key: string]: string | number }) => string {
    const listen1 = this._language;

    if (this.i18n) {
      return this.overridableT;
    }

    return ((unused: string) => '');
  }

  @computed get currencyFormatter() {
    return new Intl.NumberFormat(
      this.language,
      { style: 'currency', currency: this.currency, currencyDisplay: 'narrowSymbol' }
    )
  }

  @computed get roundedCurrencyFormatter() {
    return new Intl.NumberFormat(
      this.language,
      { style: 'currency', currency: this.currency, currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0 }
    )
  }

  @computed get decimalFormatter() {
    return new Intl.NumberFormat(
      // temp workaround, but need to ask for user country in settings
      (this.language === 'fr' || this.language === 'en') ? this.language + '-CA' : this.language,
    )
  }

  @computed get currencyRoundingFactor() {
    return Math.pow(10, this.currencyFormatter.resolvedOptions().maximumFractionDigits)
  }

  tAll = memoize((key: string, params?: { [key: string]: string | number }) => ({
    en: this.englishT(key, params),
    fr: this.frenchT(key, params),
    es: this.spanishT(key, params),
  }), (key, params) => (
    [key, this.i18n || false, !params || JSON.stringify(params)].join('#')
  ));

  initialize(language) {
    const resources = {
      en: { translation: require('locales/en/translation.json') },
      fr: { translation: require('locales/fr/translation.json') },
      es: { translation: require('locales/es/translation.json') },
    };

    i18next
      //.use(Backend)
      .init({
        // allow keys to be phrases having `:`, `.`
        lng: language,
        nsSeparator: false,
        keySeparator: false,
        nonExplicitWhitelist: true,
        whitelist: ['en', 'fr', 'es'],
        fallbackLng: 'en',
        resources,
        /*backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json'
        },*/
      }, (err, t) => {
        this.englishT = i18next.getFixedT('en');
        this.frenchT = i18next.getFixedT('fr');
        this.spanishT = i18next.getFixedT('es');

        this.i18n = i18next;
        this._language = language;
      });
  }
}

const i18n = new ObservableI18n();

export default i18n;

window.i18n = i18n;