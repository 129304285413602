
import { Button, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ObserverComponent from 'components/common/ObserverComponent';
import { isProduction } from 'environment';
import * as React from 'react';
import { fetchUrl } from 'utils/HttpRequestUtils';
import i18n from 'utils/i18n';

const styles = require('./AppSnackbar.module.scss');

export default class AppSnackbar extends ObserverComponent {
  openedWindow = null;

  showChangeLogIfNeeded = async (shouldForce = false) => {
    this.openedWindow?.close?.();

    let url = isProduction() ? 'https://evalumo.com/fr/changelog/' : 'http://localhost:8000/fr/changelog/';
    url += '?cb=' + Date.now().toString().slice(-10); // cache bust

    if (shouldForce) {
      this.openedWindow = window.open(url, '_blank', 'width=800,height=800');
      return;
    }

    const content = await fetchUrl(url);
    // only show popup for major updates
    if (/name="should-popup-in-app" value="([^"]+)"/.exec(content || '')?.[1] === 'true') {
      this.openedWindow = window.open(url, '_blank', 'width=800,height=800');
    }
  }

  updateServiceWorker = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          debugger;
          window.location.reload();
        }
      });
    } else {
      debugger;
      window.location.reload(); // ???
    }
  };

  closeSnackbar = () => {
    const { commonStore } = this.context;
    commonStore.isNewVersionAvailable = false
  }

  _render() {
    const { commonStore } = this.context;
    const { isNewVersionAvailable } = commonStore;
    return (
      <Snackbar
        className={styles.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isNewVersionAvailable}
        message={
          <div className={styles.root}>
            <div className={styles.title}>
              {i18n.t('A new version is available')}
            </div>
            <span className={styles.changesLink} onClick={() => this.showChangeLogIfNeeded(true)}>
              {i18n.t('View changes')}
            </span>
          </div>
        }
        action={
          <>
            <Button className={styles.actionButton} color="primary" variant="contained" onClick={async () => {
              await this.showChangeLogIfNeeded();
              this.updateServiceWorker();
            }}>
              {i18n.t('Reload page')}
            </Button>
            <IconButton className={styles.closeButton} size="small" onClick={this.closeSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    )
  }
}