
import { CircularProgress } from '@material-ui/core';
import Globals from 'Globals';
import ObserverComponent from 'components/common/ObserverComponent';
import DrawLayer from 'components/drawing/DrawLayer/DrawLayer';
import { IEditComponentProps } from 'constants/CommonProps';
import { PanelIds } from 'constants/PanelIds';
import * as React from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import StoresContext from 'stores/StoresContext';
import { getPanelProps } from 'utils/PanelUtil';
import i18n from 'utils/i18n';
import DialogsContainer from '../DialogsContainer/DialogsContainer';
import DrawingHeader from '../DrawingHeader/DrawingHeader';
import { SquareIcon } from '../Icons';
import Panel from '../Panel/Panel';
import SelectedMeasurements from '../SelectedMeasurements/SelectedMeasurements';
import SelectedMeasurementsHeader from '../SelectedMeasurementsHeader/SelectedMeasurementsHeader';
import WorksheetsTree from '../WorksheetsTree/WorksheetsTree';
import WorksheetsTreeHeader from '../WorksheetsTree/WorksheetsTreeHeader';

const styles = require('./DrawingEditDialogDetailsForm.module.scss');

export default class DrawingEditDialogDetailsForm extends ObserverComponent<IEditComponentProps> {
  _render() {
    const { settingsStore, treeNodesStore: drawingTreeNodesStore } = Globals.drawingStores;
    const { drawingPanelSizes: panelSizes } = settingsStore.settings;

    if (!settingsStore.settings || !drawingTreeNodesStore.rootNode) {
      // good usecase to render spinner or suspense
      return <div className={styles.progress}><CircularProgress /></div>;
    }

    return (
      <StoresContext.Provider value={Globals.drawingStores}>

        <div className={styles.root}>
          <DialogsContainer />

          {/*<DialogTitle id="draggable-dialog-title" className={styles.title}>
          {projectTreeNodesStore.selectedTreeNode.name + ' - ' + i18n.t('Reference Drawing')}
    </DialogTitle>*/}

          <ReflexContainer
            className={styles.content}
            orientation="vertical"
          >
            <ReflexElement {...getPanelProps(PanelIds.LeftBar, panelSizes, Globals.drawingStores)}>
              <ReflexContainer orientation="horizontal">

                <ReflexElement {...getPanelProps(PanelIds.LeftBarTop, panelSizes, Globals.drawingStores)}>
                  <Panel
                    header={
                      <WorksheetsTreeHeader icon={<SquareIcon />} title={i18n.t('Drawing Elements')}/>
                    }
                    content={<WorksheetsTree forceObserve={drawingTreeNodesStore.rootNode.shapes.length < 100} />}
                  />
                </ReflexElement>

                <ReflexSplitter propagate />

                <ReflexElement {...getPanelProps(PanelIds.LeftBarMiddle, panelSizes, Globals.drawingStores)}>
                  <Panel
                    header={<SelectedMeasurementsHeader />}
                    content={<SelectedMeasurements shouldAllowCheckboxes />}
                  />
                </ReflexElement>
              </ReflexContainer>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement {...getPanelProps(PanelIds.MainColumn, panelSizes, Globals.drawingStores)}>
              <Panel
                header={<DrawingHeader forceObserve />}
                content={drawingTreeNodesStore.selectedTreeNode && drawingTreeNodesStore.rootNode && <DrawLayer forceObserve />}
              />
            </ReflexElement>
          </ReflexContainer>

        </div>
      </StoresContext.Provider>
    )
  }
}