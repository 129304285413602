import { MuiThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import Globals from 'Globals';
import "pwaInstallation";
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import "react-medium-image-zoom/dist/styles.css";
import 'react-reflex/styles.css';
import { ThroughProvider } from 'react-through';
import CategoriesStore from 'stores/CategoriesStore';
import StoresContext from 'stores/StoresContext';
import './DragDropTouch';
import IntlApp from './IntlApp';
import registerServiceWorker from './registerServiceWorker';
import { theme } from './theme';


const firstImport = CategoriesStore;

if (!window.location.host.includes('10.0.0')) {
  document.domain = window.location.host.includes('evalumodev') ? 'evalumodev.ca' : 'evalumo.com';
}

const app = (
  <StoresContext.Provider value={Globals.defaultStores}>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThroughProvider>
          <IntlApp />
        </ThroughProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </StoresContext.Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(app);
//ReactDOM.unstable_createRoot(document.getElementById('root') as HTMLElement).render(app);

registerServiceWorker({
  onUpdate: () => {
    const { commonStore } = Globals.defaultStores
    commonStore.isNewVersionAvailable = true;
  }
});
//unregister();
