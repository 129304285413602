export enum PanelIds {
  LeftBar = 'LeftBar',
  MainColumn = 'MainColumn',
  RightBar = 'RightBar',
  RightBarTop = 'RightBarTop',
  RightBarBottom = 'RightBarBottom',
  LeftBarTop = 'LeftBarTop',
  LeftBarMiddle = 'LeftBarMiddle',
  LeftBarBottom = 'LeftBarBottom',
  MainColumnTop = 'MainColumnTop',
  MainColumnBottom = 'MainColumnBottom',
}
