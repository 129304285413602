import { sum, sumBy } from 'lodash';
import ProvidingItem from 'models/ProvidingItem';
import i18n from "./i18n";

// Round to currency max precision before doing the math operation
// Not a blocker for now, but should use these everywhere at some point

export const multiplyPrices = (prices: number[]): number => (
  prices.reduce(
    (prev, current) => roundPrice(current) * prev,
    1
  )
);

export const sumPrices = (prices: number[]): number => (roundPrice(sumBy(prices, price => roundPrice(price))));

export const roundPrice = (price: number) => {
  const retval = Math.round((price || 0) * i18n.currencyRoundingFactor) / i18n.currencyRoundingFactor;

  return (isNaN(retval) || retval === Infinity)
    ? 0
    : retval;
};

export const getPriceWithFees = (price: number, providingItem: ProvidingItem, shouldRound = true) => {
  if (!providingItem) {
    return 0;
  }

  const { settingsStore } = providingItem.stores;

  // keep old calculation for old projects
  if (settingsStore.shouldUseLegacyFeesCalculation) {
    return legacyGetPriceWithFees(price, providingItem);
  }

  return (shouldRound ? sumPrices : sum)([
    price,
    ...providingItem.getProvidingItemFeesValues(price, shouldRound).values()
  ]);
}

const legacyGetPriceWithFees = (price: number, providingItem: ProvidingItem) => {
  const { totalsStore } = providingItem.stores;
  return roundPrice(price * (1 + totalsStore.legacyEffectiveFeesPercentage / 100));
}


export const getPriceWithMerchantAdjustment = (price: number, providingItem: ProvidingItem) => {
  if (!providingItem) {
    return 0;
  }

  return roundPrice(price * (1 + providingItem.priceAdjustmentPercent / 100))
}