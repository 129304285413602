
import * as React from 'react';

interface ListItemAvatarProps {
  className?: string,
  style?: any,
  children: any,
}

export const ListItemAvatar = React.memo(({ className, style, children }: ListItemAvatarProps) => (
  <div className={(className || '') + ' ' + 'MuiListItemAvatar-root'} style={style}>{children}</div>
));