
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import UpdateIcon from '@material-ui/icons/Edit';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./SubscriptionPaymentFailedDialog.module.scss');

export default class SubscriptionPaymentFailedDialog extends ObserverComponent {
  _render() {
    const {routerStore} = this.context
    return (
      <Dialog
        className={styles.root}
        open
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={styles.title}>
          <CreditCardIcon />
          <div>{i18n.t('Please update your Payment Information')}</div>
        </DialogTitle>

        <DialogContent>
          <h1>{i18n.t('There was a problem receiving your last payment. Please update your billing information to continue. If you believe this is an error, contact us at:')} <a href="mailto:support@evalumo.com">support@evalumo.com</a> {i18n.t('or')} <a href="tel:1-866-766-3313">1-866-766-3313</a></h1>
          <br/>
          <DialogContentText>

          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" className={styles.button} color="primary" onClick={routerStore.goToPaymentPage}>
            <UpdateIcon />&nbsp;
            {i18n.t('Update Billing Information')}
            </Button>
        </DialogActions>

      </Dialog>
    )
  }
}