import Clipper from '@doodle3d/clipper-js';
import { DRAWING_SCALE } from 'constants/Constants';
import { MeasurementType } from 'constants/MeasurementType';
import { compact, isEmpty, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";
import Shape from "models/Shape";
import { list, object, serializable } from "serializr";
import { isClockwise } from 'utils/GeometryUtils';
import { OFFSET_SURFACE_FUNCTION, REPEAT_LINE_FUNCTION, getFunctionParameter, getFunctionParameters } from "utils/MeasurementFormatter";
import { getPathString, getSurfaceSquarePixels, getSurfaceSquarePixelsWithSlope } from 'utils/ShapeUtil';
import { getSafe } from 'utils/Utils';
import Line from './Line';
import Point from "./Point";
import SimpleSurface from './SimpleSurface';

export default class Surface extends Shape {
  @serializable type = 'Surface';

  index = 1;

  @observable @serializable(list(object(SimpleSurface))) holes: SimpleSurface[] = [];

  @computed get surfaceSquarePixels(): number {
    return getSurfaceSquarePixels(this.points) - sumBy(this.holes, hole => getSurfaceSquarePixels(hole.points));
  }

  @computed
  get pathString(): string {
    return (
      getPathString(this.points) +
      this.holes
        .map(hole => isClockwise(hole.points) ? hole.pathString : hole.pathStringReversed)
        .join(' ')
    );
  }

  // sq. meters
  @computed get surface(): number {
    return this.surfaceSquarePixels / Math.pow(DRAWING_SCALE, 2)
  }

  @computed get perimeter(): number {
    return sumBy(
      this.points, 
      point => {
        const index = this.points.indexOf(point);
        return point.distance(this.points[(index + 1) % this.points.length], DRAWING_SCALE)
      });
  }

  @computed get roofSurface(): number {
    if (!this.treeNode) {
      return 0;
    }

    const roofParams = [MeasurementType.RoofSlopeRatio, MeasurementType.RoofSlopeDirection, MeasurementType.DrawingProjection];

    const roofMeasurementValues = this.treeNode.ancestorOrNodeMeasurementValuesArray
      .filter(measurementValue => Object.values(roofParams).includes(measurementValue.measurementId));

    if (roofMeasurementValues.length !== 3) {
      return this.surface;
    }

    return this.getSurfaceWithSlope(
      roofMeasurementValues.find(mv => mv.measurementId === MeasurementType.RoofSlopeRatio).metricValue,
      roofMeasurementValues.find(mv => mv.measurementId === MeasurementType.RoofSlopeDirection).metricValue,
      roofMeasurementValues.find(mv => mv.measurementId === MeasurementType.DrawingProjection).metricValue
    );
  }

  @computed get roofSlopeDirection(): number {
    return getSafe(() => this.treeNode.ancestorOrNodeMeasurementValuesArray
      .find(mv => mv.measurementId === MeasurementType.RoofSlopeDirection)
      .metricValue
    );
  }

  getSurfaceWithSlope = computedFn((slopeAngle: number, slopeDirection: number, drawingProjection: number) => {
    return (
      getSurfaceSquarePixelsWithSlope(this.points, slopeAngle, slopeDirection, drawingProjection) -
      sumBy(this.holes, hole => getSurfaceSquarePixelsWithSlope(hole.points, slopeAngle, slopeDirection, drawingProjection))
    ) / Math.pow(DRAWING_SCALE, 2);
  })

  @computed get bboxWidthPixels() {
    return Math.abs(Math.max(...this.points.map(pt => pt.x)) - Math.min(...this.points.map(pt => pt.x)));
  }

  @computed get offsetSurfaces(): Surface[] {
    if (!this.treeNode || this.treeNode.hasNonDrawingRootBubbleChildren) {
      return [];
    }

    const { measurementsStore } = this.stores;

    // DUPLICATE Line.ts
    // should simplify all the map to single function
    return getSafe(() => compact(this.treeNode.ancestorOrNodeMeasurementValuesArray
      .filter(value => value.formula.includes(OFFSET_SURFACE_FUNCTION))
      .map(value => {
        const measurement = measurementsStore.getItemByNameAndPreferredCategory(
          getFunctionParameter(value.formula, OFFSET_SURFACE_FUNCTION)?.replace?.('-', ''),
          value.measurement.category
        )

        if (!measurement) {
          return null;
        }

        const measurementValue = this.treeNode.bubbleRoot.measurementValues.get(measurement.id);
        const metricValue = measurementValue.metricValue;

        const offsetSurface = this.getOffsetSurface(
          // awful, needs to use real parser soon
          getFunctionParameter(
            value.formula,
            OFFSET_SURFACE_FUNCTION)?.startsWith?.('-')
            ? -metricValue
            : metricValue
        );

        return offsetSurface;
      }))) || [];
  }

  @computed get offsetPaths(): string[] {
    return this.offsetSurfaces.map(surface => surface.pathString) || ['']
  }

  @computed get repeatedLinePaths(): string[][] {
    if (!this.treeNode || this.treeNode.hasBubbleChildren) {
      return [];
    }

    const { measurementsStore } = this.stores;

    // DUPLICATE
    return getSafe(() =>
      this.treeNode.ancestorOrNodeMeasurementValuesArray
        .filter(measurementValue => measurementValue.formula.includes(REPEAT_LINE_FUNCTION) && measurementValue.measurement)
        .sort((a, b) => a.measurement.index - b.measurement.index)
        .map(measurementValue => {
          // should try to get this from hot formula parser
          const paramNames = getFunctionParameters(measurementValue.formula, REPEAT_LINE_FUNCTION);
          const spacingParamName = paramNames[0];
          const spacingParamMeasurement = measurementsStore.getItemByNameAndPreferredCategory(spacingParamName, measurementValue.measurement.category);
          const spacingParamMeasurementValue = measurementValue.treeNode.measurementValues.get(spacingParamMeasurement.id);
          const spacingMetricValue = spacingParamMeasurementValue.metricValue;

          let angleMetricValue = 0;
          if (paramNames.length > 1) {
            const angleParamName = paramNames[1];
            const angleParamMeasurement = measurementsStore.getItemByNameAndPreferredCategory(angleParamName, measurementValue.measurement.category);
            const angleParamMeasurementValue = measurementValue.treeNode.measurementValues.get(angleParamMeasurement.id);
            angleMetricValue = angleParamMeasurementValue.metricValue;
          }

          let shouldConsiderOpenings = true;
          if (paramNames.length > 2) {
            // expects 0 or 1
            shouldConsiderOpenings = parseInt(paramNames[2]);
          }

          return this.getRepeatedLinesOnSurface(spacingMetricValue, angleMetricValue, shouldConsiderOpenings)
            .map(line => line.pathString);
        })
    ) || [];
  }


  getSurfaceRepeatedLinesLength = computedFn((spacingMetricValue: number, angleMetricValue: number, shouldConsiderOpenings: boolean): number => {
    return sumBy(this.getRepeatedLinesOnSurface(spacingMetricValue, angleMetricValue, shouldConsiderOpenings), 'length') || 0;
  });

  getSurfaceRepeatedLinesQuantity = computedFn((spacingMetricValue: number, angleMetricValue: number, shouldConsiderOpenings: boolean): number => {
    return this.getRepeatedLinesOnSurface(spacingMetricValue, angleMetricValue, shouldConsiderOpenings).length || 0;
  });

  getRepeatedLinesOnSurface = computedFn((spacingMetricValue: number, angleMetricValue: number, shouldConsiderOpenings: boolean): Line[] => {
    if (this.points.length < 3 || !spacingMetricValue) {
      return [];
    }

    const spacingDistancePixels = spacingMetricValue * DRAWING_SCALE;
    const surfaceClipper = new Clipper([
      this.points.map(point => ({ X: point.x, Y: point.y })),
      ...(shouldConsiderOpenings ? this.holes : []).map(hole => (isClockwise(hole.points) ? hole.points : hole.points.slice(0).reverse()).map(point => ({ X: point.x, Y: point.y })))
    ]);

    const boundingBox = surfaceClipper.shapeBounds();

    const gridLines = [];

    // 0.001 is to go slightly outside of the bounding box, to avoid having a repeating line fall exactly on a vertex
    // spacingMetricValue * 0.05 is to avoid last line to fall too close to the last beam

    // VERTICAL LINES (right now suppose 0 degree)
    if (angleMetricValue === 0) {
      for (let i = boundingBox.left - 0.001; i < boundingBox.right - spacingMetricValue * 0.05; i += spacingDistancePixels) {
        gridLines.push([
          { X: i, Y: boundingBox.top - 0.001 },
          { X: i, Y: boundingBox.bottom + 0.001 },
        ]);
      }
      // HORIZONTAL LINES (suppose 90 degrees)
    } else {
      for (let i = boundingBox.top - 0.001; i < boundingBox.bottom - spacingMetricValue * 0.05; i += spacingDistancePixels) {
        gridLines.push([
          { X: boundingBox.left - 0.001, Y: i },
          { X: boundingBox.right + 0.001, Y: i },
        ]);
      }
    }

    const gridClipper = new Clipper(gridLines, false)

    const intersections = surfaceClipper.intersect(gridClipper);

    return intersections.paths.map(path => new Line(
      this.stores,
      new Point(path[0].X, path[0].Y), new Point(path[1].X, path[1].Y))
    );
  });


  getAreaWithOffset = computedFn((offsetMetricValue: number): number => {
    return this.getOffsetSurface(offsetMetricValue).surface;
  });

  // returns surface object
  getOffsetSurface = computedFn((offsetMetricValue: number): Surface => {
    if (isEmpty(this.points)) {
      return new Surface(this.stores);
    }
    const offsetDistancePixels = offsetMetricValue * DRAWING_SCALE;

    const clipper = new Clipper([this.points.map(point => ({ X: point.x, Y: point.y }))]);
    const polyline = clipper.offset(offsetDistancePixels, { jointType: 'jtMiter' });

    const retval = new Surface(this.stores, polyline.paths[0].map(pointArray => new Point(pointArray.X, pointArray.Y)));

    retval.holes = this.holes.slice(0);

    return retval;
  });
}