import ModelBase from "models/ModelBase";
import FirebaseStore from "stores/FirebaseStore";

export function handleChange<T>(model: T, property: keyof T) {
  return (event) => {
    const value = event?.value ?? event?.target?.value;
    model[property] = value;
  };
}

export function handleCheckboxChange<T>(model: T, property: keyof T) {
  return (event, checked) => {
    model[property] = checked;
  }
};

export function handleSliderChangeAndSave<T extends ModelBase>
  (model: T, property: keyof T, store: FirebaseStore<T>) {
  return (event, value) => {
    model[property] = value;
    // This might not be ALWAYS the right property to pass as fieldsToUpdate, e.g.
    // if it is more than 1 level deep inside the model
    store.addEditItemDebounced(model, true, [property]);
  };
}

export function handleChangeAndSave<T extends ModelBase>
  (model: T, property: keyof T, store: FirebaseStore<T>) {
  return (event) => {
    handleChange(model, property)(event);
    // This might not be ALWAYS the right property to pass as fieldsToUpdate, e.g.
    // if it is more than 1 level deep inside the model
    store.addEditItem(model, true, [property]);
  };
}

export function handleCheckboxChangeAndSave<T extends ModelBase>
  (model: T, property: keyof T, store: FirebaseStore<T>) {
  return (event, checked) => {
    handleCheckboxChange(model, property)(event, checked);
    // This might not be ALWAYS the right property to pass as fieldsToUpdate, e.g.
    // if it is more than 1 level deep inside the model
    store.addEditItem(model, true, [property]);
  };
}