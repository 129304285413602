import Globals from 'Globals';
import { Tree } from 'antd';
import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { renderNode } from 'utils/TreeUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { TriangleDownIcon } from '../Icons';

const styles = require('./DrawingCopyDialog.module.scss');

// DONT USE THIS.CONTEXT HERE, this is a tree meant to copy drawings from main project tree only

interface DrawingCopyDialogProps extends IDialogProps {
  // the selected treeNode which is parent to a drawing node (if a drawing currently exists)
  treeNode: TreeNode,
  onConfirm: (treeNode: TreeNode) => void,
}

// should better think how to store selected node with multiple trees shown
interface DrawingCopyDialogState {
  selectedNode: TreeNode,
}

export default class DrawingCopyDialog extends ObserverComponent<DrawingCopyDialogProps, DrawingCopyDialogState> {
  state = {
    selectedNode: null as TreeNode
  }

  /// bad
  componentDidMount() {
    const { treeNodesStore } = Globals.defaultStores;
    treeNodesStore.allNodes.forEach(node => node.isSelected2 = false);
  }

  shouldDisableFunction = (node: TreeNode) => (
    !node.childDrawingNode || node === this.props.treeNode
  )

  onNodeClick = (event, node: TreeNode) => {
    const { drawToolsStore, treeNodesStore, tasksStore } = Globals.defaultStores;

    node = treeNodesStore.getItem(node.id);

    if (!getSafe(() => event.nativeEvent.target.className.includes('WorksheetsTreeNode'))) {
      return;
    }

    if (this.shouldDisableFunction(node)) {
      return;
    }

    treeNodesStore.allNodes.forEach(node => node.isSelected2 = false);
    node.isSelected2 = true;
    this.setState({ selectedNode: node });
  }

  onExpand = (unused, { node: { id }, expanded }) => {
    const { treeNodesStore } = this.context;
    const nodeToChange = treeNodesStore.getItem(id);

    nodeToChange.isExpanded2 = expanded;
  }


  _treeView

  set treeView(value) {
    const { treeNodesStore } = Globals.defaultStores;
    const { rootNode, selectedTreeNode: projectSelectedNode } = treeNodesStore;

    this._treeView = value;

    if (value) {
      const domTree = ReactDOM.findDOMNode(value);
      domTree.querySelector('#node_' + (projectSelectedNode.parent || projectSelectedNode).id)?.scrollIntoView?.();
    }
  }

  get treeView() {
    return this._treeView;
  }

  _render() {
    // should always show the main project tree, not one from context
    const { treeNodesStore } = Globals.defaultStores;

    const { open, dialogId, onConfirm } = this.props;
    const { rootNode, selectedTreeNode: projectSelectedNode } = treeNodesStore;
    const { selectedNode } = this.state;

    return (
      <ConfirmDialog
        dialogId={dialogId}
        open={open}
        title={<div>
          <span>
            {i18n.t('Choose drawing to copy to')}&nbsp;
          </span>
          <b>{projectSelectedNode.name}</b>
        </div>}
        onConfirm={() => onConfirm(selectedNode)}
        yesLabel={i18n.t('Copy')}
        noLabel={i18n.t('Cancel')}
        isActionButtonDisabled={!selectedNode}
        content={
          <div className={styles.root}>
            <Tree
              ref={ref => this.treeView = ref}
              showLine
              treeData={[rootNode]}
              fieldNames={{ title: 'name', key: 'id', children: 'items' }}
              titleRender={renderNode(true, 'isSelected2', this.shouldDisableFunction)}
              onClick={this.onNodeClick}
              switcherIcon={<TriangleDownIcon />}
              selectedKeys={[]}
              expandedKeys={treeNodesStore.allVisibleNodes.filter(n => n.hasNonDrawingRootChildren && n.isExpanded2).map(n => n.id)}
              onExpand={this.onExpand}
            />

            {selectedNode && (
              <div className={styles.message}>
                <span>
                  {i18n.t('Copy drawing from')}
                </span>
                <b>{selectedNode.name}</b>
                <span>{i18n.t('to')}</span>
                <b>{projectSelectedNode.name}</b>?
              </div>
            )}
          </div>
        }

      />
    );
  }
}