
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ReloadIcon from '@material-ui/icons/Refresh';
import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import * as React from 'react';
import i18n from 'utils/i18n';
import { forceReloadPage } from 'utils/Utils';

const styles = require('./ErrorDialog.module.scss');

export interface ErrorDialogProps extends IDialogProps {
  error: string,
}

export default class ErrorDialog extends ObserverComponent<ErrorDialogProps> {
  _render() {
    const { open, error } = this.props;
    return (
      <Dialog
        className={styles.root}
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={styles.title}>
          <ErrorIcon className={styles.errorIcon} />
          <div>{i18n.t('An error has occurred')}</div>
        </DialogTitle>

        <DialogContent>
          <h1>{i18n.t('If this message appears after reloading the page, please contact us at')} <a href="mailto:support@evalumo.com">support@evalumo.com</a> {i18n.t('or')} <a href="tel:1-866-766-3313">1-866-766-3313</a></h1>
          <br/>
          <DialogContentText>
            <pre>{error.substring(0, 500)}</pre>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => forceReloadPage()}>
            <ReloadIcon />&nbsp;
            {i18n.t('Reload Page and Try Again')}

            </Button>
        </DialogActions>

      </Dialog>
    )
  }
}