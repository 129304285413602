// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { IDialogProps } from 'constants/CommonProps';
import { compact } from 'lodash';
import Settings from 'models/Settings';
import * as React from 'react';
import i18n from 'utils/i18n';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import ObserverComponent from '../ObserverComponent';
import SettingsDetailsForm from '../SettingsDetailsForm/SettingsDetailsForm';

interface ISettingsEditDialog extends IDialogProps {
  settings: Settings,
}

export default class SettingsEditDialog extends ObserverComponent<ISettingsEditDialog> {
  _render() {
    const { settingsStore, userInfoStore } = this.context;
    const { open, dialogId, settings } = this.props;

    return (
      <EditDialogComponent
        dialogId={dialogId}
        open={open}
        models={compact([
          settings,
          userInfoStore.user,
          userInfoStore.nonImpersonatedEmail !== userInfoStore.userEmail && userInfoStore.nonImpersonatedUser
        ])}
        stores={compact([
          settingsStore,
          userInfoStore,
          userInfoStore.nonImpersonatedEmail !== userInfoStore.userEmail && userInfoStore
        ])}
        FormComponent={SettingsDetailsForm}
        title={i18n.t('Settings')}
        shouldHideCancel
        shouldDisableUndo
      />
    );
  }
}