import { Button, IconButton, Menu } from '@material-ui/core';
import PaintIcon from '@material-ui/icons/FormatColorFill';
import ObserverComponent from 'components/common/ObserverComponent';
import { omit } from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import { ChromePicker, CompactPicker } from 'react-color';
import i18n from 'utils/i18n';


const styles = require('./ColorButton.module.scss');

interface ColorButtonProps {
  color: string,
  className: string,
  onChange: (color: any) => void,
  onClose: (shouldSave: boolean) => void,
  width?: number,
  height?: number,
}

export default class ColorButton extends ObserverComponent<ColorButtonProps> {
  buttonRef;

  handleClose(shouldSave = true, popupState) {
    popupState.close();
    this.props.onClose?.(shouldSave);
  }

  _render() {
    const { color, onChange, className, width, height } = this.props;

    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => {
          const iconButtonProps = bindTrigger(popupState);
          this.buttonRef?.parentElement?.classList?.toggle('isPopupOpen', popupState.isOpen);

          return (
            <React.Fragment>
              <div ref={ref => this.buttonRef = ref} className={styles.button + ' ' + className}>
                <IconButton
                  {...omit(iconButtonProps, 'onClick')}
                  onClick={e => { e.preventDefault(); e.stopPropagation() }}
                  onClickCapture={e => { e.preventDefault(); e.stopPropagation(); iconButtonProps.onClick(e) }}
                  style={{ width: 'auto', height: 'auto' }}
                >
                  <div className={styles.swatch} style={{padding: width < 19 ? 1 : 5}}>
                    <div className={styles.color} style={{ backgroundColor: color, width, height }} />

                    {!color && (<PaintIcon className={styles.paintIcon} />)}
                  </div>
                </IconButton>
              </div>

              <Menu {...bindMenu(popupState)}>
                <li className={styles.root}>
                  <CompactPicker color={color} onChange={onChange} disableAlpha />
                  <ChromePicker color={color} onChange={onChange} disableAlpha />
                </li>
                <li className={styles.bottomButtons}>
                  {/* not implemented <Button onClick={() => this.handleClose(false, popupState)} color="primary">
                    {i18n.t('Cancel')}
          </Button>*/}

                  <Button onClick={() => this.handleClose(true, popupState)} color="primary" variant="contained">
                    {i18n.t('OK')}
                  </Button>
                </li>
              </Menu>
            </React.Fragment>
          );
        }}
      </PopupState>
    );
  }
}
