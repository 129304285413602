import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { ReportTasksFilter, ReportTotalsGrouping } from 'constants/ReportOptionsConstants';
import { round } from 'lodash';
import * as React from 'react';
import { sumPrices } from 'utils/CurrencyUtil';
import i18n from 'utils/i18n';
import { formatCurrency, formatPercentage } from 'utils/NumberFormatter';
import { isFilterAffectingTotal } from 'utils/ReportUtil';

const styles = require('./ReportTotal.module.scss');

interface ReportTotalProps {
  shouldPageBleed: boolean,
}

export default class ReportTotal extends ObserverComponent<ReportTotalProps> {

  _render() {
    const { shouldPageBleed } = this.props;
    const { settingsStore, totalsStore, reportsStore } = this.context;

    const report = reportsStore.selectedItem;
    const { totalsGrouping, tasksFilter } = report;
    const logoColor = report?.logoColors?.color1 || 'rgb(24,39,53)';
    const { taxes, fees, areFeesIncludedInItemPrice } = settingsStore.settings;

    const shouldShowSubtotalOnly = totalsGrouping === ReportTotalsGrouping.SubtotalOnly || isFilterAffectingTotal(tasksFilter);

    let { subtotalMaterial, subtotalLabour, subtotalBeforeFees, subtotalWithFees, subtotalMaterialWithFees, subtotalHours, subtotal, shouldShowReportTimeTotals } = report;

    if (tasksFilter === ReportTasksFilter.MaterialOnly) {
      subtotalBeforeFees = report.subtotalMaterialBeforeFees;
      subtotal = report.subtotalMaterial;
    } else if (tasksFilter === ReportTasksFilter.LabourOnly) {
      subtotalBeforeFees = report.subtotalLabourBeforeFees;
      subtotal = report.subtotalLabour;
    }

    return (
      <table className={classnames(styles.root, 'keep-together', { [styles.shouldPageBleed]: shouldPageBleed })}>
        <tbody>
          {!shouldShowSubtotalOnly && (
            <>
              <tr>
                <td className={styles.label}>{i18n.t('Material')}</td>
                <td className={styles.value}>{formatCurrency(subtotalMaterial)}</td>
                <td />{/* emtpy td is to allow background color bleed of grand total */}
              </tr>
              <tr>
                <td className={styles.label}>{i18n.t('Labour & misc.')}</td>
                <td className={styles.value}>{formatCurrency(subtotalLabour)}</td>
                <td />
              </tr>
            </>
          )}
          <tr className={styles.underline}>
            <td className={styles.label}>{
              areFeesIncludedInItemPrice
                ? i18n.t('Subtotal')
                : i18n.t('Subtotal before fees')
            } {shouldShowReportTimeTotals && `(${round(subtotalHours, 1)} h.)`} </td>
            <td className={styles.value}>{formatCurrency(subtotal)}</td>
            <td />
          </tr>
          <tr>
            <td colSpan={3} />
          </tr>
          {!areFeesIncludedInItemPrice && (<>{
            fees
              .filter(fee => fee.percentage)
              .map((fee, index) => (
                <tr key={'f' + index}>
                  <td className={styles.label}>
                    {fee.name}&nbsp;({formatPercentage(fee.percentage)})&nbsp;
                  </td>
                  <td className={styles.value}>{formatCurrency(totalsStore.feesTotalsByFee.get(fee))}</td>
                  <td />
                </tr>
              ))}
            <tr className={styles.underline}>
              <td className={styles.label}>{i18n.t('Subtotal')}</td>
              <td className={styles.value}>{formatCurrency(subtotalWithFees)}</td>
              <td />
            </tr>
            <tr>
              <td colSpan={3} />
            </tr>
          </>
          )}
          {taxes.map((tax, index) => (
            <tr key={'t' + index} className={index === taxes.length - 1 ? styles.lastTax : ''}>
              <td className={styles.label}>
                {tax.name}&nbsp;({formatPercentage(tax.percentage)})&nbsp;
                {tax.number && <span className={styles.taxNumber}>#{tax.number}</span>}
              </td>
              <td className={styles.value}>{formatCurrency((tax.shouldTaxExcludeLabour ? subtotalMaterialWithFees : subtotalWithFees) * tax.percentage / 100)}</td>
              <td />
            </tr>
          ))}
          <tr className={styles.grandTotal} style={{ backgroundColor: logoColor }}>
            <td className={styles.label}>{i18n.t('Total')}</td>
            <td className={styles.value}>{formatCurrency(sumPrices([subtotalWithFees, ...taxes.map(tax => (tax.shouldTaxExcludeLabour ? subtotalMaterialWithFees : subtotalWithFees) * tax.percentage / 100)]))}</td>
            <td />
          </tr>
        </tbody>
      </table>
    )
  }
}