
import ObserverComponent from 'components/common/ObserverComponent';
import { CategoryType } from 'constants/CategoryType';
import { IDialogProps } from 'constants/CommonProps';
import { groupBy, isEmpty } from 'lodash';
import { computed } from 'mobx';
import Category from 'models/Category';
import ModelBaseWithCategory from 'models/ModelBaseWithCategories';
import * as React from 'react';
import { getCategoryTypeForItem } from 'utils/CategoryUtil';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const styles = require('./CategoryMoveDialog.module.scss');

interface CategoryMoveDialogProps<T extends ModelBaseWithCategory> extends IDialogProps {
  models: T[],
  title?: string | JSX.Element,
  yesLabel?: string,
  actionButtonColor?: string,
  onConfirm?: () => void,
}

// should better think how to store selected node with multiple trees shown
interface CategoryMoveDialogState {
  category: Category,
  subcategory: Category,
}

export default class CategoryMoveDialog<T extends ModelBaseWithCategory> extends ObserverComponent<CategoryMoveDialogProps<T>, CategoryMoveDialogState> {
  state = {
    category: null,
    subcategory: null,
  }

  onConfirm = () => {
    const { models, categoryType } = this.props;
    const { category, subcategory } = this.state;
    const { tasksListsStore, providingItemsStore, measurementsStore, tasksStore, projectsStore } = this.context;

    const modelsByType = groupBy(models, 'type');

    const batch = firestoreBatch();

    Object.keys(modelsByType).forEach(modelType => {
      modelsByType[modelType].forEach(model => {
        model.category = category;
        model.subcategory = subcategory;
      });

      let store;
      switch (modelType) {
        case 'Project':
          store = projectsStore;
          break;
        case 'ProvidingItem':
          store = providingItemsStore;
          break;
        case 'Task':
          store = tasksStore;
          break;
        case 'TasksList':
          store = tasksListsStore;
          break;
        default:
          store = measurementsStore;
          break;
      }

      store.addEditItems(modelsByType[modelType], true, ['categoryId', 'subcategoryId'], batch);
      store.selectedItems.clear();
    });

    batch.commit();

    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  @computed get categoryType(): CategoryType {
    const { models } = this.props;

    // expects all selected items to be of same type, but could easily adjust if need more than one category type
    return getCategoryTypeForItem(models?.[0]);
  }

  _render() {
    const { open, dialogId, models, title, yesLabel, actionButtonColor } = this.props;
    const { category, subcategory } = this.state;

    if (isEmpty(models)) {
      return null;
    }

    return (
      <ConfirmDialog
        dialogId={dialogId}
        open={open}
        title={title || i18n.t('moveItems', { count: models.length })}
        onConfirm={this.onConfirm}
        yesLabel={yesLabel || i18n.t('Move')}
        noLabel={i18n.t('Cancel')}
        isActionButtonDisabled={!category && !subcategory}
        actionButtonColor={actionButtonColor}
        content={
          <div className={styles.root}>
            <CategoriesCombobox
              allowShowEveryCategory
              categoryTypes={[this.categoryType]}
              category={category}
              onChange={(category: Category) => this.setState({ category })}
            />

            <CategoriesCombobox
              parentCategory={category}
              category={subcategory}
              onChange={(subcategory: Category) => this.setState({ subcategory })}
            />
          </div>
        }
      />
    );
  }
}