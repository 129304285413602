import { isNumber, omit } from 'lodash';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { roundPrice } from './CurrencyUtil';
import i18n from './i18n';

export const formatPercentage = (value: number, wrapInNonBreakableSpan = false): string | JSX.Element => {
  const percent = (formatDecimal(value) || 0) + ' %';

  return wrapInNonBreakableSpan
    ? <span style={{whiteSpace: 'nowrap'}}>{percent}</span>
    : percent;
}

export const formatDecimal = (value: number): string => (
  i18n.decimalFormatter.format(value)
)

// wrapInNonBreakableSpan not sur if a good idea or not
export function formatCurrency(value: number, wrapInNonBreakableSpan = false, shouldRound = false): JSX.Element | string {
  if (value && value !== roundPrice(value)) {
   // debugger;
  }

  const formatter = shouldRound ? i18n.roundedCurrencyFormatter : i18n.currencyFormatter;
  const price = isNumber(value)
    ? formatter.format(value).replace(' CA', '') // ugly fix for bug in intl after Chrome update
    : '';

  return wrapInNonBreakableSpan
    ? <span style={{whiteSpace: 'nowrap'}}>{price}</span>
    : price;
}

export function parseCurrency(value: string): number {
  const numericValue = parseFloat(value);

  return numericValue;
}

// todo: currency symbol is not always a suffix, depending on the locale
export const InputNumberFormatBase = (suffix = '', decimalScale = 2, fixedDecimalScale = false, prefix = '') => (props) => {
  const { inputRef, onChange } = props;

  return (
    <NumberFormat
      {...omit(props, 'inputRef', 'onChange')}
      allowEmptyFormatting
      getInputRef={inputRef}
      onValueChange={(values, isUserEvent) => {
        if (values.value == (parseFloat(props.value) || 0).toFixed(Math.max(decimalScale, 2))) {
          // avoids losing precision during a temporary change
          // mostly on prices because they only have 2 decimals
          // However this can create rounding errors later on unless we do round
          // them before saving to db
          return;
        }
        onChange({
          target: {
            value: values.floatValue,
            formattedValue: values.formattedValue,
          },
          isUserEvent
        });
      }}
      thousandSeparator=""
      decimalSeparator="."
      suffix={suffix && ` ${suffix}`}
      prefix={prefix}
      decimalScale={fixedDecimalScale ? decimalScale : undefined}
      fixedDecimalScale={fixedDecimalScale}
      onKeyDown={(e) => {
        const { key, target } = e;
        //@ts-ignore
        const { selectionStart, value } = target;

        if (
          selectionStart === 0 && 
          value == /*not triple equal*/ 0 &&
          key.match(/[0-9]/)
        ) {
          target.value = '';
        }

        if (key === '.') {
          // TODO: cannot make comma work as decimal separator
          //e.preventDefault();
          //@ts-ignore
          //target.value = `${value.substr(0, selectionStart)},${value.substr(selectionStart, value.length)}`;
          //target.value = target.value.replace('.',',')
        }
      }}
    />
  );
}

export const InputNumberFormatCurrency = InputNumberFormatBase('$', 2, true);
export const InputNumberFormatHour = InputNumberFormatBase('h');
export const InputNumberFormatMeter = InputNumberFormatBase('m');
export const InputNumberFormatMilimeter = InputNumberFormatBase('mm', 0);
export const InputNumberFormatLabourDuration = InputNumberFormatBase('h', 6);
export const InputNumberFormatHourlyRate = InputNumberFormatBase('$/h');
export const InputNumberFormatWholePercent = InputNumberFormatBase('%', 0);
export const InputNumberFormatPercent = InputNumberFormatBase('%', 1);
export const InputNumberFormatPercentTax = InputNumberFormatBase('%', 6);
export const InputNumberFormatInteger = InputNumberFormatBase('', 0);
export const InputNumberFormatDecimal = InputNumberFormatBase('', 6);

// using ' and " instead of ft. in. because not straightforward to translate
export const InputNumberFormatFeet = InputNumberFormatBase("'", 0);
export const InputNumberFormatInches = InputNumberFormatBase('"', 2);
export const InputNumberMetricScale = InputNumberFormatBase('', 0, true, '1 : ');
