import ProvidingItemEditDialog from 'components/common/ProvidingItemEditDialog/ProvidingItemEditDialog';
import Dialog from 'models/Dialog';
import ProvidingItem from 'models/ProvidingItem';
import Task from 'models/Task';
import * as React from 'react';
import Stores from 'stores/Stores';

export const showProvidingItemDialog = (providingItem: ProvidingItem, parentTask: Task) => {
  const { stores } = providingItem;
  const { dialogsStore } = stores;

  const newDialog = new Dialog(stores);
  newDialog.dialogComponent = ({ open }) => (
    <ProvidingItemEditDialog open={open} dialogId={newDialog.id} providingItem={providingItem} task={parentTask} />
  )
  dialogsStore.showDialog(newDialog);
}

export const onProvidingItemDrop = (task: Task) => async (event) => {
  if (!task) {
    return;
  }

  const { dragAndDropStore, tasksStore, providingItemsStore } = task.stores;

  if (!(dragAndDropStore.dragObject instanceof ProvidingItem)) {
    dragAndDropStore.dragObject = null;
    return;
  }

  const providingItem: ProvidingItem = dragAndDropStore.dragObject as ProvidingItem;
  task.providingItem = providingItem;

  await providingItem.waitOnMetaReady();
  providingItemsStore.ensureSavedInProjectCollection([providingItem]);

  tasksStore.addEditItem(task);

  dragAndDropStore.dragObject = null;
}

export const showProvidingItemRatesChangeDialog = (context: Stores) => () => {
 /* const CategoryEditDialog = require('components/common/CategoryEditDialog/CategoryEditDialog').default;

  const { categoriesStore, dialogsStore } = stores;
  const newDialog = new Dialog(stores);
  newDialog.dialogComponent = ({ open }) => (
    <CategoryEditDialog
      open={open}
      dialogId={newDialog.id}
      category={category}
      onClose={(shouldSave, modelCopies) => {
        if (shouldSave && modelCopies) {
          const newCategory = modelCopies[0] as Category;
          assignToExistingObject(category, newCategory);

          // save right away or wait to press ok button?
          categoriesStore.addEditItem(modelCopies[0] as Category);
        }
      }}
    />
  )
  dialogsStore.showDialog(newDialog);*/
}