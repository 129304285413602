import { Unit } from "constants/Unit";
import { UnitType } from "constants/UnitType";
import { memoize } from 'lodash';
import i18n from 'utils/i18n';

export const formatUnit = memoize((unit: Unit, useShortFormat: boolean = true, lang = i18n.language): string => {
  const shortStrings = {
    [Unit.Unknown]: '',
    [Unit.DefaultMetric]: '-',
    [Unit.CubicFoot]: 'cu. ft.',
    [Unit.CubicYard]: 'cu. yd.',
    [Unit.Liter]: 'liter(s)',
    [Unit.USGallon]: 'gallon(s)',
    [Unit.US5Gallon]: '5-gallon(s)',
    [Unit.CubicMeter]: 'cu. m.',
    [Unit.Foot]: 'ft.',
    [Unit.Inch]: 'in.',
    [Unit.Centimeter]: 'cm.',
    [Unit.Kilometer]: 'km.',
    [Unit.Milimeter]: 'mm.',
    [Unit.Meter]: 'm.',
    [Unit.SquareFoot]: 'sq. ft.',
    [Unit.SquareInch]: 'sq. in.',
    [Unit.SquareYard]: 'sq. yd.',
    [Unit.SquareMeter]: 'sq. m.',
    [Unit.Unit]: 'unit(s)',
    [Unit.Pack]: 'pack(s)',
    [Unit.Percent]: '%',
    [Unit.Box]: 'box(es)',
    [Unit.Kilogram]: 'kg.',
    [Unit.CurrencySign]: i18n.currencySign,
    //[Unit.BritTon]: '. tonne.',
    [Unit.USTon]: 'imp. ton.',
    // Most units dont need a point at the end, should fix
    [Unit.Pound]: 'lb.',
    [Unit.MetricTon]: 'm. ton.',
    [Unit.Hour]: 'h.',
    [Unit.Minute]: 'min.',
    [Unit.Day]: 'day(s)',
    [Unit.Month]: 'month(s)',
    [Unit.Year]: 'year(s)',
    [Unit.Week]: 'week(s)',
    [Unit.Budget]: 'budget',
    [Unit.Allocation]: 'alloc.',
    //[Unit.TimeBlock]: 'time block(s)',
    [Unit.Truck]: 'truck(s)',
    [Unit.Step]: 'step(s)',
    [Unit.Ratio]: ': 12',
    // metric slope?
    // and other types of slope like excavation which are : 1  eg. 3/4 : 1
    [Unit.SlopePercentage]: '%',
    [Unit.Degree]: 'deg.',
    [Unit.Radian]: 'rad',
    [Unit.Unitless]: ''
  }

  const longStrings = {}; //TODO

  if (!shortStrings[unit]) {
    return '';
  }

  return i18n.t(shortStrings[unit]);
}, (a, b, c) => {
  return [a || '', b || true, c || i18n.language].join('#');
});

export function formatUnitType(unitType: UnitType): string {
  const longStrings = {
    [UnitType.Unknown]: 'Unspecified',
    [UnitType.Surface]: 'Surface',
    [UnitType.Length]: 'Length',
    [UnitType.Volume]: 'Volume',
    [UnitType.Weight]: 'Weight',
    [UnitType.Ratio]: 'Ratio',
    [UnitType.Unit]: 'Units',
    [UnitType.Time]: 'Time',
    [UnitType.Pack]: 'Packs',
    [UnitType.Angle]: 'Angle',
    [UnitType.RoofSlope]: 'Roof Slope',
    [UnitType.FixedPrice]: 'Price',
    [UnitType.Projection]: 'Projection',
  }

  return i18n.t(longStrings[unitType]);
}

export function getUnitTypeForUnit(unit: Unit) {
  const unitTypes = Array.from(getUnitsByUnitType().keys());
  return unitTypes.find(
    unitType => getUnitsByUnitType().get(unitType).includes(unit)
  );
}

const unitsByUnitType = new Map<UnitType, Unit[]>();
unitsByUnitType.set(
  UnitType.Surface,
  [Unit.SquareFoot, Unit.SquareInch, Unit.SquareYard, Unit.SquareMeter],
);
unitsByUnitType.set(
  UnitType.Length,
  [Unit.Foot, Unit.Inch, Unit.Meter, Unit.Centimeter, Unit.Milimeter, Unit.Kilometer],
);
unitsByUnitType.set(
  UnitType.Volume,
  [Unit.CubicFoot, Unit.CubicYard, Unit.CubicMeter, Unit.Liter, Unit.USGallon, Unit.US5Gallon],
);
unitsByUnitType.set(
  UnitType.Unit,
  [Unit.Unit, Unit.Budget, Unit.Allocation, Unit.Truck, Unit.Step],
);
unitsByUnitType.set(
  UnitType.Pack,
  [Unit.Pack, Unit.Box]//, Unit.TimeBlock],
);
unitsByUnitType.set(
  UnitType.Ratio,
  [Unit.Percent],
);
unitsByUnitType.set(
  UnitType.Angle,
  [Unit.Degree],
);
unitsByUnitType.set(
  UnitType.RoofSlope,
  [Unit.Ratio],
);
unitsByUnitType.set(
  UnitType.Time,
  [Unit.Hour, Unit.Minute, Unit.Day, Unit.Week, Unit.Month, Unit.Year],
);
unitsByUnitType.set(
  UnitType.Weight,
  [/*Unit.BritTon, */Unit.Kilogram, Unit.Pound, Unit.USTon, Unit.MetricTon],
);
unitsByUnitType.set(
  UnitType.FixedPrice,
  [Unit.CurrencySign]
);
unitsByUnitType.set(
  UnitType.Unknown,
  [Unit.Unknown],
);
unitsByUnitType.set(
  UnitType.Projection,
  [Unit.Unitless],
);
export function getUnitsByUnitType(): Map<UnitType, Unit[]> {
  return unitsByUnitType;
}
