
import classnames from 'classnames';
import ColorButton from 'components/common/ColorButton/ColorButton';
import MeasurementsCombobox from 'components/common/MeasurementsCombobox/MeasurementsCombobox';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';

const styles = require('./DrawToolsOptionsComponent.module.scss');

interface DrawToolsOptionsComponentProps {
  disabled?: boolean,
}



export default class DrawToolsOptionsComponent extends ObserverComponent<DrawToolsOptionsComponentProps> {
  onChangeCountSelectedMeasurement = (measurement, action) => {
    const { drawToolsStore } = this.context;

    drawToolsStore.selectedCountToolMeasurement = measurement;
  }

  _render() {
    const { drawToolsStore, measurementsStore } = this.context;
    const { disabled } = this.props;

    return (
      <div className={classnames(styles.root, { [styles.disabled]: disabled })}>
        {/* refactor if other tools have options, for now only count */}
        <MeasurementsCombobox
          selectedMeasurement={drawToolsStore.selectedCountToolMeasurement}
          onChange={this.onChangeCountSelectedMeasurement}
        />
        {drawToolsStore.selectedCountToolMeasurement && (
          <ColorButton
            className={styles.colorButton}
            color={drawToolsStore.selectedCountToolMeasurement.legendColor}
            onChange={color => {
              drawToolsStore.selectedCountToolMeasurement.legendColor = color.hex;
              measurementsStore.addEditItemLongDebounced(drawToolsStore.selectedCountToolMeasurement, true, ['legendColor'])
            }}
          />
        )}
      </div>
    );
  }
}