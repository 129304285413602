import { BuiltinCategories } from 'constants/BuiltinCategories';
import { CategoryType } from 'constants/CategoryType';
import { DbLocationType } from 'constants/DbLocationType';
import { compact, filter, first, groupBy, uniq } from 'lodash';
import { action, computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import Category from 'models/Category';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { getSafe, shouldUseCache } from 'utils/Utils';
import SearchableFirebaseStore from './SearchableFirebaseStore';

export default class CategoriesStore extends SearchableFirebaseStore<Category> {
  sortField = 'index';
  storeKey = 'categories';

  dbLocationsTypes = new Set([
    DbLocationType.Master, 
    DbLocationType.User, 
    DbLocationType.Project
  ]);


  hasCategories = false;

  shouldKeepUserItems = true;

  isProjectDbOptional = true;

  @observable taskCategoryBeingEdited: Category = null;

  @observable categoryBeingFilteredIn: Category = null;

  // expanded by default
  @observable collapsedTaskCategories: Set<Category> = new Set<Category>([]);

  getCategoryIdWithoutSubtype = (fullId: string) => first((fullId).split(':'))

  @computed get generalCategory() {
    return this.getItem(BuiltinCategories.General);
  }

  async getItemAsync(key: ModelId, allowWait = true, allowDbGet = false): Promise<Category> {
    if (!key) {
      return this.generalCategory;
    }

    return super.getItemAsync(key, allowWait, allowDbGet);
  }

  @computed get rootCategories() {
    return [this.generalCategory, ...this.items.filter(item => !item.parentCategory)];
  }

  getItemsByParentCategory = computedFn((parentCategory?: Category) => (
    getSafe(() => this.items
      .find(category => category.id === parentCategory.id)
      .children
    ) || []
  ));

  getItemByOriginalName = computedFn(({ name, parentCategory }: { name: string, parentCategory?: Category }) => {
    // not sure what this really does!!
    const items = parentCategory
      ? this.getItemsByParentCategory(parentCategory)
      : this.items;


    return items.find(item => item.originalName && item.originalName === name || item.name === name)
  })

  getRootCategoriesByType = computedFn((type: CategoryType) => (
    this.rootCategories.filter(item => item?.categoryTypes?.includes(type))
  ));

  getRootCategoriesByTypes = computedFn((types: CategoryType[]) => (
    this.rootCategories.filter(item => item?.categoryTypes?.find(categoryType => types.includes(categoryType)))
  ));

  @computed get areAllVisibleTaskCategoriesCollapsed() {
    const { treeNodesStore, tasksStore } = this.stores;
    if (!treeNodesStore.selectedTreeNode) {
      return false;
    }

    return (
      tasksStore.itemsCategories.every(
        categ => this.collapsedTaskCategories.has(categ)
      )
    );
  }

  /* override */ addCachedMasterItems() {
    if (!shouldUseCache()) {
      return;
    }

    this.cachedMasterData = require('assets-sw/db-cache/categories.json');
    this.applyCachedData(this.cachedMasterData);
  }

  @action
  toggleAllTaskCategories = () => {
    const { treeNodesStore, tasksStore } = this.stores;
    if (!treeNodesStore.selectedTreeNode) {
      return;
    }

    if (this.areAllVisibleTaskCategoriesCollapsed) {
      this.collapsedTaskCategories.clear();
    } else {
      (tasksStore.itemsCategories).forEach(
        categ => this.collapsedTaskCategories.add(categ)
      );
    }
  }


  // make sure taskslist are properly loaded
  // a bit dangerous to use with task categories
  @computed get unusedCategories() {
    const { providingItemsStore, measurementsStore, tasksStore, tasksListsStore } = this.stores;
    return this.items.filter(category => (
      category.id !== BuiltinCategories.General &&
      !providingItemsStore.items.find(item => item.categoryId === category.id || item.subcategoryId === category.id) &&
      !measurementsStore.items.find(item => item.categoryId === category.id || item.subcategoryId === category.id) //&&
      //!tasksStore.items.find(item => item.categoryId === category.id || item.subcategoryId === category.id) &&
      //!tasksListsStore.items.find(item => item.categoryId === category.id || item.subcategoryId === category.id)
    ));
  }

  @action cleanupCategoryTypesAndParent() {
    const { providingItemsStore, measurementsStore, tasksListsStore } = this.stores;

    // adjust category types with contained items
    // not possible to do for tasks however
    this.items.forEach(category => {
      category.categoryTypes = compact([
        providingItemsStore.materialItems.some(item => item.category === category || item.subcategory === category) && CategoryType.Material,
        providingItemsStore.labourItems.some(item => item.category === category || item.subcategory === category) && CategoryType.Labour,
        measurementsStore.items.some(item => item.category === category || item.subcategory === category) && CategoryType.Measurement,
        category.categoryTypes.includes(CategoryType.Task) && CategoryType.Task,
        category.categoryTypes.includes(CategoryType.TasksList) && CategoryType.TasksList,
      ]);
    });

    [...providingItemsStore.items, ...measurementsStore.items].forEach(item => {
      if (
        item.subcategory.id !== BuiltinCategories.General &&
        !item.category.childrenIds.includes(item.subcategoryId)
      ) {
        item.category.childrenIds.push(item.subcategoryId);
      }
    });

    this.addEditItems(this.items, true, ['categoryTypes', 'childrenIds']);
  }

  @action removeDuplicates() {
    const { providingItemsStore, measurementsStore, tasksListsStore } = this.stores;
    const duplicates = filter(
      groupBy(this.rootCategories, 'name'),
      categories => categories.length > 1
    );

    const batch = firestoreBatch();
    duplicates.forEach(categories => {
      // if categ as an alphabetic key, it means it's a builtin item
      const categoryToKeep = categories.find(categ => !categ.id.match(/[0-9]/)) || first(categories);
      const categoryIdsToDelete = categories
        .filter(category => category !== categoryToKeep)
        .map(category => category.id);

      // cleanup underscores once happy with categories and ready to export to json
      const __imageUrl = categories.find(categ => categ.imageUrl)?.__imageUrl;
      const __thumbUrl = categories.find(categ => categ.thumbUrl)?.__thumbUrl;

      if (__imageUrl) {
        categoryToKeep.__imageUrl = __imageUrl;
      }
      if (__thumbUrl) {
        categoryToKeep.__thumbUrl = __thumbUrl;
      }

      // doesn't seem to work
      categoryToKeep.childrenIds = uniq(categories.map(category => category.childrenIds).flat());

      const stores = [providingItemsStore, measurementsStore, tasksListsStore];

      stores.forEach(store => {
        store.items
          .filter(item => categoryIdsToDelete.includes(item.categoryId))
          .forEach(item => {
            item.categoryId = categoryToKeep.id;
            store.addEditItem(item, true, ['categoryId'], batch);
          });
      });

      this.deleteItems(categoryIdsToDelete, true, batch);
    });

    batch.commit();
  }
}

/* batch importing categories

const categs = JSON.parse(`["07 07- Uréthane giclée","05 05- Métaux ouvrés (m.o et mat.)"]`)
    .sort()
    .map((categName, index) => {
       const categ = new globals.defaultStores.categoriesStore.items[0].constructor(globals.defaultStores.categoriesStore, categName, ['Task']);
        categ.index = -500+index;
        return categ;
    });

globals.defaultStores.categoriesStore.addEditItems(categs, false); // or true
*/
