import Fee from "models/Fee";
import Tax from "models/Tax";
import Stores from "stores/Stores";

export function getDefaultTaxes(stores: Stores): Tax[] {
  return [
   new Tax(stores, { en: 'GST', fr: 'TPS' }, 5),
   new Tax(stores, { en: 'QST', fr: 'TVQ' }, 9.975),
  ];
}

export function getDefaultFees(stores: Stores): Fee[] {
  const isNewDefaultValue = stores?.userInfoStore?.user?.versionOnCreation >=4;
  return [
   new Fee(stores, { en: 'General fees', fr: 'Frais généraux' }, isNewDefaultValue ? 10 : 0),
   new Fee(stores, { en: 'Administration fees', fr: 'Frais d\'administration' }, isNewDefaultValue ? 10 : 0),
   new Fee(stores, { en: 'Profit', fr: 'Profit' }, isNewDefaultValue ? 12 : 0),
  ];
}

