const styles = require('./SnapLine.module.css');
import ObserverComponent from 'components/common/ObserverComponent';
import Line from 'models/Line';
import * as React from 'react';

interface ISnapLineProps {
  line: Line;
}

export default class SnapLine extends ObserverComponent<ISnapLineProps> {
  public _render(): JSX.Element {
    const { line } = this.props;

    const { settingsStore } = this.context;

    // don't use DimensionLine on snapline, instead add alternative view to angle, that shows dx dy as inputs
    // for example to draw a triangle or parallelogram


    return line && (<>
      <line
        className={styles.default}
        x1={line.points[0].x}
        y1={line.points[0].y}
        x2={line.points[1].x}
        y2={line.points[1].y}
      />
      {
      // if we decide to show dimensions on snap line, we should snap line length to whole number and allow input line length
      /*<DimensionLine
        isImperial={settingsStore.isImperial}
        language={settingsStore.language}
        //--
        lineToMeasure={line}
        // ??
        forceObserve
        readonly
    /> */}
    </>
    )
  }
}
