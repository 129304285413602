
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import RichText2 from 'components/common/RichText2/RichText2';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import i18n from 'utils/i18n';

const styles = require('./ReportNotes.module.scss');
const stylesContent = require('../ReportContentList/ReportContentList.module.scss');

interface ReportNotesProps {
  nodesWithNotes: TreeNode[]
}

export default class ReportNotes extends ObserverComponent<ReportNotesProps> {
  _render() {
    const { treeNodesStore } = this.context;
    const { nodesWithNotes } = this.props;

    // would be cleaner to strip html tags
    const notesLength = nodesWithNotes.map(node => node.description2).reduce((a, b)=> a+b).length;

    return (
      <div className={classnames(styles.root)}>
        <div className={classnames({ ['force-page-break']: notesLength > 500 })} />
        <div>{i18n.t('Notes')}</div>

        <table className={classnames(
          'ReportContentList ',
          stylesContent.root,
        )}>
          {nodesWithNotes.map(node => (
            <React.Fragment key={`thead${node.id}`}>
              <tbody>
                <tr
                  className={classnames(
                    stylesContent.groupRow,
                    {
                      [stylesContent.isSemiEmphasized]: false,
                      [stylesContent.isEmphasized]: false,
                    })}>
                  <th className={stylesContent.groupName} >
                    {treeNodesStore.getPath(node)}
                  </th>
                </tr>
                <div
                  className={classnames(
                    stylesContent.task,
                    stylesContent['indentLevel0'],
                  )}
                >
                  <div className={stylesContent.providingItemCell} style={{paddingLeft: '10px!important'}}>
                    <RichText2 className={styles.richText} model={node} property={'description2'} />
                  </div>
                </div>
              </tbody>
            </React.Fragment>
          ))}

        </table>

      </div>
    )
  }
}