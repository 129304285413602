import * as csv from '@fast-csv/parse';

export function parseCsvString(csvString, options = {}, rowProcessor = r => r) {
  return new Promise((resolve, reject) => {
    const data = [];

    csv
      .parseString(csvString, options)
      .on("error", reject)
      .on("data", (row) => {
        const obj = rowProcessor(row);
        if (obj) data.push(obj);
      })
      .on("end", () => {
        resolve(data);
      });
  });
}