import { computed, observable } from "mobx";
import { serializable } from "serializr";
import Fee from "./Fee";

export default class Tax extends Fee {
  // aka tax id
  @serializable @observable number: string = '';

  @computed get shouldTaxExcludeLabour() {
    return JSON.stringify(this.providingItemsSubtypes) ===  JSON.stringify(['Material']);
  }
}