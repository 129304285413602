import { sumBy } from 'lodash';
import { computed, observable } from 'mobx';
import UndoDataPoint from 'models/UndoDataPoint';
import BaseStore from './BaseStore';

const MAX_MUTATIONS_HISTORY = 5000;

export default class UndoStore extends BaseStore {
  @observable undoDataPoints = [] as UndoDataPoint[];
  @observable currentPointIndex = -1;

  @observable isBusy = false;

  lastDataPointPushTime = 0;

  constructor(stores) {
    super(stores);
    this.reset();
  }

  @computed get mutationsHistoryCount() {
    return sumBy(this.undoDataPoints, (point: UndoDataPoint) => sumBy(point.batches, batch => batch.mutations.length));
  }

  pushDataPoint(dataPoint: UndoDataPoint) {
    if (Date.now() - this.lastDataPointPushTime < 200) {
      // consider as same undo point if too close in time from last undo point
      // could merge mutations instead of batches, but works for now
      this.currentDataPoint.batches = [...this.currentDataPoint.batches, ...dataPoint.batches];
      this.currentDataPoint.rollbackBatches = [...this.currentDataPoint.rollbackBatches, ...dataPoint.rollbackBatches];
    } else {
      this.undoDataPoints = this.undoDataPoints.slice(0, this.currentPointIndex + 1);
      this.undoDataPoints.push(dataPoint);

      if (this.mutationsHistoryCount < MAX_MUTATIONS_HISTORY) {
        this.currentPointIndex++;
      } else {
        this.undoDataPoints.shift();
      }
    }

    this.lastDataPointPushTime = Date.now();
  }

  @computed get currentDataPoint() {
    return this.undoDataPoints[this.currentPointIndex];
  }

  @computed get canUndo() {
    return (
      !this.isBusy &&
      this.currentPointIndex !== -1 &&
      this.undoDataPoints.length - this.currentPointIndex > 0
    );
  }

  @computed get canRedo() {
    return (
      !this.isBusy &&
      this.undoDataPoints.length > this.currentPointIndex + 1
    );
  }

  undo = async () => {
    if (!this.canUndo) {
      console.log('nothing to undo');
      return;
    }

    this.isBusy = true;
    await this.currentDataPoint.rollback();
    this.isBusy = false;

    this.currentPointIndex--;
  }

  redo = async () => {
    if (!this.canRedo) {
      console.log('nothing to redo');
      return;
    }

    this.currentPointIndex++;
    this.isBusy = true;
    await this.currentDataPoint.commit();
    this.isBusy = false;
  }

  reset = () => {
    this.isBusy = false;
    this.undoDataPoints = [];
    this.currentPointIndex = -1;
  }
}
