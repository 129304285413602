
import { IEditableProps, IReportProps } from 'constants/CommonProps';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';

const styles = require('./ReportProjectDescription.module.scss');

export default class ReportProjectDescription extends ObserverComponent<IReportProps & IEditableProps> {
  changeReportInfo = property => ({ target: { value } }) => {
    const { report } = this.props;
    report[property] = value;
  }

  saveToDb = () => {
    const { reportsStore } = this.context;
    const { report } = this.props;
    reportsStore.addEditItem(report, true, ['_description']);
  }

  _render() {
    const { isEditable, report, className } = this.props;
    const { description } = report;

    return isEditable ? (
      <div className={styles.root + ' ' + styles.isEditable}>
        <TextField
          label={i18n.t('Project Description')}
          multiline
          rows="2"
          rowsMax="4"
          value={description}
          onChange={this.changeReportInfo('description')}
          onBlur={this.saveToDb}
        />
      </div>
    ) : description && (
      <div className={styles.root + ' ' + className}>
        <div className={styles.title}>{i18n.t('Project Description')}</div>
        <div className={styles.content}>
          {description}
        </div>
      </div>
    );
  }
}