import FooterComponent from 'components/common/FooterComponent/FooterComponent';
import ObserverComponent from 'components/common/ObserverComponent';
import * as React from 'react';

export default class FooterView extends ObserverComponent {
  _render() {
    return (
      <FooterComponent />
    );
  }
}
