import { Checkbox, List, ListItem, ListSubheader } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import CategoryIcon from '@material-ui/icons/Category';
import DeleteIcon from '@material-ui/icons/Delete'; //Archive
import CopyIcon from '@material-ui/icons/FileCopy';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Globals from 'Globals';
import classnames from 'classnames';
import { BuiltinCategories } from 'constants/BuiltinCategories';
import { compact, first } from 'lodash';
import ModelBaseWithCategory from 'models/ModelBaseWithCategories';
import * as React from 'react';
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import AnimateReorder from 'react-flip-move';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as VirtualizedList } from "react-window";
import { DraggableTypes } from 'stores/DragAndDropStore';
import SearchableFirebaseStore from 'stores/SearchableFirebaseStore';
import { IFlattenedItemsByCategSubcateg, showCategoryEditDialog } from 'utils/CategoryUtil';
import { archiveItems, deleteItem, unarchiveItems } from 'utils/DeleteUtils';
import { getKeyFromFlattenedItem, handleCategoryToggle, handleCheckItem, isCategoryChecked } from 'utils/GroupedListUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import ColorButton from '../ColorButton/ColorButton';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import OrderableItem from '../OrderableItem/OrderableItem';
import OrderableItemsContainer from '../OrderableItemsContainer/OrderableItemsContainer';

const styles = require('./GroupedList.module.scss');

// Annoying flicker when refreshes, should try to fix
interface GroupedListProps<T extends ModelBaseWithCategory> {
  ungroupedItems: T[],
  itemsByCategSubcategFlattened: IFlattenedItemsByCategSubcateg<T>[],
  shouldAllowCheckboxes?: boolean,
  className?: string,
  ItemRenderer: ({ item }: { item: T }) => JSX.Element | React.Component<{ item: T }>,
  store: SearchableFirebaseStore<T>,
  ItemPopupButton?: ({ item }: { item: T }) => JSX.Element | React.Component<{ item: T }>,
  onDuplicateItem?: (item: T) => void,
  onChangeItemCategory?: (item: T) => void,
  itemHeight?: number,
  menuItems?: any[],
  hidePopupMenu?: boolean,
  shouldAllowReorderItems?: boolean,
  hasSubsubcategories?: boolean,
  innerRef?: any,
  canDeleteMasterItems?: boolean,
}

const VIRTUAL_LIST_MINIMUM_ITEMS = 0;

interface RowProps<T extends ModelBaseWithCategory> extends GroupedListProps<T> {
  index: number,
  style: any,
}

// This is bad because the row should always render the same object so we can only refresh subcomponents if needed
// not mount unmount on every scroll
class Row<T extends ModelBaseWithCategory> extends ObserverComponent<RowProps<T>> {
  _render() {
    const { store, onDuplicateItem, hasSubsubcategories } = this.props;
    const { selectedItems } = store;
    const {
      ungroupedItems,
      itemsByCategSubcategFlattened,
      shouldAllowCheckboxes,
      ItemRenderer,
      ItemPopupButton,
      onChangeItemCategory,
      index,
      style,
      hidePopupMenu,
      canDeleteMasterItems,
    } = this.props;

    const rowData = itemsByCategSubcategFlattened[index];

    if (!rowData) {
      return <li>{i18n.t('Loading')}...</li>;
    }

    const { categoriesStore } = this.context;

    const { category, subcategory, subsubcategory, item } = rowData;

    if (category && !subcategory && !subsubcategory && !item) {
      // categ
      return (
        <ListSubheader
          component="div"
          style={style}
          className={classnames(styles.listSubHeader, styles.listSubHeaderCateg, styles.row)}
          disableSticky
          key={index}
          onClick={(e) => {
            const node = ReactDOM.findDOMNode(this);
            if (
              e.target !== node && (
                ['input'].includes(e.target.tagName.toLowerCase()) ||
                !node?.contains?.(e.target)
              )) {
              return;
            }

            if (category.id !== BuiltinCategories.General || category.name !== i18n.t('General')) {
              showCategoryEditDialog(category, this.context);
            }
          }}
        >
          {shouldAllowCheckboxes && (
            <Checkbox
              className={styles.itemToggle}
              {...isCategoryChecked(store, ungroupedItems, category)}
              onChange={handleCategoryToggle(store, ungroupedItems, category)}
            />)}
          <span>{category.name}</span>

          {false && store?.stores === Globals.drawingStores && (
            <>
              {/*<div style={{ flex: 1 }} />*/}
              <ColorButton
                width={category.legendColor ? 13 : 18}
                height={category.legendColor ? 6 : undefined}
                className={styles.colorButton}
                color={category.legendColor}
                onChange={color => {
                  category.legendColor = color.hex;
                  categoriesStore.addEditItemDebounced(category, true, ['legendColor']);
                }}
              />
            </>
          )}
        </ListSubheader>
      );

    }

    if (category && subcategory && !subsubcategory && !item) {
      // subcateg
      return (
        <ListSubheader
          component="div"
          style={style}
          className={classnames(
            styles.listSubHeader,
            // make the subcategory look like category, when there is a subsubcategory under
            hasSubsubcategories ? styles.listSubHeaderCateg : styles.listSubHeaderSubcateg,
            styles.row
          )}
          // somehow height doesn't work, need to use minHeight maxHeight
          onClick={(e) => {
            // hack, somehow dispatches when closing color button dialog by clicking anywhere
            const node = ReactDOM.findDOMNode(this);
            if (
              e.target !== node && (
                ['input'].includes(e.target.tagName.toLowerCase()) ||
                !node?.contains?.(e.target)
              )) {
              return;
            }
            showCategoryEditDialog(subcategory, this.context);
          }}
          disableSticky
          key={index}
        >
          {shouldAllowCheckboxes && (
            <Checkbox
              className={styles.itemToggle}
              {...isCategoryChecked(store, ungroupedItems, category, subcategory)}
              onChange={handleCategoryToggle(store, ungroupedItems, category, subcategory)}
            />)}
          <span>{category.name} &gt; {subcategory.name}</span>

          {false && store?.stores === Globals.drawingStores && (
            <>
              {/*<div style={{ flex: 1 }} />*/}
              <ColorButton
                width={category.legendColor ? 13 : 18}
                height={category.legendColor ? 6 : undefined}
                className={styles.colorButton}
                color={subcategory.legendColor}
                onChange={color => {
                  subcategory.legendColor = color.hex;
                  categoriesStore.addEditItemDebounced(subcategory, true, ['legendColor']);
                }}
              />
            </>
          )}
        </ListSubheader>
      );
    }

    if (category && subcategory && subsubcategory && !item) {
      // subsubcateg
      return (
        <ListSubheader
          component="div"
          style={style}
          className={classnames(
            styles.listSubHeader,
            styles.listSubHeaderSubcateg,
            styles.row,
            styles.subsubcateg
          )}
          // somehow height doesn't work, need to use minHeight maxHeight
          disableSticky
          // no onclick because right now only used for "Information to provide" and "Calculated results" which are not real categs
          key={index}
        >
          {shouldAllowCheckboxes && (
            <Checkbox
              className={styles.itemToggle}
              {...isCategoryChecked(store, ungroupedItems, category, subcategory, subsubcategory)}
              onChange={handleCategoryToggle(store, ungroupedItems, category, subcategory, subsubcategory)}
            />)}
          <span>
            {category.name}
            {(subcategory.id === BuiltinCategories.General)
              ? ''
              : <span> &gt; {subcategory.name}</span>}
            &nbsp;&#58;&nbsp;
            {subsubcategory.name}
          </span>
        </ListSubheader>
      );
    }

    if (item) {
      return (
        <ListItem
          id={item?.id}
          component="div"
          style={style}
          className={styles.row}
          key={index}
        >
          
          {/*<div>&nbsp;&nbsp;{item.index}</div>*/}
          {/*<div>&nbsp;&nbsp;{item.minUserVersion} {item.maxUserVersion} {item._isDeleted?'true':''}</div>*/}


          <ItemRenderer key={'ir'+item?.id} item={item} />
          <>
            {shouldAllowCheckboxes && (
              <Checkbox
                className={styles.itemToggle}
                checked={selectedItems.has(item)}
                onChange={handleCheckItem(store, item)}
              />
            )}
            {!hidePopupMenu && (
              ItemPopupButton // unused
                ? <ItemPopupButton className={styles.menuButton} item={item} />
                : <MenuPopupButton
                  className={styles.menuButton}
                  menuItems={
                    compact([
                      onDuplicateItem && { icon: <CopyIcon />, text: i18n.t('Duplicate'), handler: () => onDuplicateItem(item) },
                      [
                        onChangeItemCategory && { icon: <CategoryIcon />, text: i18n.t('Move to a different category'), handler: () => onChangeItemCategory(item) },
                        item.isHidden
                          ? { icon: <UnarchiveIcon />, text: i18n.t('Unarchive'), handler: () => unarchiveItems([item], store) }
                          : { icon: <ArchiveIcon />, text: i18n.t('Archive'), handler: () => archiveItems([item], store) },
                        (!(item.cascadeOrders.has(0) && store.hasMasterDbLocation) || canDeleteMasterItems) && { icon: <DeleteIcon />, text: i18n.t('Delete forever'), handler: () => deleteItem(item, store), danger: true }
                      ],
                    ].flat())
                  } />
            )}
          </>
        </ListItem>
      );
    }

    return (
      <ListItem
        component="div"
        style={style}
        className={styles.row}
        key={index}
      />
    );
  }
}

export default class GroupedList<T extends ModelBaseWithCategory> extends ObserverComponent<GroupedListProps<T>> {
  static defaultProps = {
    itemHeight: 48,
    shouldAllowReorderItems: false,
  };

  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source, draggableId } = result;
    const { store, itemsByCategSubcategFlattened } = this.props;
    const item = store.getItem(draggableId);

    const items = compact(itemsByCategSubcategFlattened.map(itemGroup => itemGroup.item));

    if (!destination || !item || result.type !== DraggableTypes.Measurements) {
      return;
    }

    const previousItem = getSafe(() => items[
      destination.index < source.index
        ? destination.index - 1
        : destination.index
    ]);

    // make index match the other sorting (by formula type etc)
    // because db sort will only take index into account
    items
      .forEach((item, arrayIndex) => {
        item.index = arrayIndex;
      });

    store.reorderItem(
      item,
      previousItem,
      items,
    );
  }

  _render() {
    const {
      ungroupedItems,
      itemsByCategSubcategFlattened,
      className,
      itemHeight,
      shouldAllowReorderItems,
      hidePopupMenu,
      innerRef,
      store,
    } = this.props;

    const itemCount = itemsByCategSubcategFlattened.length;

    const rootClasses = classnames(styles.root, className, { [styles.hidePopupMenu]: hidePopupMenu });

    const hasOnlyOneActiveCateg = ungroupedItems.every(item => (
      item.category === first(ungroupedItems).category &&
      item.subcategory === first(ungroupedItems).subcategory
    ));

    if (shouldAllowReorderItems && itemCount && hasOnlyOneActiveCateg && !store.searchQuery) {
      return (<>
        <Row
          {...this.props}
          key="categoryNameRow"
          index={0}
          style={{ minHeight: itemHeight }}
        />
        <DragDropContext onDragEnd={this.onDragEnd}>
          <OrderableItemsContainer
            className={rootClasses}
            id={DraggableTypes.Measurements} // refactor when we reorder other types
            type={DraggableTypes.Measurements}
          >
            <AnimateReorder typeName={null} enterAnimation="none" leaveAnimation="none" appearAnimation="none">
              {compact(itemsByCategSubcategFlattened).map((itemGroup, index) => {
                const { item } = itemGroup;
                return item && (
                  <OrderableItem
                    className={classnames(styles.orderableItem, {
                    })}
                    id={item.id}
                    key={item.id}
                    index={
                      item.index ||
                      itemsByCategSubcategFlattened.filter(itemGroup => itemGroup.item).findIndex(ig => ig == itemGroup)
                    }

                  >
                    {
                      (dragHandle: JSX.Element) => (
                        <>
                          {dragHandle}
                          <Row
                            {...this.props}
                            key={getKeyFromFlattenedItem(itemsByCategSubcategFlattened[index], index)}
                            index={index}
                            style={{ minHeights: itemHeight }}
                          />
                        </>
                      )
                    }
                  </OrderableItem>
                );
              })}
            </AnimateReorder>
          </OrderableItemsContainer>
        </DragDropContext>
      </>
      )
    }


    return (itemCount > VIRTUAL_LIST_MINIMUM_ITEMS)
      ? (
        <div className={styles.listContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <VirtualizedList
                ref={ref => innerRef?.(ref)}
                className={classnames(rootClasses, styles.isVirtualizedList)}
                width={width}
                height={height}
                itemCount={itemsByCategSubcategFlattened.length}
                itemSize={itemHeight}
                overscanCount={15}
              >
                {({ index, style }) =>
                  <Row
                    {...this.props}
                    key={getKeyFromFlattenedItem(itemsByCategSubcategFlattened[index], index)}
                    index={index}
                    style={style}
                  />}
              </VirtualizedList>
            )}
          </AutoSizer>
        </div>
      ) : (
        <List className={rootClasses}>
          {itemsByCategSubcategFlattened.map((item, index) => (
            <Row
              {...this.props}
              key={getKeyFromFlattenedItem(itemsByCategSubcategFlattened[index], index)}
              index={index}
              style={{ minHeight: itemHeight }}
            />))}
        </List>
      );
  }
}
