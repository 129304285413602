
import { CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Globals from 'Globals';
import { CategoryType } from 'constants/CategoryType';
import { IEditComponentProps } from 'constants/CommonProps';
import { PanelIds } from 'constants/PanelIds';
import { compact, isEmpty } from 'lodash';
import Category from 'models/Category';
import TasksList from 'models/TasksList';
import * as React from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import StoresContext from 'stores/StoresContext';
import { handleChange } from 'utils/FormUtils';
import { getPanelProps } from 'utils/PanelUtil';
import i18n from 'utils/i18n';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import DialogsContainer from '../DialogsContainer/DialogsContainer';
import ImageBox from '../ImageBox/ImageBox';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import Panel from '../Panel/Panel';
import RichText2 from '../RichText2/RichText2';
import RightBar from '../RightBar/RightBar';
import SelectedMeasurements from '../SelectedMeasurements/SelectedMeasurements';
import SelectedMeasurementsHeader from '../SelectedMeasurementsHeader/SelectedMeasurementsHeader';
import Tasks from '../Tasks/Tasks';
import TasksHeader from '../Tasks/TasksHeader';
import TasksListAvatar from '../TasksListAvatar/TasksListAvatar';
import TextField from '../TextField/TextField';

const styles = require('./TasksListDetailsForm.module.scss');

interface TasksListDetailsFormProps {
  tasksListDraftId: string,
  tasksListId: string,
}

// refactor detailsforms duplicate
export default class TasksListDetailsForm extends ObserverComponent<IEditComponentProps & TasksListDetailsFormProps> {
  get model(): TasksList {
    return this.props.models[0] as TasksList;
  }

  onCategoryChange = (category: Category) => {
    this.model.category = category;
    this.model.subcategory = null;
  }

  onSubcategoryChange = (category: Category) => {
    this.model.subcategory = category;
  }

  public _render() {
    const { settingsStore, tasksListsStore, categoriesStore } = Globals.listDraftStores;
    const { model } = this;
    const { dialogId, tasksListId } = this.props;

    if (!settingsStore.settings) {
      // good usecase to render spinner or suspense
      return <div className={styles.progress}><CircularProgress /></div>;
    }

    const hasNodeSelected = true; //!!TreeNodesStore.selectedTreeNode;

    const { tasksListPanelSizes: panelSizes } = settingsStore.settings;

    const menuItems = compact([
      //{ icon: <CopyIcon />, text: i18n.t('Dupliquer'), handler: () => this.duplicateItem },
      {
        icon: <DeleteIcon />,
        text: i18n.t('Supprimer'),
        handler: () => {
          const item = tasksListsStore.getItem(tasksListId);
          tasksListsStore.deleteItem(item.id);
          Globals.defaultStores.dialogsStore.hideDialog(dialogId);
        },
        danger: true
      },
    ]);

    // suspense?
    if (!categoriesStore.isReady) {
      return <div className={styles.root}><CircularProgress className={styles.progress} /></div>
    }

    return (
      <StoresContext.Provider value={Globals.listDraftStores}>
        <div className={styles.root}>
          <DialogsContainer />

          <div className={styles.title} id="draggable-dialog-title">
            <TextField
              id="name"
              label={i18n.t('Name')}
              className={styles.nameField}
              value={model.name}
              onChange={handleChange(model, 'name')}
            />

            <CategoriesCombobox
              className={styles.categoryCombo}
              categoryTypes={[CategoryType.TasksList]}
              category={model.category}
              onChange={this.onCategoryChange}
            />

            <CategoriesCombobox
              className={styles.categoryCombo}
              categoryTypes={[CategoryType.TasksList]}
              parentCategory={model.category}
              category={model.subcategory}
              onChange={this.onSubcategoryChange}
            />

            {!isEmpty(menuItems) && <MenuPopupButton menuItems={menuItems} />}
          </div>

          <ReflexContainer
            className={styles.content}
            orientation="vertical"
          >
            <ReflexElement {...getPanelProps(PanelIds.LeftBar, panelSizes, this.context)}>
              <ReflexContainer orientation="horizontal">
                <ReflexElement {...getPanelProps(PanelIds.LeftBarTop, panelSizes, this.context)}>
                  <div className={styles.notes}>
                    <RichText2
                      model={model}
                      property="description2"
                      store={tasksListsStore}
                      isEditable
                    />
                  </div>

                </ReflexElement>

                <ReflexSplitter propagate />

                <ReflexElement {...getPanelProps(PanelIds.LeftBarMiddle, panelSizes, this.context)}>
                  <Panel
                    header={
                      //<WorksheetsTree /> debug
                      <SelectedMeasurementsHeader />
                    }
                    content={<SelectedMeasurements shouldAllowCheckboxes />}
                  />
                </ReflexElement>

              </ReflexContainer>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement {...getPanelProps(PanelIds.MainColumn, panelSizes, this.context)}>
              <ReflexContainer orientation="horizontal">
                <ReflexElement {...getPanelProps(PanelIds.MainColumnTop, panelSizes, this.context)}>
                  <Panel
                    header={<div />}
                    content={
                      <ImageBox
                        model={model}
                        store={tasksListsStore}
                        className={styles.imageBox}
                        imageHeight={'100%'}
                        placeHolderIcon={<TasksListAvatar className={styles.placeHolderImage} tasksList={model} />}
                      />
                    }
                  />
                </ReflexElement>

                <ReflexSplitter />

                <ReflexElement {...getPanelProps(PanelIds.MainColumnBottom, panelSizes, this.context)}>
                  <Panel
                    header={<TasksHeader />}
                    content={<Tasks />}
                  />
                </ReflexElement>
              </ReflexContainer>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement {...getPanelProps(PanelIds.RightBar, panelSizes, this.context)}>
              <RightBar shouldHideLists />
            </ReflexElement>
          </ReflexContainer>
        </div>
      </StoresContext.Provider >
    );
  }
}
