import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import SyncDisabledButton from '@material-ui/icons/SyncDisabled';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import ObserverComponent from 'components/common/ObserverComponent';
import { isEmpty } from 'lodash';
import { computed } from 'mobx';
import ProvidingItem from 'models/ProvidingItem';
import moment from 'moment';
import * as React from 'react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import timeagoFr from 'timeago.js/lib/lang/fr';
import { getPriceWithFees, getPriceWithMerchantAdjustment } from 'utils/CurrencyUtil';
import { formatCurrency } from 'utils/NumberFormatter';
import { showProvidingItemDialog } from 'utils/ProvidingItemUtil';
import { CURRENCY_EXCEL } from 'utils/ReportUtil';
import i18n from 'utils/i18n';
import { IEditDialogComponentProps } from '../EditDialogComponent/EditDialogComponent';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ProvidingItemComponent from '../ProvidingItemComponent/ProvidingItemComponent';
timeago.register('fr', timeagoFr);

const styles = require('./PriceUpdateDialogDetailsForm.module.scss');


interface IPriceUpdateDialogDetailsFormState {
  selectedItems: Set<ProvidingItem>
}

export default class PriceUpdateDialogDetailsForm extends ObserverComponent<IEditDialogComponentProps, IPriceUpdateDialogDetailsFormState> {
  state = {
    selectedItems: new Set<ProvidingItem>(this.items),
  }

  @computed get items(): ProvidingItem[] {
    return (this.props.models as ProvidingItem[]).filter(i => i.url);
  }

  handleCheckItem = (item: ProvidingItem) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { selectedItems } = this.state;
    checked
      ? selectedItems.add(item)
      : selectedItems.delete(item);

    this.setState({ selectedItems });
  }

  disablePriceSyncing = (item: ProvidingItem) => () => {
    const { providingItemsStore } = this.context;
    item.url = '';
    // in this case, need to apply the change right away
    providingItemsStore.addEditItem(item);
  }

  _render() {
    const { priceUpdateStore, projectsStore } = this.context;
    const { priceUpdate } = priceUpdateStore;
    const { selectedItems } = this.state;

    return (
      <div className={styles.root}>
        {/*<div className={styles.subheader}>{i18n.t('New prices are available for these items')}</div>*/}

        <Table id="priceupdates">
          <TableBody>
            <tr style={{ display: 'none' }}>
              <th>{projectsStore.selectedProject.name + ' - ' + i18n.t('Price Changes') + ' - ' + moment().format("LL")}</th>
            </tr>
            <tr style={{ display: 'none' }}></tr>
            <tr style={{ display: 'none' }}>
              <th>{i18n.t('Description')}</th>
              <th>{i18n.t('Old Price')}</th>
              <th></th>
              <th>{i18n.t('New Price')}</th>
              <th></th>
              <th>{i18n.t('Old Price incl. fees')}</th>
              <th></th>
              <th>{i18n.t('New Price incl. fees')}</th>
            </tr>

            {this.items
              .map((item: ProvidingItem) => {
                const oldPrice = item.priceWithPriceAdjustment;
                const newPrice = getPriceWithMerchantAdjustment(priceUpdate.priceMap.get(item.id), item);

                return (
                  <TableRow key={item.id} className={styles.row}>
                    {/*<TableCell className={styles.checkbox}>
                <Checkbox
                  className={styles.itemToggle}
                  checked={selectedItems.has(item)}
                  onChange={this.handleCheckItem(item)}
                />
              </TableCell>
              */}
                    {/*Bad hack, pricewithfees doesn,t show fees unless we are in Report page */}
                    <TableCell className={styles.item} data-v={item.name}>
                      <ProvidingItemComponent className={styles.providingItem} providingItem={item} />
                    </TableCell>

                    <td style={{ display: 'none' }} data-v={oldPrice} data-z={CURRENCY_EXCEL}></td>

                    <TableCell className={styles.arrowIcon} data-v="→">
                      <ArrowRight />
                    </TableCell>

                    <TableCell className={styles.newPrice} data-v={newPrice} data-z={CURRENCY_EXCEL}>
                      <Typography variant="subtitle2" style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                        {formatCurrency(newPrice)}
                      </Typography>
                    </TableCell>

                    <td style={{ display: 'none' }} data-v=""></td>
                    <td style={{ display: 'none' }} data-v={item.priceWithFees} data-z={CURRENCY_EXCEL}></td>
                    <td style={{ display: 'none' }} data-v="→"></td>
                    <td style={{ display: 'none' }} data-v={getPriceWithFees(newPrice, item)} data-z={CURRENCY_EXCEL}></td>

                    <TableCell className={styles.options}>
                      <div className={styles.hoverToolsRight}>
                        <MenuPopupButton menuItems={[
                          { icon: <SyncDisabledButton />, text: i18n.t('Never check prices for this item'), handler: this.disablePriceSyncing(item) },
                        ]} />
                      </div>
                    </TableCell>

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>


        {!isEmpty(priceUpdateStore.unsyncableItems) && (
          <div className={styles.badNewsSection}>

            <div className={styles.message}>
              <SyncProblemIcon className={styles.icon} />
              <div>
                <h2>{i18n.t('Prices for the following items are no longer available online.')}</h2>
                <h3>
                  {i18n.t('Click an item to enter the URL of an equivalent product or to manually change the price.')}
                  <br />
                  {i18n.t('If changing the URL, you can use a different merchant from the original item if needed.')}
                </h3>
              </div>
            </div>

            <Table>
              <TableBody>
                {priceUpdateStore.unsyncableItems.map((item, index) => (
                  <TableRow key={'usi' + index}>
                    <TableCell className={styles.item} data-v={item.name} onClick={() => showProvidingItemDialog(item, null)}>
                      <ProvidingItemComponent
                        providingItem={item}
                        secondaryText={<span>
                          {i18n.t('Price updated')}&nbsp;
                          <TimeAgo
                            datetime={Math.max(item.priceUpdatedMiliseconds, new Date('2021-08-01').getTime())}
                            locale={i18n.language === 'fr' ? 'fr' : 'en_US'}
                          />
                        </span>
                        } />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </div>
        )}
      </div>
    )
  }
}