import { mapValues } from 'lodash';
import SerializableBase from 'models/SerializableBase';
import { deserialize } from 'serializr';
import Stores from 'stores/Stores';

export default class DbItemFactory {
  // The callback is when the item if finished creating
  // It has to be done in 2 steps, because of circular references ex:
  // Wall1 has reference to Wall2 and vice-versa, so 2 walls are created and references are added
  // in a second pass (all done automatically by mobx serializr)

  public static create(itemData: object, stores: Stores, callback?: (err: any, result: SerializableBase) => void): SerializableBase {
    if (!itemData) {
      return null;
    }

    // This constant only seems to work when defined inside this function
    const TYPES = {
      'Wall': require("models/Wall").default,
      'CountPoint': require("models/CountPoint").default,
      'User': require("models/User").default,
      'Project': require("models/Project").default,
      'TreeNode': require("models/TreeNode").default,
      'Surface': require("models/Surface").default,
      'Task': require("models/Task").default,
      'Measurement': require("models/Measurement").default,
      'MeasurementValue': require("models/MeasurementValue").default,
      'ProvidingItem': require("models/ProvidingItem").default,
      'ProvidingItemMeta': require("models/ProvidingItemMeta").default,
      'Settings': require("models/Settings").default,
      'Category': require("models/Category").default,
      'Client': require("models/Client").default,
      'Report': require("models/Report").default,
      'CopiedTask': require("models/CopiedTask").default,
      'TasksList': require("models/TasksList").default,
      'DrawToolOption': require("models/DrawToolsOption").default,
      'PriceUpdate': require("models/PriceUpdate").default,
      'BackgroundImage': require("models/BackgroundImage").default,
      'BackgroundImageScale': require("models/BackgroundImageScale").default,
      'Rectangle': require("models/Rectangle").default,
      'Merchant': require("models/Merchant").default,
      'Subscription': require("models/Subscription").default,
    }

    const type = (itemData as SerializableBase).type;
    if (!type) {
      console.error('deserialize attempted with undefined type', itemData);
      return null;
    }

    const constructor = TYPES[type];

    if (constructor) {
      // backward compatibility fix
      // from now on, will be stored localized so will arrive in itemData._imageUrl and itemData.__thumbUrl
      if (itemData.imageUrl) {
        itemData.__imageUrl = { 'en': itemData.imageUrl };
      }
      if (itemData._imageUrl?.en) {
        itemData.__imageUrl = itemData._imageUrl;
      }
      if (itemData._thumbUrl) {
        itemData.__thumbUrl = { 'en': itemData._thumbUrl };
      }

      // already localized, but moved up
      if (itemData._defaultFormula) {
        itemData.__defaultFormula = itemData._defaultFormula;
      }

      // proxy fix
      itemData.__thumbUrl = mapValues(itemData.__thumbUrl, url => (url || '').replace('proxy.evalumo', 'merchants.evalumo'));
      itemData.__imageUrl = mapValues(itemData.__imageUrl, url => (url || '').replace('proxy.evalumo', 'merchants.evalumo'));
      itemData._imageUrls = mapValues(itemData._imageUrls, urls => urls.map(url => (url || '').replace('proxy.evalumo', 'merchants.evalumo')));
      itemData._thumbUrls = mapValues(itemData._thumbUrls, urls => urls.map(url => (url || '').replace('proxy.evalumo', 'merchants.evalumo')));

      itemData.__thumbUrl = mapValues(itemData.__thumbUrl, url => (url || '').replace('connect.evalumo', 'merchants.evalumo'));
      itemData.__imageUrl = mapValues(itemData.__imageUrl, url => (url || '').replace('connect.evalumo', 'merchants.evalumo'));
      itemData._imageUrls = mapValues(itemData._imageUrls, urls => urls.map(url => (url || '').replace('connect.evalumo', 'merchants.evalumo')));
      itemData._thumbUrls = mapValues(itemData._thumbUrls, urls => urls.map(url => (url || '').replace('connect.evalumo', 'merchants.evalumo')));

      // priceFormula localization
      if (itemData.priceFormula && !itemData._priceFormula) {
        itemData._priceFormula = { fr: itemData.priceFormula };
      }

      itemData.cascadeOrders = [];

      const result = deserialize(constructor, itemData, callback, stores);

      return result;
    }

    console.error(`Need to add ${type} to DbItemFactory supported types`);
    return null;
  }
}
