// not related to imperial metric, which is only when outputting to user / taking input
// todo determine default unit ex: sq m. for Surface, for storage in db
export enum UnitType {
  FixedPrice = 'FixedPrice',
  Unit = 'Unit',
  Pack = 'Pack',
  Time = 'Time',
  Length = 'Length',
  Surface = 'Surface',
  Volume = 'Volume',
  Weight = 'Weight',
  Ratio = 'Ratio',
  Angle = 'Angle', // could be angle!
  RoofSlope = 'RoofSlope',
  Unknown = 'Unknown',
  Projection = 'Projection',
}
