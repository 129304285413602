import { Avatar, Button, ClickAwayListener, IconButton, ListItemAvatar, ListItemText } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import classnames from 'classnames';
import Dialog from 'models/Dialog';
import TasksList from 'models/TasksList';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import ObserverComponent from '../ObserverComponent';
import TasksListEditDialog from '../TasksListEditDialog/TasksListEditDialog';
import TextField from '../TextField/TextField';

const styles = require('./TasksListComponent.module.scss');

interface ITasksListComponentProps {
  tasksList: TasksList;
}

interface ITasksListComponentState {
  tasksListCopy: TasksList,
}

// Lot of duplicate code with TasksListComponent/MeasurementComponent
export default class TasksListComponent extends ObserverComponent<ITasksListComponentProps, ITasksListComponentState> {
  state = {
    tasksListCopy: null,
  };

  _ref: HTMLDivElement;

  get ref() {
    return this._ref;
  }

  set ref(value) {
    this._ref = value;

    try {
      scrollIntoView(this._ref, { scrollMode: 'if-needed' })
    } catch (e) { }
  }

  componentDidMount() {
    const { tasksList } = this.props;
    const { tasksListCopy } = this.state;

    if (tasksList && !tasksListCopy) {
      const tasksListCopy = tasksList.clone();
      this.setState({ tasksListCopy });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.exitEditMode();
  }

  enterEditMode = (event) => {
    const { tasksListsStore } = this.context;
    if (
      event.target.getAttribute('aria-label') === 'Zoom image' ||
      event.target.getAttribute('aria-label') === 'Unzoom image'
    ) {
      return;
    }

    tasksListsStore.tasksListBeingEdited = this.props.tasksList;

    const { tasksList } = this.props;
    const tasksListCopy = tasksList.clone();

    this.setState({
      tasksListCopy,
    });
  }

  exitEditMode = () => {
    const { tasksListsStore } = this.context;
    if (getSafe(() => this.props.tasksList.id === tasksListsStore.tasksListBeingEdited.id)) {
      tasksListsStore.tasksListBeingEdited = null;
    }
  }


  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.saveChanges();
    } else if (e.key === 'Escape') {
      this.exitEditMode();
    }
  }

  handleChange = (propertyName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { tasksListCopy } = this.state; // todo: separate edit mode/dialog better
    let { value } = event.target as any;

    tasksListCopy[propertyName] = value;
  }

  saveChanges = () => {
    const { tasksListsStore } = this.context;
    const { tasksListCopy } = this.state;
    tasksListsStore.addEditItem(tasksListCopy);
    this.exitEditMode();
  }

  showEditDialog = () => {
    const { dialogsStore } = this.context;
    const { tasksListCopy } = this.state;
    const newDialog = new Dialog(this.context);

    newDialog.dialogComponent = ({ open }) => (
      <TasksListEditDialog open={open} dialogId={newDialog.id} tasksList={tasksListCopy} />
    )
    dialogsStore.showDialog(newDialog);
    this.exitEditMode();
  }

  _render() {
    const { tasksListsStore } = this.context;
    const { tasksList } = this.props;
    const { tasksListCopy } = this.state;
    const isEditMode = getSafe(() => tasksListsStore.tasksListBeingEdited.id === this.props.tasksList.id && tasksListCopy);

    return isEditMode
      ? (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.saveChanges}>
          <div ref={ref => this.ref = ref} className={styles.rootEdit} onKeyDown={this.onKeyPress}>
            <TextField
              label={i18n.t('Item name')}
              shouldFocusOnMount
              shouldSelectAllOnFocus
              className={styles.textField + ' ' + styles.nameField + ' ' + styles.textFieldNoLabel}
              value={tasksListCopy.name}
              onChange={this.handleChange('name')}
            />

            <div className={styles.editTools} >
              <Button className={styles.detailsButton} onClick={this.showEditDialog}>{i18n.t('Details')}</Button>
              <div className={styles.flexSeparator} />
              <IconButton className={styles.cancelButtonIcon} onClick={this.exitEditMode}><CancelIcon /></IconButton>
              <IconButton className={styles.acceptButtonIcon} onClick={this.saveChanges}><AcceptIcon /></IconButton>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <div className={classnames(styles.root, { [styles.isHidden]: tasksList.isHidden })} onClick={this.enterEditMode}>
          <ListItemAvatar>
            <Avatar style={{ width: 35, height: 35 }} className={styles.icon}>
              <AvatarImageWithFallback model={tasksList.thumbUrl ? tasksList : tasksList.category} />
            </Avatar>
          </ListItemAvatar>

          <ListItemText
            className={styles.materialText}
            primary={tasksList.name}
          />

          {tasksListsStore.shouldMarkMasterItems && tasksList.cascadeOrders.has(0) && <VerifiedIcon className={styles.verifiedIcon} />}
        </div>
      )
  }
}