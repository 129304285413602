
import * as React from 'react';

interface ListItemTextProps {
  primary: string | JSX.Element,
  secondary?: string | JSX.Element,
  className: string,
  style: any,
}

const displayToolTipIfTruncated = (event) => {
  const { target } = event;
  if (target.offsetWidth < target.scrollWidth) {
    target.title = target.innerText;
  } else {
    target.title = "";
  } 
}

export const ListItemText = React.memo(({ primary, secondary, className, style }: ListItemTextProps) => (
  <div className={className + ' ' + "MuiListItemText-root MuiListItemText-multiline"} style={style}>
    <span onMouseEnter={displayToolTipIfTruncated} className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
      {primary}
    </span>
    <p className="MuiTypography-root MuiListItemText-secondary MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">
      {secondary}
    </p>
  </div>
));