
import * as React from 'react';
import { Table as MatUITable, TableProps } from '@material-ui/core';

const styles = require('./Table.module.scss');
import './Table.scss';

export default class Table extends React.Component<TableProps> {
  render() {
    return (
      <MatUITable className={styles.root} {...this.props} />
    )
  }
}