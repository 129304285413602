
import { Button as MatUIButton, ButtonProps } from '@material-ui/core';
import * as classnames from 'classnames';
import * as React from 'react';

const styles = require('./Button.module.scss');

export default class Button extends React.Component<ButtonProps> {
  render() {
    const { className } = this.props;
    return (
      <MatUIButton {...this.props} className={classnames(styles.root, className)} />
    );
  }
}