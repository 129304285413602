let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  debugger;
  // Prevent the mini-infobar from appearing on mobile
  //e.preventDefault();
  // Stash the event so it can be triggered later.
  //deferredPrompt = e;
  // Update UI notify the user they can install the PWA
  //showInstallPromotion();
});