
import * as React from 'react';
import i18n from 'utils/i18n';
import FeesCalculationDetailsForm from '../FeesCalculationDetailsForm/FeesCalculationDetailsForm';
import FeesDetailsForm from '../FeesDetailsForm/FeesDetailsForm';
import ObserverComponent from '../ObserverComponent';

const styles = require('./ReportFeesOptions.module.scss');

export default class ReportFeesOptions extends ObserverComponent {
  _render() {
    const { settingsStore } = this.context;
    return (
      <div className={styles.root}>
        <FeesDetailsForm models={[settingsStore.settings]} />

        <div className={styles.subheader}>
          {i18n.t('Fees Calculation Options')}
        </div>

        <FeesCalculationDetailsForm models={[settingsStore.settings]} />
      </div>
    )
  }
}