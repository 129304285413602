import Globals from 'Globals';
import { compact, debounce, sumBy } from 'lodash';
import { computed, observable, runInAction } from 'mobx';
import { runProjectCompatibilityCheck, runUserCompatibilityCheck } from 'utils/CompatibilityUtil';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { waitOnStoresReady } from 'utils/StoreUtil';
import BaseStore from './BaseStore';
import FirebaseStore from './FirebaseStore';

export default class CommonStore extends BaseStore {
  get importantStores() {
    return [
      this.stores.categoriesStore,
      this.stores.measurementsStore,
      this.stores.treeNodesStore,
      this.stores.tasksStore,
      this.stores.providingItemsStore,
      this.stores.userInfoStore,
      this.stores.priceUpdateStore,
    ];
  }

  // selected project id OR tasks list id
  @observable _selectedProjectId = '';

  @computed get selectedProjectId() {
    return this._selectedProjectId;
  }

  set selectedProjectId(value: string) {
    const previousSelectedProjectId = this._selectedProjectId;

    if (value !== previousSelectedProjectId) {
      this.flushDebouncedCalls();
    }

    this._selectedProjectId = value;
    // could use this to have proper loading screen and blocking user interaction
    // would also need to improve loading order to start with most important (treenodes)

    if (value && value !== previousSelectedProjectId) {
      waitOnStoresReady(this.importantStores)
        .then(() => {
          runProjectCompatibilityCheck(this.stores);
          runUserCompatibilityCheck(this.stores);
        });
    }
  }

  @observable error = '';

  @computed get projectLoadingProgress() {
    return sumBy(
      compact(this.importantStores),
      store => store.receivedSnapshotsCascadeOrders.size / (store.collections.length || 1) / this.importantStores.length
    ) * 100;
  }

  @computed get areImportantStoresReady() {
    return compact(this.importantStores).every(store => store.isReady);
  }

  @observable isNewVersionAvailable = false;

  @computed get shouldIncludeFeesInTasks() {
    const {settingsStore, routerStore} = this.stores;
    return (settingsStore.settings.areFeesIncludedInItemPrice && routerStore.isReportPage);
  }

  /*
  @observable __isBigUpdateOngoing = false;

  @computed get _isBigUpdateOngoing() {
    return this.__isBigUpdateOngoing;
  }
  set _isBigUpdateOngoing(value) {
    if (value != this.__isBigUpdateOngoing) {
      this.__isBigUpdateOngoing = value;
      console.log('tmp, isbigupdate', value);
    }
  }
*/

  @observable _isBigUpdateOngoing = false;

  @computed get isBigUpdateOngoing() {
    return this._isBigUpdateOngoing;
  }

  set isBigUpdateOngoing(value: boolean) {
    this.setIsBigUpdateOngoing(value);
  }

  setIsBigUpdateOngoing(value: boolean, quickReset = false) {
    if (value !== this._isBigUpdateOngoing) {
      this._isBigUpdateOngoing = value;
    }

    if (value) {
      quickReset
        ? this.quickResetFlagAfterInactivity()
        : this.resetFlagAfterInactivity();
    }
  }

  quickResetFlagAfterInactivity = debounce(
    () => this._isBigUpdateOngoing = false,
    1000,
    { leading: false, trailing: true }
  );

  resetFlagAfterInactivity = debounce(
    () => this._isBigUpdateOngoing = false,
    6000,
    { leading: false, trailing: true }
  );

  get hasPendingDebouncedCalls() {
    const { treeNodesStore, settingsStore } = this.stores;
    return [treeNodesStore, settingsStore].some(store => store.hasPendingDebouncedCalls);
  }

  flushDebouncedCalls = () => {
    const { treeNodesStore, settingsStore } = this.stores;
    return [treeNodesStore, settingsStore].forEach(store => store.flushDebouncedCalls());
  }


  // putting here because commonStore is separate per context (ex list draft) whereas projectsstore is often only linked to default stores
  vacuumSelectedProject = async () => {
    const { treeNodesStore, shapesStore, providingItemsStore, projectsStore, tasksStore, measurementsStore, categoriesStore, backgroundImagesStore } = this.stores;

    const stores = [treeNodesStore, shapesStore, providingItemsStore, tasksStore, measurementsStore, categoriesStore, backgroundImagesStore] as FirebaseStore<any>[];

    const batch = firestoreBatch();
    //batch.isUndoable = false;

    runInAction(() => {
      tasksStore.items.forEach(task => {
        // old version had some adjustment with "Add" type
        task.adjustment.type = 'Adjustment';

        if (!treeNodesStore.getNodeForTask(task)) {
          tasksStore.deleteItem(task.id, true, batch);
        }
      });

      treeNodesStore.items.forEach(node => {
        Array.from(node.ownMeasurementValues.values()).forEach(mv => {
          if (!measurementsStore.getItem(mv.measurementId)) {
            node.ownMeasurementValues.delete(mv.measurementId);
          }
        });

        Array.from(node.ownMeasurementAdjustments.values()).forEach(adjustment => {
          if (!adjustment.value && adjustment.subtype === 'Add') {
            node.ownMeasurementAdjustments.delete(adjustment.measurementId);
          }
        });
      });
    });

    await Promise.all(stores.map(async store => {
      store.emptyTrash(batch, true);
      const itemsToVacuum = store.items.filter(i => i.cascadeOrders.has(store.collections.length - 1));
      store.addEditItems(itemsToVacuum, true, undefined, batch);
    }));



    if (Globals.defaultStores !== this.stores) {
      batch.isUndoable = false;
    } else {
      projectsStore.addEditItem(projectsStore.selectedProject, true, undefined, batch);
    }

    await batch.commit();

    console.log('vacuum complete');
  }

}

