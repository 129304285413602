import ImageIcon from '@material-ui/icons/Image';
import Measurement from 'models/Measurement';
import Task from 'models/Task';
import * as React from 'react';
import { addNewMeasurementToTask, onMeasurementDrop } from 'utils/MeasurementUtil';
import i18n from 'utils/i18n';
import { Avatar } from '../Avatar/Avatar';
import DroppableDiv from '../DroppableDiv/DroppableDiv';
import { ListItemText } from '../ListItemText/ListItemText';
import ObserverComponent from '../ObserverComponent';
;

const styles = require('./EmptyMeasurementComponent.module.scss');

interface IEmptyMeasurementComponentProps {
  task: Task,
  className?: string,
}

export default class EmptyMeasurementComponent extends ObserverComponent<IEmptyMeasurementComponentProps> {
  showQuantityInput = () => {
    const { measurementsStore, tasksStore } = this.context;
    const { task } = this.props;

    addNewMeasurementToTask(task, this.context);
    measurementsStore.measurementBeingEdited = task.measurement;
    tasksStore.taskBeingEdited = task;
  }

  _render() {
    const { className, task } = this.props;
    const { dragAndDropStore } = this.context;
    const isMeasurement = dragAndDropStore.dragObject instanceof Measurement;

    return (
      <DroppableDiv
        className={className}
        onDrop={onMeasurementDrop(task)}
        shouldEnable={isMeasurement}
      >
        <Avatar>
          <ImageIcon />
        </Avatar>
        <div className={styles.flexColumn}>
          <ListItemText
            secondary={i18n.t('Drag a measurement here')}
          />
          <span
            className={styles.enterQuantityLink}
            onClick={this.showQuantityInput}
          >
            {i18n.t('or enter a quantity')}
          </span>
        </div>
      </DroppableDiv>
    )
  }
}