import Line from 'models/Line';
import { serializable } from 'serializr';
// cannot use @serializable decorator for this class since refers to itself for connected walls
// go to createModelSchema section instead
// Not a wall, but Line, unfortunately can't rename anymore to stay compatible with existing lines.
export default class Wall extends Line {

  @serializable type = 'Wall';

  index = 3;
}
