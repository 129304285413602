
import { CategoryType, getDefaultCategoryTypes } from 'constants/CategoryType';
import { first, isEmpty, orderBy, uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import ModelBaseWithCategory from 'models/ModelBaseWithCategories';
import * as React from 'react';
import SearchableFirebaseStore from 'stores/SearchableFirebaseStore';
import { getNonEmptyCategoriesForCategoryTypes } from 'utils/CategoryUtil';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import CategoriesListEditDialog from '../CategoriesListEditDialog/CategoriesListEditDialog';
import CategoryEditDialog from '../CategoryEditDialog/CategoryEditDialog';
import ComboBox from '../ComboBox/ComboBox';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import ObserverComponent from '../ObserverComponent';

const colors = require('../../../Colors.scss');

const styles = require('./CategoriesCombobox.module.scss');

interface ICategoriesComboboxProps {
  // need to specify at least category OR selectedCategories
  // should refactor to use always selectedCategories
  category?: Category,
  selectedCategories?: Category[],

  onChange?: (category: Category, action: 'select-option' | 'deselect-option') => void,

  allowShowEveryCategory?: boolean, // disabled to avoid creating huge list except when really needed
  categoryTypes?: CategoryType[], // all category types if not present
  parentCategory?: Category,
  categories?: Category[], // to restrict visible categories
  className?: string,
  placeholder?: string,
  isFilter?: boolean,
  disabled?: boolean,
  shouldFocusOnMount?: boolean,
  shouldHideBottomBar?: boolean,
  isMulti?: boolean,
  inputClassName?: string,
  store?: SearchableFirebaseStore<ModelBaseWithCategory> // used for subcategories
}

export default class CategoriesCombobox extends ObserverComponent<ICategoriesComboboxProps> {
  static defaultProps = {
    categoryTypes: []
  }

  commitChange = (category: Category) => {
    const { parentCategory, categoryTypes } = this.props;
    const { categoriesStore } = this.context;

    const isNew = !categoriesStore.getItem(category.id);

    // multiple category types not supported in create mode
    const categoryType = first(categoryTypes) as CategoryType;

    if (isNew) {
      // hack to ensure no categories have same index 0 because unstable sort. 
      // This is until categories are ordered manually in manage categories dialog
      let categoryIndex = Math.max(...categoriesStore.items.filter(c => c.index < 1).map(c => c.index)) + 0.0001;
      category.index = categoryIndex;

      // edge case when creating new category with same name as category in another categorytype
      const categWithSameName = categoriesStore.getItemByName(category.name);
      if (categWithSameName?.parentCategory === category.parentCategory) {
        if (!categWithSameName.categoryTypes.includes(categoryType)) {
          categWithSameName.categoryTypes.push(categoryType);
          categoriesStore.addEditItem(categWithSameName);
        }
        this.props.onChange(categWithSameName);
        return;
      }
    }

    if (parentCategory) {
      parentCategory.addSubcategoryIfNotExists(category);
      categoriesStore.addEditItem(parentCategory);
    }

    categoriesStore.addEditItem(category);
    if (isNew) {
      this.props.onChange(category);
    }
  }

  addEditCategory = (categoryName?: string, categoryId?: ModelId) => {
    const { parentCategory } = this.props;
    const { categoriesStore, dialogsStore } = this.context;
    const { categoryTypes } = this.props;
    const category = categoriesStore.getItem(categoryId);

    // multiple category types not supported in create mode
    const categoryType = first(categoryTypes) as CategoryType;

    const newCategory = category || new Category(this.context, i18n.t('New category'), getDefaultCategoryTypes(categoryType));

    if (parentCategory) {
      newCategory.parentCategoryId = parentCategory.id;
    }

    if (categoryName) {
      newCategory.name = categoryName;
      this.commitChange(newCategory);
    } else {
      const newDialog = new Dialog(this.context);
      newDialog.dialogComponent = ({ open }) => (
        <CategoryEditDialog
          open={open}
          dialogId={newDialog.id}
          category={newCategory}
          onClose={(shouldSave, modelCopies) => {
            if (shouldSave && modelCopies) {
              this.commitChange(modelCopies[0] as Category);
            }
          }}
        />
      )
      dialogsStore.showDialog(newDialog);
    }
  }

  onReorderOptions = () => {

    const { parentCategory, categoryTypes } = this.props;
    const { dialogsStore } = this.context;

    // multiple category types not supported in reorder mode
    const categoryType = first(categoryTypes) as CategoryType;

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <CategoriesListEditDialog
        open={open}
        dialogId={newDialog.id}
        parentCategory={parentCategory}
        categoryType={categoryType}
      />
    )
    dialogsStore.showDialog(newDialog);
  }

    onCreateCategory = (categoryName?: string) => this.addEditCategory(categoryName, null);
    onEditCategory = (categoryId: ModelId) => this.addEditCategory(null, categoryId);

  onDeleteCategory = (categoryId: ModelId) => {
    const { categoriesStore, dialogsStore } = this.context;
    const category = categoriesStore.getItem(categoryId);
    const { parentCategory } = this.props;
    if (!category) {
      return;
    }
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        open={open}
        dialogId={newDialog.id}
        onConfirm={() => {
          if (parentCategory) {
            //remove(parentCategory.children, subcategory => subcategory.id === category.id)
            //CategoriesStore.addEditItem(parentCategory);
          }
          categoriesStore.deleteItem(category.id);
          // Doit déplacer les tâches de cette catég vers general, mais doit se faire automatiquement
          // car pourrait affecter autres projets
        }}
        title={i18n.t('Do you really want to delete the category {{- categoryName}}?', { categoryName: i18n.t(category.name) })}
        actionButtonColor={colors.red}
      />
    );
    dialogsStore.showDialog(newDialog);
  }

  onChange = ({ value }, { action, option, removedValue }) => {
    const { categoriesStore } = this.context;
    // option.value is for isMulti mode
    const category = categoriesStore.getItem(option?.value || removedValue?.value || value);

    if (action === 'remove-value') {
      // why do they differentiate between the 2 ?
      action = 'deselect-option';
    }
    this.props.onChange(category, action);
  }

  _render() {
    const { category, selectedCategories,inputClassName,  placeholder, className, parentCategory, disabled, shouldHideBottomBar, categoryTypes, shouldFocusOnMount, isFilter, store, allowShowEveryCategory, isMulti } = this.props;
    const { categoriesStore } = this.context;

    if (isEmpty(categoryTypes) && !parentCategory && !allowShowEveryCategory) {
      return null;
    }

    const allCategories = parentCategory
      ? parentCategory.children.filter(subcategory => (
        isEmpty(categoryTypes) ||
        subcategory.categoryTypes.find(categoryType => categoryTypes.includes(categoryType))
      )) : (
        !isEmpty(categoryTypes)
          ? categoriesStore.getRootCategoriesByTypes(categoryTypes)
          : categoriesStore.rootCategories
      );

    const nonEmptyCategories = parentCategory
      ? (
        store
          ? (store.subcategoriesByCategory[parentCategory.id] || []).filter(subcategory => (
            isEmpty(categoryTypes) ||
            subcategory.categoryTypes.find(categoryType => categoryTypes.includes(categoryType))
          )) : []
      ) : (
        !isEmpty(categoryTypes)
          ? getNonEmptyCategoriesForCategoryTypes(categoryTypes, this.context)
          : []
      );

    return (
      <ComboBox
        placeholder={placeholder}
        shouldFocusOnMount={shouldFocusOnMount}
        disabled={disabled}
        className={styles.root + ' ' + (className || '')}
        label={(parentCategory || disabled) ? i18n.t('Subcategory') : (isMulti ? i18n.t('') : i18n.t('Category'))}
        //manageLabel={i18n.t('Manage categories')}
        addNewLabel={parentCategory ? i18n.t('Add new subcategory') : i18n.t('Add new category')}
        // ugly
        value={selectedCategories?.map(c => c?.id) || category?.id || null}
        onChange={this.onChange}
        onCreateOption={!shouldHideBottomBar && !isFilter && this.onCreateCategory}
        onManageClick={!shouldHideBottomBar && this.onReorderOptions}
        onEditOption={!shouldHideBottomBar && this.onEditCategory}
        onDeleteOption={!shouldHideBottomBar && this.onDeleteCategory}
        isMulti={isMulti}
        classes={{input: inputClassName}}
        formatCreateLabel={inputValue => parentCategory
          ? i18n.t('"{{- category}}" (Create subcategory)', { category: inputValue })
          : i18n.t('"{{- category}}" (Create category)', { category: inputValue })
        }
        suggestions={orderBy(
          uniqBy([
            ...[new Category(this.context, i18n.t(parentCategory ? 'All subcategories' : 'All categories'), getDefaultCategoryTypes(), null, -1)].map(
              category => ({
                index: category.index,
                value: category.id,
                label: category.name,
                isEmpty: false,
              })),
            ...nonEmptyCategories.map(
              category => ({
                index: category.index,
                value: category.id,
                label: category.name,
                isEmpty: false,
              })),
            ...(isFilter
              ? []
              : allCategories.map(
                category => ({
                  index: category.index,
                  value: category.id,
                  label: category.name,
                  isEmpty: true,
                })))
          ],
            'value'
          ), ['isEmpty', 'index']
        )}
        SuggestionIcon={observer(({ modelId }: { modelId: ModelId }) => <AvatarImageWithFallback model={categoriesStore.getItem(modelId)} />)}
      />
    )
  }
}