import { UnitType } from 'constants/UnitType';
import { compact, first } from 'lodash';
import { computed } from "mobx";
import Shape from "models/Shape";
import { serializable } from "serializr";
import Point from "./Point";

export default class CountPoint extends Shape {
  @serializable type = 'CountPoint';

  index = 4;

  @computed get iconUrl(): string {
    let iconUrl = null;

    if (!this.treeNode) {
      return null;
    }

    // find first ancestor with color item
    [this.treeNode, ...this.treeNode.bubbleAncestors].forEach(treeNode => {
      if (iconUrl) {
        return;
      }

      const measurementValues = treeNode?.ownMeasurementValuesArray?.filter(mv => (
        mv.measurement?.unitType === UnitType.Unit
        && mv.metricValue !== 0
      )) || [];

      iconUrl = first(compact(measurementValues.map(mv => mv.measurement?.imageUrl)));
    });

    return iconUrl;
  }

  @computed get point(): Point {
    return first(this.points);
  }

  set point(point: Point) {
    this.points = [point];
  }
}