// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { IDialogProps } from 'constants/CommonProps';
import Settings from 'models/Settings';
import * as React from 'react';
import i18n from 'utils/i18n';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import FeesCalculationDetailsForm from '../FeesCalculationDetailsForm/FeesCalculationDetailsForm';
import ObserverComponent from '../ObserverComponent';
const styles = require('./FeesCalculationEditDialog.module.scss');

interface FeesCalculationEditDialogProps extends IDialogProps {
  settings: Settings,
}

export default class FeesCalculationEditDialog extends ObserverComponent<FeesCalculationEditDialogProps> {
  _render() {
    const { settingsStore } = this.context;
    const { open, dialogId, settings } = this.props;

    return (
      <EditDialogComponent
        dialogId={dialogId}
        open={open}
        models={[settings]}
        stores={[settingsStore]}
        FormComponent={FeesCalculationDetailsForm}
        title={i18n.t('Fees Calculation Options')}
      />
    );
  }
}