
import { IEditComponentProps } from 'constants/CommonProps';
import Settings from 'models/Settings';
import * as React from 'react';
import ObserverComponent from '../ObserverComponent';
import TaxesOptionPriceInclusionToggle from './TaxesOptionLabourExclusionToggle';

const styles = require('./TaxesCalculationDetailsForm.module.scss');

export default class TaxesCalculationDetailsForm extends ObserverComponent<IEditComponentProps> {
  get settings() {
    return this.props.models[0] as Settings;
  }

  _render() {
    const settings = this.settings;

    return (
      <div className={styles.root}>
        <TaxesOptionPriceInclusionToggle settings={settings} />
      </div>
    )
  }
}