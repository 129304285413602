export const getFirebaseUiLanguageCode = (navigatorLanguage) => {
  if (navigatorLanguage.includes('fr')) {
    return 'fr';
  }

  if (navigatorLanguage.includes('es')) {
    return 'es_419';
  }

  return 'en';
}