import { computed, observable } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import BaseStore from './BaseStore';

export default class RouterStore extends BaseStore {
  _router: BrowserRouter;

  get router(): BrowserRouter {
    return this._router;
  }

  set router(value) {
    this._router = value;

    if (this._router) {
      this.pathname = this._router.history.location.pathname;

      const onPathChange = (pathname: string) => {
        this.pathname = pathname;

        const { location } = window;

        if (!this.pathname.includes('/projects/') && location.search.includes('index=')) {
          // clear project index param that differentiate between projects with the same name if no project loaded
          location.href = location.href.replace(/[?&]index=[^$?&]+/g, '');
        }
        this.queryParamsSettings = new URLSearchParams(location.search);
      }

      this._router.history.listen(event => onPathChange(event.pathname));
      onPathChange(window.location.pathname);
    }
  }

  @observable pathname: string = '';

  @computed get isReportPage() {
    return this.pathname.includes('/report');
  }

  @computed get isProjectsListPage() {
    return this.pathname.match(/projects$/)
  }

  @computed get isLoginPage() {
    return this.pathname.match(/login$/)
  }

  @computed get isDownloadsPage() {
    return this.pathname.match(/downloads$/)
  }

  @computed get isProjectView() {
    return this.pathname.match(/projects\//) && !this.isReportPage;
  }

  @observable queryParamsSettings = new URLSearchParams(window.location.search);

  // CAREFUL, make a backup before using this, because it can overwrite drawings
  @computed get isRescueMode() {
    return this.queryParamsSettings.get('mode') == 'rescue';
  }

  goToProjectView() {
    const { projectsStore } = this.stores;
    const { history } = this.router;
    history.push(`/ca/projects/${projectsStore.selectedProject.encodedName}${window.location.search}`);
  }

  goToPaymentPage = () => {
    const { userInfoStore } = this.stores;
    const { user } = userInfoStore;

    window.location.href = `https://evalumo.com/zone-client/${user.quadernoUserId}/edit`;
  }
}