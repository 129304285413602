// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { IDialogProps } from 'constants/CommonProps';
import Settings from 'models/Settings';
import * as React from 'react';
import i18n from 'utils/i18n';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import FeesDetailsForm from '../FeesDetailsForm/FeesDetailsForm';
import ObserverComponent from '../ObserverComponent';

interface ITaxEditDialog extends IDialogProps {
  settings: Settings,
}

export default class TaxEditDialog extends ObserverComponent<ITaxEditDialog> {
  _render() {
    const { settingsStore } = this.context;
    const { open, dialogId, settings } = this.props;

    return (
      <EditDialogComponent
        dialogId={dialogId}
        open={open}
        models={[settings]}
        stores={[settingsStore]}
        FormComponent={FeesDetailsForm}
        FormComponentProps={{ isTax: true }}
        title={i18n.t('Taxes')}
      />
    );
  }
}