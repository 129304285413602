import { Avatar, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import NoteIcon from '@material-ui/icons/DescriptionOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CopyIcon from '@material-ui/icons/FileCopy';
import CopyToExternalProjectIcon from '@material-ui/icons/Forward';
import StrikeThroughIcon from '@material-ui/icons/StrikethroughS';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InvisibilityIcon from '@material-ui/icons/VisibilityOff';
import Globals from 'Globals';
import classnames from 'classnames';
import WorksheetsTreeNodeEditor from 'components/common/WorksheetsTree/WorksheetsTreeNodeEditor';
import { DrawToolType } from 'constants/DrawToolType';
import { TreeNodePropertiesTabs } from 'constants/TreeNodePropertiesTabs';
import { TreeNodeStatus } from 'constants/TreeNodeStatus';
import { compact, isEmpty, remove } from 'lodash';
import Dialog from 'models/Dialog';
import Point from 'models/Point';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { addChildNode, addSiblingNode, compressDrawingShapes, expandDrawingShapes } from 'utils/TreeUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import AvatarImage from '../AvatarImage/AvatarImage';
import CopyNodeToExternalProjectDialog from '../CopyNodeToExternalProjectDialog/CopyNodeToExternalProjectDialog';
import { BubbleIcon, CompressIcon, DecompressIcon, ExplodeIcon, ImplodeIcon, StatusIcon } from '../Icons';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import ShapePreview from '../ShapePreview/ShapePreview';

const colors = require('Colors.scss');

const styles = require('./WorksheetsTreeNode.module.scss');

interface IWorksheetsTreeNodeProps {
  node: TreeNode;
  selectedField: keyof TreeNode;
  readonly?: boolean;
  shouldDisableFunction?: (node: TreeNode) => boolean
}

export default class WorksheetsTreeNode extends ObserverComponent<IWorksheetsTreeNodeProps> {
  state = {
    isEditMode: false,
  }

  static defaultProps = {
    shouldDisableFunction: (node: TreeNode) => false,
  }


  _ref: HTMLDivElement;

  get ref() {
    return this._ref;
  }

  set ref(value) {
    this._ref = value;
    /*

    // expensive and not sure how useful
        const { node } = this.props;
        const { treeNodesStore } = this.context;

        if (
          node.isSelected &&
          node.id === first(treeNodesStore.selectedTreeNodeIds)
        ) {
          try {
            scrollIntoView(this._ref, { scrollMode: 'if-needed' })
          } catch (e) { }
        }
        */
  }


  cursorPosOnMouseDown: Point

  get isSelected() {
    const { treeNodesStore } = this.context;
    return getSafe(() => treeNodesStore.selectedTreeNodes.includes(this.props.node));
  }

  get isEditMode() {
    const { treeNodesStore } = this.context;
    return getSafe(() => this.props.node.id === treeNodesStore.treeNodeBeingRenamed.id);
  }

  componentDidMount() {

  }

  private deleteNode = () => {
    const { treeNodesStore, drawToolsStore, dialogsStore } = this.context;
    const { node } = this.props;

    drawToolsStore.nodeBeingAdded = null;
    if (drawToolsStore.selectedTool !== DrawToolType.Select) {
      drawToolsStore.selectedTool = DrawToolType.Select;
    };

    treeNodesStore.deleteNodeAndDescendants(node);
  }

  private duplicateNode = () => {
    const { treeNodesStore } = this.context;
    const { node } = this.props;

    const nodeCopy = treeNodesStore.duplicateNode(node);
    treeNodesStore.selectedTreeNode = nodeCopy;
  }

  private askToDuplicateNodeToExternalProject = () => {
    const { node } = this.props;

    const { dialogsStore } = this.context;

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <CopyNodeToExternalProjectDialog
        open={open}
        dialogId={newDialog.id}
        node={node}
      />
    );
    dialogsStore.showDialog(newDialog);
  }

  private copyDrawing = () => {
    const { node } = this.props;
    const { treeNodesStore } = this.context;

    treeNodesStore.drawingTreeNodeBeingCopied = node.childDrawingNode;
  }

  toggleVisibility = (event: React.MouseEvent) => {
    const { treeNodesStore } = this.context;

    const { node } = this.props;
    event.preventDefault();
    event.stopPropagation();
    node.isVisible = !node.isVisible;
    treeNodesStore.addEditItems([node, ...node.descendants], true, ['_isVisible']);
  }

  setStatus = (status: TreeNodeStatus) => () => {
    const { treeNodesStore } = this.context;
    const { node } = this.props;
    node.status = status;

    // children, parent??
    treeNodesStore.addEditItems([node, ...node.nonDrawingDescendants], true, ['_status']);
  }

  toggleExcludeFromReports = (event: React.MouseEvent) => {
    const { treeNodesStore } = this.context;

    const { node } = this.props;
    event.preventDefault();
    event.stopPropagation();
    node.shouldExcludeFromReports = !node.shouldExcludeFromReports;
    treeNodesStore.addEditItem(node, true, ['shouldExcludeFromReports']);
  }

  toggleBubble = (event: React.MouseEvent) => {
    const { treeNodesStore } = this.context;
    const { node } = this.props;
    event.preventDefault();
    event.stopPropagation();

    /*if (node.childDrawingNode) {
      node.childrenIds = remove(node.childrenIds, node.childDrawingNode.id);
    }*/

    node.shouldBubbleMeasurements = !node.shouldBubbleMeasurements;
    treeNodesStore.addEditItem(node, true, ['shouldBubbleMeasurements', 'childrenIds']);

    // use this if we want to do this recursively for children, but for now disabled
    /*const newValue = !first(node.nonDrawingDescendants).shouldBubbleMeasurements;

    const nodes = node.nonDrawingDescendants;

    nodes.forEach(node => node.shouldBubbleMeasurements = newValue);

    treeNodesStore.addEditItems(nodes, true, ['shouldBubbleMeasurements']);
    */
  }

  explodeProjectDrawing = () => {
    const { treeNodesStore } = this.context;
    const { node } = this.props;

    const nodesToModify = [];

    const { childDrawingNode: originalDrawingNode } = node;

    originalDrawingNode.isDrawingNode = false;

    nodesToModify.push(node);

    originalDrawingNode.children.forEach(drawingChild => {
      const newChildNode = new TreeNode(this.context);
      newChildNode.name = drawingChild.name;
      newChildNode.shouldBubbleMeasurements = false;
      nodesToModify.push(newChildNode);

      // if this is a pure shape like line or surface, needs a new parent element (new drawingRoot)
      // otherwise, we convert the group to become the parent element of new drawing
      const newDrawingNode = !isEmpty(drawingChild.ownShapes)
        ? new TreeNode(this.context)
        : drawingChild;

      newDrawingNode.name = drawingChild.name;
      newDrawingNode.shouldBubbleMeasurements = true;
      newDrawingNode.isDrawingNode = true;
      newDrawingNode.backgroundImageId = originalDrawingNode.backgroundImageId;
      newDrawingNode.backgroundImageScale = originalDrawingNode.backgroundImageScale;

      if (newDrawingNode !== drawingChild) {
        newDrawingNode.childrenIds = [drawingChild.id];
      }

      nodesToModify.push(newDrawingNode);

      newChildNode.childrenIds = [newDrawingNode.id];
      node.childrenIds.push(newChildNode.id);
    });

    remove(node.childrenIds, id => id === originalDrawingNode.id);

    node.isExpanded = true;

    treeNodesStore.deleteItem(originalDrawingNode.id);
    treeNodesStore.addEditItems(nodesToModify);
  }

  implodeProjectDrawing = () => {
    const { treeNodesStore } = this.context;
    const { node } = this.props;

    const nodesToModify = [];

    const { childDrawingNode: originalDrawingNode } = node;

    const newDrawingNode = originalDrawingNode || new TreeNode(this.context);
    newDrawingNode.name = node.name;
    newDrawingNode.shouldBubbleMeasurements = true;
    newDrawingNode.isDrawingNode = true;
    newDrawingNode.backgroundImageId = node.descendants.find(d => d.backgroundImageId)?.backgroundImageId;
    newDrawingNode.backgroundImageScale = node.descendants.find(d => d.backgroundImageScale)?.backgroundImageScale;

    node.nonDrawingChildren.forEach(child => child.shouldBubbleMeasurements = true);
    nodesToModify.push(...node.nonDrawingChildren);

    newDrawingNode.children = node.nonDrawingChildren;
    node.childrenIds = [newDrawingNode.id];

    nodesToModify.push(newDrawingNode, node);

    newDrawingNode.descendants.forEach(drawingChild => {
      const subDrawingNode = drawingChild.childDrawingNode;
      if (subDrawingNode) {
        const { parent } = subDrawingNode;
        remove(parent.children, node => node === subDrawingNode);
        parent.childrenIds = [...parent.childrenIds, ...subDrawingNode.childrenIds];

        nodesToModify.push(parent);
        nodesToModify.push(drawingChild);

        treeNodesStore.deleteItem(subDrawingNode.id);
      }
    });

    treeNodesStore.addEditItems(nodesToModify);
  }

  _render() {
    const { userInfoStore, treeNodesStore, routerStore } = this.context;
    const { node, readonly, selectedField, shouldDisableFunction } = this.props;
    const { user } = userInfoStore;
    if (!node || !user) {
      return null;
    }

    const { shouldShowVisibilityIcon } = user;
    const { text, isVisible, shouldBubbleMeasurements, isParentExcludedFromReports, shouldExcludeFromReports, status } = node;
    const isSelected = node[selectedField];
    // if some children are invisible but not all
    const isVisibleUndefined = isVisible === undefined;

    // expert mode not shown in drawing mode
    const isDrawingTree = this.context === Globals.drawingStores;

    // very hack check
    const isCopyDrawingTarget = selectedField !== 'isSelected' && node.isSelected;

    const menuItems = compact([
      !node.isRootNode && {
        icon: <span className={styles.bubbleMenuItem}><Checkbox checked={node.shouldBubbleMeasurements} /><BubbleIcon /></span>,
        text: i18n.t('Transfer results to parent ({{-parentNodeName}})', { parentNodeName: node.parent?.name }),
        handler: this.toggleBubble,
        disabled: isDrawingTree
      },
      { icon: <CopyIcon />, text: i18n.t('Duplicate'), handler: this.duplicateNode, isGroupStart: true },
      !isDrawingTree && { icon: <CopyToExternalProjectIcon />, text: i18n.t('Copy to a different project...'), handler: this.askToDuplicateNodeToExternalProject },
      //!isEmpty(node.shapes) && { icon: <ContentCopyIcon />, text: i18n.t('Copy Drawing'), handler: this.copyDrawing },
      !isDrawingTree && node.children.length === 1 && node.children[0].isDrawingNode && node.children[0].children.length > 0 && node.shapes.length > 1 && { icon: <ExplodeIcon />, text: i18n.t('Explode Drawing'), handler: this.explodeProjectDrawing },
      !isDrawingTree && isEmpty(node.hasDrawing) && isEmpty(node.childTasks) && node.children.some(child => child.hasDrawing) && { icon: <ImplodeIcon />, text: i18n.t('Implode Drawing'), handler: this.implodeProjectDrawing },
      // todo: show conditions
      isDrawingTree && user.canCompressDrawing && /*isEmpty(node.hasDrawing) && isEmpty(node.childTasks) && node.children.some(child => child.hasDrawing) &&*/ { icon: <CompressIcon />, text: i18n.t('Compress Drawing Shapes'), handler: () => compressDrawingShapes(node) },
      isDrawingTree && /*node.children.length === 1 && node.children[0].isDrawingNode && node.children[0].children.length > 0 &&*/ node.ownShapes.length > 1 && { icon: <DecompressIcon />, text: i18n.t('Expand Drawing Shapes'), handler: () => expandDrawingShapes(node) },
      !isDrawingTree && !node.isRootNode && !node.isParentExcludedFromReports && { icon: <StrikeThroughIcon />, text: node.shouldExcludeFromReports ? i18n.t('Stop excluding from reports') : i18n.t('Should exclude from reports'), handler: this.toggleExcludeFromReports },
      isDrawingTree && !node.isRootNode && !treeNodesStore.hasSomeInvisibleNodes && !shouldShowVisibilityIcon && { icon: <InvisibilityIcon />, text: i18n.t('Hide drawing temporarily'), handler: this.toggleVisibility },
      !isDrawingTree && {
        icon: <StatusIcon />,
        text: i18n.t('Status'),
        subItems: [
          { icon: <CheckIcon style={{ color: colors.green }} />, text: i18n.t('Verified'), handler: this.setStatus(TreeNodeStatus.Verified) },
          { icon: <ErrorIcon style={{ color: colors.orange }} />, text: i18n.t('Incomplete'), handler: this.setStatus(TreeNodeStatus.Incomplete) },
          { icon: <div style={{ width: 24 }} />, text: i18n.t('Not specified'), handler: this.setStatus(TreeNodeStatus.None) },
        ],
        isGroupStart: true
      },
      !node.isRootNode && { icon: <DeleteIcon />, text: i18n.t('Delete'), handler: this.deleteNode, danger: true },
    ]);

    const isDisabled = shouldDisableFunction(node);

    const firstMeasurementSubcategWithImage = node.ownMeasurementValuesArray.find(mv => mv.measurement.subcategory?.imageUrl)?.measurement.subcategory;

    return (
      <div
        id={'node_' + node.id}
        ref={ref => this.ref = ref}
        className={classnames(
          styles.root,
          styles[`indentLevel${node.indentLevel}`], {
          "k-state-disabled": isDisabled,
          [styles.isSelected]: isSelected,
          [styles.isHidden]: isDrawingTree && !isVisible && !isVisibleUndefined,
          [styles.hasTasks]: !isEmpty(node.ownTasks),
          [styles.hasNoMeasurements]: isEmpty(node.nonOneTimeUseMeasurementValuesArray),
          [styles.isCopyDrawingTarget]: isCopyDrawingTarget
        })}
      >
        {
          this.isEditMode
            ? <WorksheetsTreeNodeEditor node={node} />
            : (
              <div className={styles.default}>
                {routerStore.queryParamsSettings.get('mode') !== 'rescue' && (
                  <div className={styles.avatar}>
                    {(!node.isRootNode || !isDrawingTree || node.shapes.length < 100) && (// too expensive to render whole drawing for root node
                      ((node.imageUrl || firstMeasurementSubcategWithImage) && (
                        node.shapes.length == 0 ||
                        node.imageUrl?.includes?.('.svg') && (node.isRootNode || node.parent?.isRootNode)
                      )) ? (
                        <Avatar className={styles.avatarImage}>
                          <AvatarImage
                            style={(node.imageUrl || !firstMeasurementSubcategWithImage) ? {} : { width: 30, height: 30 }}
                            model={(node.imageUrl || !firstMeasurementSubcategWithImage) ? node : firstMeasurementSubcategWithImage}
                          />
                        </Avatar>
                      ) : (
                        <ShapePreview
                          withLoadingDelay={node.stores.treeNodesStore.allVisibleNodes.length > 200}
                          width={30}
                          height={30}
                          containerHeight={30}
                          containerWidth={30}
                          treeNode={node}
                          shapes={node.shapes}
                          className={styles.preview}
                        />
                      )
                    )}
                  </div>
                )}
                {shouldBubbleMeasurements/* && (!isDrawingTree || !isEmpty(node.measurementValuesArray))*/ && (
                  <BubbleIcon className={styles.bubbleIcon} />
                )}
                <div className={classnames(
                  'nodeText',
                  styles.nodeText, {
                  [styles.shouldExcludeFromReports]: isParentExcludedFromReports || shouldExcludeFromReports,
                })}>{text}</div>
                {!isDrawingTree && node.textDescription2 && (
                  <Tooltip title={node.textDescription2}>
                    <div
                      onClick={(event) => {
                        this.context.settingsStore.settings.selectedTreeNodePropertiesTab = TreeNodePropertiesTabs.NOTES;
                        event.preventDefault();
                      }}
                      className={styles.note}
                    >
                      <NoteIcon />
                    </div>
                  </Tooltip>
                )}
                {!isDrawingTree && (
                  <div className={styles.status}>
                    {status === TreeNodeStatus.Verified && <CheckIcon style={{ color: colors.green }} />}
                    {status === TreeNodeStatus.Incomplete && <ErrorIcon style={{ color: colors.orange }} />}
                  </div>
                )}
              </div>
            )
        }
        {(<div className={styles.buttons + ' TreeNodeButtons'} tabIndex={0}>
          {!readonly && !node.isRootNode && (
            <Tooltip title={i18n.t('Add Group')} >
              <IconButton onClick={() => node.isRootNode ? addChildNode(node, false) : addSiblingNode(node)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}


          {isDrawingTree && (shouldShowVisibilityIcon || treeNodesStore.hasSomeInvisibleNodes) && !node.isRootNode && (
            <IconButton className={styles.button + ' ' + styles.visibilityButton} onClick={this.toggleVisibility}>{
              isVisible || isVisibleUndefined
                ? <VisibilityIcon className={isVisibleUndefined ? styles.visibilityIconUndefined : ''} />
                : <InvisibilityIcon />
            }</IconButton>
          )}

          {!readonly && <MenuPopupButton menuItems={menuItems} />}
        </div>)}

      </div>
    );
  }
}