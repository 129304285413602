import { FormControlLabel, Switch } from '@material-ui/core';
import Settings from 'models/Settings';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

interface TaxesOptionLabourExclusionToggleProps {
  settings: Settings
}

export default class TaxesOptionLabourExclusionToggle extends ObserverComponent<TaxesOptionLabourExclusionToggleProps> {
  get isWorkingOnACopy() {
    const { settingsStore } = this.context;
    return this.props.settings !== settingsStore.settings;
  }

  handleChange = (event, checked) => {
    const { settingsStore } = this.context;
    const { settings } = this.props;
    const { taxes } = settings;

    taxes.forEach(tax => {
      if (checked) {
        tax.providingItemsSubtypes = ['Material'];
      } else {
        tax.clearFilters();
      }
    });

    if (!this.isWorkingOnACopy) {
      settingsStore.addEditItem(settings);
    }
  }

  _render() {
    const { settings } = this.props;
    const { taxes } = settings;
    const shouldTaxesExcludeLabour = taxes?.[0]?.shouldTaxExcludeLabour;

    return (
      <FormControlLabel
        control={
          <Switch
            checked={shouldTaxesExcludeLabour}
            onChange={this.handleChange}
          />
        }
        label={i18n.t('Apply taxes to Material only')}
      />
    );
  }
}