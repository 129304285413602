import { computed, observable } from "mobx";
import { serializable } from "serializr";
import { getSafe } from "utils/Utils";
import Category from "./Category";
import ModelBase from './ModelBase';

export const DEFAULT_SUBTYPE = 'Default';

export default abstract class ModelBaseWithCategory extends ModelBase {
  @observable @serializable categoryId: ModelId;

  @computed get category(): Category {
    const { categoriesStore } = this.stores;
    return getSafe(() => categoriesStore.getItem(this.categoryId)) || categoriesStore.generalCategory
  }

  set category(value) {
    this.categoryId = getSafe(() => value.id) || ''
  }

  @observable @serializable subcategoryId: ModelId;

  @computed get subcategory(): Category {
    return this.getSubcategoryImpl();
  }

  getSubcategoryImpl(): Category {
    const { categoriesStore } = this.stores;
    return getSafe(() => categoriesStore.getItem(this.subcategoryId)) || categoriesStore.generalCategory
  }

  set subcategory(value) {
    this.subcategoryId = getSafe(() => value.id) || '';
  }

  @computed get categoryName(): string {
    return getSafe(() => this.category.name) || '';
  }

  @computed get subcategoryName(): string {
    return getSafe(() => this.subcategory.name) || '';
  }
}