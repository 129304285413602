import { FormControlLabel, Switch } from '@material-ui/core';
import Settings from 'models/Settings';
import * as React from 'react';
import { handleCheckboxChange, handleCheckboxChangeAndSave } from 'utils/FormUtils';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

interface FeeOptionRecursivityToggleProps {
  settings: Settings
}

export default class FeeOptionRecursivityToggle extends ObserverComponent<FeeOptionRecursivityToggleProps> {
  get isWorkingOnACopy() {
    const { settingsStore } = this.context;
    return this.props.settings !== settingsStore.settings;
  }

  _render() {
    const { settings } = this.props;
    const { settingsStore } = this.context;

    return (
      <FormControlLabel
        control={
          <Switch
            checked={settings.areFeesRecursivelyCalculated}
            onChange={this.isWorkingOnACopy
              ? handleCheckboxChange(settings, 'areFeesRecursivelyCalculated')
              : handleCheckboxChangeAndSave(settings, 'areFeesRecursivelyCalculated', settingsStore)
            }
          />
        }
        label={<>
          <div>{i18n.t('Apply each fee to other preceding fees.')}</div>
          <div>{i18n.t('For example, fee #2 will apply to project cost + fee #1.')}</div>
        </>}
      />
    );
  }
}