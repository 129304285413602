
import ObserverComponent from 'components/common/ObserverComponent';
import { throttle } from 'lodash';
import { action } from 'mobx';
import { Rectangle } from 'models/Rectangle';
import * as React from 'react';
import { cursorToLocalPoint } from 'utils/Coords';
import { drawToolMouseMoveThrottledBase, MOUSE_MOVE_THROTTLE_TIME } from 'utils/DrawToolsUtils';
import { getSafe } from 'utils/Utils';

const styles = require('./CropBackgroundImageTool.module.scss');

export default class CropBackgroundImageTool extends ObserverComponent {
  svgTag: SVGSVGElement;

  componentDidMount() {
    this.svgTag.addEventListener('click', this.mouseDown);
    window.document.addEventListener('mousemove', this.mouseMove);

    const { shapesStore } = this.context;

    shapesStore.backgroundImageCropRectangle = new Rectangle(this.context);
    shapesStore.isCroppingBackgroundImageSecondPoint = false;
    shapesStore.isConfirmingBackgroundImageCrop = false;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { shapesStore } = this.context;

    this.svgTag.removeEventListener('click', this.mouseDown);
    window.document.removeEventListener('mousemove', this .mouseMove);
    shapesStore.backgroundImageCropRectangle = null;
  }

  @action.bound
  mouseDown(ev: MouseEvent) {
    const { shapesStore } = this.context;
    if (
      // duplicate
      ev.ctrlKey ||
      ev.button !== 0 ||
      shapesStore.isDragPanningOnDrawing ||
      (ev?.target as HTMLElement)?.tagName === 'INPUT'
    ) {
      return;
    }

    if (shapesStore.isConfirmingBackgroundImageCrop) {
      return;
    }

    const localPt = cursorToLocalPoint(ev, this.svgTag);

    if (shapesStore.isCroppingBackgroundImageSecondPoint) {
      shapesStore.backgroundImageCropRectangle.bottomRight = localPt.clone();
      shapesStore.isConfirmingBackgroundImageCrop = true;
    } else {
      shapesStore.backgroundImageCropRectangle.topLeft = localPt.clone();
      shapesStore.isCroppingBackgroundImageSecondPoint = true;
    }
  }


  @action.bound
  mouseMove(ev: MouseEvent) {
    this.mouseMoveThrottled(ev);
  }

  @action
  mouseMoveThrottled = throttle((ev: MouseEvent) => {
    const { shapesStore } = this.context;

    drawToolMouseMoveThrottledBase(ev, this.svgTag, this.context);

    if (shapesStore.isConfirmingBackgroundImageCrop) {
      return;
    }

    const localPt = cursorToLocalPoint(ev, this.svgTag);

    if (shapesStore.isCroppingBackgroundImageSecondPoint) {
      shapesStore.backgroundImageCropRectangle.bottomRight = localPt.clone();
    } else {
    // should only be down on mouse down for first point
     // shapesStore.backgroundImageCropRectangle.topLeft = localPt.clone();
    }

  }, MOUSE_MOVE_THROTTLE_TIME)

  _render() {
    return (
      <g id="CropTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement) || this.svgTag} />
    );
  }
}