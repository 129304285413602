
import { Avatar } from '@material-ui/core';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import AvatarImage from '../AvatarImage/AvatarImage';
import ObserverComponent from '../ObserverComponent';

const styles = require('./AvatarImageWithFallback.module.scss');

interface AvatarImageWithFallbackProps {
  model?: ModelBase,
  className?: string,
}

export default class AvatarImageWithFallback extends ObserverComponent<AvatarImageWithFallbackProps> {
  _render() {
    const { model, className } = this.props;

    if (!model) {
      return null;
    }

    return (model.thumbUrl) ? (
      <Avatar className={className + ' ' + styles.icon}>
        <AvatarImage model={model} className={styles.root} />
      </Avatar>
    ) : (
      <Avatar className={styles.icon}>
        {model.name.replace(/[^A-Za-z]/g, '').substring(0, 1)}
      </Avatar>
    );
  }
}