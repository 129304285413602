// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import ObserverComponent from 'components/common/ObserverComponent';
import { IDialogProps } from 'constants/CommonProps';
import Category from 'models/Category';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import CategoryEditDetailsForm from '../CategoryEditDetailsForm/CategoryEditDetailsForm';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';

interface ICategoryEditDialog extends IDialogProps {
  category: Category,
  onClose: (shouldSave: boolean, modelCopies: ModelBase[]) => void;
}

export default class CategoryEditDialog extends ObserverComponent<ICategoryEditDialog> {
  _render() {
    const { open, dialogId, category, onClose } = this.props;
    const { categoriesStore } = this.context;

    return (
      <EditDialogComponent
        dialogId={dialogId}
        open={open}
        models={[category]}
        FormComponent={CategoryEditDetailsForm}
        onClose={onClose}
      />
    );
  }
}