import { filter } from "lodash";

// COULDNT FIGURE OUT HOW TO IMPORT FROM node_modules

"use strict";



// Helper functions.
const getContext = function(width, height){
  const canvas = document.createElement("canvas");
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  return canvas.getContext('2d');
};

const getImageData = function(img, loaded){

  const imgObj = new Image();
  const imgSrc = img.src || img;

  // Can't set cross origin to be anonymous for data url's
  // https://github.com/mrdoob/three.js/issues/1305
  if ( imgSrc.substring(0,5) !== 'data:' )
    imgObj.crossOrigin = "Anonymous";

  imgObj.onload = function(){
    const context = getContext(imgObj.width, imgObj.height);
    context.drawImage(imgObj, 0, 0);

    const imageData = context.getImageData(0, 0, imgObj.width, imgObj.height);
    loaded && loaded(imageData.data);
  };

  imgObj.src = imgSrc;

};

const makeRGB = function(name){
  return ['rgb(', name, ')'].join('');
};

const mapPalette = function(palette){
  const arr = [];
  for (const prop in palette) { arr.push( frmtPobj(prop, palette[prop]) ) };
  arr.sort(function(a, b) { return (b.count - a.count) });
  return arr;
};

const fitPalette = function(arr, fitSize) {
  if (arr.length > fitSize ) {
  return arr.slice(0,fitSize);
} else {
  for (let i = arr.length-1 ; i < fitSize-1; i++) { arr.push( frmtPobj('0,0,0', 0) ) };
  return arr;
};
};

const removeWhiteAndBlackish = function(arr) {
  return filter(arr, color => {
    const rgb = color.name.replace('rgb(', '').replace(')', '').split(',').map(colorString => Number(colorString));
    const threshold = 80;

    return (Math.abs(rgb[0] - rgb[1]) > threshold) ||
      (Math.abs(rgb[0] - rgb[2]) > threshold) ||
      (Math.abs(rgb[1] - rgb[2]) > threshold);
  });
}

const frmtPobj = function(a,b){
  return {name: makeRGB(a), count: b};
}


// RGBaster Object
// ---------------
//
const PALETTESIZE = 10;

class RGBaster {

  static colors = function(img, opts){

  opts = opts || {};
  const exclude = opts.exclude || [ ], // for example, to exclude white and black:  [ '0,0,0', '255,255,255' ]
      paletteSize = opts.paletteSize || PALETTESIZE;

  getImageData(img, function(data){

            let colorCounts   = {},
                rgbString     = '',
                rgb           = [],
                colors        = {
                  dominant: { name: '', count: 0 },
                  palette:  []
                };

            let i = 0;
            for (; i < data.length; i += 4) {
              rgb[0] = data[i];
              rgb[1] = data[i+1];
              rgb[2] = data[i+2];
              rgbString = rgb.join(",");

              // skip undefined data and transparent pixels
              if (rgb.indexOf(undefined) !== -1  || data[i + 3] === 0) {
                continue;
              }

              // Ignore those colors in the exclude list.
              if ( exclude.indexOf( makeRGB(rgbString) ) === -1 ) {
                if ( rgbString in colorCounts ) {
                  colorCounts[rgbString] = colorCounts[rgbString] + 1;
                }
                else{
                  colorCounts[rgbString] = 1;
                }
              }

            }

            if ( opts.success ) {
              let palette = mapPalette(colorCounts);
              palette = removeWhiteAndBlackish(palette);
              palette = fitPalette( palette, paletteSize+1 );


              opts.success(
                palette.length > 0 && palette[0].name,
              );
            }
  });
};

}

export default RGBaster