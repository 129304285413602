import Globals from 'Globals';
import { sumBy } from 'lodash';
import { ReusableBatch } from 'utils/ReusableBatch';

export default class UndoDataPoint {
  constructor(
    public batches: ReusableBatch[],
    public rollbackBatches: ReusableBatch[]
  ) {};

  // This commit only gets called on Redo/Undo, not on the original commit
  // semi duplicate
  async commit(batches = this.batches) {
    const { undoStore } = Globals.defaultStores;

    undoStore.isBusy = true;

    console.log("Committing(undo/redo) " + (sumBy(batches, batch => batch.mutations.length)) + " changes");

    await batches.reduce(async (previousBatchTask, batch) => {
      await previousBatchTask;
      await batch.commit();
      return '';
    }, Promise.resolve(''));

    undoStore.isBusy = false;
  }

  async rollback() {
    return this.commit(this.rollbackBatches);
  }
}
