const styles = require('./SnapPoint.module.css');
import ObserverComponent from 'components/common/ObserverComponent';
import Point from 'models/Point';
import * as React from 'react';

interface ISnapPointProps {
  point: Point;
}

export default class SnapPoint extends ObserverComponent<ISnapPointProps> {
  private RADIUS = 15;
  private CROSS_HAIR_EXTRA_LENGTH = 8;
  private CROSS_HAIR_LENGTH = this.RADIUS + 2 * this.CROSS_HAIR_EXTRA_LENGTH;

  public _render(): JSX.Element[] {
    const { point } = this.props;

    return point && [
      <circle
        key="circle"
        className={styles.default}
        cx={point.x}
        cy={point.y}
        r={this.RADIUS}
      />,
      <line
        key="verticalLine"
        className={styles.default}
        x1={point.x}
        y1={point.y - this.CROSS_HAIR_LENGTH}
        x2={point.x}
        y2={point.y + this.CROSS_HAIR_LENGTH}
      />,
      <line
        key="horizontalLine"
        className={styles.default}
        x1={point.x - this.CROSS_HAIR_LENGTH}
        y1={point.y}
        x2={point.x + this.CROSS_HAIR_LENGTH}
        y2={point.y}
      />
    ]
  }
}
