
import { TextField as MatUITextField } from '@material-ui/core';
import { FilledTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import * as classnames from 'classnames';
import { defer, omit } from 'lodash';
import * as React from 'react';
const styles = require('./TextField.module.scss');

export type ITextFieldProps = Partial<TextFieldProps> & {
  shouldFocusOnMount?: boolean,
  shouldSelectAllOnFocus?: boolean,
  shouldStayFocused?: boolean,
  shouldStaySelectedAll?: boolean,
  shouldScrollIntoView?: boolean,
};

export default class TextField extends React.Component<ITextFieldProps> {
  static defaultProps = {
    shouldSelectAllOnFocus: false, // would be good to set true by default but too buggy
  };

  fieldRef;

  componentDidMount() {
    const { shouldSelectAllOnFocus, shouldFocusOnMount, disabled } = this.props;
    if (shouldFocusOnMount && !disabled) {
      this.fieldRef?.focus?.();
      if (shouldSelectAllOnFocus) {
        document.execCommand("selectall", null);
      }

      defer(() => {
        this.fieldRef?.focus?.();
        if (shouldSelectAllOnFocus) {
          document.execCommand("selectall", null);
        }
      })
    }
  }

  componentDidUpdate() {
    const { shouldStayFocused, shouldStaySelectedAll, shouldScrollIntoView, disabled } = this.props;
    if (shouldStayFocused && !disabled) {
      this.fieldRef?.focus?.({ preventScroll: !shouldScrollIntoView });

      if (shouldStaySelectedAll) {
        document.execCommand("selectall", null);
      }
    }

    if (shouldScrollIntoView) {
      this.fieldRef?.scrollIntoViewIfNeeded?.(); // not supported in Firefox
    }
  }

  handleFocus = (event) => {
    const { shouldSelectAllOnFocus } = this.props;
    if (shouldSelectAllOnFocus) {
      document.execCommand("selectall", null);
    }
  }

  handleOnMouseUp = (event) => {
    if (this.props.value === 0) {
      // avoids positioning cursor beside 0 and when typing 1, getting 10
      // not 100% working
      document.execCommand("selectall", null);
    }
  }

  render() {
    const { className, disabled, margin } = this.props;
    const filteredProps = omit(this.props, 'onFocus', 'onMouseUp', 'className', 'shouldFocusOnMount', 'shouldSelectAllOnFocus', 'inputRef', 'margin') as FilledTextFieldProps;

    return (
      <MatUITextField
        onFocus={(event) => defer(() => {
          this.handleFocus(event);
          if (this.props.onFocus) {
            this.props.onFocus(event);
          }
        })}
        onMouseUpCapture={(event) => {
          this.handleOnMouseUp(event);
          if (this.props.onMouseUp) {
            this.props.onMouseUp(event);
          }
        }}
        inputRef={ref => {
          const { inputRef } = this.props;
          this.fieldRef = ref;
          if (inputRef) {
            inputRef(ref);
          }
        }}
        margin={margin || 'normal'}
        variant="filled"
        className={classnames(
          styles.root, {
          [styles.isDisabled]: disabled,
          [className]: !!className
        }
        )}
        {...filteredProps}
      />
    )
  }
}