
import { MenuItem, MenuItemProps } from '@material-ui/core';
import * as React from 'react';
import ObserverComponent from '../ObserverComponent';

interface MenuItemComponentProps {
  item: any;
};

export default class MenuItemComponent extends ObserverComponent<MenuItemComponentProps & MenuItemProps> {

  componentDidMount(): void {
    if (this.props.item.danger) {
      window.document.addEventListener('keydown', this.onKeyDown);
    }

  }
  componentWillUnmount(): void {
    if (this.props.item.danger) {
      window.document.removeEventListener('keydown', this.onKeyDown);
    }
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      this.props.item?.handler(null);
    }
  }

  _render() {
    return (
      <MenuItem {...this.props} />
    );
  }
}