import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import DimensionSurface from 'components/drawing/DimensionSurface/DimensionSurface';
import { DrawToolType } from 'constants/DrawToolType';
import { isEmpty } from 'lodash';
import Surface from 'models/Surface';
import * as React from 'react';

// no modules because svg
require('./SurfaceComponent.scss');

const DOUBLE_CLICK_THRESHOLD = 200;

interface ISurfaceComponentProps {
  surface: Surface;
  shouldShowDimensions?: boolean;
  readonly?: boolean;
  forceObserve?: boolean,
  containerWidth?: number,
  containerHeight?: number,
};

export default class SurfaceComponent extends ObserverComponent<ISurfaceComponentProps> {
  private queuedOnClicks: number/*NodeJS.Timeout*/[] = [];

  onClickDelayed = (event: React.MouseEvent<SVGPathElement>) => {
    event.stopPropagation();
    event.preventDefault();
    event.persist();
    this.queuedOnClicks.push(setTimeout(this.onClick, DOUBLE_CLICK_THRESHOLD, event));
  }

  // duplicate
  onClick = (event: React.MouseEvent<SVGPathElement>) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;

    this.queuedOnClicks.forEach(timer => (
      clearTimeout(timer)
    ));
    this.queuedOnClicks = [];

    // reverse wire double click and click, because surface should highlight parent group by default, because less likely
    // to want to select an individual surface than an individual line
    drawToolsStore.dispatchEvent(new CustomEvent('doubleclick', { detail: { shape: this.props.surface, originalEvent: event } }));
  }

  // duplicate
  onDoubleClick = (event: React.MouseEvent<SVGPathElement>) => {
    const { drawToolsStore, treeNodesStore } = this.context;
    this.queuedOnClicks.forEach(timer => (
      clearTimeout(timer)
    ));
    this.queuedOnClicks = [];

    drawToolsStore.dispatchEvent(new CustomEvent('click', { detail: { shape: this.props.surface, originalEvent: event } }));
  }

  _render() {
    const { routerStore, drawToolsStore } = this.context;
    const { isReportPage } = routerStore;
    const { surface, readonly, shouldShowDimensions, containerWidth, containerHeight } = this.props;
    if (!surface) {
      return null;
    }

    const isSelectOrGroup = drawToolsStore.selectedTool === DrawToolType.Select || drawToolsStore.selectedTool === DrawToolType.GroupShapes;

    const { legendColor } = surface;

    return (
      <g
        onClick={readonly ? undefined : this.onClickDelayed}
        onDoubleClick={readonly ? undefined : this.onDoubleClick}
        className={classnames('SurfaceComponent', {
          isShapeHidden: !(readonly || surface.isVisible),
          isHighlightedFromMeasurement: !readonly && drawToolsStore.shapesToHighlightFromHoveredMeasurement.has(surface)
        })}
        style={(!isReportPage && legendColor) ? { color: legendColor } : {}}
      >
        {!isEmpty(surface.offsetSurfaces) && (
          <g id="offsetPaths">
            {surface.offsetSurfaces.map((offsetSurface, index) => {
              return (
                <path
                  key={`offset${index}`}
                  className={'measurementPath' + ' ' + 'offsetPath' + ' ' + `offsetPath${index}` + ((offsetSurface.surface < surface.surface) ? ' internalOffset' : '')}
                  d={offsetSurface.pathString}
                />
              );
            })}
          </g>
        )}

        {!isEmpty(surface.repeatedLinePaths) && (
          <g id="repeatedVerticalLinePaths">
            {surface.repeatedLinePaths.map((pathGroup, pathGroupIndex) => pathGroup.map((path, index) => (
              <path
                key={`repeatedVerticalLine${index}`}
                className={'measurementPath' + ' ' + 'repeatedLine' + ' ' + `repeatedLine${pathGroupIndex}`}
                d={path}
              />
            )))}
          </g>
        )}

        <path
          key="fill"
          className="fill"
          d={surface.pathString}
        />

        {shouldShowDimensions && (!containerWidth || containerWidth > 150) && <DimensionSurface surfaceToMeasure={surface} readonly={readonly} containerWidth={containerWidth} containerHeight={containerHeight} />}
      </g>
    );
  }
}
