import * as React from 'react';
import ObserverComponent from '../ObserverComponent';

const styles = require('./Panel.module.scss');

interface IPanelProps {
  header?: string | JSX.Element |  JSX.Element[],
  content?: JSX.Element |  JSX.Element[],
  hidden?: boolean,
  className?: string,
}

export default class Panel extends ObserverComponent<IPanelProps> {
  _render() {
    const { header, content, hidden, className } = this.props;

    return (
      <div className={className + ' ' + styles.root + ' ' + (hidden ? styles.hidden : '')}>
        <div className={styles.borderContainer}>
          <div className={styles.header}>
            {header}
          </div>

          <div className={styles.content}>
            {content}
          </div>
        </div>
      </div>
    );
  }
}
