
import { IEditComponentProps } from 'constants/CommonProps';
import Settings from 'models/Settings';
import * as React from 'react';
import ObserverComponent from '../ObserverComponent';
import FeeOptionPriceInclusionToggle from './FeeOptionPriceInclusionToggle';
import FeeOptionRecursivityToggle from './FeeOptionRecursivityToggle';

const styles = require('./FeesCalculationDetailsForm.module.scss');

export default class FeesCalculationDetailsForm extends ObserverComponent<IEditComponentProps> {
  get settings() {
    return this.props.models[0] as Settings;
  }

  _render() {
    const settings = this.settings;

    return (
      <div className={styles.root}>
        <FeeOptionPriceInclusionToggle settings={settings} />
        <FeeOptionRecursivityToggle settings={settings} />
      </div>
    )
  }
}