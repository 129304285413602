
const pendingFetches: Map<string, Promise<string | void>> = new Map<string, Promise<string | void>>();

export function fetchUrl(url): Promise<string | void> {
  if (pendingFetches.has(url)) {
    return pendingFetches.get(url);
  }

 // console.log('call');
  const fetchPromise = fetch(url)
    .then((response) => {
      return response.text();
    }).catch(reason => {
   //   console.log('fetch error', reason);
      pendingFetches.delete(url);
    });

  pendingFetches.set(url, fetchPromise);

  return fetchPromise;
}

export function fetchURLs(urls: string[]): Promise<(string | void)[]> {
  return Promise.all(urls.map(url => fetchUrl(url)));
}
