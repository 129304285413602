import { isObjectLike, isUndefined, mapValues } from 'lodash';
import SerializableBase from 'models/SerializableBase';
import { serialize } from "serializr";

// File named deserialize utils, but only serialize utils

export function modelToPlainObject (item: SerializableBase): any {
  // change undefined to null
  let plainItem = mapValues(serialize(item), item => isUndefined(item) ? null : item);
  return plainItem;
}

export function modelToPlainObjectNonNested (item: SerializableBase): any {
  let plainItem = modelToPlainObject(item);

  plainItem = mapValues(plainItem, itemProp => isObjectLike(itemProp) ? JSON.stringify(itemProp) : itemProp);

  return plainItem;
}

