// Allows to turn off observation when doing big changes
// ALWAYS call super.componentWillUnmount() in child classes if they override it

import { untracked } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Stores from 'stores/Stores';
import StoresContext from 'stores/StoresContext';

export interface ObserverComponentProps {
  forceObserve?: boolean;
}

@observer
export default class ObserverComponent<P = {}, S = {}> extends React.Component<P & ObserverComponentProps, S> {
  //declare context: React.ContextType<typeof StoresContext>
  context: Stores;
  static contextType = StoresContext;

  render() {
    // disable updates of all components at the cost of updating every components once
    // when we put the flag to true
    // should be able to avoid if using concurrent mode on most expensive renders
    const isBigUpdateOngoing = this.context?.commonStore?.isBigUpdateOngoing;

    if (isBigUpdateOngoing && !this.props.forceObserve) {
      return untracked(() => this._render());
    }
    return this._render();
  }

  _render() {
    return null;
  }
}