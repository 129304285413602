
import TuneIcon from '@material-ui/icons/Tune';
import { FormulaType } from 'constants/FormulaType';
import * as React from 'react';
import { CalculateIcon, SquareIcon } from '../Icons';

const styles = require('./FormulaTypeIcon.module.scss');

interface FormulaTypeIconProps {
  formulaType: FormulaType,
}

export default class FormulaTypeIcon extends React.Component<FormulaTypeIconProps> {
  render() {
    const { formulaType } = this.props;

    let icon = null;
    switch (formulaType) {
      case FormulaType.UserParameter:
        icon = <TuneIcon />;
        break;
      case FormulaType.DrawingParameter:
      case FormulaType.DrawingParameterUnresolved:
        icon = <SquareIcon />;
        break;
      case FormulaType.ComputedParameter:
        icon = <CalculateIcon />;
        break;
    }

    return (
      <div className={styles.root + ' FormulaTypeIcon'}>
        {icon}
      </div>
    )
  }
}