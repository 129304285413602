import firebase from 'firebase/compat/app';
import Globals from 'Globals';
import { pick } from 'lodash';
import UndoDataPoint from 'models/UndoDataPoint';
import { ReusableBatch, ReusableBatchMutation } from './ReusableBatch';

// https://github.com/Mikkelet/firebase-helpers/blob/master/batch_bulk.ts

export default function firestoreBatch() {
  return new BatchInstance();
}

export class BatchInstance {
  private _batch: ReusableBatch;
  private _rollbackBatch: ReusableBatch;
  private _size = 0;
  private _batches: ReusableBatch[];
  private _rollbackBatches: ReusableBatch[];

  public isUndoable = true;

  get dbCache() {
    return Globals.defaultStores.dbCacheStore.cache;
  }

  /**
   * initiate batch
   */
  public constructor() {
    this._batch = new ReusableBatch();
    this._batches = [];
    this._rollbackBatch = new ReusableBatch();
    this._rollbackBatches = [];
  }
  /**
   * Set or overwrite data to new or existing document
   * @param doc Docuement to be set
   * @param data Data to be applied
   */
  set(doc: firebase.firestore.DocumentReference, data: any, options?: firebase.firestore.SetOptions) {
    this._batch.mutations.push(new ReusableBatchMutation(doc, data, options));

    let previousData = this.dbCache.get(doc.path);
    if (options?.merge) {
      previousData = pick(previousData, Object.keys(data));
      Object.keys(data).forEach(key => {
        if (previousData[key] === undefined) {
          previousData[key] = firebase.firestore.FieldValue.delete();
        }
      });
    }

    if (previousData) {
      this._rollbackBatch.mutations.push(new ReusableBatchMutation(doc, previousData, options));
    } else {
      // careful, means that undoing will delete that document
      this._rollbackBatch.mutations.push(new ReusableBatchMutation(doc, null));
    }

    this._size++;
    this.addBatchIfFull();
  }
  /**
   * Delete a document
   * @param doc document to be deleted
   */
  delete(doc: firebase.firestore.DocumentReference) {
    this._batch.mutations.push(new ReusableBatchMutation(doc, null));

    const previousData = this.dbCache.get(doc.path);
    this._rollbackBatch.mutations.push(new ReusableBatchMutation(doc, previousData));

    this._size++;
    this.addBatchIfFull();
  }

  private addBatchIfFull() {
    if (this._size < 500) return;
    this._batches.push(this._batch);
    this._rollbackBatches.push(this._rollbackBatch);
    this.resetBatch()
  }

  async commit() {
    const { undoStore, commonStore } = Globals.defaultStores;
    // if any docs left in current batch, push to batch list
    if (this._size > 0) {
      this._batches.push(this._batch);
      this._rollbackBatches.push(this._rollbackBatch);
    }

    // if batch list has any batches
    if (this._batches.length > 0) {
      console.log("Committing " + ((this._batches.length - 1) * 500 + this._size) + " changes")

      // sequential to preserver order, but maybe dangerous that not all batches get sent

      // push to undo right away, not waiting for commit to complete, to make sure that multiple commit commands sent
      // at the same time all get merged in the same undo point
      // user won't be able to undo until commit has completed because we are in "busy" state
      if (this.isUndoable) {
        undoStore.isBusy = true;
        undoStore.pushDataPoint(new UndoDataPoint(this._batches, this._rollbackBatches.slice(0).reverse()));
      }

      await this._batches.reduce(async (previousBatchTask, batch) => {
        await previousBatchTask;
        try {
          await batch.commit();
        } catch (error) {
          if (navigator.onLine) {
            commonStore.error =  error.message + '\n' + batch.mutations.slice(0,5).map(mut => mut.doc.path).join('; \n');
            throw (error);
          }
        }

        return '';
      }, Promise.resolve(''));

      undoStore.isBusy = false;

      this._batches = [];
      this._rollbackBatches = [];
      this.resetBatch()
    }
  }

  private resetBatch() {
    this._size = 0;
    this._batch = new ReusableBatch();
    this._rollbackBatch = new ReusableBatch();
  }
}