import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';

const styles = require('./ButtonOverlay.module.scss');

interface IButtonOverlayProps {
  label: string,
  onClick?: () => void,
}

export default class ButtonOverlay extends React.Component<IButtonOverlayProps> {
  static defaultProps = {
    onClick: () => {;},
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.container} onClick={this.props.onClick}>
          <AddIcon />
          {this.props.label}
          {this.props.children}
        </div>
      </div>
    )
  }
}