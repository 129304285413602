import { Button } from '@material-ui/core';
// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import Globals from 'Globals';
import { IDialogProps } from 'constants/CommonProps';
import { compact, uniqBy } from 'lodash';
import { action } from 'mobx';
import TasksList from 'models/TasksList';
import * as React from 'react';
import { WriteBatch, firestore } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { getMeasurementValueAtNode, getMeasurementsWithDependencies } from 'utils/MeasurementUtil';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import * as uuidv4 from 'uuid/v4';
import EditDialogComponent from '../EditDialogComponent/EditDialogComponent';
import ObserverComponent from '../ObserverComponent';
import TasksListDetailsForm from '../TasksListDetailsForm/TasksListDetailsForm';
import { copyTasksFromListToProject } from '../TasksListsList/TasksListUtil';
;

const styles = require('./TasksListEditDialog.module.scss');

interface ITasksListEditDialog extends IDialogProps {
  tasksList: TasksList,
}

interface TasksListEditDialogState {
  tasksListCopy: TasksList,
}

// A bit confusing because this uses both stores context to move content between the 2
// (current project stores context and tasks list stores context)
export default class TasksListEditDialog extends ObserverComponent<ITasksListEditDialog, TasksListEditDialogState> {
  draftSuffix = '_draft_' + uuidv4();

  state = {
    tasksListCopy: null,
  };

  get tasksListId() {
    return this.props.tasksList.id;
  }

  get tasksListDraftId() {
    return this.props.tasksList.id + this.draftSuffix;
  }

  handleClose = async (shouldSave: boolean) => {
    const { listStores, listDraftStores, defaultStores } = Globals;

    const tasksListDraft = defaultStores.tasksListsStore.itemsMap.get(this.tasksListDraftId);
    const tasksListDraftNode = listDraftStores.treeNodesStore.rootNode;

    let batch = firestoreBatch();

    // Save the list itself (not applying it to project yet)
    if (shouldSave) {
      // Probably overkill to do this, should just load the tasks list without calculating for current project node
      // also, measurements bubbled from child dont get copied correctly in the first place, on tasks list dialog open

      // move measurements from leafs to root node, in preparation of removing child nodes
      // also need to use ID when comparing between stores in different contexts
      const measurements = getMeasurementsWithDependencies(compact(tasksListDraftNode.ownTasks.map(task => task.measurement)));
      measurements.forEach(measurement => {
        const rootMeasurementValue = getMeasurementValueAtNode(tasksListDraftNode, measurement);

        tasksListDraftNode.ownMeasurementValues.set(
          measurement.id,
          rootMeasurementValue
        )
      });

      // cleanup
      listDraftStores.treeNodesStore.removeUnneededMeasurements(tasksListDraftNode);

      // remove child nodes
      //listDraftStores.treeNodesStore.deleteDescendants(tasksListDraftNode, true, batch);
      //listDraftStores.treeNodesStore.batchAddEditItem(tasksListDraftNode, batch);

      // --
      // copy from draft to task list
      //
      // tasklist itself
      const tasksList = tasksListDraft.clone();
      tasksList.id = this.tasksListId;
      tasksList.isHidden = false;
      tasksList.isMultiCategs = Object.keys(listDraftStores.tasksStore.itemsByCateg).length > 1;
      
      defaultStores.tasksListsStore.batchAddEditItem(tasksList, batch);

      // Measurements (quantity measurements changed id, maybe that wasn't necessary)
      listStores.measurementsStore.batchAddEditItems(
        // sometimes measuremnt is null, not sure if it is a bug
        compact(tasksListDraftNode.ownMeasurementValuesArray.map(measurementValue => measurementValue.measurement)),
        batch
      );

      // root node (only node)
      listStores.treeNodesStore.batchAddEditItem(listDraftStores.treeNodesStore.rootNode.clone(), batch);

      // tasks (replace)
      listStores.tasksStore.dropTable(batch);
      // need allItems because deleted tasks need to be saved to overwrite masterprojectdb tasks
      listStores.tasksStore.batchAddEditItems(listDraftStores.tasksStore.allItems.map(model => model.clone()), batch);

      await batch.commit();
    }

    batch = firestoreBatch();
    batch.isUndoable = false;

    this.deleteDraft(batch);
    await batch.commit();

    listDraftStores.tasksStore.selectedItems.clear();

    listStores.commonStore.selectedProjectId = '';
    listDraftStores.commonStore.selectedProjectId = '';
  }

  deleteDraft = (batchParam: WriteBatch) => {
    const { tasksListsStore } = Globals.defaultStores;
    const { treeNodesStore, tasksStore, measurementsStore } = Globals.listDraftStores;
    const batch = batchParam || firestoreBatch();

    treeNodesStore.dropTable(batch);
    tasksStore.dropTable(batch);
    measurementsStore.dropTable(batch);

    tasksListsStore.getItem(this.tasksListDraftId).cascadeOrders.delete(0);
    tasksListsStore.deleteItems([this.tasksListDraftId], true, batch);
    
    if (!batchParam) {
      batch.isUndoable = false;
      batch.commit();
    }
  }

  async componentDidMount() {
    const { treeNodesStore, tasksStore, commonStore } = Globals.listStores;
    const { treeNodesStore: draftTreeNodesStore, tasksStore: draftTasksStore, commonStore: draftCommonStore } = Globals.listDraftStores;

    if (commonStore.selectedProjectId !== this.tasksListId) {
      commonStore.selectedProjectId = this.tasksListId;
    }

    if (draftCommonStore.selectedProjectId !== this.tasksListDraftId) {
      draftCommonStore.selectedProjectId = this.tasksListDraftId;
    }

    await waitOnStoresReady([treeNodesStore, tasksStore, draftTreeNodesStore, draftTasksStore]);

    if (!treeNodesStore.rootNode || !draftTreeNodesStore.rootNode) {
      debugger;
    }

    this.prepareTaskListDraft();
  }

  @action
  prepareTaskListDraft() {
    let { tasksList } = this.props;
    const { defaultStores, listStores, listDraftStores } = Globals;

    const projectTreeNodesStore = Globals.defaultStores.treeNodesStore;

    const db = firestore();
    let batch = firestoreBatch();

    // --
    // Copy from taskList to taskList draft
    // NOT NECESSARILY IN SAME COLLECTION (if master task list)
    // tasklist itself
    tasksList = tasksList.clone(listStores); // ensure proper source store -- hack
    const tasksListDraft = tasksList.cloneDeep(batch, Globals.listDraftStores, this.tasksListDraftId, true);
    tasksListDraft.isHidden = true;

    defaultStores.tasksListsStore.batchAddEditItem(tasksListDraft, batch);

    const tasksListDraftRootNode = listDraftStores.treeNodesStore.rootNode;

    // --
    // Copy drawing node under taskList draft rootNode (skipping tasks), and apply project node measurements
    const drawingNode = projectTreeNodesStore.selectedTreeNode.childDrawingNode;
    if (drawingNode) {
      const drawingNodeCopy = drawingNode.cloneDeep(batch, Globals.listDraftStores, uuidv4(), new Map<string, string>(), []);
      listDraftStores.treeNodesStore.batchAddEditItem(drawingNodeCopy, batch);
      listDraftStores.treeNodesStore.appendNode(drawingNodeCopy, tasksListDraftRootNode, 0, batch);
    }

    listDraftStores.treeNodesStore.applyMeasurementValues(
      tasksListDraftRootNode,
      uniqBy([
        ...tasksListDraftRootNode.ownMeasurementValuesArray,
        ...projectTreeNodesStore.selectedTreeNode.ownMeasurementValuesArray,
      ], measurementValue => getSafe(() => measurementValue.measurement.id)),
      false,
      batch
    );

    batch.isUndoable = false;
    batch.commit();
  }

  // add tasks to project
  addToProject = async () => {
    const { dialogId, tasksList } = this.props;
    const { dialogsStore, treeNodesStore } = this.context;

    await copyTasksFromListToProject(
      tasksList,
      treeNodesStore.selectedTreeNode,
      this.context,
    );

    this.handleClose(true);
    dialogsStore.hideDialog(dialogId);
  }

  _render() {
    const { tasksListsStore, treeNodesStore } = Globals.defaultStores;
    const { treeNodesStore: tasksListTreeNodesStore, tasksStore: tasksListTasksStore } = Globals.listDraftStores;
    const { open, dialogId, tasksList } = this.props;

    const tasksListCopy = tasksListsStore.itemsMap.get(this.tasksListDraftId);

    return (tasksListCopy && tasksListTreeNodesStore.rootNode)
      ? (
        <EditDialogComponent
          dialogId={dialogId}
          open={open}
          models={[tasksList]}
          modelCopies={[tasksListCopy]}
          onClose={this.handleClose}
          FormComponent={TasksListDetailsForm}
          FormComponentProps={{
            tasksListDraftId: this.tasksListDraftId,
            tasksListId: this.tasksListId
          }}
          extraButtons={
            <div className={styles.addButton}>
              <Button onClick={this.addToProject} color="primary" variant="contained">
                {i18n.t(
                  'Add {{count}} task(s) to "{{-selectedNodeName}}"', {
                  selectedNodeName: getSafe(() => treeNodesStore.selectedTreeNode.name),
                  count: tasksListTasksStore.selectedItems.size || tasksListTreeNodesStore.rootNode.ownTasks.length
                })}
              </Button>
            </div>
          }
        />
      ) : null; // loading
  }
}