import firebase from 'firebase/compat/app';
import Globals from 'Globals';
const fs = firebase.firestore();

export class ReusableBatchMutation {
  constructor(
    public doc: firebase.firestore.DocumentReference,
    public data: any,
    public options?: any
  ) { }
}

export class ReusableBatch {
  mutations = [] as ReusableBatchMutation[];

  commit(reverse = false): Promise<void> {
    const firestoreBatch = fs.batch();

    (reverse ? this.mutations.slice(0).reverse() : this.mutations).forEach(mutation => {
      // update cache right away because when delete is received from db,
      // app will try to check lower cascade in cache  (ex undo add item from project, item refresh from project then master collection)
      Globals.defaultStores.dbCacheStore.cache.set(mutation.doc.path, mutation.data);

      if (mutation.data) {
        firestoreBatch.set(mutation.doc, mutation.data, mutation.options);
      } else {
        firestoreBatch.delete(mutation.doc);
      }
    });

    return firestoreBatch.commit();
  }
}
