
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FilledInput, FormControl, InputLabel, MenuItem, Select, Switch } from '@material-ui/core';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { AdjustmentType } from 'constants/AdjustmentType';
import Measurement from 'models/Measurement';
import MeasurementValue from 'models/MeasurementValue';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { handleChange } from 'utils/FormUtils';
import { InputNumberFormatDecimal } from 'utils/NumberFormatter';
import i18n from 'utils/i18n';
import TextField from '../TextField/TextField';

const styles = require('./AdjustmentFormItem.module.scss');

interface AdjustmentFormItemProps {
  shortLabel: string | JSX.Element,
  longLabel: string | JSX.Element,
  treeNode: TreeNode,
  measurement: Measurement,
  model: MeasurementValue | Task
}

interface AdjustmentFormItemState {
  areAdjustementsExpanded: boolean,
}

export default class AdjustmentFormItem extends ObserverComponent<AdjustmentFormItemProps> {
  state = {
    areAdjustementsExpanded: false,
  }

  handleAdjustmentsExpandedChange = (event, expanded: boolean) => {
    const { model } = this.props;
    this.setState({ areAdjustementsExpanded: expanded });

    if (!expanded && model.adjustment) {
      model.adjustment.value = 0;
      model.adjustment.subtype = AdjustmentType.Add;
    } else {

    }
  }

  _render() {
    const { model, measurement, longLabel, shortLabel } = this.props;
    const areAdjustementsExpanded = this.state.areAdjustementsExpanded || !!model?.adjustmentValue || model.adjustmentType === AdjustmentType.Multiply;

    return (
      <ExpansionPanel
        className={styles.expansionPanel}
        expanded={areAdjustementsExpanded}
        onChange={this.handleAdjustmentsExpandedChange}
      >
        <ExpansionPanelSummary className={styles.adjustmentsTitle}>
          <Switch checked={areAdjustementsExpanded} />
          <span className={classnames({[styles.isActive]: areAdjustementsExpanded})}>
            {areAdjustementsExpanded ? longLabel: shortLabel}
          </span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.expansionPanelContent}>
          <div className={styles.row}>
            <FormControl className={styles.textField} margin="normal">
              <InputLabel htmlFor="filled-age-simple">{i18n.t('Adjustment Type')}</InputLabel>
              <Select
                value={model.adjustmentType}
                onChange={handleChange(model, 'adjustmentType')}
                input={<FilledInput name="adjustementType" />}
              >
                <MenuItem value={AdjustmentType.Add}>{i18n.t('Add')} (+)</MenuItem>
                <MenuItem value={AdjustmentType.Subtract}>{i18n.t('Subtract')} (-)</MenuItem>
                <MenuItem value={AdjustmentType.Multiply}>{i18n.t('Multiply')} (x)</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="quantity"
              label={i18n.t("Adjustment Value")}
              className={styles.textField + ' ' + styles.tableValueQuantity}
              value={model.adjustmentType === AdjustmentType.Multiply
                ? model.adjustmentValue / 100
                : model.adjustmentValue
              }
              onChange={model.adjustmentType === AdjustmentType.Multiply
                ? (event) => handleChange(model, 'adjustmentValue')({value: (event?.value || event?.target?.value || 0) * 100})
                : handleChange(model, 'adjustmentValue')
              }
              shouldSelectAllOnFocus
              InputProps={{
                inputComponent: model.adjustmentType === AdjustmentType.Multiply
                  ? InputNumberFormatDecimal
                  : measurement.inputFormatter,
              }}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}