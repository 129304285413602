import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RectangleIcon from '@material-ui/icons/Crop169';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import NewIcon from '@material-ui/icons/Star';
import ObserverComponent from 'components/common/ObserverComponent';
import { MeasurementType } from 'constants/MeasurementType';
import { isEmpty, uniqBy } from 'lodash';
import { observer } from 'mobx-react';
import Dialog from 'models/Dialog';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { SizeMe } from 'react-sizeme';
import { askToDeleteDrawing } from 'utils/DeleteUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import uuidv4 from 'uuid/v4';
import DrawingCopyDialog from '../DrawingCopyDialog/DrawingCopyDialog';
import DrawingEditDialog from '../DrawingEditDialog/DrawingEditDialog';
import RectangularDrawingOptions from '../RectangularDrawingOptions/RectangularDrawingOptions';
import ShapePreview from '../ShapePreview/ShapePreview';

const styles = require('./DrawingButton.module.scss');

export default class DrawingButton extends ObserverComponent {
  showNewDrawingDialog = () => {
    const { dialogsStore } = this.context;

    const newDialog = new Dialog(this.context);
    newDialog.name = 'drawing';
    newDialog.dialogComponent = observer(({ open }) => (
      <DrawingEditDialog
        open={open}
        dialogId={newDialog.id}
      />
    ));

    // force close other dialogs that sometimes get stuck and make weird lines
    dialogsStore.visibleDialogs.filter(dialog => dialog.name == 'drawing').forEach(dialog => {
      dialogsStore.hideDialog(dialog.id);
    })

    dialogsStore.showDialog(newDialog);
    //drawToolsStore.shouldShowDrawingButtonOptions = false;
  }

  showCopyDrawingDialog = () => {
    const { treeNodesStore, dialogsStore, drawToolsStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    const onConfirm = (sourceNode: TreeNode) => {
      const { treeNodesStore } = this.context;
      const { selectedTreeNode } = treeNodesStore;
      const batch = firestoreBatch();

      const nodeCopy = sourceNode.childDrawingNode.cloneDeep(batch, this.context, uuidv4(), new Map<string, string>(), []);

      nodeCopy.ownMeasurementValues.clear();
      nodeCopy.children.forEach(node => {
        node.ownMeasurementValues.clear();
      })

      treeNodesStore.batchAddEditItem(nodeCopy, batch);
      treeNodesStore.appendNode(nodeCopy, selectedTreeNode, 0, batch);
      treeNodesStore.applyMeasurementValues(
        selectedTreeNode,
        uniqBy([
          ...selectedTreeNode.ownMeasurementValuesArray,
          ...sourceNode.ownMeasurementValuesArray.filter(measurementValue => getSafe(() => (
            [MeasurementType.GeneralLength, MeasurementType.GeneralSurface].includes(measurementValue.measurement.id)
          ))),
        ], measurementValue => getSafe(() => measurementValue.measurement.id)),
        false,
        batch
      );

      batch.commit();
    }

    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <DrawingCopyDialog
        open={open}
        dialogId={newDialog.id}
        treeNode={selectedTreeNode}
        onConfirm={onConfirm}
      />
    )
    dialogsStore.showDialog(newDialog);
    drawToolsStore.shouldShowDrawingButtonOptions = false;
  }

  addButtonHandler = () => {
    const { drawToolsStore } = this.context;
    drawToolsStore.shouldShowDrawingButtonOptions = true;
  }

  showDimensionsInputs = () => {
    const { drawToolsStore } = this.context;
    drawToolsStore.shouldShowDrawingButtonOptions = false;
    drawToolsStore.shouldShowDrawingButtonMeasurements = true;
  }

  _render() {
    const { treeNodesStore, drawToolsStore, settingsStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    const { shouldShowDrawingButtonOptions } = drawToolsStore;
    const { shapes } = selectedTreeNode.childDrawingNode || {};

    return (
      <div className={styles.root}>
        <SizeMe monitorHeight monitorWidth refreshMode="debounce" refreshRate={300} noPlaceholder>
          {({ size }) => (
            <div className={styles.sizeMeContainer} style={{ position: 'relative', height: '100%', width: '100%' }}>
              <div style={{ position: 'absolute', width: '100%', height: '100%', padding: '8px' }}>
                {(!isEmpty(selectedTreeNode?.childDrawingNode?.shapes) || selectedTreeNode?.childDrawingNode?.backgroundImage)
                  ? (
                    <>
                      <ShapePreview
                        className={styles.clickable}
                        onClick={this.showNewDrawingDialog}
                        treeNode={selectedTreeNode}
                        shapes={shapes}
                        shouldShowDimensions={shapes.length < 40}
                        shouldShowBackgroundImage
                        containerWidth={size.width}
                        containerHeight={size.height}
                      />

                      <IconButton className={styles.deleteIcon} onClick={() => askToDeleteDrawing(treeNodesStore.selectedTreeNode.childDrawingNode, treeNodesStore)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  ) : (
                    shouldShowDrawingButtonOptions ? (
                        <div className={styles.flexColumn}>
                          <Button onClick={this.showNewDrawingDialog} className={styles.button}>
                            <NewIcon />
                            {i18n.t('Create new drawing')}
                          </Button>

                          {treeNodesStore.rootNode?.descendants?.find(n => !isEmpty(n.shapes)) && (
                            <Button onClick={this.showCopyDrawingDialog} className={styles.button}>
                              <CopyIcon />
                              {i18n.t('Copy existing drawing')}
                            </Button>
                          )}

                          {settingsStore.isImperial && (
                            <Button
                              onClick={this.showDimensionsInputs}
                              className={styles.button}>
                              <RectangleIcon />
                              {i18n.t('Enter simple dimensions')}
                            </Button>
                          )}
                        </div>
                      ) : (
                        (drawToolsStore.shouldShowDrawingButtonMeasurements && settingsStore.isImperial) // todo metric
                          ? (
                            <div className={styles.flexColumn + ' ' + styles.rectangularOptions}>
                              <RectangularDrawingOptions />
                            </div>
                          ) : (
                            <div className={styles.flexColumn + ' ' + styles.clickable}>
                              <Button
                                onClick={this.addButtonHandler}
                                className={styles.button}>
                                <AddIcon />
                                {i18n.t('Add reference drawing')}
                              </Button>
                            </div>
                          )
                      )
                  )
                }
              </div>
            </div>
          )}
        </SizeMe>
      </div>
    )
  }
}