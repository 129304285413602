
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { IDialogProps } from 'constants/CommonProps';
import { noop } from 'lodash';
import * as React from 'react';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

const styles = require('./ConfirmDialog.module.scss');
const colors = require('../../../Colors.scss');

export interface IConfirmDialogProps extends IDialogProps {
  onConfirm?: () => void,
  onClose?: (shouldSave?: boolean) => void,
  title?: string | JSX.Element,
  content?: string | JSX.Element,
  actionButtonColor?: string,
  shouldHideCancel?: boolean,
  yesLabel?: string,
  noLabel?: string,
  extraButtons?: JSX.Element,
  isActionButtonDisabled?: boolean,
}

export default class ConfirmDialog extends ObserverComponent<IConfirmDialogProps> {
  static defaultProps = {
    onClose: noop,
  }

  actionButtonRef;

  onClose = (shouldSave?: boolean) => {
    const { dialogsStore } = this.context;
    const { dialogId, onConfirm, onClose } = this.props;

    if (shouldSave) {
      const retval = onConfirm();
      if (retval instanceof Promise) {
        retval.then(() => {
          onClose(shouldSave);
          dialogsStore.hideDialog(dialogId);
        });
        return;
      }
    }

    onClose(shouldSave);
    dialogsStore.hideDialog(dialogId);
  }

  componentDidMount() {
    setTimeout(() => this.actionButtonRef?.focus(), 0);
  }

  _render() {
    const { open, title, content: message, actionButtonColor = null, yesLabel, noLabel, extraButtons, shouldHideCancel, isActionButtonDisabled } = this.props;

    return (
      <Dialog
        className={styles.root}
        open={open}
        onClose={() => this.onClose(false)}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText component="div">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {extraButtons}
          {!shouldHideCancel && (
            <Button onClick={() => this.onClose(false)} color="primary">
              {noLabel || i18n.t('No')}
            </Button>
          )}
          <Button
            ref={ref => this.actionButtonRef = ref}
            onClick={() => this.onClose(true)}
            color="primary"
            variant="contained"
            style={actionButtonColor ? {
              backgroundColor: actionButtonColor,
              color: (actionButtonColor === colors.red || actionButtonColor === colors.primary || actionButtonColor === colors.action)
                ? colors.body
                : colors.text
            } : {}}
            disabled={isActionButtonDisabled}
          >
            {yesLabel || i18n.t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}