import i18n from "./i18n";

// language parameter required to make observable work in @computed that calls this
export const getDescriptionRichText = (language: string) => JSON.stringify({
  "blocks": [
    {
      "key": "3n42k",
      "text": i18n.t('Notes'),
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
  ],
  "entityMap": {}
});

export const getEmptyRichText = () => JSON.stringify({"blocks":[{"key":"3n42k","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}});

// language parameter required to make observable work in @computed that calls this
export const getDefaultGeneralTermsRichText = (language: string) => JSON.stringify({
  "blocks": [
    {
      "key": "3n42k",
      "text": i18n.t('Responsibilities of the Client'),
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [
        {
          "offset": 0,
          "length": i18n.t('Responsibilities of the Client').length,
          "style": "BOLD"
        }
      ],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "2slck",
      "text": i18n.t('Any modification to the present contract will be considered as extra and billed as extra') + '.',
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "6ntoo",
      "text": i18n.t("Any modification will have to be requested in writing. A purchase order will then be provided and sent to you for approbation. Once we receive your written approbation and a 50% deposit, the request will be executed. No verbal request will be accepted") + '.',
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "fm0c0",
      "text": i18n.t("If applicable, blasting is an extra and payable upon receipt of the invoice") + '.',
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "1g6v0",
      "text": i18n.t("Under no circumstances, should requests be made directly to employees or sub-contractors") + '.',
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "17b0u",
      "text": i18n.t("Any problem not initially visible and not included in this estimate is considered an extra") + '.',
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "dg30h",
      "text": i18n.t("Payment Schedule"),
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [
        {
          "offset": 0,
          "length": i18n.t("Payment Schedule").length,
          "style": "ITALIC"
        }
      ],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "ad92e",
      "text": i18n.t("There will be a 25% deposit due at signature"),
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "e9gv7",
      "text": i18n.t("Another 25% at mid-completion"),
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "5ir3e",
      "text": i18n.t("Last 50% payment at completion"),
      "type": "ordered-list-item",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
});


export const getDefaultSignatureHtmlText = (language: string) => `<p>${i18n.t('By signing this document, the customer agrees to the services and conditions outlined in this document.')}</p><p><br></p><p><strong>_______________________________             ___________________</strong></p><p>${i18n.t('Client\'s signature                                        Date')}</p>`;