import { Button } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import ProvidingItem from 'models/ProvidingItem';
import Task from 'models/Task';
import * as React from 'react';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { onProvidingItemDrop } from 'utils/ProvidingItemUtil';
import i18n from 'utils/i18n';
import { Avatar } from '../Avatar/Avatar';
import DroppableDiv from '../DroppableDiv/DroppableDiv';
import { HelmetIcon, WoodStackIcon } from '../Icons';
import { ListItemText } from '../ListItemText/ListItemText';
import ObserverComponent from '../ObserverComponent';

const styles = require('./EmptyMaterialComponent.module.scss');

interface IEmptyMaterialComponentProps {
  task: Task,
}

interface IEmptyMaterialComponentState {
  isCreatingItem: boolean,
}

export default class EmptyMaterialComponent extends ObserverComponent<IEmptyMaterialComponentProps> {
  state = {
    isCreatingItem: false,
  };

  askForNewItemType = () => {
    this.setState({ isCreatingItem: true });

    /*
    const { task } = this.props;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ProvidingItemTypeDialog open={open} dialogId={newDialog.id} parentTask={task} />
    )
    DialogsStore.showDialog(newDialog);
    */
  }

  createItem = (itemSubtype) => {
    const { providingItemsStore, tasksStore, userInfoStore } = this.context;
    const { task } = this.props;
    const { nonImpersonatedUser } = userInfoStore;

    const batch = firestoreBatch();

    if (itemSubtype) {
      const providingItem = new ProvidingItem(this.context);
      providingItem.subtype = itemSubtype;

      if (!nonImpersonatedUser.shouldAutoSyncProvidingItems) {
        providingItemsStore.saveToDb(
          [providingItem],
          providingItemsStore.collections.filter(collection => collection === providingItemsStore.projectCollection),
          undefined,
          batch
        );
      } else {
        providingItemsStore.batchAddEditItem(providingItem, batch);
      }

      task.providingItem = providingItem;
      tasksStore.batchAddEditItem(task, batch);

      batch.commit();

      providingItemsStore.providingItemBeingEdited = providingItem;
      tasksStore.taskBeingEdited = task;
    }
  }

  _render() {
    const { dragAndDropStore } = this.context;
    const { task } = this.props;
    const { isCreatingItem } = this.state;

    const isProvidingItem = dragAndDropStore.dragObject instanceof ProvidingItem;

    return (
      <DroppableDiv
        onDrop={onProvidingItemDrop(task)}
        shouldEnable={isProvidingItem}
      >
        {isCreatingItem
          ? (
            <div className={styles.flexColumn + ' ' + styles.hideDeleteIcon}>
              <div className={styles.createItemButtons}>
                <Button
                  variant="outlined"
                  onClick={() => this.createItem(ProvidingItemSubtype.Material)}
                  color="primary"
                >
                  <WoodStackIcon />
                  {i18n.t('Matériel')}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => this.createItem(ProvidingItemSubtype.Labour)}
                  color="primary"
                >
                  <HelmetIcon />
                  {i18n.t("Main d'oeuvre et frais")}
                </Button>

                <div style={{ width: '100%' }} />

                <Button
                  onClick={() => this.setState({ isCreatingItem: false })}
                >
                  {i18n.t("Annuler")}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Avatar>
                <ImageIcon />
              </Avatar>
              <div className={styles.flexColumn}>
                <ListItemText
                  secondary={i18n.t('Drag an item from the right sidebar here')}
                />
                <span className={styles.createItem} onClick={this.askForNewItemType}>
                  {i18n.t('or create a new item')}
                </span>
              </div>
            </>
          )}
      </DroppableDiv >
    )
  }
}