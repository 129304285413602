import { TreeViewExpandChangeEvent } from '@progress/kendo-react-treeview';
import Globals from 'Globals';
import WorksheetsTreeNode from 'components/common/WorksheetsTree/WorksheetsTreeNode';
import { DrawToolType } from 'constants/DrawToolType';
import { isEmpty, remove, uniqBy } from 'lodash';
import { action } from 'mobx';
import Line from 'models/Line';
import Surface from 'models/Surface';
import TreeNode from 'models/TreeNode';
import React from 'react';
import Stores from 'stores/Stores';
import { segmentsGroupNodeNameByType, segmentsNodeNameByType, surfaceNodeNameByType } from './DrawToolsUtils';
import firestoreBatch from './FirestoreBatchUtil';
import { trySetTimeout } from './Utils';
import i18n from './i18n';
;

export const onExpandChange = (context: Stores, expandedField: keyof TreeNode = 'isExpanded') => (event: TreeViewExpandChangeEvent) => {
  const { treeNodesStore } = context;
  event.item[expandedField] = !event.item[expandedField];
  treeNodesStore.selectedTreeNode = event.item;
  //treeNodesStore.addEditItem(event.item);
  event.target.forceUpdate();
}

export const renderNode = (readonly: boolean, selectedField: keyof TreeNode = 'isSelected', shouldDisableFunction = (node: TreeNode) => false) => node => (
  <WorksheetsTreeNode node={node} readonly={readonly} selectedField={selectedField} shouldDisableFunction={shouldDisableFunction} />
)

export const addChildNode = action((parentNode: TreeNode, shouldAddAtTheEnd = true): TreeNode => {
  const { treeNodesStore, measurementsStore, commonStore } = parentNode.stores;
  if (commonStore.isBigUpdateOngoing) {
    commonStore.isBigUpdateOngoing = false;
  }
  
  const isDrawingTree = parentNode.stores === Globals.drawingStores;

  const newNode: TreeNode = new TreeNode(parentNode.stores);
  newNode.name = isDrawingTree ? i18n.t('Element') : i18n.t('Location');
  newNode.isExpanded = true; // workaround ish, to be able to drag into empty group
  newNode.isSelected = true;
  // ugly hack... for now everything under root node in drawing mode is bubble up
  newNode.shouldBubbleMeasurements = parentNode.stores === Globals.drawingStores
    ? true
    : parentNode.shouldBubbleMeasurements;

  const batch = firestoreBatch();
  treeNodesStore.batchAddEditItem(newNode, batch);
  treeNodesStore.appendNode(newNode, parentNode || treeNodesStore.rootNode, shouldAddAtTheEnd ? undefined : 0, batch);

  batch.commit();
  treeNodesStore.selectedTreeNodes = [newNode];
  treeNodesStore.treeNodeBeingRenamed = newNode;
  trySetTimeout(() => {
    treeNodesStore.treeComponentRef.scrollTo({ key: newNode.id });
  }, 0)

  return newNode;
});

export const addSiblingNode = action((node: TreeNode): TreeNode => {
  const { treeNodesStore, measurementsStore } = node.stores;
  const isDrawingTree = node.stores === Globals.drawingStores;

  const newNode: TreeNode = new TreeNode(node.stores);
  newNode.name = isDrawingTree 
    ? i18n.t('Element') 
    : i18n.t('Location');
  newNode.isSelected = true;
  // ugly hack... for now everything under root node in drawing mode is bubble up
  newNode.shouldBubbleMeasurements = node.stores === Globals.drawingStores
    ? true
    : node.shouldBubbleMeasurements;

  const batch = firestoreBatch();
  treeNodesStore.batchAddEditItem(newNode, batch);
  treeNodesStore.appendNode(newNode, node.parent, node.parent.children.findIndex(childNode => childNode === node) + 1, batch);

  batch.commit();
  treeNodesStore.selectedTreeNodes = [newNode];
  treeNodesStore.treeNodeBeingRenamed = newNode;
  trySetTimeout(() => {
    treeNodesStore.treeComponentRef.scrollTo({ key: newNode.id });
  }, 0)

  return newNode;
});



export const findCommonPath = (treeNodes: TreeNode[]): string => {
  treeNodes = uniqBy(treeNodes, 'id');

  const treeNodesStore = Globals.defaultStores.treeNodesStore;

  if (treeNodes.length === 0) {
    return treeNodesStore.rootNode?.name;
  }

  if (treeNodes.length === 1) {
    return treeNodes[0].path;
  }

  let commonAncestorsIds = [];
  treeNodes.forEach(treeNode => {
    if (commonAncestorsIds.length === 0) {
      commonAncestorsIds = treeNode.ancestors.map(n => n.id);
    }
    commonAncestorsIds.forEach(ancestorId => {
      if (!treeNode.ancestors.map(n => n.id).includes(ancestorId)) {
        remove(commonAncestorsIds, n => n.id === ancestorId);
      }
    });
  })

  commonAncestorsIds.pop(); // remove root node

  return commonAncestorsIds.reverse().map(id => treeNodesStore.getItem(id)?.name || '').join(' > ');
}

export const compressDrawingShapes = async (node: TreeNode) => {
  const { treeNodesStore, routerStore } = node.stores;

  const nodesToSave = new Set<TreeNode>();
  const nodesToDelete = new Set<TreeNode>();
  const batch = firestoreBatch();

  if (isEmpty(node.shapes) && isEmpty(node.shapesIds)) {
    return null;
  }

  // counters cannot be imploded
  // simple case, no counters
  if (routerStore.isRescueMode) {
    node.ownShapesIdsArray = node.shapesIds;
  } else {
    node.ownShapes = node.shapes.filter(shape => shape instanceof Surface || shape instanceof Line);
    node.ownShapes.forEach(shape => shape.treeNode = node);
  }

  node.children.forEach(childNode => {
    if (!childNode.shapes.find(shape => shape.type === 'CountPoint')) {

      if (childNode.ownShapesIds.size > 0) {
        childNode.ownShapesIds.clear();
      }

      childNode.descendants.forEach(childDescendant => {
        if (childDescendant.ownShapesIds.size > 0) {
          childDescendant.ownShapesIds.clear();
        }
      });
      treeNodesStore.deleteNodeAndDescendants(childNode, true, batch);
    }
  });

  if (!routerStore.isRescueMode) {
    // with counters
    node.descendants
      .filter(descendant => (
        descendant.shape instanceof Surface ||
        descendant.shape instanceof Line
      ))
      .forEach(descendant => {
        remove(descendant.parent.childrenIds, childNodeId => childNodeId === descendant.id)
        nodesToSave.add(descendant.parent);
        nodesToDelete.add(descendant);
      });

    // not perfect, should be recursive, but enough for now

    node.children.forEach(childNode => {
      if (isEmpty(childNode.shapes)) {
        [childNode, ...childNode.descendants].forEach(descendant => {
          nodesToSave.delete(descendant);
          nodesToDelete.add(descendant);
        })

        remove(node.parent.childrenIds, childNodeId => childNodeId === childNode.id);
      }
    })
  }

  nodesToSave.add(node);

  treeNodesStore.deleteItems([...nodesToDelete].map(node => node.id), true, batch);
  treeNodesStore.addEditItems([...nodesToSave], true, ['childrenIds'], batch);

  await batch.commit();
}

export const expandDrawingShapes = async (node: TreeNode) => {
  const { treeNodesStore } = node.stores;

  const batch = firestoreBatch();

  node.ownShapes.filter(shape => shape instanceof Surface)
    .forEach((surface, i) => {

      const surfaceNode = new TreeNode(node.stores);
      surfaceNode._name = i18n.tAll(surfaceNodeNameByType[DrawToolType.GeneralDraw], { number: i + 1 });
      surfaceNode.shape = surface;

      treeNodesStore.batchAddEditItem(surfaceNode, batch);
      treeNodesStore.appendNode(
        surfaceNode,
        node,
        undefined,
        batch,
      );
    });

  const linesNode = new TreeNode(node.stores);
  linesNode._name = i18n.tAll(segmentsGroupNodeNameByType[DrawToolType.GeneralDraw]);

  node.ownShapes.filter(shape => shape instanceof Line)
    .forEach((line, i) => {

      const lineNode = new TreeNode(node.stores);
      lineNode._name = i18n.tAll(segmentsNodeNameByType[DrawToolType.GeneralDraw], { number: i + 1 });
      lineNode.shape = line;

      treeNodesStore.batchAddEditItem(lineNode, batch);
      treeNodesStore.appendNode(
        lineNode,
        linesNode,
        undefined,
        batch,
      );
    });

  treeNodesStore.appendNode(
    linesNode,
    node,
    undefined,
    batch,
  );

  node.ownShapes = [];

  batch.commit();

  treeNodesStore.batchAddEditItem(node, batch);
}