import IconButton from '@material-ui/core/IconButton';
import TreeIcon from '@material-ui/icons/AccountTreeOutlined';
import GroupAddIcon from '@material-ui/icons/AddCircleOutline';
import classnames from 'classnames';
import * as React from 'react';
import { addChildNode } from 'utils/TreeUtil';
import i18n from 'utils/i18n';
import { CollapseAllIcon, ExpandAllIcon } from '../Icons';
import ObserverComponent from '../ObserverComponent';

const styles = require('./WorksheetsTreeHeader.module.scss');

interface WorksheetsTreeHeaderProps {
  icon?: JSX.Element,
  title?: string,
}

export default class WorksheetsTreeHeader extends ObserverComponent<WorksheetsTreeHeaderProps> {
  _render() {
    const { treeNodesStore } = this.context;
    let {icon, title} = this.props;

    icon = icon || <TreeIcon />;
    title = title || i18n.t('Project Composition');

    const isDrawingTree = this.context.name !== 'defaultStores';

    return treeNodesStore.drawingCopyTargetNode
      ? (
        <div></div>
      ) : (
        <>
          <div className={styles.title}>
            {icon}
            <div>{title}</div>
          </div >
          <div className={styles.buttons}>
            {!isDrawingTree && treeNodesStore.rootNode && treeNodesStore.rootNode.nonDrawingChildren.some(childNode => childNode.hasNonDrawingRootChildren) && (
              <IconButton
                className={classnames(
                  styles.button,
                  styles.expandAllButton,
                )}
                onClick={treeNodesStore.expandOrCollapseAllNodes}
              >
                {treeNodesStore.areAllNodesCollapsed ? <ExpandAllIcon /> : <CollapseAllIcon />}
              </IconButton>
            )}

            {<IconButton className={styles.button} onClick={() => addChildNode(treeNodesStore.rootNode)}>
              <GroupAddIcon />
              {/*i18n.t('Add a group')*/}
            </IconButton>}
          </div>
        </>
      );
  }
}
