
import { Button, ClickAwayListener, IconButton } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { Unit } from 'constants/Unit';
import { get as lget, set as lset } from 'lodash';
import { observer } from 'mobx-react';
import MeasurementValue from 'models/MeasurementValue';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import FirebaseStore from 'stores/FirebaseStore';
import MeasurementConverter from 'utils/MeasurementConverter';
import MeasurementFormatter from 'utils/MeasurementFormatter';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import TextField from '../TextField/TextField';

const styles = require('./Editable.module.scss');

interface IEditableProps {
  canChangeUnit?: boolean,
  className?: string,
  classNameEditMode?: string,
  displayComponent?: JSX.Element,
  editLabel?: string,
  editModeComponent?: (renderProps: { modelCopy: any }) => JSX.Element,
  formatFunction?: (any) => string | JSX.Element,
  InputClassName?: string,
  inputNumberFormatter?: (props: any) => JSX.Element,
  isEditable?: boolean,
  isNumber?: boolean,
  label?: string | JSX.Element,
  model: ModelBase,
  onDetailsClick?: () => void,
  onEditModeChange?: (isEditMode: boolean) => void,
  onSave?: (model: ModelBase) => void,
  afterSave?: (model: ModelBase) => void,
  propertyName: string,
  shouldEditOnMount?: boolean,
  store?: FirebaseStore<ModelBase>,
}

interface IEditableState {
  // this will only copy the model, but if it has references to other models they won't be copied
  // e.g. ProvidingItem is copied, but Category will not. So far, it's not a problem
  modelCopy: ModelBase,
}

// alternative name PropertyEditor
@observer
export default class Editable extends React.Component<IEditableProps, IEditableState> {
  state = {
    modelCopy: null,
    isEditMode: false,
  }

  static defaultProps = {
    isEditable: true,
  }

  componentWillUnmount() {
    const { onEditModeChange } = this.props;
    onEditModeChange?.(false);
    
    super.componentWillUnmount?.();
  }

  componentDidMount() {
    const { shouldEditOnMount, isEditable } = this.props;
    if (shouldEditOnMount && isEditable) {
      this.enterEditMode();
    }
  }

  enterEditMode = (event?: MouseEvent) => {
    if (!this.props.isEditable) {
      return;
    }

    if ((event?.target as HTMLElement)?.tagName?.toLowerCase() === 'button') {
      return;
    }

    const { model, onEditModeChange } = this.props;
    const modelCopy = model.clone();

    this.setState({
      modelCopy,
      isEditMode: true,
    }, () => onEditModeChange && onEditModeChange(true)
    );
  }

  exitEditMode = () => {
    const { onEditModeChange } = this.props;
    this.setState({
      modelCopy: null,
      isEditMode: false,
    }, () => onEditModeChange && onEditModeChange(false)
    );
  }

  saveChanges = () => {
    const { store, onSave, afterSave, isNumber, propertyName, model } = this.props;
    const { modelCopy } = this.state;

    const value = lget(modelCopy, propertyName);
    if (isNumber && !value) {
      // avoid setting NaN and breaking totals calculations
      lset(modelCopy, propertyName, isNumber ? (parseFloat(value) || 0) : value);
    }

    if (value !== model[propertyName]) {
      if (onSave) {
        onSave(modelCopy);
      }
  
      if (store) {
        store.addEditItem(modelCopy);
      }
  
      if (afterSave) {
        afterSave(store.getItem(modelCopy.id));
      }  
    }

    this.exitEditMode();
  }

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.saveChanges();
    } else if (e.key === 'Escape') {
      this.exitEditMode();
    }
  }

  handleChangeFromEvent = (event) => {
    this.handleChange(event.target);
  }

  handleChange = ({ value }) => {
    const { propertyName, isNumber } = this.props;
    const { modelCopy } = this.state;

    if (propertyName.includes('.')) {
      // note: lodash set doesn't trigger mobx computed set
      lset(modelCopy, propertyName, isNumber ? parseFloat(value) : value);
    } else {
      modelCopy[propertyName] = isNumber ? parseFloat(value) : value;
    }
  }

  handleMeasurementChange = (event) => {
    const { target } = event;
    let { value } = target;
    const { propertyName, isNumber } = this.props;
    const { modelCopy } = this.state;
    const measurementValue = modelCopy[propertyName] as MeasurementValue;
    const { measurement } = measurementValue;

    measurementValue.formula = '' + MeasurementConverter.convert(parseFloat(value), measurement.displayUnit, Unit.DefaultMetric);

    modelCopy[propertyName] = measurementValue;
  }

  getInputComponent() {
    const { modelCopy } = this.state;
    const { propertyName, editLabel, InputClassName, inputNumberFormatter, editModeComponent } = this.props;

    if (editModeComponent) {
      return editModeComponent({ modelCopy });
    }

    let value = getSafe(() => lget(modelCopy, propertyName));
    let onChange = this.handleChangeFromEvent;
    if (value instanceof MeasurementValue) {
      value = MeasurementFormatter.getValueWithoutUnit(value);
      onChange = this.handleMeasurementChange;
    }

    return (
      <TextField
        label={editLabel}
        shouldFocusOnMount
        className={styles.textField}
        value={value}
        onChange={onChange}
        InputProps={{
          className: InputClassName,
          inputComponent: inputNumberFormatter,
        }}
        margin="none"
      />
    );
  }

  render() {
    const { isEditMode } = this.state;
    const { model, propertyName, onDetailsClick, className, classNameEditMode, displayComponent, formatFunction, label, isEditable } = this.props;

    const value = model[propertyName];

    return isEditMode ? (
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.saveChanges}>
        <div className={styles.rootEdit + ' ' + classNameEditMode} onKeyDown={this.onKeyPress}>
          {this.getInputComponent()}
          <div className={styles.editTools} >
            {onDetailsClick && <Button className={styles.detailsButton} onClick={onDetailsClick}>{i18n.t('Details')}</Button>}
            <div className={styles.flexSeparator} />
            <IconButton className={styles.cancelButtonIcon} onClick={this.exitEditMode}><CancelIcon /></IconButton>
            <IconButton className={styles.acceptButtonIcon} onClick={this.saveChanges}><AcceptIcon /></IconButton>
          </div>
        </div>
      </ClickAwayListener >

    ) : (
      <div
        className={classnames(styles.root, className, { [styles.isEditable]: isEditable })}
        onClick={this.enterEditMode}
      >
        {displayComponent || (<>
          <div className={styles.label}>{label}</div>
          <div className={styles.value}>{formatFunction ? formatFunction(value) : value}</div>
        </>)}
      </div>
    )
  }
}