import Globals from 'Globals';
import { DrawToolType } from 'constants/DrawToolType';
import { compact } from 'lodash';
import { computed, observable } from "mobx";
import Line from "models/Line";
import Point from "models/Point";
import BaseStore from './BaseStore';

const SNAP_THRESHOLD = 10; // make unit more meaningful
const SNAP_EXPIRATION = 6 * 1000; // remove snap point if not using it after a while (except for first point and previous point)

export default class SnapStore extends BaseStore {
  // extra points to snap except for first point and previous point
  @observable _snapPointsMap = new Map<string /* serialized point */, number /* date */>();

  snapPointsUpdateTimer = null;

  @computed get snapPoints(): Point[] {
    return Array.from(this._snapPointsMap.keys()).map(serializedPt => {
      const pt = JSON.parse(serializedPt);
      return new Point(pt.x, pt.y);
    });
  }

  @observable _cursorPosition: Point;

  @computed get firstPoint(): Point {
    const { drawToolsStore } = this.stores;
    return drawToolsStore.segmentsGroupBeingAdded?.shapes?.[0]?.points?.[0]?.clone();
  }

  @computed get cursorPosition(): Point {
    return this._cursorPosition;
  }

  set cursorPosition(value: Point) {
    this._cursorPosition = value;

    this.updateSnapPoints();
  }

  @computed get snapThreshold(): number {
    const { shapesStore } = this.stores;
    return SNAP_THRESHOLD / shapesStore.zoomController.observableScale;
  }

  @computed get snapPoint(): Point {
    const { userInfoStore, drawToolsStore } = this.stores;

    if (!this.cursorPosition) {
      return null;
    }

    let snapPoint: Point = null;

    const { shapesStore } = this.stores;

    shapesStore.points.forEach((potentialSnapPt: Point) => {
      if (!potentialSnapPt.isSnappable) {
        return;
      }

      if (!userInfoStore.user?.isDrawingSnapPointEnabled && potentialSnapPt !== drawToolsStore.firstPt) {
        return;
      }

      const distance = potentialSnapPt.distancePixels(this.cursorPosition);
      const prevDistance = snapPoint ? snapPoint.distancePixels(this.cursorPosition) : null;
      if (distance < this.snapThreshold * 2) {
        if (!this.snapPoints.find(pt => pt.x == potentialSnapPt.x && pt.y == potentialSnapPt.y)) {
          this.snapPoints.push(potentialSnapPt.clone());
        }

        if (prevDistance === null || distance < prevDistance) {
          snapPoint = potentialSnapPt.clone();
        }
      }
    });

    return snapPoint;
  }

  // TODO possible to merge these 2 methods together
  @computed get snapLineHorizontal(): Line {
    if (
      !this.cursorPosition ||
      Globals.drawingStores.treeNodesStore.rootNode?.satelliteImageUrl ||
      !this.stores.userInfoStore.user?.isDrawingSnapPointEnabled
    ) {
      return null;
    }

    const { drawToolsStore } = this.stores;

    let snapPointY: Point = null;

    compact([this.firstPoint, drawToolsStore.lineBeingAdded?.startPt, ...this.snapPoints]).forEach((potentialSnapPt: Point) => {
      if (!potentialSnapPt.isSnappable) {
        return;
      }

      const distanceY = Math.abs(potentialSnapPt.y - this.cursorPosition.y);
      const prevDistanceY = snapPointY ? Math.abs(snapPointY.y - this.cursorPosition.y) : null;

      if (
        distanceY < this.snapThreshold * 2 &&
        prevDistanceY === null || distanceY < prevDistanceY
      ) {
        snapPointY = potentialSnapPt.clone();
      }
    });

    return snapPointY && new Line(this.stores, snapPointY, new Point(this.cursorPosition.x, snapPointY.y));
  }

  @computed get snapLineVertical(): Line {
    if (
      !this.cursorPosition ||
      // not sure if global drawing store is required or could use this.stores
      Globals.drawingStores.treeNodesStore.rootNode?.satelliteImageUrl ||
      !this.stores.userInfoStore.user?.isDrawingSnapPointEnabled
    ) {
      return null;
    }

    const { drawToolsStore } = this.stores;

    let snapPointX: Point = null;

    compact([this.firstPoint, drawToolsStore.lineBeingAdded?.startPt, ...this.snapPoints]).forEach((potentialSnapPt: Point) => {
      if (!potentialSnapPt.isSnappable) {
        return;
      }

      const distanceX = Math.abs(potentialSnapPt.x - this.cursorPosition.x);
      const prevDistanceX = snapPointX ? Math.abs(snapPointX.x - this.cursorPosition.x) : null;

      if (
        distanceX < this.snapThreshold &&
        prevDistanceX === null || distanceX < prevDistanceX
      ) {
        snapPointX = potentialSnapPt.clone();
      }
    });

    return snapPointX && new Line(this.stores, snapPointX, new Point(snapPointX.x, this.cursorPosition.y));
  }

  updateSnapPoints = () => {
    const activePoints = compact([
      this.firstPoint, this.snapPoint, this.snapLineHorizontal?.startPt, this.snapLineVertical?.startPt
    ]).filter(pt => pt.isSnappable);

    // mark all active points
    activePoints.forEach(pt => {
      this._snapPointsMap.set(JSON.stringify(pt.toSimpleObject()), Date.now());
    });

    // remove old points
    this._snapPointsMap.forEach((expiration, serializedPt) => {
      if (Date.now() - SNAP_EXPIRATION > expiration) {
        this._snapPointsMap.delete(serializedPt);
      }
    });

    if (this.snapPointsUpdateTimer) {
      clearTimeout(this.snapPointsUpdateTimer);
      this.snapPointsUpdateTimer = null;
    }

    if (
      this.stores.drawToolsStore.selectedTool !== DrawToolType.Select &&
      this.snapPoints.length > 0
    ) {
      this.snapPointsUpdateTimer = setTimeout(this.updateSnapPoints, 500);
    } else {
      this._snapPointsMap.clear();
    }
  }
}
