import { FilledInput, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { IEditableProps, IReportProps } from 'constants/CommonProps';
import { ReportTasksFilter, ReportTotalsVisibility } from 'constants/ReportOptionsConstants';
import * as React from 'react';
import { isFilterAffectingTotal } from 'utils/ReportUtil';
import i18n from 'utils/i18n';
import ObserverComponent from '../ObserverComponent';

const styles = require('./ReportTasksFilterComponent.module.scss');

export default class ReportTasksFilterComponent extends ObserverComponent<IReportProps & IEditableProps> {
  changeType = ({ target: { value } }) => {
    const { reportsStore } = this.context;
    const { report } = this.props;
    report.tasksFilter = value;

    // hide task price because it won't add up to total
    // REMOVE THIS WHEN ALLOWING TO ATTACH MATERIAL TASKS TO ONE LABOUR TASK
    if (
      [ReportTasksFilter.HideLabour, ReportTasksFilter.HideMaterial].includes(report.tasksFilter) &&
      report.totalsVisibility === ReportTotalsVisibility.Task
    ) {
      report.totalsVisibility = ReportTotalsVisibility.Group;
    }

    reportsStore.addEditItem(report, true, ['_totalsVisibility', 'tasksFilter']);
  }

  _render() {
    const { isEditable, report } = this.props;

    return isEditable ? (
      <FormControl className={styles.root + ' ' + styles.isEditable} margin="none">
        <InputLabel>{i18n.t('Tasks Filter')}</InputLabel>
        <Select
          //disabled={report.tasksVisibility === ReportTasksVisibility.Hidden}
          value={report.tasksFilter}
          onChange={this.changeType}
          input={<FilledInput className={styles.input} />}
          startAdornment={isFilterAffectingTotal(report.tasksFilter) && (<Tooltip title={i18n.t('Total of this document will not be the total of the full project.')}><WarningIcon style={{ color: 'red' }} /></Tooltip>)}
        >
          {Object.values(ReportTasksFilter).map((type, index) => (
            <MenuItem key={index} value={type}>{i18n.t(type)}</MenuItem>
          ))}
          {/*<MenuItem value={'other'}>{i18n.t('Autre')}</MenuItem>*/}
        </Select>
      </FormControl>
    ) : (
      <div className={styles.root}>
        &nbsp;- {i18n.t(report.tasksFilter + (isFilterAffectingTotal(report.tasksFilter) ? 'Short' : ''))}
      </div>
    )
  }
}